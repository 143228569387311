import { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import Spinner from "../../../spinner/Spinner";
import CommentDetails from "../commentDetails/commentDetails";
import { IoMdAddCircleOutline } from 'react-icons/io';

export default function CommentCard(props) {
    const [score, setScore] = useState(0);
    const [content, setContent] = useState(JSON.parse(props.comment.content));
    const [homeLoad, setHomeLoad] = useState(false);
    const [publicLoad, setPublicLoad] = useState(false);

    useEffect(() => {
        let content = JSON.parse(props.comment.content);
        setContent(content);
        let total = 0;
        Object.values(content).map(array => Object.values(array).map(data => {
            data = parseInt(data);
            if (!isNaN(data)) {
                total += data;
            }
        }))

        setScore((total / 22).toFixed(1));
    }, [props.comment]);

    function homeClick() {
        if (props.comment.is_on_home) {
            props.RemoveforOnHome(props.comment.id, setHomeLoad)
        } else {
            props.forOnHome(props.comment.id, setHomeLoad)
        }
    }

    function publicClick() {
        props.forPublishOrUnPublish(props.comment.id, setPublicLoad);
    }

    let update_at = new Date(props.comment.updated_at.replace(/\s/, 'T'))
    var options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <Col sm={12} className='mb-3'>
            <Card className='shadowForCard'>
                <Card.Header>
                    <Card.Title>
                        <Row className='d-flex justify-content-around'>
                            {
                                props.displayButton ?
                                    <>
                                        <Col xs={12} md={6} className='d-flex justify-content-around mb-2'>
                                            {
                                                homeLoad ?
                                                    <Spinner />
                                                    :
                                                    <Button className='whiteSVG' variant='secondary' onClick={() => homeClick()}>
                                                        <IoMdAddCircleOutline /> {props.comment.is_on_home ? 'Retirer de l\'accueil' : 'Ajouter sur l\'accueil'}
                                                    </Button>
                                            }
                                        </Col>
                                        <Col xs={12} md={6} className='d-flex justify-content-around mb-2'>
                                            {
                                                publicLoad ?
                                                    <Spinner />
                                                    :
                                                    <Button className='whiteSVG' variant='secondary' onClick={() => publicClick()}>
                                                        <IoMdAddCircleOutline /> {props.comment.is_public ? 'Retirer du logement' : 'Ajouter sur le logement'}
                                                    </Button>
                                            }
                                        </Col>
                                    </>
                                    :
                                    <></>
                            }
                            <Col xs={12} sm={4} className='text-center'>
                                Logement : {props.comment.apartment_data.reference}
                            </Col>
                            <Col xs={12} sm={4} className='text-center'>
                                {props.comment.first_name + ' ' + props.comment.last_name}
                            </Col>
                        </Row>
                    </Card.Title>
                    <Row>
                        <Col xs={12} className='text-center'>
                            {`Créé le : ${update_at.toLocaleDateString('fr-CA', options)}`}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12} md={10}>
                            <Col xs={12}>
                                <Card.Title>
                                    Avis global :
                                </Card.Title>
                            </Col>
                            <Col xs={12}>
                                <p>{content.global}</p>
                            </Col>
                            <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                                <Card.Title>
                                    score général : {score} / 5
                                </Card.Title>
                            </Col>
                        </Col>
                        <Col xs={12} md={2} className='d-flex justify-content-center align-items-center'>
                            <CommentDetails comment={props.comment} score={score} setReload={props.setReload} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}