import React, { useState } from 'react';
import { Row, Col, Button, Badge, Image, Card, Modal } from 'react-bootstrap';
import InvoiceList from '../../invoice/invoiceList';
import BailLife from '../bail/bailLife';
import { AiFillEye, AiOutlineUser } from 'react-icons/ai';
import { IoIosMail } from 'react-icons/io';
import { FaPhoneAlt } from 'react-icons/fa';
import { FcHighPriority } from 'react-icons/fc';
import { TiDocumentAdd } from 'react-icons/ti'; 
import PaymentCard from './payment/paymentCard';
import OptionCard from './option/optionCard';
import CreateOrEditPayment from './payment/formulaire/createOrEditPayment';
import CreateOrEditOption from './option/formulaire/createOrEditOption';
import EditBooking from '../edit/editBooking';
import { useDispatch, useStore } from 'react-redux';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import Extend from './extend/extend';
import CheckInInformation from './checkIn/checkInInformation';
import CheckInKeys from './checkIn/checkInKeys';
import CheckInInventory from './checkIn/checkInInventory';
import CheckInPlaceState from './checkIn/checkInPlaceState';
import Spinner from '../../../spinner/Spinner';
import './bookingDetails.scss';
import '../bookingTab.scss';
import ListDoc from './checkIn/supplementaryDoc/listDoc';
import ReRent from './reRent/reRent';
import AskProForma from './proforma/askProFormat';
import GenerateInvoieOrBillRider from '../../invoice/generate/genrateInvoiceOrBillRider';

export default function BookingDetails(props) {
    const [visibility, setVisibility] = useState(false);
    const [generateIsSubmit, setGenerateIsSubmit] = useState(false);
    const [booking, setBooking] = useState(props.booking);
    const role = useStore().getState().header.role;
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();
    var correspondTarget = { rent: 'loyer', rate: 'tarif', commission: 'commission' };

    function handleChange(booking) {
        setBooking(booking);
        if (props.cardOnChange) {
            props.cardOnChange(booking);
        }
    }
    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function sendPersonalInformationMail(){
        setIsSubmit(true);

        instanceOfAxios.get('/admin/booking/' + booking.id + '/sendMailPersonalInformation', {
            headers: { 'Cache-Control': 'no-cache' },
            responseType: 'blob',
        })
            .then((res) => {
                dispatch(show({ message: "Mail d'enregistrement envoyée", variant: 'success' }));
                setIsSubmit(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Mail d\'enregistrement non envoyée', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    function sendWelcomeCard(){
        setIsSubmit(true);

        instanceOfAxios.get('/admin/booking/' + booking.id + '/sendWelcomeCard', {
            headers: { 'Cache-Control': 'no-cache' },
            responseType: 'blob',
        })
            .then((res) => {
                dispatch(show({ message: "Fiche de bienvenue envoyée", variant: 'success' }));
                setIsSubmit(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Fiche de bienvenue non envoyée', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    function returnBadgeState() {
        const stateColor = { ['no Show']: 'secondary', ['trop perçu']: 'danger', 'annulé': 'danger', ['à payer']: 'danger', payé: 'success', ['acompte versé']: 'secondary', ['en attente']: 'dark' }

        let color = stateColor[booking.state];
        let state = booking.state;

        if (!booking.is_confirmed && booking.state !== 'annulé') {
            color = 'warning';
            state = 'attente validation';
        }
        return <Badge variant={color}>{state}</Badge>
    }

    const beginDate = new Date(booking.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(booking.end_date_hour.replace(/\s/, 'T'));
    const created = new Date(booking.created_at.replace(/\s/, 'T'));
    // il veut pas le replace
    let rerent = booking.re_rent_at ? new Date(booking.re_rent_at.replace(/\s/, 'T')) : null;
    const today = new Date();
    let totalOptions = 0;

    booking.options.map(option => {
        switch (option.sign) {
            case '-':
                totalOptions -= option.value;
                break;
            case '+':
                totalOptions += option.value;
                break;
        }
    })

    let total = (booking.assurance + booking.tourism + booking.EDF + booking.cleaning + booking.commission + booking.rent + totalOptions).toFixed(2);

    return (
        <>
            <Button className="bookingDetails whiteSVG" onClick={() => setVisibility(true)} variant="secondary">
                <AiFillEye className='margin-eye' />
            </Button>
            {
                visibility &&
                <Modal
                    dialogClassName='custom-dialog'
                    show={visibility}
                    onHide={() => setVisibility(false)}
                    scrollable
                    backdropClassName="backdropThird"
                    centered
                    size='xl'
                    style={{ zIndex: '20002' }}
                    animation={true}>
                    <Modal.Header closeButton>
                        <Row className='noPadding' style={{ width: '100%' }}>
                            <Col xs={12} md={4} className='reducePadding'>
                                <Modal.Title>
                                    Réservation : {booking.reference}
                                </Modal.Title>
                            </Col>
                            {
                                booking.site_tiers &&
                                <Col className="m-auto reducePadding pb-2 pb-lg-0" xs={6} md={1}>
                                    <Badge className="apartmentText" variant={'info'}>{booking.site_tiers}</Badge>
                                </Col>
                            }
                            <Col className="d-flex justify-content-center m-auto" xs={6} md={2}>
                                {returnBadgeState()}
                            </Col>
                            <Col className="d-flex justify-content-center m-auto reducePadding pb-2 pb-lg-0" xs={6} md={2}>
                                {
                                    booking.bail.is_payed ?
                                        <Badge variant='success'>Caution payé</Badge>
                                        :
                                        <Badge variant='danger'>Caution à payer</Badge>
                                }
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center reducePadding" xs={6} md={2}>
                                <BailLife booking={booking} BookingDetailsOnChange={handleChange} />
                            </Col>
                            {
                                role === 'super-admin' || role === 'admin' ?
                                    <Col className="d-flex justify-content-center align-items-center reducePadding" xs={6} md={1}>
                                        <EditBooking booking={booking} handleChange={handleChange} />
                                    </Col>
                                    :
                                    <></>
                            }
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            booking.extend_for &&
                            <p className="text-center"> <FcHighPriority /> Extention de la réservation n° <strong>{booking.extend_for.reference}</strong></p>
                        }
                        <p className='mt-3 mb-3'>Details :</p>
                        <div className='reducePadding cartridge'>
                            <Row className='d-flex justify-content-center'>
                                <Col xs={12} md={6} lg={4} className='mt-2 mb-2'>
                                    <Row className='backGrayBorder shadowForCard'>
                                        <Col xs={8} className='noPadding'>
                                            <p className={total == booking.rate ? "apartmentText correctTotal" : "apartmentText wrongTotal"}>Tarif séjour :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className={total == booking.rate ? "apartmentTextAlignRight correctTotal" : "apartmentTextAlignRight wrongTotal"}> <strong>{(booking.rate).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Tarif par nuit :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{((booking.rate).toFixed(2) / getDifferenceInDays(beginDate, endDate)).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Déjà payé :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{(booking.amount_allready_payed).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Reste à payer :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className={(booking.rate - booking.amount_allready_payed).toFixed(2) > 0 ? "apartmentTextAlignRight wrongTotal" : "apartmentTextAlignRight correctTotal"}> <strong>{(booking.rate - booking.amount_allready_payed).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Options supplémentaires :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{totalOptions.toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Caution :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{booking.bail ? (booking.bail.amount).toFixed(2) : 0} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">acompte :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{booking.payments[0] && (booking.payments[0].amount).toFixed(2)} €</strong></p>
                                        </Col>
                                        {
                                            booking.site_tiers &&
                                            <>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Commission site tiers :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"> <strong>{booking.site_tiers_com && (booking.site_tiers_com).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Différence site tiers :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"> <strong>{booking.site_tiers_dif && (booking.site_tiers_dif).toFixed(2)} €</strong></p>
                                                </Col>
                                            </>
                                        }
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Nuitées :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{getDifferenceInDays(beginDate, endDate)}</strong></p>
                                        </Col>
                                        {
                                            booking.price_influencer_target ?
                                                <>
                                                    <Col xs={8} className='noPadding'>
                                                        <p className="apartmentText">{booking.price_influencer_value > 0 ? 'Majoration ' : 'Réduction '} {`${correspondTarget[booking.price_influencer_target]} `}:</p>
                                                    </Col>
                                                    <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                        <p className="apartmentTextAlignRight"> <strong>{booking.price_influencer_value} €</strong></p>
                                                    </Col>
                                                </>
                                                :
                                                <></>
                                        }
                                    </Row>
                                </Col>
                                <Col xs={12} md={6} lg={4} className='mt-2 mb-2'>
                                    <Row className='backGrayBorder shadowForCard'>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Loyer propriétaire : </p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{(booking.rent).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Commission (HT) : </p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{(booking.commission - booking.tva_value).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">TVA :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{(booking.tva_value).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Ménage : </p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{(booking.cleaning).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">EDF :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{(booking.EDF).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Taxe de séjour : </p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{(booking.tourism).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Assurance :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{(booking.assurance).toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Options supplémentaires :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> <strong>{totalOptions.toFixed(2)} €</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className={total == booking.rate ? "apartmentText correctTotal" : "apartmentText wrongTotal"}><strong>total :</strong></p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className={total == booking.rate ? "apartmentTextAlignRight correctTotal" : "apartmentTextAlignRight wrongTotal"}><strong>{total} €</strong></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={6} lg={4} className='d-flex align-items-start flex-column mt-2 mb-2'>
                                    <Row className='backGrayBorder shadowForCard mb-auto'>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Nombre de personnes : </p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{booking.nb_person}</strong></p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Nombre d'adultes : </p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"><strong>{booking.nb_adult}</strong></p>
                                        </Col>
                                        <Col xs={6} className='noPadding'>
                                            <p className="apartmentText">Début :</p>
                                        </Col>
                                        <Col xs={6} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight">{beginDate.toLocaleDateString()}</p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Fin :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> {endDate.toLocaleDateString()}</p>
                                        </Col>
                                        <Col xs={8} className='noPadding'>
                                            <p className="apartmentText">Créé :</p>
                                        </Col>
                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                            <p className="apartmentTextAlignRight"> {created.toLocaleDateString()}</p>
                                        </Col>
                                        {
                                            rerent &&
                                            <>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Ré-ouverture :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"> {rerent.toLocaleDateString()}</p>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} className='d-flex justify-content-center justify-content-md-start'>
                                    <ReRent booking={booking} BookingDetailsOnChange={handleChange} />
                                </Col>
                                <Col xs={6} className='d-flex justify-content-center justify-content-md-end'>
                                    {
                                        booking.extend_for == null &&
                                        <Extend booking={booking} BookingDetailsOnChange={handleChange} />
                                    }
                                </Col>
                            </Row>
                        </div>
                        <p className='mt-3 mb-3'>Contact :</p>
                        <div className='cartridge'>
                            {
                                booking.contact &&
                                <Row className='text-center d-flex justify-content-center'>
                                    <Col xs={12} md={6} lg={3} style={{ padding: '0' }}>
                                        <AiOutlineUser /><span className="apartmentText">{booking.contact.first_name + ' ' + booking.contact.last_name}</span>
                                    </Col>
                                    <Col xs={12} md={6} lg={2} style={{ padding: '0' }}>
                                        <span className='noReturn apartmentText'><FaPhoneAlt /><strong><a href={'tel:' + booking.contact.phone}> {booking.contact.phone}</a></strong></span>
                                    </Col>
                                    <Col xs={12} md={6} lg={4} style={{ padding: '0' }}>
                                        <span className='noReturn apartmentText'><IoIosMail /><strong><a href={'mailto:' + booking.contact.email}> {booking.contact.email}</a></strong></span>
                                    </Col>
                                    {
                                        booking.ref_airbnb &&
                                        <Col xs={12} md={6} lg={4} style={{ padding: '0' }}>
                                            <span className='noReturn apartmentText'>Référence site passerelle: <strong>{booking.ref_airbnb}</strong></span>
                                        </Col>
                                    }
                                </Row>
                            }
                        </div>
                        <p className='mt-3 mb-3'>Logement:</p>
                        <div className='cartridge'>
                            <Row className="align-items-center text-align-center">
                                <Col xs={1}>
                                    <Image loading='lazy' src={booking.apartment.principal_picture_url && booking.apartment.principal_picture_url.mini} fluid></Image>
                                </Col>
                                <Col xs={5} md={3}>
                                    {booking.apartment.reference}
                                </Col>
                                <Col xs={5} md={3}>
                                    {booking.apartment.title}
                                </Col>
                                <Col xs={12} md={5}>
                                    {booking.apartment.short_description}
                                </Col>
                            </Row>
                        </div>
                        <Row noGutters className='mb-5'>
                            <Col md={6} sm={12} className='pt-1 pr-1'>
                                <Row noGutters style={{ height: '50px' }} className='d-flex align-items-center'>
                                    <Col xs={11}>
                                        Options :
                                    </Col>
                                    {booking.state !== 'annulé' && (role === 'super-admin' || role === 'admin' || role === 'trainee') ?
                                        <Col xs={1} className="align-middle">
                                            <CreateOrEditOption for={'create'} booking={booking} BookingDetailsOnChange={handleChange} />
                                        </Col>
                                        :
                                        <></>}
                                </Row>
                                <div className="paymentScroll">
                                    {booking.options.map((option) => {
                                        return (<OptionCard key={option.id + option.value} booking={booking} option={option} BookingDetailsOnChange={handleChange} />)
                                    })}
                                </div>
                            </Col>
                            <Col md={6} sm={12} className='pt-1 pl-1'>
                                <Row noGutters style={{ height: '50px' }} className='d-flex align-items-center'>
                                    <Col xs={11} >
                                        Paiements :
                                    </Col>
                                    {booking.state !== 'annulé' && booking.state !== 'payé' && (role === 'super-admin' || role === 'admin' || role === 'trainee') &&
                                        <Col xs={1} className="align-middle">
                                            <CreateOrEditPayment for={'create'} payable={booking} booking={booking} BookingDetailsOnChange={handleChange} type={'booking'} />
                                        </Col>}
                                </Row>
                                <div className="paymentScroll">
                                    {booking.payments.map((payment) => {
                                        return (<PaymentCard type={'booking'} key={payment.id + payment.state} payable={booking} booking={booking} payment={payment} BookingDetailsOnChange={handleChange} />)
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                                <div className='separator'
                                    style={{
                                        border: '1px solid #B7A57E',
                                        color: '#b7a57e',
                                        width: '90%'
                                    }}></div>
                            </Col>
                            <Col className="reducePadding mt-2 mb-2" xs={12} lg={6}>
                                <Row>
                                    {
                                        generateIsSubmit ?
                                            <Spinner />
                                            :
                                            <Col className='d-flex justify-content-center mb-3' xs={12} lg={6}>
                                                <GenerateInvoieOrBillRider 
                                                invoiceUrl={'/admin/booking/' + booking.id + '/generateInvoice'}
                                                billRiderUrl={'/admin/booking/' + booking.id + '/generateBillRider'}
                                                reloadFrom={`/apartment/${booking.apartment_id}/booking/${booking.id}`}
                                                set={handleChange}/>
                                            </Col>
                                    }
                                    {
                                        booking.invoice_as_pro_forma == 1 &&
                                        <AskProForma url={`/admin/booking/${booking.id}/disableProForma`} handleChange={handleChange} />
                                    }
                                </Row>
                            </Col>
                            <Col xs={12} lg={7}>
                                <small>
                                    <ul>
                                        <li>
                                            En cliquant sur générer un facture vous créerez une nouvelle facture pro-forma.
                                        </li>
                                        <li>
                                            En cliquant sur Désactivation du pro forma ceci créeras une nouvelle facture.
                                        </li>
                                    </ul>
                                </small>
                            </Col>
                            <Col className="d-flex flex-column reducePadding mb-3" xs={12} lg={6}>
                                <InvoiceList invoices={booking.invoice_urls} />
                            </Col>
                            <Col className="reducePadding" xs={12} lg={6}>
                                <div className='cartridge'>
                                    <Row className='text-center'>
                                        <Col xs={12} className='noPadding'>
                                            <Row className='d-flex justify-content-center'>
                                                {
                                                    !booking.extend_for ?
                                                        <>
                                                            <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                                                                <CheckInKeys infos={booking} handleChange={handleChange} />
                                                            </Col>

                                                            <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                                                                <Button variant='secondary' className='whiteSVG' onClick={() => sendPersonalInformationMail()}>Envoyer le mail d'enregistrement</Button>
                                                            </Col>

                                                            <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                                                                <Button variant='secondary' className='whiteSVG' onClick={() => sendWelcomeCard()}>Envoyer la fiche de bienvenue</Button>
                                                            </Col>

                                                            {
                                                                getDifferenceInDays(today, beginDate) <= 2 && !booking.extend_for &&
                                                                <>
                                                                    <Col xs={12} md={4} className='d-flex justify-content-center'>
                                                                        <CheckInInventory infos={booking} />
                                                                    </Col>
                                                                    <Col xs={12} md={4} className='d-flex justify-content-center'>
                                                                        <CheckInPlaceState infos={booking} />
                                                                    </Col>
                                                                </>
                                                            }
                                                            {
                                                                booking.personal_information && booking.personal_information.is_complet ?
                                                                    <Col xs={12} md={4} className='reducePadding d-flex justify-content-center mt-2'>
                                                                        <CheckInInformation infos={booking.personal_information} />
                                                                    </Col>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                        :
                                                        <Col xs={12} className='d-flex justify-content-center'>
                                                            <p style={{ margin: 'unset' }}>Retrouvez les informations du check-in sur la réservation parents</p>
                                                        </Col>
                                                }
                                                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-2 mb-2'>
                                                    <div className='separator'
                                                        style={{
                                                            border: '1px solid #B7A57E',
                                                            color: '#b7a57e',
                                                            width: '90%'
                                                        }}></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className='d-flex justify-content-between align-items-center'>
                                            <ListDoc booking={booking} identity={booking.contact.identity_url} />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}