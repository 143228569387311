import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Tab, Tabs } from 'react-bootstrap'
import { ShowCreate } from '../apartmentSlice'
import ListType from './ListType';
import GlobalDocument from '../globalDocuments/GlobalDocument';

export default function ApartmentsList(props) {
    const dispatch = useDispatch();

    return (
        <div className={props.className} style={{ width: '100%' }}>
            <Row className='padding-row center auto-margin'>
                <Col xs={12}>
                    <h2>Gestion des logements</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
                <Col md="12" className='d-flex justify-content-center'>
                    <Button variant="secondary" className="btn-rounded button-margin-top" onClick={() => dispatch(ShowCreate())}>
                        Ajouter un logement
                    </Button>
                </Col>
            </Row>
            <Tabs defaultActiveKey="public" id="uncontrolled-tab-example">
                <Tab eventKey="public" title="Logements publiés">
                    <ListType url={'admin/apartments?page='} type='all'/>
                </Tab>
                <Tab eventKey="home" title="Logements sur la page d'accueil">
                    <ListType url={'admin/apartments/onHome?page='} type='home'/>
                </Tab>
                <Tab eventKey="toPublish" title="Logements à Publier">
                    <ListType url={'admin/apartments/toPublish?page='} type='toPublish'/>
                </Tab>
                <Tab eventKey="archive" title="Logements archivés">
                    <ListType url={'admin/apartments/archive?page='} type='archive'/>
                </Tab>
                <Tab eventKey="documents" title="Gestion des documents globaux">
                    <GlobalDocument/>
                </Tab>
            </Tabs>
        </div>)
}