import { createSlice } from '@reduxjs/toolkit';

export const ApartmentsListSlice = createSlice({
    name: 'apartmentsList',
    initialState: {
        typeListToReload: '',
        reload: false
    },
    reducers: {
        setReload: (state, value) => {
            state.reload = value.payload;
        },
        setTypeListToReload: (state, value) => {
            state.typeListToReload = value.payload
        }
    },
});

export const { setReload, setTypeListToReload } = ApartmentsListSlice.actions;


export const getReload = state => state.apartmentsList.reload;
export const getTypeToReload = state => state.apartmentsList.typeListToReload;

export default ApartmentsListSlice.reducer;
