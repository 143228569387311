import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { useDispatch } from 'react-redux';
import instanceOfAxios from '../../../app/axios/axios';
import { hide, show } from '../../admin/Alerter/alerterSlice';
import Spinner from '../../spinner/Spinner';

export default function AskConfirmCancel(props) {

    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const dispatch = useDispatch();

    let datas = [];

    if (datas.length < 1) {
        if (props.type === 'cancel') {
            datas['message'] = 'Vous êtes sur le point de refuser la réservation n°' + props.booking.reference + ', pour un loyer de ' + props.booking.rent + '€';
            datas['title'] = 'Annulation';
        } else {
            datas['message'] = 'Vous êtes sur le point de confirmer la réservation n°' + props.booking.reference + ', pour un loyer de ' + props.booking.rent + '€';
            datas['title'] = 'Confirmation';
        }
    }

    function getIcon() {
        if (props.type === 'cancel') {
            return <Button className='btn-rounded' variant="primary" onClick={() => setVisibility(!visibility)}><FcCancel /></Button>
        }
        return <Button className='btn-rounded' variant="primary" onClick={() => setVisibility(!visibility)}><FcCheckmark /></Button>
    }

    function sendAction() {
        setPending(true);
        instanceOfAxios.post('/apartment/' + props.booking.apartment.id + '/booking/' + props.booking.id + '/' + props.type)
            .then((res) => {
                let message = ' Confirmée'
                if (props.type === 'cancel') {
                    message = ' Annulé';
                }
                dispatch(show({ message: 'Réservation n°' + props.booking.reference + message, variant: 'success' }));
                props.handleChange(props.booking.id);
                setVisibility(false);
                setPending(false);
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
                setVisibility(false);
                setPending(false);
            })
    }

    return (
        <>
            {getIcon()}
            <Modal
                backdropClassName="backdrop"
                show={visibility}
                onHide={() => setVisibility(!visibility)}
                centered
                style={{ zIndex: '1000000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{datas.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{datas.message}</p>
                    {
                        props.type == 'cancel' &&
                        <ul>
                            <li>un refus de réservation implique des conséquences tels que :
                                <ul>
                                    <li>Relocation du voyageur</li>
                                    <li>Frais d'annulation</li>
                                    <li>Déclassement de votre annonce</li>
                                    <li>Commentaire négatif</li>
                                </ul>
                            </li>
                        </ul>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        pending ?
                            <Spinner />
                            :
                            <>
                                <Button variant="success" onClick={() => sendAction()}>
                                    Confirmer
                                </Button>
                                <Button variant="danger" onClick={() => setVisibility(!visibility)}>
                                    Annuler
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}