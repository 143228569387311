import { Col, Card } from 'react-bootstrap';
import CreateOrEditArticle from "../createOrEdit/createOrEditArticle";
import { BsFillEyeSlashFill } from 'react-icons/bs'

export default function ArticleCard(props) {
    const createdAtDate = new Date(props.article.created_at.replace(/\s/, 'T'));
    const publishAtDate = props.article.publish_at ? new Date(props.article.publish_at.replace(/\s/, 'T')) : null;
    var options = { year: "numeric", month: "long", day: "numeric", hour: '2-digit' };

    return (
        <Col xs={12} lg={6} className='mt-2'>
            <Card style={{ height: '100%' }}>
                <Card.Header className='d-flex flex-column'>
                    <p style={{textOverflow: 'ellipsis',
                                width: '100%',
                                whiteSpace : 'nowrap',
                                overflow : 'hidden'}}>
                        {props.article.name}
                    </p>
                    <small>Créé le : {createdAtDate.toLocaleDateString('fr-FR', options)}</small>
                    {
                        publishAtDate ?
                        <small>{props.article.is_public ? 'Publié' : 'Dépublié'} le : {publishAtDate.toLocaleDateString('fr-FR', options)}</small>
                        :
                        <small>Cet article n'a jamais été publié</small>
                    }
                </Card.Header>
                <Card.Body className='d-flex justify-content-center'>
                    <div class="thumbnail-container">
                        {
                            props.article.content ?
                                <div class="thumbnail">
                                    <iframe srcdoc={props.article.content} frameborder="0" />
                                </div>
                                :
                                <div className='d-flex flex-column align-items-center justify-content-center' style={{height: '100%'}}>
                                    <p>Pas de design enregistré</p>
                                    <BsFillEyeSlashFill size='150'/>
                                </div>
                        }
                    </div>
                </Card.Body>
                <Card.Footer className='d-flex justify-content-center'>
                    <CreateOrEditArticle categories={props.categories} action={'edit'} article={props.article} reload={props.reload} />
                </Card.Footer>
            </Card>
        </Col>
    )
}