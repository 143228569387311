import { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { AiOutlineSend } from 'react-icons/ai';
import instanceOfAxios from '../../../../../../app/axios/axios';
import { show } from '../../../../Alerter/alerterSlice';
import { useDispatch } from 'react-redux';
import Spinner from '../../../../../spinner/Spinner';

export default function Recall(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const [paymentMode, setPaymentMode] = useState();
    const [backDropStatic, setBackDropStatic] = useState(false);
    const dispatch = useDispatch();

    function sendRecall() {
        setBackDropStatic(true);
        setPending(true);
        instanceOfAxios.post(props.url, { payment_mode: paymentMode })
            .then((res) => {
                dispatch(show({ message: 'Email de paiement envoyé', variant: 'success' }));
                setBackDropStatic(false);
                setPending(false);
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de l\'envoi', variant: 'danger' }));
                setBackDropStatic(false);
                setPending(false);
            })
    }

    function hide(){
        setVisibility(false)
        setBackDropStatic(false);
        setPending(false);
        setPaymentMode(undefined)
    }

    return (
        <>
            <Button
                variant="secondary"
                className="apartmentText whiteSVG"
                size='sm'
                onClick={() => setVisibility(true)}>
                <AiOutlineSend />
            </Button>
            {
                visibility &&
                <Modal
                    onHide={hide}
                    centered
                    backdropClassName="backdropFourth"
                    style={{ zIndex: '20003' }}
                    animation={true}
                    backdrop={backDropStatic ? 'static' : true}
                    show={visibility}>
                    <Modal.Header closeButton>
                        Envoi d'un e-mail de rappel
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>Mode de paiement *</Form.Label>
                                    <Form.Control as="select" name="payment_mode" value={paymentMode || ''} onChange={(e) => setPaymentMode(e.target.value)} required>
                                        <option>Selectionner une option</option>
                                        <option>Carte Bancaire</option>
                                        <option>Virement</option>
                                        {
                                            props.forBail &&
                                            <option>Swikly</option>
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} className='d-flex justify-content-center'>
                                {
                                    paymentMode &&
                                    <>
                                        {
                                            pending ?
                                                <Spinner />
                                                :
                                                <Button variant='secondary' onClick={sendRecall}>
                                                    Envoyer un rappel pour un paiement par {paymentMode}
                                                </Button>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}