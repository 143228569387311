import { createSlice } from '@reduxjs/toolkit';

export const UsersSlice = createSlice({
    name: 'usersTab',
    initialState: {
        showCreate: false,
        showList: true,
        userToUpdate: undefined
    },
    reducers: {
        ShowCreate: (state, value = undefined) => {
            state.showCreate = true
            state.showList = false;
            if(value.payload !== undefined){
                state.userToUpdate = value.payload
            }
        },
        ShowList: state => {
            state.showList = true
            state.showCreate = false;
            state.userToUpdate = undefined
        },
        setUserToUpdate: (state, value) => {
            state.userToUpdate = value.payload;
        }
    },
});

export const { ShowCreate, ShowList, setUserToUpdate } = UsersSlice.actions;

export const getShowCreate = state => state.usersTab.showCreate;
export const getShowList = state => state.usersTab.showList;
export const getUserToUpdate = state => state.usersTab.userToUpdate;

export default UsersSlice.reducer;
