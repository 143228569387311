import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Container, InputGroup, DropdownButton, Dropdown, FormControl, Card } from 'react-bootstrap';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import { show, hide } from '../../Alerter/alerterSlice';
import { setStep } from './apartmentFormSlice';
import instanceOfAxios from '../../../../app/axios/axios';
import { connect, useDispatch, useStore } from 'react-redux';
import { getApartmentForUpdate } from '../apartmentSlice'
import Spinner from '../../../spinner/Spinner';
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';

function StepTwo(props) {
    const [surfaceParam, setSurfaceParam] = useState({});
    const [addressParam, setAddressParam] = useState({});
    const [address, setAddress] = useState(props.apartment.address);
    const [surface, setSurface] = useState(props.apartment.surfaces);
    const [apartmentParam, setApartmentParam] = useState(props.apartment);
    const apartment = props.apartment;
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorsAddress, setErrorsAddress] = useState([]);
    const [errorsSurface, setErrorsSurface] = useState([]);
    const [errorsApartment, setErrorsApartment] = useState([]);
    const role = useStore().getState().header.role;
    let districtTitle = 'Choisir un quartier';
    const districts = [
        'Les Arceaux',
        'Aiguelongue',
        'Antigone',
        'Aiguerelles',
        'Alco',
        'Bagatelle',
        'Beaux-Arts',
        'Boutonnet',
        'Cité Mion',
        'Croix d\'Argent',
        'Celleneuve',
        'Cévennes',
        'Consuls de Mer',
        'La Chamberte',
        'Ecusson',
        'Euromédecine',
        'Estanove',
        'Figuerolles',
        'Gambetta',
        'Grammont',
        'Grisettes',
        'Hauts de Saint-Priest',
        'Hôpitaux-Facultés',
        'Hauts de Massane',
        'Jacques Cœur',
        'Lironde',
        'La Rauze',
        'Lemasson',
        'Millénaire',
        'Mas Drevon',
        'La Martelle',
        'Malbosc',
        'Nouvelle Mairie',
        'Richter',
        'Ovalie',
        'Pas du Loup',
        'La Pompignane',
        'Les Aubes',
        'Port Marianne',
        'Plan des 4 Seigneurs',
        'PierresVives',
        'Mosson',
        'Parc Marianne',
        'Prés d\'Arènes',
        'Puech d\'Argent',
        'Rive Gauche',
        'Saint-Martin',
        'Gare Saint-Roch',
        'Nouveau Saint-Roch',
        'Tournezy',
        'Tastavin',
        'Comédie',
        'Centre Historique'
    ];

    if (apartmentParam && apartmentParam.district) {
        districtTitle = apartmentParam.district;
    }

    const dispatch = useDispatch();

    function handleAddressChange(event) {
        handleChange(addressParam, event, 'address');
    }

    function handleApartmentChange(event) {
        handleChange(apartmentParam, event, 'apartment');
    }

    function handleSurfaceChange(event) {
        handleChange(surfaceParam, event, 'surface');
    }

    function deleteSurface(id) {
        instanceOfAxios.delete('/apartment/' + apartment.id + '/surface/' + id)
            .then((res) => {
                dispatch(show({ message: 'Suppression réussi', variant: 'success' }));
                let surfaces = Object.assign([], props.apartment.surfaces);
                let apartment = Object.assign({}, props.apartment);
                surfaces = surfaces.filter(surface => surface.id !== id);
                apartment.surfaces = surfaces;
                dispatch(setUpdater(apartment));
            })
    }
    function handleSubmit() {
        let addressForm = setFormData(addressParam, address);
        let apartmentForm = setFormData(apartmentParam, apartment);

        let url = '/address';

        if (address) {
            url = '/address/' + address.id;
        }

        addressForm.set('model', 'apartment');
        addressForm.set('model_id', apartment.id);

        let send = 0;
        for (var pair of addressForm.entries()) {
            send++;
        }
        if (send > 3) {
            instanceOfAxios.post(url, addressForm)
                .then((res) => {
                    let stateApartment = Object.assign({}, props.apartment);
                    stateApartment.address = res.data.Address;
                    dispatch(setUpdater(stateApartment));
                    setIsSubmit(false);
                    setErrorsAddress([]);
                    setErrorsSurface([]);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    dispatch(setReload(true));
                    dispatch(setStep(3));
                })
                .catch((error) => {
                    handleError(error, 'address');
                })
        }

        send = 0;
        for (var pair of addressForm.entries()) {
            send++;
        }
        if (send > 1) {
            instanceOfAxios.post('/apartment/' + apartment.id, apartmentForm)
                .then((res) => {
                    dispatch(setUpdater(res.data.Apartment));
                    setIsSubmit(false);
                    setErrorsAddress([]);
                    setErrorsSurface([]);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    dispatch(setReload(true));
                    dispatch(setStep(3));
                })
                .catch((error) => {
                    handleError(error, 'apartment');
                })
        }
    }

    function handleSurfaceSubmit() {
        setIsSubmit(true);
        let url = '/apartment/' + props.apartment.id + '/surface';
        let surfaceData = setFormData(surfaceParam, surface);
        instanceOfAxios.post(url, surfaceData)
            .then((res) => {
                let stateApartment = Object.assign({}, props.apartment);
                let surfaces = Object.assign([], props.apartment.surfaces);
                surfaces.push(res.data.Surface);
                stateApartment.surfaces = surfaces;
                dispatch(setUpdater(stateApartment));
                setIsSubmit(false);
                setErrorsAddress([]);
                setErrorsSurface([]);
                dispatch(show({ message: 'Action réussi', variant: 'success' }));

                dispatch(setReload(true));
            })
            .catch((error) => {
                handleError(error, 'surface');
            })
    }

    function handleChange(params, event, type) {
        let param = Object.assign({}, params);
        if (event.target.name == 'type' || event.target.name == 'district') {
            param[event.target.name] = event.target.getAttribute('value');
        } else {
            param[event.target.name] = event.target.value;
        }
        switch (type) {
            case 'address':
                setAddressParam(param);
                break;
            case 'surface':
                setSurfaceParam(param);
                break;
            case 'apartment':
                setApartmentParam(param);
                break;
        }
    }

    function setFormData(params, object) {
        let formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        });
        if (object && object.id) {
            formData.set('_method', 'PATCH');
        }
        return formData;
    }

    function handleError(errors, type) {
        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        switch (type) {
            case 'address':
                setErrorsAddress(error);
                break;
            case 'surface':
                setErrorsSurface(error);
                break;
            case 'apartment':
                setErrorsApartment(error);
                break;
        }
        setIsSubmit(false);
        dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
        dispatch(setStep(2));

    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button variant="secondary" className="btn-rounded"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </Button>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');
            const lang = navigator.language;
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Recherche"
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled" style={{ position: 'relative', overflow: 'auto', maxHeight: '150px' }}>
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <Container className='pb-3'>
            <Row>
                <Col xs={12} md={6}>
                    <label><strong>Adresse</strong></label>
                    <Form>
                        <Form.Group>
                            <Form.Label>Adresse</Form.Label>
                            {errorsAddress['address'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsAddress['address']}</small></label>}
                            <Form.Control name='address' size="sm" placeholder="15 passage Lonjon" defaultValue={address ? address.address : ''} onChange={(e) => handleAddressChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Adresse ligne 2</Form.Label>
                            {errorsAddress['second_address'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsAddress['second_address']}</small></label>}
                            <Form.Control name='second_address' size="sm" placeholder="Logement, studio ou étage" defaultValue={address ? address.second_address : ''} onChange={(e) => handleAddressChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Numéro d'apartement</Form.Label>
                            {errorsAddress['third_address'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsAddress['third_address']}</small></label>}
                            <Form.Control name='third_address' size="sm" placeholder="n°56" defaultValue={address ? address.third_address : ''} onChange={(e) => handleAddressChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nom de la résidence</Form.Label>
                            {errorsApartment['building_name'] && <label htmlFor="building_name"> <small style={{ color: "red" }}> {errorsApartment['building_name']}</small></label>}
                            <Form.Control defaultValue={apartmentParam ? apartmentParam.building_name : ''} name="building_name" placeholder="nom de la résidence" onChange={(e) => handleApartmentChange(e)} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Pays</Form.Label>
                            {errorsAddress['country'] && <label htmlFor="country"> <small style={{ color: "red" }}> {errorsAddress['country']}</small></label>}
                            <Form.Control size="sm" name="country" as="select"
                                defaultValue={address ? address.country : 'France'}
                                onChange={(e) => handleAddressChange(e)}
                            >
                                <option key="1" value="FRANCE">France</option>
                                <option key="2" value="SUISSE">Suisse</option>
                            </Form.Control>
                        </Form.Group>

                        {errorsApartment['district'] && <label htmlFor="district"><small style={{ color: "red" }}> {errorsApartment['district']}</small></label>}
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Ville</Form.Label>
                                {errorsAddress['city'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsAddress['city']}</small></label>}
                                <Form.Control name='city' size="sm" placeholder="Montpellier" defaultValue={address ? address.city : ''} onChange={(e) => handleAddressChange(e)} />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Code Postal</Form.Label>
                                {errorsAddress['postal_code'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsAddress['postal_code']}</small></label>}
                                <Form.Control name='postal_code' size="sm" placeholder='34000' defaultValue={address ? address.postal_code : ''} onChange={(e) => handleAddressChange(e)} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Group className='d-flex justify-content-center'>
                            <div>
                                <Form.Label className='d-flex justify-content-center'>Quartier</Form.Label>
                                <Dropdown className="d-flex mt--2">
                                    <Dropdown.Toggle as={CustomToggle}>
                                        {districtTitle}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu} style={{ padding: '10px', margin: '0px' }}>
                                        {districts ? districts.map((value) =>
                                            <Dropdown.Item key={value} name='district' value={value} id={value} eventkey={value} onClick={(e) => handleApartmentChange(e)}>{value}</Dropdown.Item>)
                                            :
                                            <Dropdown.Item>Aucun quartier</Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} md={6}>
                    <label><strong>Surface</strong></label>
                    <Form>
                        <Form.Group>
                            <Form.Label>Intitulé</Form.Label>
                            {errorsSurface['title'] && <label htmlFor="title"><small style={{ color: "red" }}>{errorsSurface['title']}</small></label>}
                            <Form.Control size="sm" name="title" as="select" onChange={(e) => handleSurfaceChange(e)}>
                                <option>Sélectionner un intitulé</option>
                                <option>habitable</option>
                                <option>terrasse</option>
                                <option>Jardin</option>
                            </Form.Control>
                        </Form.Group>
                        <InputGroup>
                            {errorsSurface['type'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsSurface['type']}</small></label>}
                            {errorsSurface['value'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsSurface['value']}</small></label>}
                            <Form.Control type='number' name='value' size="sm" placeholder="entrer un nombre" defaultValue={surface ? surface.value : ''} onChange={(e) => handleSurfaceChange(e)} />
                            <DropdownButton
                                size='sm'
                                as={InputGroup.Append}
                                variant="outline-secondary"
                                title={surfaceParam.type || "Selectionner un type de surface"}
                                id="type"
                            >
                                <Dropdown.Item name='type' value='m²' onClick={(e) => handleSurfaceChange(e)}>m²</Dropdown.Item>
                                <Dropdown.Item name='type' value='carrez' onClick={(e) => handleSurfaceChange(e)}>carrez</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    </Form>
                    <Row className='justify-content-end'>
                        <Button variant="secondary" className="d-flex mt-3 mb-3" onClick={() => handleSurfaceSubmit()}>Ajouter</Button>
                    </Row>
                    <Row>
                        {apartment && apartment.surfaces && apartment.surfaces.map((surface) => {
                            return (
                                <Col lg={6}>
                                    <Card className='d-flex mb-2 shadowForCard'>
                                        <Row className='mt-2 mb-2' key={surface.id + '/' + surface.value}>
                                            <Col lg={6}>{surface.title} :</Col>
                                            <Col className='d-flex justify-content-end align-items-center' lg={6}>
                                                <DeleteModal role={role} deleteFunction={() => deleteSurface(surface.id)} />
                                            </Col>
                                            <Col lg={12}>{surface.value + surface.type}</Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-end">
                {isSubmit ? <Spinner/> : <Button variant="secondary" onClick={() => handleSubmit()}>Suivant</Button>}
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepTwo);