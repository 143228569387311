import { Row, Col, Modal, Button, Card } from 'react-bootstrap';
import { useState } from 'react';
import { AiOutlineEuroCircle } from 'react-icons/ai';

export default function Info(props) {
    const [visibility, setVisibility] = useState(false);
    const [specifiquRate, setSpecifiquRate] = useState(props.apartment.rates.filter(rate => !rate.is_principal));
    const [principalRate, setPrincipalRate] = useState(props.apartment.rates.filter(rate => rate.is_principal)[0]);
    const month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    return (
        <>
            <Col
                md={12}
                lg={4}
                className='menuItem d-flex justify-content-center align-items-end'
                onClick={() => setVisibility(true)}>
                <div className='container-text d-flex flex-column align-items-center justify-content-center'>
                    <AiOutlineEuroCircle />
                    <h2>Tarifs</h2>
                </div>
            </Col>
            <Modal
                show={visibility}
                backdropClassName="backdrop"
                scrollable
                centered
                onHide={() => setVisibility(false)}
                style={{ zIndex: '20001' }}
                size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Tarifs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} className='mb-3'>
                            <h2>Tarif principal</h2>
                        </Col>
                        <Col xs={12} sm={4} className='noPadding text-center'>
                            <p style={{ margin: '0' }}>Nuit : </p>
                            <p><strong>{principalRate.rent_by_night} </strong>€</p>
                        </Col>
                        <Col xs={12} sm={4} className='noPadding text-center'>
                            <p style={{ margin: '0' }}>Semaine : </p>
                            <p><strong>{principalRate.rent_by_week} </strong>€</p>
                        </Col>
                        <Col xs={12} sm={4} className='noPadding text-center'>
                            <p style={{ margin: '0' }}>Mois : </p>
                            <p><strong>{principalRate.rent_by_month} </strong>€</p>
                        </Col>
                        <Col sm={12} lg={12}>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%',
                                    marginBottom: '20px',
                                    marginTop: '20px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='mb-3'>
                            <h2>Tarifs personnalisés</h2>
                        </Col>
                        {
                            specifiquRate.map(rate => {
                                let beginDate = rate.begin_period.split('-');
                                let endDate = rate.end_period.split('-');
                                var options = { month: 'long', day: 'numeric' };
                                endDate = new Date(2022, parseInt(endDate[1]) - 1, parseInt(endDate[0]) + 1);
                                beginDate = new Date(2022, parseInt(beginDate[1]) - 1, parseInt(beginDate[0]));
                                return (
                                    <>
                                        <Col md={12} lg={6}>
                                            <Card style={{ marginBottom: '2vh' }}>
                                                <Card.Header>
                                                    <Row>
                                                        <Col xs={12} sm={6} className='noPadding text-center'>
                                                            <p style={{ margin: '0' }}>Début : </p>
                                                            <strong>{beginDate.toLocaleDateString(undefined, options)} au soir</strong>
                                                        </Col>
                                                        <Col xs={12} sm={6} className='noPadding d-flex flex-column text-center'>
                                                            <p style={{ margin: '0' }}>Fin : </p>
                                                            <strong>{endDate.toLocaleDateString(undefined, options)} au matin</strong>
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={12} className='noPadding text-center'>
                                                            <p style={{ margin: '0' }}>Minimum de {rate.min_night} nuit</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} sm={4} className='noPadding text-center'>
                                                            <p style={{ margin: '0' }}>Nuit : </p>
                                                            <p><strong>{rate.rent_by_night} </strong>€</p>
                                                        </Col>
                                                        <Col xs={12} sm={4} className='noPadding text-center'>
                                                            <p style={{ margin: '0' }}>Semaine : </p>
                                                            <p><strong>{rate.rent_by_week} </strong>€</p>
                                                        </Col>
                                                        <Col xs={12} sm={4} className='noPadding text-center'>
                                                            <p style={{ margin: '0' }}>Mois : </p>
                                                            <p><strong>{rate.rent_by_month} </strong>€</p>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                )
                            })
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='goldenHover' onClick={() => setVisibility(false)}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}