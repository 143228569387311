import React from 'react';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Popover, OverlayTrigger, Image } from 'react-bootstrap';
import './marker.scss';
import { connect } from 'react-redux';
import { getParam } from '../../../components/searchBar/searchBarSlice';
import { useState } from 'react';

function Wrapper({ apartment, isShowing, nightPrice, url }) {
  const [mouseOverSelf, setMouseOverSelf] = useState(false);
  return (
    <OverlayTrigger
      onToggle={(show) => setMouseOverSelf(show)}
      placement="top"
      show={isShowing || mouseOverSelf ? true : false}
      overlay={
        <Popover id="popover-basic">
          <Popover.Title>
            <strong> {apartment && apartment.reference} </strong>
            {
            nightPrice > 0 &&
              <>
                {nightPrice ? ':' + parseFloat(nightPrice).toFixed(2) : (apartment.minRate).toFixed(2)}€ / nuit
              </>
            }
          </Popover.Title>
          <Image loading='lazy' src={apartment.principal_picture_url ? apartment.principal_picture_url.mini : ''} fluid style={{ padding: '.5vh' }} />
        </Popover>}
      style={{ transform: 'translate(' + ranNum + '%, ' + ranNum + '%}}%)' }}>
      <a
        target='_blank'
        href={url}
        style={{
          position: 'absolute',
          top: '-25px',
          left: '-10px',
          height: '25px',
          width: 'auto'
        }}>
        <FaMapMarkerAlt className="apart-marker" />
      </a>
    </OverlayTrigger>
  )
};

var ranNum = Math.ceil(Math.random() * 50) * (Math.round(Math.random()) ? 1 : -1)
function Marker(props) {
  let url = props.param.begin_date && props.param.end_date && props.param.nb_person ? '/apartment/' + props.apartment.id + '?begin_date_hour=' + props.param.begin_date + '&end_date_hour=' + props.param.end_date + '&nb_person=' + props.param.nb_person : '/apartment/' + props.apartment.id;

  return (
    <Wrapper
      alt={props.text}
      onClick={props.onClick}
      apartment={props.apartment}
      isShowing={props.isShowing}
      nightPrice={props.nightPrice}
      url={url}
    />
  )
};

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    param: getParam(state)
  };
}

export default connect(mapStateToProps)(Marker);
