import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { 
    getVisibilityCreate, 
    getVisibilityList, 
    getReload, 
    getResult, 
    setReload,
} from './quoteSlice'
import CreateQuote from './create/createQuote';
import ListQuote from './list/listQuote';

const Quote = ({ showCreate, showList, reload, result }) => {
    const dispatch = useDispatch();
    let classNameForCreate = 'slider-create-right slide-out-create-right';
    let classNameForList = 'slider-list-left slide-out-list-left';
    if(showCreate){
        classNameForCreate = 'slider-create-right slide-in-create-right'; 
    }

    if(showList){
        classNameForList = 'slider-list-left slide-in-list-left'; 
    }

    if(reload){
        dispatch(setReload(false));
    }

    return (
    <div id='quotes' class='users'>
        {showCreate ? 
            <CreateQuote className={classNameForCreate}/>
        : 
            <ListQuote className={classNameForList} result={result}/>
        }
    </div>)
}

function mapStateToProps(state) {
    return {
        showCreate: getVisibilityCreate(state),
        showList: getVisibilityList(state),
        reload: getReload(state),
        result: getResult(state)
    }
}
export default connect(mapStateToProps)(Quote);