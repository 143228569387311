import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useStore } from 'react-redux';

export default function OptionCard(props) {

    const [option, setOption] = useState(props.option);
    const role = useStore().getState().header.role;

    return (
        <Card>
            <Card.Header>
            <Row className='text-center d-flex justify-content-center'>
                    <Col md='auto'>
                        <p className="apartmentText">Description : {option.description}</p>
                    </Col>
                    <Col md='auto'>
                        <p className="apartmentText">Montant : <strong>{option.sign} {parseFloat(option.value)} €</strong></p>
                    </Col>
                </Row>
            </Card.Header>
        </Card>
    )
}
