import { useEffect, useState } from 'react';
import { Modal, Button, Card, Row, Col, Badge, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import instanceOfAxios from '../../../../../app/axios/axios';
import Spinner from '../../../../spinner/Spinner';
import { show } from '../../../Alerter/alerterSlice';
import { FcHighPriority } from 'react-icons/fc';
import { BiExtension } from 'react-icons/bi';
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';
import { IoMdAddCircleOutline } from 'react-icons/io';
import './extend.scss';

export default function Extend(props) {
    const [visibility, setVisibility] = useState(false);
    const [collapseList, setCollapseList] = useState(true);
    const [collapseCreate, setCollapseCreate] = useState(true);
    const [collapseSimulated, setCollapseSimulated] = useState(true);
    const [simulated, setSimulated] = useState();
    const [date, setDate] = useState();
    const [recalculate, setRecalculate] = useState(false);
    const [lastExtend, setLastExtend] = useState();
    const [simulatedPending, setSimulatedPending] = useState(false);
    const [createPending, setCreatePending] = useState(false);
    const dispatch = useDispatch();
    var options = { year: "numeric", month: "long", day: "numeric" };
    var correspondTarget = { rent: 'loyer', rate: 'tarif', commission: 'commission' };

    function returnBadgeState(extend) {
        const stateColor = { ['trop perçu']: 'danger', 'annulé': 'danger', ['à payer']: 'danger', payé: 'success', ['acompte versé']: 'secondary', ['en attente']: 'dark' }

        let color = stateColor[extend.state];
        let state = extend.state;

        if (!extend.is_confirmed && extend.state !== 'annulé') {
            color = 'warning';
            state = 'attente validation';
        }
        return <Badge style={{ fontSize: "0.9rem" }} variant={color}>{state}</Badge>
    }

    useEffect(() => {
        getLastEndDate();
    }, [])

    function getLastEndDate() {
        let last = props.booking.extends.slice().filter((a) => a.state != 'annulé');
        last = last.sort((a, b) => new Date(a.end_date_hour) - new Date(b.end_date_hour))[last.length - 1];
        if (!lastExtend) {
            setLastExtend(last);
        }
    }

    function hide() {
        setVisibility(false);
        setCollapseList(true);
        setCollapseCreate(true);
        setCollapseSimulated(true);
    }

    function handleSubmitSimulated() {
        setSimulatedPending(true);
        instanceOfAxios.post('/admin/booking/' + props.booking.id + '/simulateExtend', { end_date_hour: date, recalculate: recalculate })
            .then(res => {
                setSimulated(res.data.simulate)
                setSimulatedPending(false);
                setCollapseSimulated(false);
            })
            .catch(err => {
                setSimulatedPending(false);
                let error = err.response.data.error;
                if (err.response.data.error.end_date_hour) {
                    error = err.response.data.error.end_date_hour;
                }
                dispatch(show({ message: error, variant: 'danger' }));
            })
    }

    function handleSubmitCreate() {
        setCreatePending(true);
        instanceOfAxios.post('/admin/booking/' + props.booking.id + '/createExtend', { end_date_hour: date, recalculate: recalculate })
            .then(res => {
                dispatch(show({ message: 'Extention créée', variant: 'success' }));
                hide();
                setCreatePending(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de la création', variant: 'danger' }));
                setCreatePending(false);
            })
    }

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function validateSwik() {
        if (getDifferenceInDays(new Date(props.booking.bail.payments[0].payed_at.replace(/\s/, 'T')), new Date()) > 180) {
            return <p style={{ color: 'red' }}><FcHighPriority />Le paiement Swik de la caution n'est plus valable</p>
        } else if (getDifferenceInDays(new Date(props.booking.bail.payments[0].payed_at.replace(/\s/, 'T')), new Date()) > 150) {
            return <p style={{ color: 'red' }}><FcHighPriority />Le paiement Swik de la caution arrive à terme</p>
        }
    }

    return (
        <>
            <Button variant="secondary" className='align-self-end p-2 mt-1 whiteSVG' onClick={() => setVisibility(!visibility)}>
                <BiExtension /> Extentions
            </Button>
            {
                props.booking && visibility &&
                <Modal
                    show={visibility}
                    onHide={hide}
                    backdropClassName="backdropFourth"
                    scrollable
                    size='lg'
                    style={{ zIndex: '20003' }}
                    centered>
                    <Modal.Header closeButton>
                        Extentions de la réservation n°{props.booking.reference}
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className='noPadding'>
                                <Row>
                                    {props.booking.site_tiers ?
                                        <Col className="m-auto" xs={1}>
                                            <Badge className="apartmentText" variant={'info'}>{props.booking.site_tiers}</Badge>
                                        </Col> :
                                        <Col xs={1}>
                                        </Col>}
                                </Row>
                                <Row>
                                    {
                                        props.booking.bail.payments[0] && props.booking.bail.payments[0].payment_mode == 'Swikly' && props.booking.bail.payments[0].payed_at &&
                                        <Col xs={12} className='text-center'>
                                            {validateSwik()}
                                        </Col>
                                    }
                                    <Col className='noPadding'>
                                        Detail :
                                        <div className='cartridge mb-3'>
                                            <Row className='d-flex justify-content-center'>
                                                <Col xs={12} md={5} className='cartridge m-3'>
                                                    <Row>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Tarif : </p><strong>{(props.booking.rate).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Loyer prop : </p><strong>{(props.booking.rent).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Comm : </p><strong>{(props.booking.commission).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">TVA : </p><strong>{(props.booking.tva_value).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">tx séjour : </p><strong>{(props.booking.tourism).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">EDF : </p><strong>{(props.booking.EDF).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Ménage : </p><strong>{(props.booking.cleaning).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Assu : </p><strong>{(props.booking.assurance).toFixed(2)} €</strong>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={5} className='cartridge d-flex align-items-center m-3'>
                                                    <Row>
                                                        {
                                                            props.booking.site_tiers &&
                                                            <>
                                                                <Col xs={12} className='d-flex justify-content-between'>
                                                                    <p className="apartmentText">Comm site tiers : </p><strong>{props.booking.site_tiers_com && (props.booking.site_tiers_com).toFixed(2)} €</strong>
                                                                </Col>
                                                                <Col xs={12} className='d-flex justify-content-between'>
                                                                    <p className="apartmentText">Dif site tiers : </p><strong>{props.booking.site_tiers_dif && (props.booking.site_tiers_dif).toFixed(2)} €</strong>
                                                                </Col>
                                                            </>
                                                        }
                                                        {
                                                            props.booking.price_influencer_target && correspondTarget[props.booking.price_influencer_target] ?
                                                                <Col xs={12} className='d-flex justify-content-between'>
                                                                    <p className="apartmentText">{props.booking.price_influencer_value > 0 ? 'Majoration ' : 'Réduction '} {`${correspondTarget[props.booking.price_influencer_target]} `}: </p><strong>{props.booking.price_influencer_value} €</strong>
                                                                </Col>
                                                                :
                                                                <></>
                                                        }
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Début : </p><strong>{new Date(props.booking.begin_date_hour.replace(/\s/, 'T')).toLocaleDateString('fr-FR', options)}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Fin : </p><strong>{new Date(props.booking.end_date_hour.replace(/\s/, 'T')).toLocaleDateString('fr-FR', options)}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Nuitées : </p><strong>{getDifferenceInDays(new Date(props.booking.begin_date_hour.replace(/\s/, 'T')), new Date(props.booking.end_date_hour.replace(/\s/, 'T')))}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Nb personnes : </p><strong>{props.booking.nb_person}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Nb adultes : </p><strong>{props.booking.nb_adult}</strong>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col className='d-flex justify-content-center'>
                                <Button variant='secondary' className='whiteSVG' onClick={() => setCollapseList(!collapseList)}><MdOutlineFormatListNumberedRtl /> Liste des extentions</Button>
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <Button
                                    className='whiteSVG'
                                    variant='secondary'
                                    onClick={() => {
                                        setCollapseCreate(!collapseCreate);
                                        setSimulated(undefined);
                                        setCollapseSimulated(true);
                                    }}><IoMdAddCircleOutline /> Créer une extention</Button>
                            </Col>
                        </Row>
                        <div className={collapseCreate ? 'CreateExtendsCollapse text-center' : 'CreateExtendsCollapse CreateExtendsUncollapse text-center'}>
                            {
                                lastExtend &&
                                <small>Il existe une ou plusieurs extentions, la date de début minimal pour une nouvelle création est donc le {new Date(lastExtend.end_date_hour.replace(/\s/, 'T')).toLocaleDateString('fr-FR', options)}</small>
                            }
                            <Form.Group className='CreateExtendsCollapseInput'>
                                <Form.Label>Date de fin de l'extention</Form.Label>
                                <Form.Control
                                    type='date'
                                    defaultValue={new Date(lastExtend ? lastExtend.end_date_hour.replace(/\s/, 'T') : props.booking.end_date_hour.replace(/\s/, 'T')).toISOString().split('T')[0]}
                                    onChange={(e) => {
                                        setDate(e.target.value)
                                        setCollapseSimulated(true);
                                        setSimulated(undefined);
                                    }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    label="En cochant cette case je recalcule le tarif nuit de référence (exemple : tarif mois au lieu de semaine)"
                                    onChange={(e) => {
                                        setRecalculate(e.target.checked)
                                        setCollapseSimulated(true);
                                        setSimulated(undefined);
                                    }} />
                            </Form.Group>
                            {
                                simulatedPending ?
                                    <Spinner />
                                    :
                                    <Button variant='secondary' onClick={handleSubmitSimulated}>Simuler</Button>
                            }
                        </div>
                        <div className={collapseSimulated ? 'SimulatedExtendsCollapse text-center' : 'SimulatedExtendsCollapse SimulatedExtendsUncollapse text-center'}>
                            {
                                !collapseSimulated &&
                                <>
                                    <Row>
                                        <Col className='noPadding'>
                                            <Row>
                                                {simulated.site_tiers ?
                                                    <Col className="m-auto" xs={1}>
                                                        <Badge className="apartmentText" variant={'info'}>{simulated.site_tiers}</Badge>
                                                    </Col> :
                                                    <Col xs={1}>
                                                    </Col>}
                                            </Row>
                                            <Row>
                                                <Col className='noPadding'>
                                                    Detail :
                                                    <div className='cartridge mb-3'>
                                            <Row className='d-flex justify-content-center'>
                                                <Col xs={12} md={5} className='cartridge m-3'>
                                                    <Row>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Tarif : </p><strong>{(simulated.rate).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Loyer prop : </p><strong>{(simulated.rent).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Comm : </p><strong>{(simulated.commission).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">TVA : </p><strong>{(simulated.tva_value).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">tx séjour : </p><strong>{(simulated.tourism).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">EDF : </p><strong>{(simulated.EDF).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Ménage : </p><strong>{(simulated.cleaning).toFixed(2)} €</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Assu : </p><strong>{(simulated.assurance).toFixed(2)} €</strong>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={5} className='cartridge d-flex align-items-center m-3'>
                                                    <Row>
                                                        {
                                                            simulated.site_tiers &&
                                                            <>
                                                                <Col xs={12} className='d-flex justify-content-between'>
                                                                    <p className="apartmentText">Comm site tiers : </p><strong>{simulated.site_tiers_com && (simulated.site_tiers_com).toFixed(2)} €</strong>
                                                                </Col>
                                                                <Col xs={12} className='d-flex justify-content-between'>
                                                                    <p className="apartmentText">Dif site tiers : </p><strong>{simulated.site_tiers_dif && (simulated.site_tiers_dif).toFixed(2)} €</strong>
                                                                </Col>
                                                            </>
                                                        }
                                                        {
                                                            simulated.price_influencer_target && correspondTarget[simulated.price_influencer_target] ?
                                                                <Col xs={12} className='d-flex justify-content-between'>
                                                                    <p className="apartmentText">{simulated.price_influencer_value > 0 ? 'Majoration ' : 'Réduction '} {`${correspondTarget[simulated.price_influencer_target]} `}: </p><strong>{simulated.price_influencer_value} €</strong>
                                                                </Col>
                                                                :
                                                                <></>
                                                        }
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Début : </p><strong>{new Date(simulated.begin_date_hour.replace(/\s/, 'T')).toLocaleDateString('fr-FR', options)}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Fin : </p><strong>{new Date(simulated.end_date_hour.replace(/\s/, 'T')).toLocaleDateString('fr-FR', options)}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Nuitées : </p><strong>{getDifferenceInDays(new Date(simulated.begin_date_hour.replace(/\s/, 'T')), new Date(simulated.end_date_hour.replace(/\s/, 'T')))}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Nb personnes : </p><strong>{simulated.nb_person}</strong>
                                                        </Col>
                                                        <Col xs={12} className='d-flex justify-content-between'>
                                                            <p className="apartmentText">Nb adultes : </p><strong>{simulated.nb_adult}</strong>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        createPending ?
                                            <Spinner />
                                            :
                                            <Button variant='secondary' style={{ margin: '1vh' }} onClick={handleSubmitCreate}>Créer</Button>
                                    }
                                </>
                            }
                        </div>
                        <div className={collapseList ? 'ListExtendsCollapse' : 'ListExtendsCollapse ListExtendsUncollapse'}>
                            {
                                props.booking.extends && props.booking.extends.map(extend =>
                                    <Card className="apartmentReservationCard shadowForCard">
                                        <Card.Header className="apartmentReservationCardPart">
                                            <Row style={{ width: '100%' }}>
                                                <Col className="d-flex align-items-center" sm={12} lg={6}>
                                                    <p className="apartmentRef">Locataire : {extend.contact.first_name + ' ' + extend.contact.last_name}</p>
                                                </Col>
                                                <Col className="d-flex align-items-center" sm={12} lg={3}>
                                                    {extend.site_tiers &&
                                                        <Badge style={{ fontSize: "0.9rem" }} variant={'info'}>{extend.site_tiers}</Badge>
                                                    }
                                                </Col>
                                                <Col className="d-flex align-items-center justify-content-center" sm={12} lg={2}>
                                                    {returnBadgeState(extend)}
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body className="apartmentReservationCardPart">
                                            <Row>
                                                <Col xs={12} md={6} lg={6}>
                                                    <p className="apartmentText">Référence logement: <strong>{extend.apartment.reference}</strong></p>
                                                    <p className="apartmentText">Référence réservation: <strong>{extend.reference}</strong></p>
                                                    <p className="apartmentText">Réservé le : <strong>{new Date(extend.created_at.replace(/\s/, 'T')).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</strong></p>
                                                </Col>
                                                <Col xs={12} md={3} lg={3}>
                                                    <p className="apartmentText">Du : {new Date(extend.begin_date_hour.replace(/\s/, 'T')).toLocaleDateString()}</p>
                                                    <p className="apartmentText">Au : {new Date(extend.end_date_hour.replace(/\s/, 'T')).toLocaleDateString()}</p>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Row noGutters>
                                                        <Col xs={6}><p className="apartmentText">Tarif : </p></Col>
                                                        <Col className="d-flex justify-content-end" xs={6}><strong>{parseFloat(extend.rate).toFixed(2)} €</strong></Col>
                                                        <Col xs={6}><p className="apartmentText">Payé : </p></Col>
                                                        <Col className="d-flex justify-content-end" xs={6}><strong>{parseFloat(extend.amount_allready_payed).toFixed(2)} €</strong></Col>
                                                    </Row>
                                                    <p className="d-flex justify-content-end" > {extend.bail.is_payed ?
                                                        <Badge style={{ fontSize: "0.9rem" }} variant='success'>Caution payé</Badge>
                                                        :
                                                        <Badge style={{ fontSize: "0.9rem" }} variant='danger'>Caution à payer</Badge>
                                                    }</p>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>)
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={hide}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}