import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import { AiOutlineSend } from 'react-icons/ai';
import { Row, Col, Button, Badge, Card, Form, Modal } from 'react-bootstrap';
import { show } from '../../../admin/Alerter/alerterSlice';
import instanceOfAxios from '../../../../app/axios/axios';
import { useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { setReload } from '../quoteSlice';
import { MdOpenInNew } from 'react-icons/md';

export default function QuoteCard(props) {
    const [sendParam, setSendParam] = useState({ send_fr: 1, send_en: 0 });
    const [visibility, setVisibility] = useState(false);
    const dispatch = useDispatch();
    const role = useStore().getState().header.role;

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function handleLangCheck(event) {
        let checked = Object.assign({}, sendParam);
        if (event.target.checked) {
            checked[event.target.name] = 1;
        } else {
            checked[event.target.name] = 0;
        }
        setSendParam(checked);
    }

    function handleSendQuote(quote) {
        instanceOfAxios.post('/admin/quote/' + quote.id + '/send', sendParam)
            .then((res) => {
                dispatch(show({ message: 'Envoi en cour', variant: 'success' }));
            })
            .catch((error) => {
                dispatch(show({ message: 'Echec de l\'envoi', variant: 'danger' }));
            })
        setSendParam({ send_fr: 1, send_en: 0 })
    }

    function handleDeleteAndCreateBooking() {
        instanceOfAxios.get('/admin/quote/' + props.quote.id + '/DeleteAndCreateBooking')
            .then((res) => {
                dispatch(show({ message: 'Création réussi', variant: 'success' }));
                dispatch(setReload(true));
                setVisibility(false);
                props.setReload(true);
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de la création', variant: 'danger' }));
            })
    }

    function deleteQuote() {
        instanceOfAxios.delete('/admin/quote/' + props.quote.id)
            .then((res) => {
                dispatch(show({ message: 'Suppression réussi', variant: 'success' }));
                dispatch(setReload(true));
                props.setReload(true);
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
            })
    }

    const beginDate = new Date(props.quote.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(props.quote.end_date_hour.replace(/\s/, 'T'));
    return (
            <Col xs={12} className='mt-2'>
                <Card>
                    <Card.Header>
                        <Row className='d-flex justify-content-between align-items-center'>
                            <Col xs={1}>
                                <Badge className="apartmentTextTitle" variant={'info'}>{props.quote.apartment.reference}</Badge>
                            </Col>
                            {props.quote.site_tiers ?
                                <Col >
                                    <Badge className="apartmentText" variant={'info'}>{props.quote.site_tiers}</Badge>
                                </Col> :
                                <></>}
                            <Col xs={2} className="d-flex justify-content-end">
                                <DeleteModal deleteFunction={deleteQuote} />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row className='d-flex justify-content-center'>
                            <Col xs={12} lg={6}>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Tarif séjour :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"> <strong>{(props.quote.rate).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Tarif par nuit :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"> <strong>{((props.quote.rate).toFixed(2) / getDifferenceInDays(beginDate, endDate)).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Caution :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"> <strong>{(props.quote.bail_amount).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Nuitées :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"> <strong>{getDifferenceInDays(beginDate, endDate)}</strong></p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Nombre de personnes : </p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{props.quote.nb_person}</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Nombre d'adultes : </p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{props.quote.nb_adult}</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Début :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight">{beginDate.toLocaleDateString()}</p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Fin :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"> {endDate.toLocaleDateString()}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Loyer propriétaire : </p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{(props.quote.rent).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Commision(TTC) : </p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{(props.quote.commission).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">TVA :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{(props.quote.tva_value).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Ménage : </p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{(props.quote.cleaning).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">EDF :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{(props.quote.EDF).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Taxe de séjour : </p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{(props.quote.tourism).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                                <Row noGutters>
                                    <Col xs={6}>
                                        <p className="apartmentText">Assurance :</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p className="apartmentTextAlignRight"><strong>{(props.quote.assurance).toFixed(2)} €</strong></p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row className="mt-3">
                            <Col xs={5} className='d-flex flex-column text-center'>
                                <a href={props.quote.invoice_urls[0] && props.quote.invoice_urls[0].url} target='_blank'>devis en englais <MdOpenInNew/></a>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check name='send_en' type="checkbox" label="En Anglais" checked={sendParam.send_en} onClick={(e) => handleLangCheck(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={5} className='d-flex flex-column text-center'>
                                <a href={props.quote.invoice_urls[1] && props.quote.invoice_urls[1].url} target='_blank'>devis en français <MdOpenInNew/></a>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check name='send_fr' type="checkbox" label="En français" checked={sendParam.send_fr} onClick={(e) => handleLangCheck(e)} />
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <Button
                                    className="apartmentText"
                                    variant='secondary'
                                    size="sm" onClick={() => handleSendQuote(props.quote)}>
                                    Envoyer
                                    <AiOutlineSend color='white' className='ml-1'/>
                                </Button>
                            </Col>
                            <Col xs={12} className='d-flex justify-content-center'>
                                <Button variant='secondary' onClick={() => setVisibility(true)}>Basculer en réservation</Button>
                                <Modal show={visibility} onHide={() => setVisibility(false)} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Basculement d'un devis en réservation</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Veuillez confirmer le basculement du devis pour <strong>{props.quote.contact.civility} {props.quote.contact.first_name} {props.quote.contact.last_name}</strong> d'un montant de <strong>{props.quote.rate}</strong> €
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="danger" onClick={() => setVisibility(false)}>
                                            Fermer
                                        </Button>
                                        <Button variant="secondary" onClick={handleDeleteAndCreateBooking}>
                                            Confirmer
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>
    )
}

