import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import '../../components/admin/tab/tab.scss';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import Tab from '../../components/admin/tab/tab'
import { BsSearch, BsFillCalendarFill } from 'react-icons/bs';
import { IoIosPaper } from 'react-icons/io';
import { FiUsers } from 'react-icons/fi';
import { AiFillHome, AiFillDashboard } from 'react-icons/ai';
import { BiEuro } from 'react-icons/bi';
import { BiCommentDetail } from 'react-icons/bi';
import { ImStatsBars } from 'react-icons/im';
import { BsArrowLeft } from 'react-icons/bs'
import { FaMoneyBill } from 'react-icons/fa';
import { MdAccountBalance } from 'react-icons/md';
import { HiInformationCircle } from 'react-icons/hi';
import { RiArticleLine } from 'react-icons/ri';
import { getAccount, setPending, getPending, getVisibility, setVisibility } from './adminSlice';
import { getRole } from '../../components/header/headerSlice';
import './admin.scss'
import Popup from '../../components/updatePopup/popup';

const AdminPanel = ({ pending, role }) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    let dataTooltip = {
        'accueil': {
            true: {'data-tooltip': 'Accueil'},
            false: {}
        },
        'bord': {
            true: {'data-tooltip': 'Tableau de bord'},
            false: {}
        },
        'booking': {
            true: {'data-tooltip': 'Réservation'},
            false: {}
        },
        'quote': {
            true: {'data-tooltip': 'Devis'},
            false: {}
        },
        'users': {
            true: {'data-tooltip': 'Utilisateurs'},
            false: {}
        },
        'apartments': {
            true: {'data-tooltip': 'Logements'},
            false: {}
        },
        'stats': {
            true: {'data-tooltip': 'Statistiques'},
            false: {}
        },
        'compta': {
            true: {'data-tooltip': 'Comptabilité'},
            false: {}
        },
        'taxes': {
            true: {'data-tooltip': 'Taxes et Agences'},
            false: {}
        },
        'calendars': {
            true: {'data-tooltip': 'Calendriers'},
            false: {}
        },
        'comments': {
            true: {'data-tooltip': 'Commentaires'},
            false: {}
        },
        'blog': {
            true: {'data-tooltip': 'Blog'},
            false: {}
        },
        'pages': {
            true: {'data-tooltip': 'Pages'},
            false: {}
        },
        'superCompta': {
            true: {'data-tooltip': 'Super-Compta'},
            false: {}
        }
    }

    if (pending) {
        dispatch(getAccount());
        dispatch(setPending(false));
    }

    return (
        <div className="adminPages">
            <Popup/>
            <SideNav className='nav-style'
                onSelect={(selected) => {
                    dispatch(setVisibility({ selected: selected, boolean: true }));
                }}
                onToggle={
                    expanded => setExpanded(expanded)
                }>
                <SideNav.Toggle />
                <SideNav.Nav>
                    <NavItem className="sidenav-item" eventKey="accueil" {...dataTooltip['accueil'][!expanded]}>
                        <NavIcon className="sideNav sidenav-icon">
                            <BsArrowLeft className="home-nav font-size"/>
                        </NavIcon>
                        <NavText>
                            Accueil
                        </NavText>
                    </NavItem>
                    {
                        role !== 'accountant' ?
                        <NavItem className="sidenav-item" eventKey="dashboard" {...dataTooltip['bord'][!expanded]}>
                            <NavIcon className="sidenav-icon">
                                <AiFillDashboard className='font-size' />
                            </NavIcon>
                            <NavText>
                                Tableau de bord
                            </NavText>
                        </NavItem>
                        :
                        <></>
                    }
                    
                    {
                        role !== 'accountant' ?
                        <NavItem className="sidenav-item" eventKey="booking" {...dataTooltip['booking'][!expanded]}>
                            <NavIcon className="sidenav-icon">
                                <BsSearch className='font-size' />
                            </NavIcon>
                            <NavText>
                                Réservation
                            </NavText>
                        </NavItem>
                        :
                        <></>
                    }
                    
                    {
                        role !== 'accountant' ?
                        <NavItem className="sidenav-item" eventKey="quote" {...dataTooltip['quote'][!expanded]}>
                            <NavIcon className="sidenav-icon">
                                <IoIosPaper className='font-size' />
                            </NavIcon>
                            <NavText>
                                Devis
                            </NavText>
                        </NavItem>
                        :
                        <></>
                    }

                    {
                        role !== 'accountant' ?
                        <NavItem className="sidenav-item" eventKey="users" {...dataTooltip['users'][!expanded]}>
                            <NavIcon className="sidenav-icon">
                                <FiUsers className='font-size' />
                            </NavIcon>
                            <NavText>
                                Gestion utilisateur
                            </NavText>
                        </NavItem>
                        :
                        <></>
                    }
                    {
                        role == 'super-admin' || role == 'admin' || role == 'trainee' ?
                            <NavItem className="sidenav-item" eventKey="apartments" {...dataTooltip['apartments'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <AiFillHome className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Gestion des biens
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }
                    {
                        role == 'super-admin' || role == 'admin' || role == 'accountant' ?
                            <NavItem className="sidenav-item" eventKey="accounting" {...dataTooltip['compta'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <BiEuro className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Comptabilité
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }
                    {
                        role == 'super-admin' || role == 'admin' ?
                            <NavItem className="sidenav-item" eventKey="taxes" {...dataTooltip['taxes'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <FaMoneyBill className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Taxes
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }
                    {
                        role == 'super-admin' || role == 'admin' ?
                            <NavItem className="sidenav-item" eventKey="calendar" {...dataTooltip['calendars'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <BsFillCalendarFill className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Calendrier
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }
                    {
                        role == 'super-admin' || role == 'admin' ?
                            <NavItem className="sidenav-item" eventKey="comment" {...dataTooltip['comments'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <BiCommentDetail className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Commentaires
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }
                    {
                        role !== 'accountant' ?
                        <NavItem className="sidenav-item" eventKey="blog"  {...dataTooltip['blog'][!expanded]}>
                            <NavIcon className="sidenav-icon">
                                <RiArticleLine className='font-size'/>
                            </NavIcon>
                            <NavText className="sidenav-text">
                                Blog
                            </NavText>
                        </NavItem>
                        :
                        <></>
                    }
                    
                    {
                        role == 'super-admin' ?
                            <NavItem className="sidenav-item" eventKey="stats" {...dataTooltip['stats'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <ImStatsBars className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Statistiques
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }
                    {
                        role == 'super-admin' ?
                            <NavItem className="sidenav-item" eventKey="pages" {...dataTooltip['pages'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <HiInformationCircle className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Gestion des Pages
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }
                    {
                        role == 'super-admin' || role == 'accountant' ?
                            <NavItem className="sidenav-item" eventKey="super-compta" {...dataTooltip['superCompta'][!expanded]}>
                                <NavIcon className="sidenav-icon">
                                    <MdAccountBalance className='font-size' />
                                </NavIcon>
                                <NavText>
                                    Super Comptabilité
                                </NavText>
                            </NavItem>
                            :
                            <></>
                    }

                </SideNav.Nav>
            </SideNav>
            <Tab />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        visibility: getVisibility(state),
        pending: getPending(state),
        role: getRole(state)
    }
}
export default connect(mapStateToProps)(AdminPanel);