import React, { useState } from 'react';
import { getApartmentForUpdate } from '../apartmentSlice'
import { connect, useDispatch, useStore } from 'react-redux';
import { Col, Container, Form, Row, Button, InputGroup } from 'react-bootstrap';
import Spinner from '../../../spinner/Spinner';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import '../Apartments.scss';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import { FaRegSave } from 'react-icons/fa';

function StepEight(props) {
    const [bedParam, setBedParam] = useState(props.apartment.bed);
    const [bed, setBed] = useState(props.apartment.bed || undefined);
    const [roomDetail, setRoomDetails] = useState(props.apartment.rooms_details ? JSON.parse(props.apartment.rooms_details) : { chambre: 0, sdb: 0, sdd: 0, toilette: 0, terrasse: 0, parking: 0, garage: 0, cave: 0 });
    const [errors, setErrors] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [apartment, setApartment] = useState(props.apartment || undefined);
    const dispatch = useDispatch();

    function handleRoomDetailChange(event) {
        roomDetail[event.target.getAttribute('name')] = event.target.value;
        let updateAppart = Object.assign({}, apartment);
        updateAppart.rooms_details = JSON.stringify(roomDetail);
        setApartment(updateAppart);
        setRoomDetails(roomDetail);
    }

    function handleUpdateRoomDetail() {
        let apart = Object.assign({}, apartment);
        apart.rooms_details = JSON.stringify(roomDetail);
        apart._method = 'PATCH';
        instanceOfAxios.post('/apartment/' + apartment.id, apart)
            .then((res) => {
                dispatch(setUpdater(apartment));
                setApartment(res.data.Apartment);
                dispatch(show({ message: 'Action réussi', variant: 'success' }));
            })
            .catch((error) => {
                handleError(error, 'apartment');
            })
    }

    function handleBedChange(event) {
        let data = Object.assign({}, bedParam);
        data[event.target.id] = event.target.value;
        setBedParam(data);
    }

    function handleBedSubmit() {
        setIsSubmit(true);
        let bedData = setFormData(bedParam, bed);
        let url = '/apartment/' + apartment.id + '/bed';
        if (bed) {
            url = '/apartment/' + apartment.id + '/bed/' + bed.id
        }
        let send = 0;
        for (var pair of bedData.entries()) {
            send++;
        }
        if(bed){
            bedData.append('_method', 'PATCH');
        }
        if (send > 0) {
            instanceOfAxios.post(url, bedData)
                .then((res) => {
                    instanceOfAxios.get('/apartment/' + apartment.id)
                        .then((res) => {
                            dispatch(setUpdater(res.data.apartment));
                            setBed(res.data.apartment.bed);
                            setBedParam({});
                            setIsSubmit(false);
                            setErrors([]);
                            dispatch(show({ message: 'Action réussi', variant: 'success' }));
                            dispatch(setReload(true));
                        })
                })
                .catch((error) => {
                    handleError(error);
                });
        } else {
            setIsSubmit(false);
        }
    }

    function setFormData(params, object) {
        let formData = new FormData();

        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        })
        return formData;
    }

    function handleError(errors) {
        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        setErrors(error);
        setIsSubmit(false);
        dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
    }

    return (
        <Container className='pb-3'>
            <label><strong>Nombre de lits</strong></label>
            <Row>
                <Col md={12}>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={3}>
                            {errors['simple_bed'] && <label htmlFor="simple_bed"><small style={{ color: "red" }}>{errors['simple_bed']}</small></label>}
                            <Form.Label>Lit simple</Form.Label>
                            <Form.Control type={'number'} id={'simple_bed'} name='simple_bed' size="sm" placeholder="3" onChange={(e) => handleBedChange(e)} defaultValue={bed ? bed.simple_bed : ''} />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={3}>
                            {errors['double_bed'] && <label htmlFor="double_bed"><small style={{ color: "red" }}>{errors['double_bed']}</small></label>}
                            <Form.Label>Lit double</Form.Label>
                            <Form.Control type={'number'} id='double_bed' name='double_bed' size="sm" placeholder="2" onChange={(e) => handleBedChange(e)} defaultValue={bed ? bed.double_bed : ''} />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={3}>
                            {errors['convert_bed'] && <label htmlFor="convert_bed"><small style={{ color: "red" }}>{errors['convert_bed']}</small></label>}
                            <Form.Label>Lit double séparable en 2</Form.Label>
                            <Form.Control type={'number'} id='convert_bed' name='convert_bed' size="sm" placeholder="1" onChange={(e) => handleBedChange(e)} defaultValue={bed ? bed.convert_bed : ''} />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={3}>
                            {errors['convert_sofa'] && <label htmlFor="convert_sofa"><small style={{ color: "red" }}>{errors['convert_sofa']}</small></label>}
                            <Form.Label>Canapé convertible</Form.Label>
                            <Form.Control type={'number'} id='convert_sofa' name='convert_sofa' size="sm" placeholder="1" onChange={(e) => handleBedChange(e)} defaultValue={bed ? bed.convert_sofa : ''} />
                        </Form.Group>
                    </Form.Row>
                </Col>
            </Row>
            <label><strong>Détail des pièces</strong></label>
            <Row style={{ marginBottom: '2vh' }}>
                <Col xs={12} md={6}>
                    <Row className='RoomDetails'>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text> * Chambres : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment ? roomDetail.chambre : 0} className='text-center' type='number' name="chambre" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Salle de bains : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment ? roomDetail.sdb : ''} className='text-center' type='number' name="sdb" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Salle de douche : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment ? roomDetail.sdd : ''} className='text-center' type='number' name="sdd" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Toilette : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment ? roomDetail.toilette : ''} className='text-center' type='number' name="toilette" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Row className='RoomDetails'>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text> Terrasse : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment ? roomDetail.terrasse : ''} className='text-center' type='number' name="terrasse" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Parking : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment ? roomDetail.parking : ''} className='text-center' type='number' name="parking" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Garage : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment ? roomDetail.garage : ''} className='text-center' type='number' name="garage" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center noPadding'>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Cave : </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control defaultValue={props.apartment && props.apartment.rooms_details ? JSON.parse(props.apartment.rooms_details).cave : 0} className='text-center' type='number' name="cave" onChange={(e) => handleRoomDetailChange(e)} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-center">
                {isSubmit ? 
                        <Spinner/> 
                    : 
                        <Button variant="secondary" className='whiteSVG' onClick={() => {
                            handleUpdateRoomDetail();
                            handleBedSubmit()
                        }}><FaRegSave /> Enregistrer</Button>}
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepEight);