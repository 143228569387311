import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const dateRangeSlice = createSlice({
    name: 'dateRange',
    initialState: {
        begin_date: undefined,
        end_date: undefined,
    },
    reducers: {
        setDate: (state, value) => {
            state[value.payload.type] = value.payload.payload;
        },
        resetDate: state => {
            state.begin_date = undefined;
            state.end_date = undefined;
        }
    },
});


export const { setDate, resetDate } = dateRangeSlice.actions;

export function setBeginDateParam(event){
    return function(dispatch){
        if(event){
            dispatch(setDate({type: 'begin_date', payload: new Date(event.getFullYear(), event.getMonth(), event.getDate(), 11)}));
        }else{
            dispatch(setDate({type: 'begin_date', payload: undefined}))
        }
    }
}

export function setEndDateParam(event){
    return function(dispatch){
        if(event){
            dispatch(setDate({type: 'end_date', payload: new Date(event.getFullYear(), event.getMonth(), event.getDate(), 11)}));
        }else{
            dispatch(setDate({type: 'end_date', payload: undefined}))
        }
    }
}

// GETTER
export const getBeginDate = state => state.dateRange.begin_date;
export const getEndDate = state => state.dateRange.end_date;
export const getShowAdmin= state => state.classNameForEnd;

export default dateRangeSlice.reducer;
