import { useState } from "react"
import { Row, Col, Button } from 'react-bootstrap';
import FormForQuery from "./formForQuery/formForQuery";
import { FcPlus } from 'react-icons/fc';

export default function Stats(props) {
    const [forms, setForms] = useState([<FormForQuery />]);

    return (
        <div className='users'>
            <Row>
                <Col md={12} className='text-center'>
                    <h2>Statistiques</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Button variant="secondary" onClick={() => {
                    let form = Object.assign([], forms);
                    form.push(<FormForQuery />);
                    setForms(form);
                }} style={{ fontSize: "1.5rem" }}>
                    +
                </Button>
            </Row>
            <Row>
                {forms.map(form => form)}
            </Row>
        </div>
    )
}