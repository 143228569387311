import { Row, Col, Tab, Tabs } from 'react-bootstrap';
import AccountingTab from './array/AccountingTab';
import AccountingInvoices from './invoices/AccountingInvoices';

export default function SuperAccounting() {

    return (
        <div className='users slider-list-left slide-in-list-left'>
            <Row>
                <Col md={12} className='text-center'>
                    <h2>Super Comptabilité</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="array" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="array" title="Tableaux référentiels">
                    <AccountingTab/>
                </Tab>
                <Tab eventKey="invoices" title="Téléchargement des factures">
                    <AccountingInvoices/>
                </Tab>
            </Tabs>
        </div>
    )
}