import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { Col, Card, Row } from 'react-bootstrap';

export default function SkeletonCard(props) {
    return (
        <Col xs={12} lg={6} xl={4}>
            <Card className="apartmentCard shadowForCard">
                <Card.Header>
                    <Card.Title className="cardTexts">
                        <Typography variant="h4">
                            <Skeleton animation='wave'/>
                        </Typography>
                    </Card.Title>
                    <Row>
                        <Col md={12} >
                            <Typography variant="body1">
                                <Skeleton animation='wave'/>
                            </Typography>
                        </Col>
                    </Row>
                </Card.Header>
                <Skeleton animation='wave' variant="rect" width="100%" className="apartment-list-image">
                    <div style={{ paddingTop: '57%' }} />
                </Skeleton>
                <Card.Body className="apartmentCardBody">
                    <Typography variant="body1">
                        <Skeleton animation='wave'/>
                    </Typography>
                    <Row noGutters className='justify-content-center'>
                        <Typography variant="h4">
                            <Skeleton animation='wave'/>
                        </Typography>
                        <Typography variant="h4">
                            <Skeleton animation='wave'/>
                        </Typography>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}