import { useState } from 'react';
import { Col, Container, Form, Row, Button, Card } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { getApartmentForUpdate } from '../apartmentSlice'
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import Spinner from '../../../spinner/Spinner';
import ViewPdf from './viewPdf/viewPdf';
import FilesList from './manageFiles/filesList';
import { FaRegSave } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

function StepNine(props) {
    const [informationSup, setInformationSup] = useState(props.apartment.supplementary_information || undefined);
    const [errors, setErrors] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();

    function handleInformationSupChange(event) {
        let data = Object.assign({}, informationSup);
        data[event.target.name] = event.target.value;
        setInformationSup(data);
    }

    function getInput(data, index) {
        return (
            <Col md={12} lg={6} className='reducePadding' key=''>
                <Card className='p-3 mb-2 shadowForCard'>
                    <Form className='d-flex flex-column'>
                        <Form.Group as={Col} xs={12} className='noPadding'>
                            <Form.Control as='textarea' key={index + '.1'} size="sm" defaultValue={data.title} onChange={(e) => handleTitleChange(e, index)} />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} className='noPadding'>
                            <Form.Control as="textarea" key={index + '.2'} rows={3} size="sm" defaultValue={data.text} onChange={(e) => handleTextChange(e, index)} />
                        </Form.Group>
                        <Button className='goldenHover' onClick={() => removeInput(index)}>
                            Supprimer
                        </Button>
                    </Form>
                </Card>
            </Col>
        )
    }

    // fonction pour ajouter un champ de text
    function addInput() {
        let infosSupCopy = Object.assign({}, informationSup);
        infosSupCopy.infos = JSON.parse(infosSupCopy.infos);
        infosSupCopy.infos.push({ title: '', text: '' });
        infosSupCopy.infos = JSON.stringify(infosSupCopy.infos);
        setInformationSup(infosSupCopy);
    }
    /////---------------------------------------------/////

    // fonction pour retirer un champ de text
    function removeInput(index) {
        let infosSupCopy = Object.assign({}, informationSup);
        infosSupCopy.infos = JSON.parse(infosSupCopy.infos);
        infosSupCopy.infos.splice(index, 1);
        infosSupCopy.infos = JSON.stringify(infosSupCopy.infos);
        setInformationSup(infosSupCopy);
    }
    /////---------------------------------------------/////

    // fonction pour ajouter les changement
    function handleTextChange(event, index) {
        let infosSupCopy = Object.assign({}, informationSup);
        infosSupCopy.infos = JSON.parse(infosSupCopy.infos);
        infosSupCopy.infos[index].text = event.target.value;
        infosSupCopy.infos = JSON.stringify(infosSupCopy.infos);
        setInformationSup(infosSupCopy);
    }

    function handleTitleChange(event, index) {
        let infosSupCopy = Object.assign({}, informationSup);
        infosSupCopy.infos = JSON.parse(infosSupCopy.infos);
        infosSupCopy.infos[index].title = event.target.value;
        infosSupCopy.infos = JSON.stringify(infosSupCopy.infos);
        setInformationSup(infosSupCopy);
    }
    /////---------------------------------------------/////

    // fonction pour envoyer les datas
    function handleInformationSupSubmit() {
        setIsSubmit(true);
        let infosSupSend = Object.assign({}, informationSup);
        infosSupSend._method = 'PATCH';
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/supplementaryInformation/' + informationSup.id, infosSupSend)
            .then((res) => {
                let values = Object.assign([], informationSup);
                let stateApartment = Object.assign({}, props.apartment);
                stateApartment.informationSup = res.data.InformationSup;
                dispatch(setUpdater(stateApartment));
                setInformationSup(values);
                setIsSubmit(false);
                setErrors([]);
                dispatch(show({ message: 'Action réussi', variant: 'success' }));
                dispatch(setReload(true));
            })
            .catch((error) => {
                handleError(error);
            });
    }

    function handleError(errors) {
        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        setErrors(error);
        setIsSubmit(false);
        dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
    }

    return (
        <Container className="pb-3">
            <Row>
                <Col xs={12}>
                    <h3>Informations statiques</h3>
                    <Form.Row className='justify-content-center'>
                        <Form.Group as={Col} sm={6} md={3}>
                            {errors['wifi_password'] && <label htmlFor="wifi_password"><small style={{ color: "red" }}>{errors['wifi_password']}</small></label>}
                            <Form.Label>Mot de passe Wifi</Form.Label>
                            <Form.Control id='wifi_password' name='wifi_password' size="sm" placeholder="GrF4DERDS4gfeFe" onChange={(e) => handleInformationSupChange(e)} defaultValue={informationSup ? informationSup.wifi_password : ''} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} md={3}>
                            {errors['wifi_name'] && <label htmlFor="wifi_name"><small style={{ color: "red" }}>{errors['wifi_name']}</small></label>}
                            <Form.Label>Nom du Wifi</Form.Label>
                            <Form.Control id='wifi_name' name='wifi_name' size="sm" placeholder={"CLASSAPPART" + props.apartment.reference} onChange={(e) => handleInformationSupChange(e)} defaultValue={informationSup ? informationSup.wifi_name : ''} />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={3}>
                            {errors['phone'] && <label htmlFor="phone"><small style={{ color: "red" }}>{errors['phone']}</small></label>}
                            <Form.Label>N° tel du logement</Form.Label>
                            <Form.Control id='phone' name='phone' size="sm" placeholder="0456543245" onChange={(e) => handleInformationSupChange(e)} defaultValue={informationSup ? informationSup.phone : ''} />
                        </Form.Group>
                    </Form.Row>
                </Col>
                <Col xs={12} className='d-flex justify-content-end'>
                    {
                        !isSubmit
                            ?
                            <Button 
                            className='whiteSVG'
                            variant="secondary" 
                            onClick={() => {
                                handleInformationSupSubmit()
                            }}><FaRegSave/> Enregistrer</Button>
                            :
                            <Spinner />
                    }
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
                <Col xs={12}>
                    <h3>Informations dynamiques</h3>
                    <small>(affichage de 11 informations maximum par page)</small>
                    <Col xs={12} className='noPadding mt-3' >
                        <Button className='whiteSVG' variant='secondary' onClick={() => addInput()}><IoMdAddCircleOutline /> Ajouter une ligne </Button>
                    </Col>
                    <Col xs={12} className='noPadding mt-3'>
                        <Row>
                            {
                                informationSup &&
                                Object.assign([], JSON.parse(informationSup.infos)).map((value, index) =>
                                    getInput(value, index)
                                )
                            }
                        </Row>
                    </Col>
                </Col>
            </Row>
            <Row className="justify-content-end">
                {
                    !isSubmit
                        ?
                        <Button 
                            className='whiteSVG'
                            variant="secondary" 
                            onClick={() => {
                                handleInformationSupSubmit()
                            }}><FaRegSave/> Enregistrer</Button>
                        :
                        <Spinner />
                }
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
            </Row>
            <Row>
                <FilesList apartment={props.apartment} reloadPdfPreview={setIsSubmit} />
            </Row>
            <ViewPdf apartment={props.apartment} isSubmit={isSubmit} />
        </Container>)
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepNine);