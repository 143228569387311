import { Card, Col, Row } from "react-bootstrap";
import TaxeList from "../TaxeList/TaxeList";
import { BsFillHouseFill } from 'react-icons/bs';
import { show } from "../../../Alerter/alerterSlice";
import instanceOfAxios from "../../../../../app/axios/axios";
import { useDispatch } from "react-redux";
import EditOrCreateAgency from '../../../agency/EditOrCreateAgency';
import DeleteModal from "../../../../generiqueModal/deleteModal/deleteModal";
import { FaPhoneAlt } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io'
import { AiOutlineUser } from 'react-icons/ai'
import '../Agency.scss';

export default function AgencyCard(props) {
    const dispatch = useDispatch();

    function handleDelete() {
        instanceOfAxios.delete(`admin/agency/${props.agency.id}`)
            .then(res => {
                props.handleChange(true);
                dispatch(show({ message: 'Agence supprimée', variant: 'success' }));
            })
            .catch(err => {
                if (err.response.status === 424) {
                    dispatch(show({ message: err.response.data.relation, variant: 'danger' }));
                } else {
                    dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
                }
            });
    }

    return (
        <>
            <Col xs={12} md={5} className='mt-3'>
                <div className='cartridge'>
                    <Row className='d-flex align-items-center justify-content-end'>
                        <Col xs={12} className='d-flex align-items-center justify-content-center mb-2 location-absolute'>
                            <BsFillHouseFill size='20' />
                            <h5 style={{ margin: 'unset' }} className='ml-1'>{props.agency.location}</h5>
                        </Col>
                        <Col xs={6} md={2} className='d-flex justify-content-center align-items-center'>
                            <EditOrCreateAgency
                                agency={props.agency}
                                handleChange={props.handleChange}
                                for='edit' />
                        </Col>
                        <Col xs={6} md={2} className='d-flex justify-content-center align-items-center'>
                            <DeleteModal deleteFunction={() => handleDelete()} />
                        </Col>
                    </Row>
                    {
                        props.agency.contact &&
                        <Row className='d-flex align-items-center justify-content-center'>
                            <Col xs={12} className='d-flex justify-content-center justify-content-lg-end noPadding'>
                                <div className='separator'
                                    style={{
                                        border: '1px solid #B7A57E',
                                        color: '#b7a57e',
                                        width: '70%',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}></div>
                            </Col>
                            <Col xs={12} className='mb-3 text-center'>
                                <Card.Text>
                                    <span><strong><AiOutlineUser />{props.agency.contact.civility} {props.agency.contact.last_name} {props.agency.contact.first_name}</strong></span>
                                </Card.Text>
                            </Col>
                            <Col xs={12} md={6} className='mb-3 text-center'>
                                <Card.Text>
                                    <span className='noReturn'><FaPhoneAlt /><strong><a href={'tel:' + props.agency.contact.phone ? props.agency.contact.phone : ''}> {props.agency.contact.phone ? props.agency.contact.phone : ''}</a></strong></span>
                                </Card.Text>
                            </Col>
                            <Col xs={12} md={6} className='mb-3 text-center'>
                                <Card.Text>
                                    <span><IoIosMail /><strong><a href={'mailto:' + props.agency.contact.email}> {props.agency.contact.email}</a></strong></span>
                                </Card.Text>
                            </Col>
                        </Row>
                    }
                    <Row className='d-flex align-items-center justify-content-center'>
                        <Col xs={12} className='d-flex justify-content-center justify-content-lg-end noPadding'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '70%',
                                    marginTop: '10px',
                                    marginBottom: '10px'
                                }}></div>
                        </Col>
                        <Col className='text-center'>
                            <TaxeList agency={props.agency} />
                        </Col>
                    </Row>
                </div>
            </Col>
        </>
    )
}