import { useState } from 'react';
import { Col, Button, Row, Modal } from 'react-bootstrap';
import '../../admin/users/userCard/usersCard.scss'
import { GiConfirmed } from 'react-icons/gi'
import { TiCancelOutline } from 'react-icons/ti'
import { MdDeleteForever } from 'react-icons/md'
import Spinner from '../../spinner/Spinner';

// we have to do a JSX lambda component for independent state for each Card
export default function DeleteModal(props) {
    const [visibility, setVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    function hide() {
        setVisibility(false);
    }
    function show() {
        setVisibility(true);
    }

    function confirmeDelete() {
        props.deleteFunction();
        setIsSubmit(true);
        setTimeout(() => {
            hide();
            setIsSubmit(false);
        }, 3000);
    }

    return (
        <div>
            <Button onClick={show} className='goldenHover' size={props.size || ''}>
                <MdDeleteForever />
            </Button>
            <Modal
                show={visibility}
                showMask={false}
                onHide={hide}
                centered
                backdropClassName="backdropFifth"
                style={{ zIndex: '2000000' }}
                className="deleteModal">
                <Modal.Header closeButton>
                    <Modal.Title>Suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    Voulez vous vraiment supprimer cette ressource ?
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                            <Row className='w-100'>
                                <Col xs='12' className='d-flex justify-content-center'>
                                    <Spinner />
                                </Col>
                            </Row>
                            :
                            <Row className='w-100'>
                                <Col xs='6' className='d-flex justify-content-center'>
                                    <Button variant="secondary" onClick={confirmeDelete} className="adminButton whiteSVG">Confirmer<GiConfirmed className="adminButtonIcon"></GiConfirmed></Button>
                                </Col>
                                <Col xs='6' className='d-flex justify-content-center'>
                                    <Button variant="secondary" onClick={hide} className="adminButton whiteSVG">Annuler<TiCancelOutline className="adminButtonIcon"></TiCancelOutline></Button>
                                </Col>
                            </Row>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}
