import { useEffect, useState } from "react"
import instanceOfAxios from "../../../../../../app/axios/axios"
import environnement from "../../../../../../app/environnement";
import { Row, Col, Card } from 'react-bootstrap';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import AddDoc from "./addDoc";
import { useDispatch } from "react-redux";
import DeleteModal from "../../../../../generiqueModal/deleteModal/deleteModal";
import { show } from "../../../../Alerter/alerterSlice";
import { MdOpenInNew } from 'react-icons/md';

export default function ListDoc(props) {
    const [listNames, setListNames] = useState([]);
    const [pending, setPending] = useState(true);
    const files = ['Identité', 'Facture', 'Contrat', 'Rib', 'Kbis'];
    const dispatch = useDispatch();

    useEffect(() => {
        if(pending){
            setPending(false)
            instanceOfAxios.get(`/booking/${props.booking.id}/getFilesName`)
                .then(res => setListNames(res.data.success))
        }
    }, [pending]);

    function getObligatoryFile(name) {
        let file = listNames.find(fileName => (fileName.name).toUpperCase() === name.toUpperCase())
        let createdAt = file ? new Date(file.created_at.replace(/\s/, 'T')) : null;
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };

        return (
            <Col xs={12} className='w-100 d-flex flex-column flex-lg-row align-items-center justify-content-between mt-2'>
                <p style={{ margin: 'unset', display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                    {
                        file ?
                            <FcCheckmark />
                            :
                            <FcCancel />
                    }
                    {name}
                </p>
                {
                    file &&
                    <div className='shadowForCard w-75'>
                        <Card.Header className='d-flex flex-column flex-sm-row justify-content-around align-items-center'>
                            <a className='mr-4' target='_blank' href={`${environnement.axios}/admin/booking/${props.booking.id}/file/${file.name}`}>{file.name} <MdOpenInNew/></a>
                            <p className='mr-4' style={{margin: 'unset'}}>ajouté le : {createdAt.toLocaleDateString('fr-CA', options)}</p>
                            <DeleteModal deleteFunction={() => handleDelete(file.name)}/>
                        </Card.Header>
                    </div>
                }
            </Col>
        )
    }

    function getOtherFile() {
        return listNames.map(fileName => {
                let createdAt = fileName ? new Date(fileName.created_at.replace(/\s/, 'T')) : null;
                var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
                if(!files.includes(fileName.name.charAt(0).toUpperCase() + fileName.name.slice(1)) ){
                    return (
                        <Col xs={12} className='w-100 d-flex justify-content-center mt-2'>
                            <Card className='shadowForCard w-100'>
                                <Card.Header className='d-flex flex-column flex-md-row justify-content-around align-items-center'>
                                    <a className='mr-4' target='_blank' href={`${environnement.axios}/admin/booking/${props.booking.id}/file/${fileName.name}`}>{fileName.name} <MdOpenInNew/></a>
                                    <p className='mr-4' style={{margin: 'unset'}}>ajouté le : {createdAt.toLocaleDateString('fr-CA', options)}</p>
                                    <DeleteModal deleteFunction={() => handleDelete(fileName.name)}/>
                                </Card.Header>
                            </Card>
                        </Col>
                    )
                }
            }
        )
    }

    function handleDelete(name){
        instanceOfAxios.delete(`/admin/booking/${props.booking.id}/file/${name}`)
            .then( res => {
                dispatch(show({variant: 'success', message: 'Document supprimé'}));
                setPending(true);
            })
            .catch( err => dispatch(show({variant: 'danger', message: 'Erreur lors de la suppression'})))
    }

    // ajouter la date de création
    return (
        <Row>
            <Col xs={12} className='d-flex justify-content-between align-items-center'>
                <p style={{ margin: 'unset' }}>Liste des documents reçus de la part du client :</p>
                <AddDoc booking={props.booking} reload={setPending}/>
            </Col>
            {
                files.map(file => getObligatoryFile(file))
            }
            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-2 mb-2'>
                <div className='separator'
                     style={{
                         border: '1px solid #B7A57E',
                         color: '#b7a57e',
                         width: '90%'
                     }}></div>
            </Col>
            <Col xs={12} className='d-flex justify-content-between align-items-center'>
                <p style={{ margin: 'unset' }}>Autres documents : </p>
            </Col>
            {
                getOtherFile()
            }
        </Row>
    )
}