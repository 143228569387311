import { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../app/axios/axios";
import { show } from "../../components/admin/Alerter/alerterSlice";
import SearchResultMap from "../../components/map/searchResultMap";
import Spinner from "../../components/spinner/Spinner";
import FavoriCard from "./favoriCard/favoriCard";
import axios from 'axios';
import { useStore } from 'react-redux';

const axiosAdressToLatLng = axios.create({ baseURL: "https://api-adresse.data.gouv.fr/" });

export default function Favori() {
    const [favorite, setFavorite] = useState([]);
    const [load, setLoad] = useState(true);
    const [rerender, setRerender] = useState(true);
    const dispatch = useDispatch();

    const state = useStore().getState();
    const auth = state.header.isConnected;
    if(!auth){
        window.location.href = '/';
    }

    useEffect(() => {
        if(rerender){
            instanceOfAxios.get('indexFavori')
            .then((res) => {
                res.data.data.map((apartment) => {
                    if (apartment) {
                        let url = apartment.address.address.replace(/\s/g, '+');
                        let url2 = "search/?q=" + url;
                        let url3 = url2 + "&postcode=" + apartment.address.postal_code;
                        axiosAdressToLatLng.get(url3)
                            .then(posRes => {
                                if (posRes.data.features[0]) {
                                    apartment.lng = posRes.data.features[0].geometry.coordinates[0];
                                    apartment.lat = posRes.data.features[0].geometry.coordinates[1];
                                    apartment.isOnMap = true;
                                    setRerender(false);
                                } else {
                                    apartment.isOnMap = false;
                                }
                            })
                        setFavorite(res.data.data);
                    }
                })
                if(res.data.data.length == 0){
                    setFavorite(res.data.data);
                    setRerender(false);
                }
                setLoad(false);
            })
            .catch((err) => {
                if(err.response){
                    dispatch(show({ message: err.response.data.message, variant: 'danger' }));
                }
                setLoad(false);
                setRerender(false);
            });
        }
    }, [rerender]);

    return (
        <Container style={{ display: 'block', paddingBottom: '5vh', position: 'relative' }} fluid className='noPadding d-flex flex-column align-items-center justify-content-center'>
            <Row style={{ width: '100%' }}>
                <Col xs={12} className='mt-2 mb-2'>
                    <h2 className='text-left'>Liste de vos favoris</h2>
                </Col>
                <Col xs={12} className='mt-2 mb-2'>
                    <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > <a href='/Favoris' className='golden'>Favoris</a></p>
                </Col>
                <Col md={6} className='d-flex flex-column justify-content-center align-items-center'>
                    {
                        load ?
                            <Spinner/>
                        :
                            <>
                                {
                                    favorite.length == 0 ?
                                        <h2>Aucun favori</h2>
                                    :
                                        favorite.map(apart => <FavoriCard apartment={apart} setLoad={setLoad} setRerender={setRerender}/>)
                                }
                            </>
                    }
                </Col>
                <Col className="search-map-disapear" md={6} style={{ width: '100%' }}>
                    <SearchResultMap markers={favorite} nightPrices={[]} class='search-map-fixed-result' />
                </Col>
            </Row>
        </Container>
    )
}