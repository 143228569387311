import { useState } from 'react';
import { Col, Button, Modal, Form } from 'react-bootstrap';
import './ApartmentRent.scss';
import { FaRegSave } from 'react-icons/fa';

export default function AddDetention(props) {
    const [visibility, setVisibility] = useState(false);
    const [option, setOption] = useState({});

    function handlechange(event){
        option[event.target.name] = event.target.value;
        setOption(option);
    }

    function handleSave(){
        let rent = Object.assign([], props.rent);
        rent.content[props.index].options.push(option);
        props.onSave(rent);
        setVisibility(!visibility);
        setOption({});
    }

    return (
        <Col xs={12} className='d-flex justify-content-center align-items-center'>
            <Button size='sm' variant='secondary' style={{margin: '.5rem'}} onClick={() => setVisibility(!visibility)} disabled={props.rent && props.rent.content[props.index].is_validate}>Ajouter ou retenir</Button>
            <Modal
                scrollable
                centered
                show={visibility}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                        Ajouter ou retenir
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Raison</Form.Label>
                            <Form.Control type="text" placeholder="Raison" name='reason' onChange={(e) => handlechange(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Montant TTC</Form.Label><br/>
                            <small> Si valeur négative ajouter le signe "-"</small>
                            <Form.Control type="number" placeholder="50" name='amount' onChange={(e) => handlechange(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Dont TVA</Form.Label>
                            <Form.Control type="number" placeholder="50" name='tva_value' onChange={(e) => handlechange(e)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="secondary" className='whiteSVG' onClick={() => handleSave()}>
                            <FaRegSave/> Enregistrer
                        </Button>
                </Modal.Footer>
            </Modal>
        </Col >
    )
}