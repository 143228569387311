import { createSlice } from '@reduxjs/toolkit';
import instanceOfAxios from "../../../../app/axios/axios";

export const apartmentFormSlice = createSlice({
    name: 'apartmentForm',
    initialState: {
        isSubmit: false,
        dropDownValue: "type",
        error: {},
        param: {},
        step: 1
    }, 
    reducers: {
        setIsSubmit: (state, value) => {
            state.isSubmit = value.payload
        },
        changeDropDownValue: (state, value) => {
            state.param.type = value.payload;
            state.dropDownValue = value.payload;
        },
        changeValueNbPersons: (state, value) => {
            state.param.nb_person = value.payload;
            state.dropDownValue = value.payload;
        },
        changeValue: (state, value) => {
            state.param[value.payload.type] = value.payload.payload
        },
        setError: (state, value) => {
            state.error = value.payload;
        },
        addError: (state, value) => {
            state.error[value.payload.type] = value.payload.payload
        },
        setType: (state, value) => {
            state.type = value.payload;
        },
        setNbPersons: (state, value) => {
            state.nbPersons = value.payload;
        },
        resetParam: state => {
            state.param = {};
        },
        setDropDownValue: (state, value) => {
            state.dropDownValue = value.payload;
        },
        setStep: (state, value) => {
            state.step = value.payload;
        },
        setOwners: (state, value) => {
            state.owners = value.payload;
        }
    },
});

export const { setStep, setOwners, resetParam, setDropDownValue, setError, setIsSubmit, addError, changeDropDownValue, changeValueNbPersons, changeValue, setType, setNbPersons } = apartmentFormSlice.actions;

export function fetchType() {
    return function (dispatch) {
        return instanceOfAxios.get("/admin/dropDown/1")
            .then(res => {
                let types = [];
                Object.keys(JSON.parse(res.data.dropDown.content)).forEach(function (key) {
                    types.push(key);
                });
                dispatch(setType(types));
            });
    }
}

export function fetchNbPersons() {
    return function (dispatch) {
        return instanceOfAxios.get("/admin/dropDown/6")
            .then(res => {
                const nbMax = [];
                Object.keys(JSON.parse(res.data.dropDown.content)).forEach(function (key) {
                    nbMax.push(key);
                });
                dispatch(setNbPersons(nbMax));
            });
    }
}

export function fetchOwners() {
    return function (dispatch) {
        return instanceOfAxios.get("/admin/owners")
            .then(res => {
                dispatch(setOwners(res.data.accounts));
            });
    }
}

// GETTER
export const getParam = state => state.apartmentForm.param;
export const getError = state => state.apartmentForm.error;
export const getIsSubmit = state => state.apartmentForm.isSubmit;
export const getType = state => state.apartmentForm.type;
export const getDropDownValue = state => state.apartmentForm.dropDownValue;
export const getNbPersons = state => state.apartmentForm.nbPersons;
export const getStep = state => state.apartmentForm.step;
export const getOwners = state => state.apartmentForm.owners;

export default apartmentFormSlice.reducer;
