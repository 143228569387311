import { Col } from 'react-bootstrap'
import '../comments.scss';
import CommentsModal from "../modalList/commentsModal";

export default function CommentsPreviewCard(props) {
    let createdAt = new Date(props.comment.created_at.replace(/\s/, 'T'));
    var options = { year: 'numeric', month: 'long' };

    return (
        <Col xs={12} lg={6} className='noPadding'>
            <div className='card-preview'>
                <span>
                    <h5>
                        {props.comment.first_name} 
                        <small className='ml-1'>{(((props.comment.total_equip / 6) + (props.comment.total_state / 7) + (props.comment.total_cleaning / 7) + (props.comment.total_check_in / 2)) / 4).toFixed(1)}</small>
                    </h5>
                    <small>{createdAt.toLocaleDateString('fr-CA', options)}</small>
                </span>
                <p>{props.comment.content.global}</p>
            </div>
            <CommentsModal comments={props.comments} apartment={props.apartment}/>
        </Col>
    )
}