import React, { useState } from 'react';
import { Row, Col, Badge, Card, Button } from 'react-bootstrap';
import instanceOfAxios from '../../../../../app/axios/axios';
import environnement from '../../../../../app/environnement';
import Spinner from '../../../../spinner/Spinner';

export default function PaymentCard(props) {
    const [dataForm, setDataForm] = useState([]);
    const [pending, setPending] = useState(true);

    const stateColor = { ['à payer']: 'danger', payé: 'success' };
    let payedAt = 'Non payé';
    if (props.payment.payed_at) {
        payedAt = new Date(props.payment.payed_at);
        payedAt =
            (payedAt.getDate() >= 10 ? (payedAt.getDate()) : '0' + (payedAt.getDate())) +
            '/' + (payedAt.getMonth() >= 10 ? (payedAt.getMonth() + 1) : '0' + (payedAt.getMonth() + 1)) +
            '/' + payedAt.getFullYear();
    }

    let askPayableLink = false;

    switch (props.for) {
        case 'booking':
            if (pending && props.payment.state !== 'payé' && props.booking.state !== 'annulé' && props.booking.state !== 'payé' && props.booking.state !== 'trop perçu') {
                askPayableLink = true;
            }
            break;
        case 'bookingbail':
            if (pending && props.payment.state !== 'payé' && props.payable.is_payed !== 'payé' && props.booking.state !== 'annulé') {
                askPayableLink = true;
            }
            break;
    }

    if (askPayableLink) {
        setPending(false);
        instanceOfAxios.get('/' + props.for + '/' + props.payable.id + '/payment/' + props.payment.id + '/edit')
            .then((res) => {
                let datas = [];
                for (const [key, value] of Object.entries(res.data.form)) {
                    datas.push(<input type="hidden" name={key} value={value}></input>);
                }
                setDataForm(datas);
            });
    }

    return (
        <Card style={{ marginTop: '1vh' }}>
            <Card.Header style={{ paddingRight: '0', paddingLeft: '0' }}>
                <Row className='text-center d-flex justify-content-around'>
                    <Col md='6' className='d-flex justify-content-around justify-content-md-center'>
                        <p className="apartmentText">Montant : <strong> {parseFloat(props.payment.amount)} €</strong></p>
                    </Col>
                    <Col md='6' className='d-flex justify-content-around justify-content-md-center'>
                        <p className="apartmentText">Payé le : {payedAt}</p>
                    </Col>
                    {
                        props.payment.payment_mode &&
                        <Col md='12' className='d-flex justify-content-around justify-content-md-center'>
                            <p className="apartmentText">Mode de paiement : <strong>{props.payment.payment_mode}</strong></p>
                        </Col>
                    }
                    {
                        props.booking.state !== 'annulé' && props.booking.state !== 'trop perçu' &&
                        <Col md='2' className="d-flex justify-content-center align-items-center">
                            <Badge variant={stateColor[props.payment.state]} className="apartmentText">{props.payment.state}</Badge>
                        </Col>
                    }
                    {props.payment.state === 'à payer' &&
                        <>
                            {
                                dataForm.length > 0 ?
                                    <Col md='2'>
                                        <form method="POST" action={environnement.payment}>
                                            {dataForm.map((input) => input)}
                                            <Button variant='primary' type='submit' className="apartmentText goldenHover" size="sm">Payer</Button>
                                        </form>
                                    </Col>
                                    :
                                    <Spinner />
                            }
                        </>
                    }
                </Row>
            </Card.Header>
        </Card>
    )
}