import { useState } from 'react';
import { Col, Button, Row, Modal } from 'react-bootstrap';
import { GiConfirmed } from 'react-icons/gi'
import { TiCancelOutline } from 'react-icons/ti'
import { MdDeleteForever } from 'react-icons/md'
import Spinner from '../../../../../spinner/Spinner';

export default function DeleteModal(props) {
    const [visibility, setVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    function confirmeDelete() {
        props.deleteFunction();
        setIsSubmit(true);
        setTimeout(() => {
            setVisibility(!visibility);
            setIsSubmit(false);
        }, 3000);
    }

    return (
        <div>
            <Button onClick={() => setVisibility(!visibility)} className='goldenHover' size={props.size || ''}>
                <MdDeleteForever />
            </Button>
            <Modal
                show={visibility}
                showMask={false}
                onHide={() => setVisibility(!visibility)}
                centered
                backdropClassName="backdropFourth"
                style={{ zIndex: '2000000' }}
                className="deleteModal">
                <Modal.Header closeButton>
                    <Modal.Title>{
                        props.canBeDeleted ?
                        'Suppression'
                        :
                        'Création d\'un avoir'
                    }</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    {
                        props.canBeDeleted ?
                        'Voulez vous vraiment supprimer cette ressource ?'
                        :
                        'La ressource ne peut pas être supprimée un avoir sera donc créé. Etes vous sur de cette opération?'
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                            <Row className='w-100'>
                                <Col xs='12' className='d-flex justify-content-center'>
                                    <Spinner />
                                </Col>
                            </Row>
                            :
                            <Row className='w-100'>
                                <Col xs='6' className='d-flex justify-content-center'>
                                    <Button variant="secondary" onClick={confirmeDelete} className="adminButton whiteSVG">Confirmer<GiConfirmed className="adminButtonIcon"></GiConfirmed></Button>
                                </Col>
                                <Col xs='6' className='d-flex justify-content-center'>
                                    <Button variant="secondary" onClick={() => setVisibility(!visibility)} className="adminButton whiteSVG">Annuler<TiCancelOutline className="adminButtonIcon"></TiCancelOutline></Button>
                                </Col>
                            </Row>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}
