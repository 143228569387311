import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import './usersCard.scss'
import { IoIosMail } from 'react-icons/io'
import { FaBuilding } from 'react-icons/fa'
import { FcHighPriority } from 'react-icons/fc'
import { AiOutlineUser, AiFillPhone } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { ShowCreate } from '../usersSlice';
import UserDetails from '../userDetails/userDetails';
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal'
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import { FaPencilAlt } from 'react-icons/fa';

const UserCard = ({ user, role, handleChange, loading }) => {
    const dispatch = useDispatch();
    const created = new Date(user.created_at.replace(/\s/, 'T'));
    const tradRole = { tenant: 'Locataire', owner: 'Propriétaire', admin: 'Administrateur', ['super-admin']: 'Super Administrateur', trainee: 'Stagiaire' }

    function deleteAccount(account) {
        loading(true);
        instanceOfAxios.delete('/admin/account/' + account.id)
            .then((res) => {
                handleChange(true);
                dispatch(show({ message: 'Compte supprimé', variant: 'success' }));
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de l\'opération', variant: 'danger' }));
            })
    }

    return (
        <Col xs={12} className='noPadding'>
            <Card className='marginCard card-max-width shadowForCard'>
                <Card.Header className='noReturn'>
                    <Row>
                        <Col md={9} lg={10} className='noReturn text-center text-md-left'>
                            {
                                user.roles[0] ?
                                    <p className='d-flex align-items-center'>{user.is_society ? <FaBuilding /> : <AiOutlineUser />} {tradRole[user.roles[0].name]}</p>
                                    :
                                    <p className='d-flex align-items-center'><FcHighPriority />Attention aucun role attribué</p>
                            }
                        </Col>
                        <Col md={3} lg={2} className='noReturn text-center text-md-right'>
                            <small>{created.toLocaleDateString()}</small>
                        </Col>
                        <Col xs={12} className='noReturn text-center text-md-left'>
                            {
                                user.roles[0] && user.roles[0].name == 'owner' && !user.has_rib &&
                                <p className='d-flex align-items-center'><FcHighPriority />Aucunes informations bancaires</p>
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-center" noGutters>
                        <Col xs={12} md={4} className='d-flex flex-column justify-content-center align-items-center mb-3'>
                            <strong>Compte :</strong>
                            <Card.Text className='text-center'>
                                <IoIosMail /><strong style={{ overflow: "auto" }}><a href={'mailto:' + user.email}> {user.email}</a></strong>
                            </Card.Text>
                        </Col>
                        {
                            user.principal_contact &&
                            <Col xs={12} md={4} className='mb-2'>
                                <strong className='d-flex justify-content-center text-truncate' >Contact principal :</strong>
                                <Col className='d-flex justify-content-center text-truncate' xs={12}>
                                    <Card.Text className='text-center'>
                                        <AiOutlineUser /> {user.principal_contact ? user.principal_contact.first_name : user.email} {user.principal_contact ? user.principal_contact.last_name : ''}
                                    </Card.Text>
                                </Col>
                                <Col className='d-flex justify-content-center' xs={12}>
                                    <Card.Text className='text-center'>
                                        <IoIosMail /><strong><a href={'mailto:' + user.principal_contact ? user.principal_contact.email : user.email}> {user.principal_contact ? user.principal_contact.email : user.email}</a></strong>
                                    </Card.Text>
                                </Col>
                                <Col className='d-flex justify-content-center text-truncate' xs={12}>
                                    <Card.Text className='text-center'>
                                        {user.principal_contact ? <strong><AiFillPhone /><a href={'tel:' + user.principal_contact.phone}> {user.principal_contact.phone}</a></strong>
                                            : <strong><AiFillPhone /><a> Pas de numéro</a></strong>}
                                    </Card.Text>
                                </Col>
                            </Col>
                        }

                        <Col md={12} lg={4} className='d-flex align-items-center justify-content-center noPadding'>
                            <Row className='mt-2 d-flex align-items-center justify-content-center noPadding'>
                                <Col xs={12} sm={10} className='d-flex flex-column justify-content-center'>
                                    <Button variant="secondary" className="icon-button-sad whiteSVG mb-2" size="sm" onClick={() => dispatch(ShowCreate(user))}>
                                        <FaPencilAlt /> Modifier le compte
                                    </Button>
                                    <UserDetails user={user} role={role} dispatch={dispatch} handleChange={handleChange} />
                                </Col>
                                <Col xs={12} sm={2} className='d-flex justify-content-center mt-2 mt-sm-0'>
                                    <DeleteModal size='sm' deleteFunction={() => deleteAccount(user)} model={user} role={role} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}
export default UserCard;