import { useEffect, useState } from "react";
import Spinner from '../../../../spinner/Spinner';
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import instanceOfAxios from "../../../../../app/axios/axios";
import PriceInfluencerForm from "./priceInfluencerForm";
import { useDispatch } from "react-redux";
import { show } from "../../../Alerter/alerterSlice";
import DeleteModal from "../../../../generiqueModal/deleteModal/deleteModal";
import ListArchivedPriceInfluencer from "../archivedPriceInfluencer/listArchivedPriceInfluencer";
import { FaRegSave } from 'react-icons/fa';

export default function PriceInfluencerList(props) {
    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [errors, setErrors] = useState([]);
    const Dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get(`/apartment/${props.apartment.id}/priceInfluencer`)
                .then(res => {
                    setData(res.data.priceInfluencer)
                    setPending(false)
                })
                .catch()
        }
    }, [pending])

    function handleSubmit() {
        setSubmit(true);
        data.map(priceInfluencer => {
            instanceOfAxios.post(`/apartment/${props.apartment.id}/priceInfluencer/${priceInfluencer.id}`, { ...priceInfluencer, _method: 'PATCH' })
                .then(res => {
                    setSubmit(false);
                    setPending(true);
                    Dispatch(show({ message: 'Enregistrement réussi', variant: 'success' }));
                })
                .catch(error => {
                    setSubmit(false);
                    Dispatch(show({ message: 'Action échouée', variant: 'danger' }));
                })
        })
    }

    function handlePriceInfluencerChange(event) {
        let array = Object.assign([], data);

        if (event.target.type === 'checkbox') {
            // association de valeur : prend la valeur de l'element qui a declenche l'evenement, et l'associe à une propriété spécifique
            array[event.target.id][event.target.name] = event.target.checked ? 1 : 0
        } else {
            array[event.target.id][event.target.name] = event.target.value;
        }
        setData(array);
    }

    function handleDelete(id) {
        instanceOfAxios.delete(`/apartment/${props.apartment.id}/priceInfluencer/${id}`)
            .then(res => {
                setSubmit(false);
                setPending(true);
                Dispatch(show({ message: 'Action réussi', variant: 'success' }));
            })
            .catch(error => {
                setSubmit(false);
                Dispatch(show({ message: 'Action échouée', variant: 'danger' }));
            })
    }

    // pour pouvoir trier un tableau selon le dernier activer/désactiver
    // il faudrait ajouter une date, celui qui à la date la plus élevée et celui mit en avant
    // des qu'un objet passe par activer/désactiver il lui faut une date

    function handleActivateOrDesactivate(id) {
        setSubmit(true);
        instanceOfAxios.get(`/admin/apartment/${props.apartment.id}/priceInfluencer/${id}/activateOrDesactivate`)
            .then(res => {
                setSubmit(false);
                setPending(true);
                Dispatch(show({ message: 'Action réussi', variant: 'success' }));
            })
            .catch(error => {
                setSubmit(false);
                Dispatch(show({ message: 'Action échouée', variant: 'danger' }));
            })
    }

    function mapForm() {
        return data.sort((a, b) => b.is_active - a.is_active).filter(priceInfluencer => priceInfluencer.is_active == 1).map((priceInfluencer, index) => {
            let begin_period = new Date(priceInfluencer.begin_date_hour);
            let end_period = new Date(priceInfluencer.end_date_hour);
            begin_period.setHours(11);
            end_period.setHours(18);
            return (
                <Col sm={12} md={6} xl={4}>
                    <Card key={priceInfluencer.id} className='mb-2 shadowForCard'>
                        <Card.Header>
                            <Row className='d-flex justify-content-center align-items-center'>
                                {priceInfluencer.name ? <Col xs={8}><strong>{priceInfluencer.name}</strong> </Col> : <strong></strong>}
                                <Col xs={2}>
                                    <DeleteModal deleteFunction={() => handleDelete(priceInfluencer.id)} />
                                </Col>
                            </Row>
                            <Col>
                                <Col className='mt-2'>
                                    {
                                        submit ?
                                            <Spinner />
                                            :
                                            <Form.Check
                                                type="switch"
                                                id={"custom-switch-on-" + priceInfluencer.id}
                                                label="Désactiver"
                                                defaultChecked={priceInfluencer.is_active}
                                                onClick={() => handleActivateOrDesactivate(priceInfluencer.id)}
                                            />
                                    }
                                </Col>
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control id={index + ''} name='name' size="sm" placeholder="Entrer un nom" onChange={(e) => handlePriceInfluencerChange(e)} defaultValue={priceInfluencer.name} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Début</Form.Label>
                                    <Form.Control type='date' id={index + ''} name='begin_date_hour' size="sm" defaultValue={begin_period.toISOString().split('T')[0]} onChange={(e) => handlePriceInfluencerChange(e)} />
                                    {errors['begin_date_hour'] && <label htmlFor="begin_date_hour"><small style={{ color: "red" }}>{errors['begin_date_hour']}</small></label>}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Fin</Form.Label>
                                    <Form.Control type='date' id={index + ''} name='end_date_hour' size="sm" defaultValue={end_period.toISOString().split('T')[0]} onChange={(e) => handlePriceInfluencerChange(e)} />
                                    {errors['end_date_hour'] && <label htmlFor="end_date_hour"><small style={{ color: "red" }}>{errors['end_date_hour']}</small></label>}
                                </Form.Group>
                            </Form.Row>
                            <strong as={Row} className="d-flex justify-self-center">Pourcentage</strong>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label as={Col} className="d-flex justify-self-center">Majoration/Réduction</Form.Label>
                                    <Form.Control as="select" id={index + ''} name='is_positive' size="sm" placeholder="3" onChange={(e) => handlePriceInfluencerChange(e)} defaultValue={priceInfluencer.is_positive ? 'Majoration' : 'Réduction'}>
                                        <option value='1'>Majoration</option>
                                        <option value='0'>Réduction</option>
                                    </Form.Control>
                                    {errors['is_positive'] && <label htmlFor="is_positive"><small style={{ color: "red" }}>{errors['is_positive']}</small></label>}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label as={Col} className="d-flex justify-self-center">Valeur</Form.Label>
                                    <Form.Control id={index + ''} name='percent' size="sm" placeholder="20" onChange={(e) => handlePriceInfluencerChange(e)} defaultValue={priceInfluencer.percent} />
                                    {errors['percent'] && <label htmlFor="percent"><small style={{ color: "red" }}>{errors['percent']}</small></label>}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Col className='mt-2'>
                                    <Form.Check
                                        type="checkbox"
                                        id={index + ''}
                                        label="Le pourcentage s'appliquera uniquement sur le loyer propriétaire"
                                        defaultChecked={priceInfluencer.is_for_owner}
                                        onChange={(e) => handlePriceInfluencerChange(e)}
                                        name="is_for_owner"
                                    />
                                </Col>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Col>)
        })
    }

    return (
        <>
            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                <div className='separator'
                    style={{
                        border: '1px solid #B7A57E',
                        color: '#b7a57e',
                        width: '90%',
                        marginBottom: '20px',
                        marginTop: '20px'
                    }}></div>
            </Col>
            <Col sm={12} lg={12} className='d-flex justify-content-center mb-3'>
                <PriceInfluencerForm apartment={props.apartment} setPending={setPending} />
            </Col>

            <Col sm={12} lg={12} className='d-flex justify-content-center mb-3'>
                <ListArchivedPriceInfluencer className='goldenHover' priceInfluencers={data} apartment={props.apartment} setPending={setPending} />
            </Col>
            <Col lg={12} className='noPadding'>
                <Row className='d-flex justify-content-center noPadding'>
                    {mapForm()}
                </Row>
                <Row className="justify-content-center mt-3">
                    {submit ? <Spinner /> : <Button variant="secondary" className='whiteSVG' onClick={() => {handleSubmit()}}><FaRegSave/> Enregistrer</Button>}
                </Row>
            </Col>
        </>
    )
};
