import moment from 'moment';
import { useEffect, useState } from 'react';
import { Row, Col, Pagination } from 'react-bootstrap';
import instanceOfAxios from "../../../app/axios/axios";
import CalendarLine from './calendarLine';
import Spinner from '../../spinner/Spinner';

export default function Calendar(props) {
    const [calendars, setCalendars] = useState([]);
    const [pending, setPending] = useState(true);
    const [month, setMonth] = useState(new Date().getMonth());

    useEffect(() => {
        if (pending) {
            getCalendar();
        }
    }, [pending]);

    let items = [
        <Pagination.Item key={moment().year} id={moment().year} active='true'>
            {moment(new Date(moment().year(), month, 1)).format('MMMM YYYY') + ' - ' + moment(new Date(moment().year(), month + 1, 1)).format('MMMM YYYY')}
        </Pagination.Item>
    ];

    function prev() {
        setMonth(month - 1);
        setPending(true);
    }

    function next() {
        setMonth(month + 1);
        setPending(true);
    }

    function getCalendar() {
        instanceOfAxios.get('/admin/apartments/planning/' + (parseInt(month) + 1))
            .then(res => {
                setPending(false);
                setCalendars(res.data.planning);
            });
    }

    return (
        <div className='users slider-list-left slide-in-list-left'>
            <Row>
                <Col md={12} className='text-center'>
                    <h2>Listing calendriers</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
            <Row style={{ paddingTop: '2vh' }} className='d-flex justify-content-center'>
                <Row style={{ marginBottom: '1rem' }}>
                    <Col style={{ textAlign: 'center' }}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-cal-ca' />
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Bloqué par ClassAppart</p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-cal-prop' />
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Bloqué par le propriétaire</p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-need' />
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation à payer</p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-wait' />
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation en attente</p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-first' />
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation acompte versé</p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-payed' />
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation payé</p>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-exed' />
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation en trop perçu</p>
                    </Col>
                </Row>
                <Col xs={12} className='d-flex justify-content-center'>
                    <Pagination style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                        <Pagination.Prev id={'prev'} onClick={() => prev()} disabled={pending} />
                        {items}
                        <Pagination.Next id={'next'} onClick={() => next()} disabled={pending} />
                    </Pagination>
                </Col>
                {pending ?
                    <>
                        <Spinner />
                    </>
                    :
                    <>
                        {
                            calendars.length > 0 ?
                                <CalendarLine dateUse={calendars} year={moment().year()} month={month} />
                                :
                                <h2>Aucun calendrier à afficher</h2>
                        }
                    </>
                }
            </Row>
        </div>
    )
}