import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap'
import { useEffect } from 'react';
import instanceOfAxios from '../../../../../app/axios/axios';
import Spinner from '../../../../spinner/Spinner';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewPdf(props) {
    const [pageNumber, setPageNumber] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [file, setFile] = useState();
    const [error, setError] = useState();
    const [load, setLoad] = useState(true);

    useEffect(() => {
        if (!props.isSubmit) {
            setLoad(true);
            setError();
            setFile();
            instanceOfAxios.get('/admin/apartment/' + props.apartment.id + '/infosSupFile', {
                    headers: { 'Cache-Control': 'no-cache' },
                    responseType: 'blob',
                })
                .then((res) => {
                    setFile(new Blob([res.data], { type: 'application/pdf' }));
                    setLoad(false);
                })
                .catch(err => {
                    setError('Le Document ne peut être chargé merci de vérifier si vous avez renseigné les champs dynamiques ainsi que les documents supplémentaires');
                    setLoad(false);
                })
        }
    }, [props.isSubmit]);

    function next() {
        if (pageNumber < maxPage) {
            setPageNumber(pageNumber + 1);
        }
    }

    function prev() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    function goTo(value) {
        if (value > 0 && value <= maxPage) {
            setPageNumber(parseInt(value));
        }
    }

    return (
        <>
            <Row className='d-flex justify-content-center mb-3'>
                <h3>Visualisation du document</h3>
            </Row>
            <Row className='mb-3'>
                <Col xs={4} className='d-flex justify-content-center'>
                    <Button variant='secondary' onClick={prev}>précedent</Button>
                </Col>
                <Col xs={4} className='d-flex justify-content-center align-items-center'>
                    <Form.Group as={Col} xs={6} className='noPadding mb-0'>
                        <Form.Control
                        className='text-center'
                        min="1" 
                        max={maxPage}
                        type='number' 
                        key='pageInput.1' 
                        size="sm" 
                        defaultValue={pageNumber} 
                        onChange={(e) => goTo(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={4} className='d-flex justify-content-center'>
                    <Button variant='secondary' onClick={next}>suivant</Button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <p>{pageNumber}/{maxPage}</p>
            </Row>
            {
                file ?
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Document
                            className='page mb-5'
                            file={URL.createObjectURL(file)}
                            onLoadSuccess={(pdf) => {
                                setMaxPage(pdf._pdfInfo.numPages);
                            }}
                            loading={<div className='loading d-flex justify-content-center align-items-center'><Spinner /></div>}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                    </Row>
                    :
                    <Row className='d-flex justify-content-center align-items-center p-3'>
                        {
                            load ?
                                <Spinner />
                            :
                                <p className='text-danger text-center'>{error}</p>
                        }
                    </Row>
            }
        </>
    )
}