import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { Col, Card, Row } from 'react-bootstrap';

export default function SkeletonCard(props) {
    return (
        <Col xs={12} className='noPadding'>
            <Card className="apartmentReservationCard shadowForCard">
                <Card.Header className="apartmentReservationCardPart">
                    <Row>
                        <Col xs={6} sm={1} lg={1}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={6} sm={2} lg={2}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col className="d-flex justify-content-center m-auto" xs={4} sm={4} lg={3}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={3} sm={3} lg={3} className="d-flex justify-content-center m-auto">
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={3} sm={3} lg={3} className="d-flex justify-content-center m-auto">
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="apartmentReservationCardPart">
                    <Row>
                        <Col xs={12} sm={12} md={5} lg={6}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={6} md={3}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={6}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={6} md={4} lg={3}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                        <Col xs={6} md={3}>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                            <Typography variant="body1">
                                <Skeleton animation='wave' />
                            </Typography>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}