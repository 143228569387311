import { Form, Col, Row, Button } from "react-bootstrap";
import Rating from "../rating/rating";

export default function StateStep(props){
    return (
        <>
        <Row className='d-flex flex-column align-items-center justify-content-center'>
            <h2>Etat générale du logement</h2>
            <Col className='text-center'>
                    <dl>
                        <dt>Informations utiles :</dt>
                        <dd>Si vous ne changez pas les scores ils prendront la note maximal par default</dd>
                        <dd>Les champs marqués d'une * sont obligatoire</dd>
                    </dl>
                </Col>
            <Col xs={12} sm={7}>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <Form.Group as={Col}>
                        <Form.Label>3/ Qu'avez-vous pensé de l'état général de votre logement ? ( décoration, état des lieux ... )?</Form.Label>
                    </Form.Group>
                </Col>
                <Col xs={12} className='noPadding'>
                    <Row className='d-flex align-items-center'>
                        <Col xs={12} sm={3} className='text-center'>
                            <Form.Label>Salon</Form.Label>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Rating defaultValue={props.defaultValue.living_room_score} handleChange={props.handleJsonChange} inputName='state_score' inputId='living_room_score' range={5} size={30} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='comment-gray-separator'></div>
                </Col>
                <Col xs={12} className='noPadding'>
                    <Row className='d-flex align-items-center'>
                        <Col xs={12} sm={3} className='text-center'>
                            <Form.Label>Cuisine</Form.Label>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Rating defaultValue={props.defaultValue.kitchen_score} handleChange={props.handleJsonChange} inputName='state_score' inputId='kitchen_score' range={5} size={30} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='comment-gray-separator'></div>
                </Col>
                <Col xs={12} className='noPadding'>
                    <Row className='d-flex align-items-center'>
                        <Col xs={12} sm={3} className='text-center'>
                            <Form.Label>Sol</Form.Label>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Rating defaultValue={props.defaultValue.floor_score} handleChange={props.handleJsonChange} inputName='state_score' inputId='floor_score' range={5} size={30} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='comment-gray-separator'></div>
                </Col>
                <Col xs={12} className='noPadding'>
                    <Row className='d-flex align-items-center'>
                        <Col xs={12} sm={3} className='text-center'>
                            <Form.Label>Chambre</Form.Label>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Rating defaultValue={props.defaultValue.room_score} handleChange={props.handleJsonChange} inputName='state_score' inputId='room_score' range={5} size={30} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='comment-gray-separator'></div>
                </Col>
                <Col xs={12} className='noPadding'>
                    <Row className='d-flex align-items-center'>
                        <Col xs={12} sm={3} className='text-center'>
                            <Form.Label>Salle de bain</Form.Label>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Rating defaultValue={props.defaultValue.bathroom_score} handleChange={props.handleJsonChange} inputName='state_score' inputId='bathroom_score' range={5} size={30} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='comment-gray-separator'></div>
                </Col>
                <Col xs={12} className='noPadding'>
                    <Row className='d-flex align-items-center'>
                        <Col xs={12} sm={3} className='text-center'>
                            <Form.Label>WC</Form.Label>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Rating defaultValue={props.defaultValue.toilet_score} handleChange={props.handleJsonChange} inputName='state_score' inputId='toilet_score' range={5} size={30} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='comment-gray-separator'></div>
                </Col>
                <Col xs={12} className='noPadding'>
                    <Row className='d-flex align-items-center'>
                        <Col xs={12} sm={3} className='text-center'>
                            <Form.Label>Extérieur</Form.Label>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Rating defaultValue={props.defaultValue.outside_score} handleChange={props.handleJsonChange} inputName='state_score' inputId='outside_score' range={5} size={30} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <div className='comment-gray-separator'></div>
                </Col>
                <Col xs={12}>
                    <Form.Group as={Col}>
                        <Form.Label>Remarque</Form.Label>
                        <Form.Control defaultValue={props.defaultValue.state_message} as="textarea" name="state_score" id='state_message' rows={2} onChange={(e) => props.handleJsonChange(e)} />
                    </Form.Group>
                </Col>
            </Col>
            <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                <div className='separator'
                    style={{
                        border: '1px solid #B7A57E',
                        color: '#b7a57e',
                        width: '60%',
                        marginBottom: '40px',
                        marginTop: '40px'
                    }}></div>
            </Col>
        </Row>
        <Row>
            <Col xs={6} className='d-flex justify-content-start'>
                <Button className='goldenHover' onClick={() => props.SetActiveStep(1)}>Précedent</Button>
            </Col>
            <Col xs={6} className='d-flex justify-content-end'>
                <Button className='goldenHover' onClick={() => props.SetActiveStep(3)}>Suivant</Button>
            </Col>
        </Row>
        </>
    )
}