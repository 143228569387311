import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { getVisibility, getMessage, getVariant, hide, show } from './alerterSlice';
import "./alerter.scss";

const Alerter = ({ visibility, message, variant }) => {
  const dispatch = useDispatch();
  const datas = {
    resultPayment: {
      success: 'Paiement accepté',
      danger: 'Paiement refusé, un mail contenant un récapitulatif vous a été envoyé'
    },
    verifyEmail: {
      success: 'Merci d\'avoir vérifié votre adresse e-mail',
      danger: 'Une erreur est survenue lors de la vérification, connectez-vous pour demander un renvoi dans votre espace Mon compte.'
    },
    resetPassword: {
      success: 'Votre mot de passe a été modifié',
      danger: 'Une erreur est survenue lors de la modification'
    }
  }

  if (visibility) {
    setTimeout(() => {
      dispatch(hide());
    }, 4000);
  }
  
  useEffect(() => {
    setTimeout(() => {
      showFromUrl();
    }, 500);
  }, []);

  function showFromUrl() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('alerter') && urlParams.get('type') && urlParams.get('variant')) {
      dispatch(show({ message: datas[urlParams.get('type')][urlParams.get('variant')], variant:  urlParams.get('variant')}));
    }
  }

  return (
    <Alert variant={variant} show={visibility} className='alert-global'>
      {message}
    </Alert>
  );
};

function mapStateToProps(state) {
  return {
    message: getMessage(state),
    variant: getVariant(state),
    visibility: getVisibility(state),
  }
}
export default connect(mapStateToProps)(Alerter);