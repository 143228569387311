import { useState } from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import { show, hide } from '../../Alerter/alerterSlice';
import { setStep } from './apartmentFormSlice';
import instanceOfAxios from '../../../../app/axios/axios';
import { connect, useDispatch } from 'react-redux';
import { getApartmentForUpdate } from '../apartmentSlice'
import Spinner from '../../../spinner/Spinner';

function StepFour(props) {
    const [logisticParam, setLogisticParam] = useState({});
    const [logistic, setLogistic] = useState(props.apartment.logistic);
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorsLogistic, setErrorsLogistic] = useState([]);

    const dispatch = useDispatch();

    function handleLogisticChange(event) {
        handleChange(logisticParam, event);
    }

    function handleChange(params, event) {
        let param = Object.assign({}, params)
        param[event.target.name] = event.target.value;
        setLogisticParam(param);
    }

    function handleSubmit() {
        setIsSubmit(true);
        let dataLogistic = setFormData(logisticParam, logistic);
        let url = 'apartment/' + props.apartment.id + '/logistic';
        if (logistic) {
            url += '/' + logistic.id;
        }
        instanceOfAxios.post(url, dataLogistic)
            .then((res) => {
                let stateApartment = Object.assign({}, props.apartment);
                stateApartment.logistic = res.data.Logistic;
                dispatch(setUpdater(stateApartment));
                setIsSubmit(false);
                setErrorsLogistic([]);
                dispatch(show({ message: 'Mise à jour réussi', variant: 'success' }));
                dispatch(setReload(true));
                dispatch(setStep(5));
            })
            .catch((error) => {
                handleError(error);
            });
    }

    function setFormData(params, object) {
        let formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        });
        if (object && object.id) {
            formData.set('_method', 'PATCH');
        }
        return formData;
    }

    function handleError(errors, type) {
        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        setErrorsLogistic(error);
        setIsSubmit(false);
        dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
    }

    return (
        <Container className='pb-3'>
            <Row>
                <Col>
                    <label><strong>Logistique</strong></label>
                        <Form.Group>
                            <Form.Label>Mode de chauffage</Form.Label>
                            {errorsLogistic['heating_mode'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['heating_mode']}</small></label>}
                            <Form.Control name='heating_mode' size="sm" defaultValue={logistic ? logistic.heating_mode : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Type de chauffage</Form.Label>
                            {errorsLogistic['heating_location'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['heating_location']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='heating_location' size="sm" defaultValue={logistic ? logistic.heating_location : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation climatisation</Form.Label>
                            {errorsLogistic['air_conditioning_location'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['air_conditioning_location']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='air_conditioning_location' size="sm" defaultValue={logistic ? logistic.air_conditioning_location : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation compteur EDF</Form.Label>
                            {errorsLogistic['location_EDF'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['location_EDF']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='location_EDF' size="sm" defaultValue={logistic ? logistic.location_EDF : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation compteur GDF</Form.Label>
                            {errorsLogistic['location_GDF'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['location_GDF']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='location_GDF' size="sm" defaultValue={logistic ? logistic.location_GDF : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nom sur la boite aux lettres</Form.Label>
                            {errorsLogistic['letter_box_name'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['letter_box_name']}</small></label>}
                            <Form.Control name='letter_box_name' size="sm" defaultValue={logistic ? logistic.letter_box_name : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation boite aux lettres</Form.Label>
                            {errorsLogistic['location_letter_box'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['location_letter_box']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='location_letter_box' size="sm" defaultValue={logistic ? logistic.location_letter_box : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation des poubelles</Form.Label>
                            {errorsLogistic['trash_location'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['trash_location']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='trash_location' size="sm" defaultValue={logistic ? logistic.trash_location : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation robinet coupure d'eau</Form.Label>
                            {errorsLogistic['water_cut_off_tap_location'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['water_cut_off_tap_location']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='water_cut_off_tap_location' size="sm" defaultValue={logistic ? logistic.water_cut_off_tap_location : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation compteur d'eau</Form.Label>
                            {errorsLogistic['water_meter_location'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['water_meter_location']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='water_meter_location' size="sm" defaultValue={logistic ? logistic.water_meter_location : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation garage</Form.Label>
                            {errorsLogistic['private_parking_location'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['private_parking_location']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='private_parking_location' size="sm" defaultValue={logistic ? logistic.private_parking_location : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Localisation parking</Form.Label>
                            {errorsLogistic['free_parking_location'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsLogistic['free_parking_location']}</small></label>}
                            <Form.Control as="textarea" rows={2} name='free_parking_location' size="sm" defaultValue={logistic ? logistic.free_parking_location : ''} onChange={(e) => handleLogisticChange(e)} />
                        </Form.Group>
                </Col>
            </Row>
            <Row className="justify-content-end">
                {isSubmit ? <Spinner></Spinner> : <Button variant="secondary" onClick={() => handleSubmit()}>Suivant</Button>}
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepFour);