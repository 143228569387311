import { createSlice } from '@reduxjs/toolkit';
import instanceOfAxios from '../../app/axios/axios';
import moment from 'moment';

export const searchBarSlice = createSlice({
    name: 'searchBar',
    initialState: {
        nbPersons: [],
        searchResult: [],
        param: {'reference': undefined, 'nb_person': 1, 'nb_adult': 1, 'begin_date': undefined, 'end_date': undefined, 'filter': undefined},
        url: '',
    },
    reducers: {
        setNbPersons: (state, value) => {
            state.nbPersons = value.payload;
        },
        setSearchResult: (state, value) => {
            state.SearchResult = value.payload;
        },
        changeNbPerson: (state, value) => {
            state.param.nb_person = value.payload;
        },
        changeValue: (state, value) => {
            state.param[value.payload.type] = value.payload.payload
        },
        setUrl: (state, value) => {
            state.url = value.payload;
        },
    },
});


export const { setUrl, setNbPersons, setSearchResult, changeNbPerson, changeValue } = searchBarSlice.actions;

export function fetchNbPersons() {
    return function (dispatch) {
        return instanceOfAxios.get("/admin/dropDown/6")
            .then(res => {
                const nbMax = [];
                Object.keys(JSON.parse(res.data.dropDown.content)).forEach(function (key) {
                    nbMax.push(key);
                });
                dispatch(setNbPersons(nbMax));
            });
    }
}

// GETTER
export const getNbPersons = state => state.searchBar.nbPersons;
export const getDropDownValue = state => state.searchBar.dropDownValue;
export const getSearchResult = state => state.searchBar.searchResult;
export const getParam = state => state.searchBar.param;
export const getUrl = state => state.searchBar.url;

export default searchBarSlice.reducer;
