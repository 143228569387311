import ListArticle from "./list/listArticle";
import { Row, Col, Tabs, Tab } from 'react-bootstrap';

export default function ArticleTab(){
    return (
        <div className='users slider-list-left slide-in-list-left'>
        <Row>
            <Col md={12} className='text-center'>
                <h2>Article de magazine</h2>
            </Col>
        </Row>
        <Tabs defaultActiveKey="listArticlePublish" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="listArticlePublish" title="Publié">
                <ListArticle publish={1}/>
            </Tab>
            <Tab eventKey="listArticleUnpublish" title="Non publié">
                <ListArticle publish={0}/>
            </Tab>
        </Tabs>
    </div>
    )
}