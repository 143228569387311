import moment from 'moment';
import 'moment/locale/fr'
import './calendar.scss';

export default function ViewerMonth(props) {

    function body() {
        return props.dateUse.map(element => {
            let datas = element[1];
            datas = datas.sort((a, b) => b.end_date_hour > a.end_date_hour ? 1 : -1);

            const daysInMonth = [];

            daysInMonth.push(
                <td className='tdCalendar' colSpan='1'><div style={{ height: '20px' }}>{element[0]}</div></td>
            );

            for (let month = 0; month < 2; month++) {
                let date = new Date(props.year, props.month + month, 1);

                for (let d = 1; d <= moment(date).daysInMonth(); d++) {

                    let caseDate = moment(new Date(props.year, props.month + month, d)).format("YYYY-MM-DD");
    
                    let className = '';

                        for (let i = 0; i < datas.length; i++) {
    
                            // Date de debut et fin de l'objet
                            let beginDateOfIndex = moment(datas[i].begin_date_hour).format("YYYY-MM-DD");
                            let endDateOfIndex = moment(datas[i].end_date_hour).format("YYYY-MM-DD");
    
                            // date de l'objet de avant
                            let beginDateOfPreviousIndex = i > 0 ? moment(datas[i - 1].begin_date_hour).format("YYYY-MM-DD") : null;
    
                            // date de l'objet de avant
                            let endDateOfNextIndex = datas[i + 1] ? moment(datas[i + 1].end_date_hour).format("YYYY-MM-DD") : null;
    
                            if (caseDate > beginDateOfIndex && caseDate < endDateOfIndex) {
                                className = getColor(
                                    (datas[i].reason || datas[i].state),
                                    (datas[i].state ? 'book' : 'cal'),
                                    'in-progress')
                            } else if (endDateOfNextIndex && caseDate == beginDateOfIndex && caseDate == endDateOfNextIndex) {
                                className = getColor(
                                    (datas[i + 1].reason ? datas[i + 1].reason : datas[i + 1].state),
                                    (datas[i + 1].state ? 'book' : 'cal'),
                                    'end',
                                    (datas[i].reason || datas[i].state),
                                    (datas[i].state ? 'book' : 'cal'),
                                    'begin')
                            } else if ((!endDateOfNextIndex && caseDate == beginDateOfIndex) || caseDate == beginDateOfIndex && (endDateOfNextIndex && caseDate != endDateOfNextIndex)) {
                                className = getColor(
                                    (datas[i].reason || datas[i].state),
                                    (datas[i].state ? 'book' : 'cal'),
                                    'begin')
                            } else if ((caseDate == endDateOfIndex && (beginDateOfPreviousIndex && caseDate != beginDateOfPreviousIndex)) ||
                                (caseDate == endDateOfIndex && !beginDateOfPreviousIndex)) {
                                className = getColor(
                                    (datas[i].reason || datas[i].state),
                                    (datas[i].state ? 'book' : 'cal'),
                                    'end')
                            }
                        }

                    let endOrBegin = false;
    
                    if(className.includes('end') || className.includes('begin')){
                        endOrBegin = true;
                    }
                    
                    daysInMonth.push(
                        <td 
                            colSpan='1' 
                            key={d + ' ' + month + ' ' + element[0]} 
                            className={'tdCalendar ' + className} 
                            style={{ border: '1px solid'}}>
                            <div 
                                className='d-flex justify-content-center align-items-center' 
                                style={{ width: '20px', height: '20px' }}>
                                {endOrBegin && <img src='images/admin/icon-end-resa.png' loading="lazy"/>}
                                </div>
                        </td>
                    );

                    endOrBegin = false;
                }
            }

            return (
                <tr>
                    {
                        daysInMonth.map(td => td)
                    }
                </tr>)
        });
    }

    function header() {
        let head = [];
        let months = [new Date(props.year, props.month, 1), new Date(props.year, props.month + 1, 1)];
        for (let i = 0; i < 2; i++) {
            for (let j = 1; j <= moment(months[i]).daysInMonth(); j++) {
                head.push(
                    <th
                        key={j + ' ' + months[i].getMonth()}
                        accessKey={j + '-' + moment(months[i].setDate(j)).format('MMMM Do YYYY')}
                        style={moment().format('MMMM Do YYYY') == moment(months[i].setDate(j)).format('MMMM Do YYYY') ? { backgroundColor: 'lightGreen' } : {}}
                        className='thBordered'>
                        {j}
                    </th>
                );
            }
        }
        return (
            <>
                <tr className='stickyTopMonth'>
                    <th></th>
                    <td className='tdCalendar' colSpan={moment(months[0]).daysInMonth()}>{moment(months[0]).format('MMMM YYYY')}</td>
                    <td className='tdCalendar' colSpan={moment(months[1]).daysInMonth()}>{moment(months[1]).format('MMMM YYYY')}</td>
                </tr>
                <tr className='stickyTopDay'>
                    <th className='thBordered'>Reference</th>
                    {head.map(date => date)}
                </tr>
            </>
        )
    }

    function getColor(firstIndex, firstEntity, firstState, secondIndex = null, secondEntity = null, secondState = null) {
        let state = {
            'à payer': 'need',
            'en attente': 'wait',
            'acompte versé': 'first',
            'payé': 'payed',
            'trop perçu': 'exed',
            'Blocage ClassAppart': 'ca',
            'Blocage propriétaire': 'prop'
        };
        return secondIndex && secondState && secondEntity
            ?
            firstState + '-' + firstEntity + '-' + state[firstIndex] + '-' + secondState + '-' + secondEntity + '-' + state[secondIndex]
            :
            firstState + '-' + firstEntity + '-' + state[firstIndex];
    }

    return (
        <table>
            <thead>
                {
                    header()
                }
            </thead>
            <tbody>
                {
                    body()
                }
            </tbody>
        </table>);
}