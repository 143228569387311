import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import moment from 'moment';

export default class Footer extends Component {
    render() {
        return (
            <>
                <Row className="d-flex footer">
                    <Col lg={5} xl={2} className=" d-flex flex-column align-items-center footerCol borderRight footerSvg">

                        <div className="footerInfo">
                            <a className="whiteLink footerTitle" href="/Presentation#presentation">
                                <h5 className="text-center">CLASS APPART</h5>
                            </a>

                            <div className="d-flex align-items-center Adresse w-100">
                                <svg className="mr-2 ml-2" xmlns="http://www.w3.org/2000/svg" width="30" height="24.347" viewBox="0 0 27.863 24.347">
                                    <path id="Icon_awesome-search-location" data-name="Icon awesome-search-location" d="M27.484,21.05l-5.426-4.741a1.407,1.407,0,0,0-.925-.333h-.887A9.084,9.084,0,0,0,22.64,9.89C22.64,4.427,17.573,0,11.32,0S0,4.427,0,9.89s5.067,9.89,11.32,9.89a12.338,12.338,0,0,0,6.966-2.092v.775a1.072,1.072,0,0,0,.381.808l5.426,4.74a1.437,1.437,0,0,0,1.845,0l1.54-1.346A1.047,1.047,0,0,0,27.484,21.05ZM11.32,16.737c-4.329,0-7.837-3.06-7.837-6.847s3.5-6.847,7.837-6.847S19.157,6.1,19.157,9.89,15.655,16.737,11.32,16.737Zm0-11.411A3.79,3.79,0,0,0,7.3,8.837c0,1.567,2.626,4.425,3.633,5.462a.551.551,0,0,0,.772,0c1.006-1.037,3.633-3.894,3.633-5.462a3.79,3.79,0,0,0-4.018-3.511Zm0,4.565A1.232,1.232,0,0,1,10.016,8.75a1.232,1.232,0,0,1,1.306-1.141A1.232,1.232,0,0,1,12.628,8.75,1.232,1.232,0,0,1,11.322,9.891Z" fill="#fff" />
                                </svg>
                                <p className='mb-1 mt-1 notranslate'>15 pass. Lonjon, 34000 Montpellier</p>
                            </div>

                            <div className="d-flex align-items-center Contact">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30.375" viewBox="0 0 18.985 30.375">
                                    <path id="Icon_awesome-mobile-alt" data-name="Icon awesome-mobile-alt" d="M16.137,0H2.848A2.848,2.848,0,0,0,0,2.848v24.68a2.848,2.848,0,0,0,2.848,2.848H16.137a2.848,2.848,0,0,0,2.848-2.848V2.848A2.848,2.848,0,0,0,16.137,0ZM9.492,28.477a1.9,1.9,0,1,1,1.9-1.9A1.9,1.9,0,0,1,9.492,28.477Zm6.645-6.407a.714.714,0,0,1-.712.712H3.56a.714.714,0,0,1-.712-.712V3.56a.714.714,0,0,1,.712-.712H15.425a.714.714,0,0,1,.712.712Z" fill="#fff" />
                                </svg>
                                <p className="mt-3">09 63 69 87 42</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center footerInfo">
                            <a href="https://fr-fr.facebook.com/ClassAppartinstagram" target="_blank">
                                <svg className="mr-2 ml-2" xmlns="http://www.w3.org/2000/svg" width="23.786" height="26.042" viewBox="0 0 23.786 26.042">
                                    <path id="facebook" d="M24.474,13.788c0-7.237-5.323-13.1-11.893-13.1S.687,6.551.687,13.788c0,6.539,4.349,11.958,10.035,12.942V17.575H7.7V13.788h3.021V10.9c0-3.283,1.774-5.1,4.492-5.1a16.67,16.67,0,0,1,2.663.256V9.283h-1.5a1.813,1.813,0,0,0-1.938,2.046v2.459h3.3l-.528,3.787H14.439V26.73C20.125,25.746,24.474,20.326,24.474,13.788Z" transform="translate(-0.688 -0.688)" fill="#fff" />
                                </svg>
                            </a>

                            <a href="https://www.instagram.com/classappart/" target="_blank">
                                <svg className="mr-2 ml-2" xmlns="http://www.w3.org/2000/svg" width="23.648" height="26.042" viewBox="0 0 23.648 26.042">
                                    <path id="instagram" d="M11.82,9.079c-3.355,0-6.062,2.981-6.062,6.677s2.706,6.677,6.062,6.677,6.062-2.981,6.062-6.677S15.175,9.079,11.82,9.079Zm0,11.018a4.163,4.163,0,0,1-3.941-4.341,4.159,4.159,0,0,1,3.941-4.341,4.159,4.159,0,0,1,3.941,4.341A4.163,4.163,0,0,1,11.82,20.1ZM19.543,8.806a1.42,1.42,0,1,1-2.828,0,1.42,1.42,0,1,1,2.828,0Zm4.015,1.581a8.158,8.158,0,0,0-1.91-5.457,6.7,6.7,0,0,0-4.954-2.1c-1.952-.122-7.8-.122-9.754,0a6.685,6.685,0,0,0-4.954,2.1,8.185,8.185,0,0,0-1.91,5.457c-.111,2.15-.111,8.595,0,10.745a8.158,8.158,0,0,0,1.91,5.457,6.7,6.7,0,0,0,4.954,2.1c1.952.122,7.8.122,9.754,0a6.653,6.653,0,0,0,4.954-2.1,8.212,8.212,0,0,0,1.91-5.457c.111-2.15.111-8.589,0-10.739ZM21.036,23.432a4.214,4.214,0,0,1-2.247,2.476c-1.556.68-5.249.523-6.969.523s-5.418.151-6.969-.523A4.213,4.213,0,0,1,2.6,23.432c-.617-1.714-.475-5.782-.475-7.676S1.992,9.788,2.6,8.08A4.214,4.214,0,0,1,4.851,5.6c1.556-.68,5.249-.523,6.969-.523s5.418-.151,6.969.523A4.213,4.213,0,0,1,21.036,8.08c.617,1.714.475,5.782.475,7.676S21.654,21.724,21.036,23.432Z" transform="translate(0.006 -2.735)" fill="#fff" />
                                </svg>
                            </a>

                            <a href="https://www.pinterest.fr/classappart/_created/" target="_blank">
                                <svg className="mr-2 ml-2" xmlns="http://www.w3.org/2000/svg" width="23.642" height="26.042" viewBox="0 0 23.642 26.042">
                                    <path id="pinterest" d="M23.642,13.709c0,7.193-5.291,13.021-11.821,13.021a10.778,10.778,0,0,1-3.5-.583,15.337,15.337,0,0,0,1.468-3.413c.143-.609.734-3.1.734-3.1a3.092,3.092,0,0,0,2.707,1.5c3.565,0,6.135-3.612,6.135-8.1a7.269,7.269,0,0,0-7.288-7.519c-5.1,0-7.812,3.77-7.812,7.881,0,1.911.925,4.29,2.4,5.046.224.116.343.063.4-.173.038-.179.238-1.066.329-1.475a.419.419,0,0,0-.081-.373,5.483,5.483,0,0,1-.872-2.972A5.385,5.385,0,0,1,11.774,7.8c2.9,0,4.938,2.179,4.938,5.3,0,3.523-1.616,5.965-3.718,5.965a1.845,1.845,0,0,1-1.749-2.352,28.381,28.381,0,0,0,.977-4.337c0-1-.486-1.832-1.5-1.832-1.187,0-2.14,1.349-2.14,3.161a5.088,5.088,0,0,0,.353,1.932S7.77,21.08,7.555,22.1a13.87,13.87,0,0,0-.043,3.738A13.063,13.063,0,0,1,0,13.709C0,6.515,5.291.688,11.821.688S23.642,6.515,23.642,13.709Z" transform="translate(0 -0.688)" fill="#fff" />
                                </svg>
                            </a>
                        </div>
                        <div className="separatorFooter"></div>
                    </Col>

                    <Col lg={5} xl={2} className="d-flex flex-column align-items-center footerCol borderRightCenter">
                        <a className="whiteLink footerTitle" href="/Locataires">
                            <h5>LOCATAIRES</h5>
                        </a>
                        <a className="whiteLink" href="/Locataires">
                            <p>Comment réserver ?</p>
                        </a>
                        <a className="whiteLink" href="/Locataires#services">
                            <p>Nos services</p>
                        </a>
                        <a className="whiteLink" href="/Locataires#avantages">
                            <p>Nos avantages</p>
                        </a>
                    </Col>

                    <Col lg={5} xl={2} className="d-flex flex-column align-items-center footerCol borderRight">
                        <a className="whiteLink footerTitle" href="/Proprietaires">
                            <h5>PROPRIÉTAIRES</h5>
                        </a>
                        <a className="whiteLink" href="/Proprietaires">
                            <p>Comment louer sa propriété</p>
                        </a>
                        <a className="whiteLink" href="/Proprietaires#avantages">
                            <p>Les avantages Class Appart</p>
                        </a>
                    </Col>

                    <Col lg={5} xl={2} className="d-flex flex-column align-items-center footerCol">
                        <a className="whiteLink footerTitle" href="/MonBesoin#my-needs">
                            <h5>UN BESOIN?</h5>
                        </a>
                        <a className="whiteLink" href="/MonBesoin#concept">
                            <p>Notre concept</p>
                        </a>
                        <a className="whiteLink" href="/MonBesoin#work-trip">
                            <p>Voyage d'affaire</p>
                        </a>
                        <a className="whiteLink" href="/MonBesoin#mutation">
                            <p>Mutations</p>
                        </a>
                        <a className="whiteLink" href="/MonBesoin#trip">
                            <p>Vacances</p>
                        </a>
                        <a className="whiteLink" href="/MonBesoin#works">
                            <p className='text-center'>Je suis en travaux, je déménage</p>
                        </a>
                    </Col>
                    <Col xs={12} lg={10} className='text-center copyright conditions'>
                        <p>©{moment().year()} - Class Appart - <a className="whiteLink" href="/CGU">Conditions générales d'utilisation</a> - <a className="whiteLink" href="/CGL">Conditions générales de location</a> - <a className="whiteLink" href="/ML">Mentions Légales</a></p>
                    </Col>
                    <Col xs={12} lg={2} className='text-center copyright conditions'>
                        <div id="translate_element"></div>
                    </Col>
                </Row>
            </>
        )
    }
}