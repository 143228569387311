import React, { useRef } from 'react';
import { useState } from 'react';
import EmailEditor from 'react-email-editor';
import { Row, Button, Col, Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import { useEffect } from 'react';
import Spinner from '../../../spinner/Spinner';
import { FaRegSave, FaPencilAlt } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

export default function (props) {
    const [visibility, setVisibility] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [article, setArticle] = useState(props.article);
    const [imgToDelete, setImgToDelete] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [category, setCategory] = useState();
    const dispatch = useDispatch();
    const emailEditorRef = useRef(null);

    useEffect(() => {
        if (isReady) {
            setIsReady(true);

            if (article && article.design_json) {
                const templateJson = JSON.parse(article.design_json);
                emailEditorRef.current.editor.loadDesign(templateJson)
            } else {
                emailEditorRef.current.editor.loadTemplate(266211);
            }

            emailEditorRef.current.editor.registerCallback('image', function (file, done) {
                var data = new FormData()
                data.append('picture', file.attachments[0])
                instanceOfAxios.post('/admin/Article/' + article.id + '/addPicture', data)
                    .then(res => {
                        done({ progress: 100, url: res.data.url })
                    })
                    .catch(err => {
                        dispatch(show({ message: 'Sauvegarde non effectuée', variant: 'danger' }));
                    });
            });

            emailEditorRef.current.editor.addEventListener('design:updated', function (event, done) {
                if (event.type === 'content:removed' && event.item && event.item.type === 'image') {
                    let pictureUrlArray = event.item.values.src.url.split('/');
                    imgToDelete.push(pictureUrlArray[pictureUrlArray.length - 1]);
                    setImgToDelete(imgToDelete);
                }
            });
        }

    }, [isReady]);


    const saveDesign = () => {
        setIsSubmit(true);
        emailEditorRef.current.editor.exportHtml((design) => {
            let data = {
                _method: 'PATCH',
                content: design.html,
                design_json: JSON.stringify(design.design),
                img_to_delete: imgToDelete,
                name: name,
                description: description,
                category: category
            }
            instanceOfAxios.post('/admin/Article/' + article.id, data)
                .then(res => {
                    dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                    setIsSubmit(false);
                    props.reload(true);
                })
                .catch(err => {
                    dispatch(show({ message: 'Sauvegarde non effectuée', variant: 'danger' }));
                    setIsSubmit(false);
                });
        });
    };

    const onLoad = () => {
        setIsReady(true);
    }

    function handleSubmit() {
        let article = { name: name };
        setIsSubmit(true);
        instanceOfAxios.post('/admin/Article', article)
            .then(res => {
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                setArticle(res.data.Article);
                setIsSubmit(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Sauvegarde non effectuée', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    function handlePublishUnpublish() {
        setIsSubmit(true);
        instanceOfAxios.get('/admin/Article/' + article.id + '/publishOrUnpublish')
            .then(res => {
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                props.reload(true);
                setIsSubmit(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Sauvegarde non effectuée', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    return (
        <>
            <Button variant='secondary' className='whiteSVG' onClick={() => setVisibility(true)}>
                {props.action == 'edit' ?
                    <>
                        <FaPencilAlt /> Modifier
                    </>
                    :
                    <>
                        <IoMdAddCircleOutline /> Ajouter un article
                    </>
                }
            </Button>
            <Modal
                dialogClassName={article ? 'custom-dialog' : ''}
                show={visibility}
                onHide={() => setVisibility(!visibility)}
                scrollable
                backdropClassName="backdropThird"
                centered
                size={article ? 'xl' : ''}
                style={{ zIndex: '20002' }}>
                <Modal.Header closeButton className='d-flex justify-content-between'>
                    <Modal.Title>
                        {
                            article ?
                                article.name
                                :
                                'Création d\'un article'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !article &&
                        <Row className="align-content-center">
                            <Col xs={12}>
                                <Form>
                                    <Form.Group className='mb-2'>
                                        <Form.Label>Nom de l'article</Form.Label>
                                        <Form.Control type='text' name='full_name' onChange={(e) => setName(e.target.value)} />
                                    </Form.Group>
                                </Form>
                            </Col>
                            {
                                isSubmit ?
                                    <Col xs={12} className='d-flex justify-content-center mb-3'>
                                        <Spinner />
                                    </Col>
                                    :
                                    <Col xs={12} className="d-flex justify-content-center">
                                        <Button className='whiteSVG' variant='secondary' onClick={handleSubmit}><FaRegSave /> Enregistrer</Button>
                                    </Col>
                            }

                        </Row>
                    }
                    {
                        article &&
                        <Row>
                            {
                                isSubmit ?
                                    <Col xs={12} className='d-flex justify-content-center mb-3'>
                                        <Spinner />
                                    </Col>
                                    :
                                    <>
                                        <Col xs={6} className='d-flex justify-content-center mb-3'>
                                            <Button className='whiteSVG' variant='secondary' onClick={saveDesign}><FaRegSave /> Enregistrer</Button>
                                        </Col>
                                        <Col xs={6} className="d-flex justify-content-center mb-3">
                                            <Button variant='secondary' onClick={handlePublishUnpublish}>
                                                {
                                                    article.is_public ?
                                                        'Dépublier'
                                                        :
                                                        'Publier'
                                                }
                                            </Button>
                                        </Col>
                                    </>
                            }
                            <Col xs={12} lg={6} className='d-flex justify-content-center mb-2'>
                                <Form.Group className='w-100'>
                                    <Form.Label>Nom de l'article</Form.Label>
                                    <Form.Control type='text' name='full_name' defaultValue={props.article ? props.article.name : article.name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className='d-flex flex-column justify-content-center mb-4'>
                                <Form.Group className='w-100'>
                                    <Form.Label>Catégorie</Form.Label>
                                    <Form.Control name="category" as="select" onChange={(e) => setCategory(e.target.value)}>
                                        {
                                            props.categories && props.categories.map(category => <option selected={props.article && props.article.category == category.name}>{category.name}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className='w-100'>
                                    <Form.Control type='text' name='category' defaultValue={props.article ? props.article.category : article.category} onChange={(e) => setCategory(e.target.value)} />
                                    <small>Entrez un nom de catégories pour créer une nouvelle catégorie (privilégiez les mots uniques)</small>
                                </Form.Group>
                            </Col>
                            <Col xs={12} className='d-flex justify-content-center mb-4'>
                                <Form.Group className='w-100'>
                                    <Form.Label>Description de l'article</Form.Label>
                                    <Form.Control type='text' rows='4' as='textarea' name='description' defaultValue={props.article ? props.article.description : article.description} onChange={(e) => setDescription(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <EmailEditor
                                    options={{
                                        editor: {
                                            confirmOnDelete: true
                                        },
                                        features: {
                                            undoRedo: false
                                        }
                                    }}
                                    projectId={120593}
                                    style={{ height: '80vh' }}
                                    ref={emailEditorRef}
                                    onLoad={onLoad} />
                            </Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};