import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import ViewModal from './ViewModal';
import './carousel.scss';

export function Carousel(props) {
    const [modalVisibility, setModalVisibility] = useState(false);
    const [activeUrl, setActiveUrl] = useState();

    function handleShow(url = null) {
        setModalVisibility(!modalVisibility);
        setActiveUrl(url);
    }

    return (
        <Row className='mt-2'>
            <Col lg={10} xs={12}>
                <Image loading='lazy' src={props.principalPicture.url} className='imgHover' fluid onClick={() => handleShow(props.principalPicture.url)} />
            </Col>
            <Col lg={2} className=' d-flex align-items-center'>
                <Row className=' d-flex flex-lg-column justify-content-center align-items-center'>
                    {
                        props.all && props.all.filter((item, idx) => idx < 3).map(image =>
                            <Col xs={3} lg={12} className='noPadding ml-2 mt-2'>
                                <Image loading='lazy' className='imgHover carousel-img-preview-hover' src={image.url} fluid onClick={() => handleShow(image.url)} alt={'image de logement que nous possèdons à la location'}/>
                            </Col>)
                    }
                </Row>
                {
                    props.all &&
                    <ViewModal images={[props.principalPicture].concat(props.all)} show={modalVisibility} handleClose={handleShow} activeUrl={activeUrl} />
                }
            </Col>
        </Row>
    )
}