import { useState, useEffect } from "react"
import { Button, Modal, Card, Row, Col } from "react-bootstrap";
import UpdateComment from "./update/updateComment";

export default function CommentDetails(props) {
    const [visibility, setVisibility] = useState(false);
    const [content, setContent] = useState(JSON.parse(props.comment.content));

    useEffect(() => {
        let content = JSON.parse(props.comment.content);
        setContent(content);
    }, [props.comment]);

    let update_at = new Date(props.comment.updated_at.replace(/\s/, 'T'))
    var options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <>
            <Button variant='secondary' onClick={() => setVisibility(!visibility)}>Détails</Button>
            <Modal
                scrollable
                centered
                show={visibility}
                size='lg'
                backdropClassName="backdropThird"
                style={{ zIndex: '20001' }}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Row className='d-flex justify-content-around w-100'>
                        <Col xs={12} sm={6} className='text-center'>
                            <h4>Logement : {props.comment.apartment_data.reference}</h4>
                        </Col>
                        <Col xs={12} sm={6} className='text-center'>
                            <h4>{props.comment.first_name + ' ' + props.comment.last_name}</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='text-center'>
                            {`${update_at.toLocaleDateString('fr-CA', options)}`}
                        </Col>
                    </Row>
                </Modal.Header>
                {
                    props.comment.content &&
                    <Modal.Body>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12} className='d-flex justify-content-center mb-2'>
                            <UpdateComment comment={props.comment} setReload={props.setReload} />
                        </Col>
                        <Col xs={12}>
                            <Card.Title>
                                Personne attribuée au ménage et check-in
                            </Card.Title>
                        </Col>
                        <Col xs={12} md={6} className='d-flex justify-content-center'>
                            <p>Check-in : </p>
                            <strong>{content.check_in}</strong>
                        </Col>
                        <Col xs={12} md={6} className='d-flex justify-content-center'>
                            <p>Ménage : </p>
                            <strong>{content.cleaning}</strong>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <Card.Title>
                                Avis global
                            </Card.Title>
                        </Col>
                        <Col xs={12}>
                            <p>Message : </p>
                            <p>{content.global}</p>
                        </Col>
                        <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                            <Card.Title>
                                score général : {props.score} / 5
                            </Card.Title>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <Card.Title>
                                Arrivée
                            </Card.Title>
                        </Col>
                        <Col xs={12}>
                            <p>Message : </p>
                            <p>{content.check_in_score.check_in_message}</p>
                        </Col>
                        <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                            <p>score : </p>
                            <p> {content.check_in_score.score} / 10</p>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <Card.Title>
                                Ménage
                            </Card.Title>
                        </Col>
                        <Col xs={12}>
                            <p>Message : </p>
                            <p>{content.cleaning_score.cleaning_message}</p>
                        </Col>
                        <Col xs={12} className='d-flex align-items-center justify-content-center'>
                            <ul>
                                score :
                                <li>Salon : {content.cleaning_score.living_room_score} / 5</li>
                                <li>Salle de bain : {content.cleaning_score.bathroom_score} / 5</li>
                                <li>Cuisine : {content.cleaning_score.kitchen_score} / 5</li>
                                <li>Chambre : {content.cleaning_score.room_score} / 5</li>
                            </ul>
                            <ul>
                                <li>Toilette : {content.cleaning_score.toilet_score} / 5</li>
                                <li>Sol : {content.cleaning_score.floor_score} / 5</li>
                                <li>Extérieur : {content.cleaning_score.outside_score} / 5</li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <Card.Title>
                                Equipements
                            </Card.Title>
                        </Col>
                        <Col xs={12}>
                            <p>Message : </p>
                            <p>{content.equipment_score.cleaning_message}</p>
                        </Col>
                        <Col xs={12} className='d-flex align-items-center justify-content-center'>
                            <ul>
                                score :
                                <li>Salon : {content.equipment_score.living_room_score} / 5</li>
                                <li>Salle de bain : {content.equipment_score.bathroom_score} / 5</li>
                                <li>Cuisine : {content.equipment_score.kitchen_score} / 5</li>
                            </ul>
                            <ul>
                                <li>Chambre : {content.equipment_score.room_score} / 5</li>
                                <li>Toilette : {content.equipment_score.toilet_score} / 5</li>
                                <li>Extérieur : {content.equipment_score.outside_score} / 5</li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <Card.Title>
                                Etat général
                            </Card.Title>
                        </Col>
                        <Col xs={12}>
                            <p>Message : </p>
                            <p>{content.state_score.cleaning_message}</p>
                        </Col>
                        <Col xs={12} className='d-flex align-items-center justify-content-center'>
                            <ul>
                                score :
                                <li>Salon : {content.state_score.living_room_score} / 5</li>
                                <li>Salle de bain : {content.state_score.bathroom_score} / 5</li>
                                <li>Cuisine : {content.state_score.kitchen_score} / 5</li>
                                <li>Chambre : {content.state_score.room_score} / 5</li>
                            </ul>
                            <ul>
                                <li>Toilette : {content.state_score.toilet_score} / 5</li>
                                <li>Sol : {content.state_score.floor_score} / 5</li>
                                <li>Extérieur : {content.state_score.outside_score} / 5</li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <Card.Title>
                                Remarque(s)
                            </Card.Title>
                        </Col>
                        <Col xs={12}>
                            <p>{content.remark}</p>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <Card.Title>
                                Recommandations(s)
                            </Card.Title>
                        </Col>
                        <Col xs={12}>
                            <p>{content.recommand}</p>
                        </Col>
                        <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '60%',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}></div>
                        </Col>
                    </Row>
                </Modal.Body>
                }
            </Modal>
        </>
    )
}