import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/searchBar/SearchBar'
import HomeCarousel from '../../components/home/homeCaroussel/HomeCarousel'
import HomeSelections from "../../components/home/homeLinks/HomeSelection/HomeSelections"
import Title from "../../components/home/homeTitle/HomeTitle";
import HomeRating from '../../components/home/homeRating/HomeRating';
import instanceOfAxios from '../../app/axios/axios';
import Informations from '../../components/home/information/Informations';

export default function Home() {
    const [apartments, setApartments] = useState(null);
    const [page, setPage] = useState(null);

    const simulatedPageData = {
        "page": [
            {
                "id": 1,
                "type": "home",
                "content": "{\"carousel\":{\"text\":{\"text1\":\" un appartement à la montagne\",\"text2\":\" un séjour atypique\",\"text3\":\" un logement au pied des pistes\"}}}",
                "updated_by": null,
                "created_at": "2022-01-18T12:00:29.000000Z",
                "updated_at": "2022-01-18T17:40:21.000000Z",
                "pictures_url": {
                    "background": "https://www.class-appart.com/api/storage/Page/home/background/background.png",
                    "carousel": [
                        {
                            "id": 9329,
                            "url": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9329/carousel.webp",
                            "medium": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9329/conversion/carousel-medium.webp",
                            "mini": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9329/conversion/carousel-medium.webp"
                        },
                        {
                            "id": 9330,
                            "url": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9330/carousel.webp",
                            "medium": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9330/conversion/carousel-medium.webp",
                            "mini": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9330/conversion/carousel-medium.webp"
                        },
                        {
                            "id": 9331,
                            "url": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9331/carousel.webp",
                            "medium": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9331/conversion/carousel-medium.webp",
                            "mini": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9331/conversion/carousel-medium.webp"
                        },
                        {
                            "id": 9332,
                            "url": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9332/carousel.webp",
                            "medium": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9332/conversion/carousel-medium.webp",
                            "mini": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9332/conversion/carousel-medium.webp"
                        },
                        {
                            "id": 9333,
                            "url": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9333/carousel.webp",
                            "medium": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9333/conversion/carousel-medium.webp",
                            "mini": "https://www.class-appart.com/api/storage/Page/home/carousel/picture_9333/conversion/carousel-medium.webp"
                        }
                    ]
                },
                "media": [
                    {
                        "id": 6353,
                        "model_type": "App\\Models\\Site\\Home\\Page",
                        "model_id": 1,
                        "collection_name": "home-background",
                        "name": "1200px-Fond_blanc",
                        "file_name": "background.png",
                        "uuid": "cb60df02-fe44-416e-a6e4-1b5df6984c33",
                        "conversions_disk": "public",
                        "mime_type": "image/png",
                        "disk": "public",
                        "size": 696,
                        "manipulations": [],
                        "custom_properties": {
                            "type": "background"
                        },
                        "responsive_images": [],
                        "order_column": 6324,
                        "created_at": "2022-01-20T12:36:36.000000Z",
                        "updated_at": "2023-05-12T14:35:20.000000Z"
                    },
                    {
                        "id": 9329,
                        "model_type": "App\\Models\\Site\\Home\\Page",
                        "model_id": 1,
                        "collection_name": "home-carousel",
                        "name": "carousel_1_",
                        "file_name": "carousel.webp",
                        "uuid": "7efd4a3e-f22c-4d6e-bd94-8b0f0beec75f",
                        "conversions_disk": "public",
                        "mime_type": "image/webp",
                        "disk": "public",
                        "size": 127210,
                        "manipulations": [],
                        "custom_properties": {
                            "type": "carousel",
                            "generated_conversions": {
                                "mini": true,
                                "medium": true
                            }
                        },
                        "responsive_images": [],
                        "order_column": 9290,
                        "created_at": "2022-03-03T15:08:28.000000Z",
                        "updated_at": "2023-05-12T15:14:37.000000Z"
                    },
                    {
                        "id": 9330,
                        "model_type": "App\\Models\\Site\\Home\\Page",
                        "model_id": 1,
                        "collection_name": "home-carousel",
                        "name": "carousel_1_",
                        "file_name": "carousel.webp",
                        "uuid": "9a52ebd1-e8cc-45bb-b96d-e0484b3f2a43",
                        "conversions_disk": "public",
                        "mime_type": "image/webp",
                        "disk": "public",
                        "size": 103114,
                        "manipulations": [],
                        "custom_properties": {
                            "type": "carousel",
                            "generated_conversions": {
                                "mini": true,
                                "medium": true
                            }
                        },
                        "responsive_images": [],
                        "order_column": 9291,
                        "created_at": "2022-03-03T15:11:50.000000Z",
                        "updated_at": "2023-05-12T15:14:42.000000Z"
                    },
                    {
                        "id": 9331,
                        "model_type": "App\\Models\\Site\\Home\\Page",
                        "model_id": 1,
                        "collection_name": "home-carousel",
                        "name": "carousel_1_",
                        "file_name": "carousel.webp",
                        "uuid": "cd17bad9-2acf-4e08-a5fd-68f15e5cf7da",
                        "conversions_disk": "public",
                        "mime_type": "image/webp",
                        "disk": "public",
                        "size": 99956,
                        "manipulations": [],
                        "custom_properties": {
                            "type": "carousel",
                            "generated_conversions": {
                                "mini": true,
                                "medium": true
                            }
                        },
                        "responsive_images": [],
                        "order_column": 9292,
                        "created_at": "2022-03-03T15:14:59.000000Z",
                        "updated_at": "2023-05-12T15:14:47.000000Z"
                    },
                    {
                        "id": 9332,
                        "model_type": "App\\Models\\Site\\Home\\Page",
                        "model_id": 1,
                        "collection_name": "home-carousel",
                        "name": "carousel_1_",
                        "file_name": "carousel.webp",
                        "uuid": "17948202-08a5-406b-b304-41bea05e2eaa",
                        "conversions_disk": "public",
                        "mime_type": "image/webp",
                        "disk": "public",
                        "size": 98368,
                        "manipulations": [],
                        "custom_properties": {
                            "type": "carousel",
                            "generated_conversions": {
                                "mini": true,
                                "medium": true
                            }
                        },
                        "responsive_images": [],
                        "order_column": 9293,
                        "created_at": "2022-03-03T15:16:26.000000Z",
                        "updated_at": "2023-05-12T15:14:53.000000Z"
                    },
                    {
                        "id": 9333,
                        "model_type": "App\\Models\\Site\\Home\\Page",
                        "model_id": 1,
                        "collection_name": "home-carousel",
                        "name": "carousel_1_",
                        "file_name": "carousel.webp",
                        "uuid": "9fc84aa0-6f97-445c-8050-7443eeec6d24",
                        "conversions_disk": "public",
                        "mime_type": "image/webp",
                        "disk": "public",
                        "size": 124668,
                        "manipulations": [],
                        "custom_properties": {
                            "type": "carousel",
                            "generated_conversions": {
                                "mini": true,
                                "medium": true
                            }
                        },
                        "responsive_images": [],
                        "order_column": 9294,
                        "created_at": "2022-03-03T15:18:08.000000Z",
                        "updated_at": "2023-05-12T15:15:00.000000Z"
                    }
                ]
            }
        ]
    };


    useEffect(() => {
        if (apartments == null) {
            FetchApartmentForHomePage();
        }
    }, [])

    useEffect(() => {
        if (page == null) {
            FetchDataPage();
        }
    }, [page])

    function FetchApartmentForHomePage() {
        instanceOfAxios.get('/apartment/forHomePage')
            .then(res => {
                setApartments(res.data.apartments);
            });
    }


    function FetchDataPage() {
        const country = getCookie('country'); // Récupérer la valeur du cookie
        const type = country === 'suisse' ? 'homeSwitzerland' : 'home'; // Conditionner le type

        if(country === 'suisse'){
            setPage(simulatedPageData.page[0]);
        }else{
            instanceOfAxios.get(`/admin/page?type=${type}`)
                .then(res => {
                    setPage(res.data.page[0]);
                });
        }
    }

    // Fonction utilitaire pour lire un cookie
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null; // Retourne null si le cookie n'existe pas
    }

    return (
        <div>
            <div>
                <Title page={page} />
                <HomeCarousel page={page} className="homeCaroussel"/>
                <div className='displaySearchBarHome'>
                    <SearchBar className="searchBar"/>
                </div>
            </div>
            <HomeSelections apartments={apartments} />
            <HomeRating />
            <Informations/>
        </div>
    );
}
