import { Button, Modal, Row, Col, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { show } from "../../../Alerter/alerterSlice";
import { useDispatch } from "react-redux";
import EditOrCreateTaxeType from "../agencyCard/agencyTaxeType/editOrCreateTaxeType";
import instanceOfAxios from "../../../../../app/axios/axios";
import DeleteModal from "../../../../generiqueModal/deleteModal/deleteModal";
import AgencyTaxeType from "../agencyCard/agencyTaxeType/agencyTaxeType";
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';

export default function TaxeList(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(true);
    const [taxeTypes, setTaxeTypes] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            setPending(false);
            instanceOfAxios.get(`admin/taxeType?agencyId=${props.agency.id}`)
                .then(res => setTaxeTypes(res.data.types));
        }
    }, [pending])

    function handleDelete(id) {
        instanceOfAxios.delete(`admin/taxeType/${id}`)
            .then(res => {
                setPending(true);
                dispatch(show({ message: 'Classe de tarifs supprimée', variant: 'success' }));
            })
            .catch(err => {
                if (err.response.status === 424) {
                    dispatch(show({ message: err.response.data.relation, variant: 'danger' }));
                } else {
                    dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
                }
            });
    }

    return (
        <>
            <Button variant="secondary whiteSVG" onClick={() => setVisibility(true)}><MdOutlineFormatListNumberedRtl /> Liste des Classes de tarifs</Button>
            <Modal
                show={visibility}
                onHide={() => setVisibility(false)}
                scrollable
                backdropClassName="backdropThird"
                centered
                style={{ zIndex: '20003' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Agence de {props.agency.location}
                        </Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} className='text-center'>
                            <EditOrCreateTaxeType
                                agency={props.agency}
                                handleChange={setPending}
                            />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center justify-content-lg-end noPadding'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '70%',
                                    marginTop: '10px',
                                    marginBottom: '10px'
                                }}></div>
                        </Col>
                        {
                            taxeTypes.length == 0 ?
                                <Col xs={12} className='text-center'>
                                    <h4>Aucune classe de taxes renseignée actuellement</h4>
                                </Col>
                                :
                                taxeTypes.map(data =>
                                    <Col xs={12}>
                                            <div className='cartridge mb-3'>
                                                <Row className='d-flex justify-content-center align-items-center'>
                                                    <Col xs={12} xl={8} className='d-flexalign-items-center'>
                                                        <h5 style={{ margin: 'unset' }}>Classe : {data.type}</h5>
                                                    </Col>
                                                    <Col xs={6} xl={2} className='d-flex justify-content-center align-items-center'>
                                                        <EditOrCreateTaxeType
                                                            taxeType={data}
                                                            agency={props.agency}
                                                            handleChange={setPending}
                                                            for='edit' />
                                                    </Col>
                                                    <Col xs={6} xl={2} className='d-flex justify-content-center align-items-center'>
                                                        <DeleteModal deleteFunction={() => handleDelete(data.id)} />
                                                    </Col>
                                                    <Col xs={12} className='d-flex justify-content-center justify-content-lg-end noPadding'>
                                                        <div className='separator'
                                                            style={{
                                                                border: '1px solid #B7A57E',
                                                                color: '#b7a57e',
                                                                width: '70%',
                                                                marginTop: '10px'
                                                            }}></div>
                                                    </Col>
                                                    <AgencyTaxeType
                                                        data={data}
                                                        agency={props.agency.id}
                                                        location={props.agency.location}
                                                        taxeType={props.taxeTypes} />
                                                </Row>
                                            </div>
                                    </Col>
                                )
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}