import React from 'react';
import { Card, Col, Button, Row, Form } from 'react-bootstrap'
import { ShowCreate } from '../apartmentSlice';
import { useDispatch } from 'react-redux';
import { setReload, setTypeListToReload } from '../list/apartmentListSlice';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../../admin/Alerter/alerterSlice';
import './CardStyle.scss'
import { useState } from 'react';
import Spinner from '../../../spinner/Spinner';

export default function ApartmentCard(props) {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);

    function reload() {
        dispatch(setTypeListToReload('home'));
        dispatch(setReload(true));
        dispatch(setTypeListToReload('all'));
        dispatch(setReload(true));
        dispatch(setTypeListToReload('toPublish'));
        dispatch(setReload(true));
        dispatch(setTypeListToReload('archive'));
        dispatch(setReload(true));
        setTimeout(() => {
            setPending(false);
        }, 1000);
    }

    function handlePublishSubmit() {
        setPending(true);
        let publish = new FormData();
        publish.set('publish', 1);
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/publish', publish)
            .then((res) => {
                reload();
            })
            .catch((error) => {
                dispatch(show({ message: 'Les informations suivante sont manquante : ' + error.response.data.error, variant: 'danger' }));
            })
    }

    function handleUnpublishSubmit() {
        setPending(true);
        let publish = new FormData();
        publish.set('publish', 0);
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/publish', publish)
            .then((res) => {
                reload();
            })
    }

    function handleUnarchiveSubmit() {
        setPending(true);
        let archive = new FormData();
        archive.set('archive', 0);
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/archive', archive)
            .then((res) => {
                reload();
            })
    }

    function handleArchiveSubmit() {
        setPending(true);
        let archive = new FormData();
        archive.set('archive', 1);
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/archive', archive)
            .then((res) => {
                reload();
            })
    }

    function handleHomeAndUnhomeSubmit(event) {
        setPending(true);
        let onHome = new FormData();
        let value = 0;
        if (event.checked) {
            value = 1;
        }
        onHome.set('is_on_home_page', value);
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/forOnHomePage', onHome)
            .then((res) => {
                reload();
            })
    }

    return (
        <Col xs={12} lg={6} xl={4}>
            <Card className="apartmentCard shadowForCard">
                <Card.Header>
                    <Card.Title className="cardTexts">{props.apartment.reference}</Card.Title>
                    <Row className="d-flex justify-content-around ">
                        {
                            pending ?
                                <div className='mt-2'>
                                    <Spinner/>
                                </div>
                                :
                                <>
                                    {
                                        !props.apartment.supplementary_information.is_archived &&
                                        <Col md={12} lg={6} className="apartment-admin-button d-flex justify-content-center">
                                            {props.apartment.supplementary_information.is_publish ?
                                                <Button variant="secondary" onClick={handleUnpublishSubmit}>Dépublier</Button> :
                                                <Button variant="secondary" onClick={handlePublishSubmit}>Publier</Button>}
                                        </Col>
                                    }
                                    <Col md={12} lg={6} className="apartment-admin-button d-flex justify-content-center">
                                        {!props.apartment.supplementary_information.is_archived ?
                                            <Button variant="secondary" onClick={handleArchiveSubmit}>Archiver</Button> :
                                            <Button variant="secondary" onClick={handleUnarchiveSubmit}>Désarchiver</Button>}
                                    </Col>
                                    {props.apartment.supplementary_information.is_publish ?
                                        <Col className='mt-2'>
                                            <Form.Check
                                                type="switch"
                                                id={"custom-switch" + props.apartment.id}
                                                label="Mettre en avant"
                                                defaultChecked={props.apartment.supplementary_information.is_on_home_page}
                                                onClick={(e) => handleHomeAndUnhomeSubmit(e.target)}
                                            />
                                        </Col>
                                        :
                                        <></>}
                                </>
                        }
                    </Row>
                </Card.Header>
                {
                    props.apartment.principal_picture_url &&
                    <Card.Img className="ApartmentCardImage" variant="top" src={props.apartment.principal_picture_url.medium} />
                }
                <Card.Body className="apartmentCardBody">
                    <small><Card.Text className="cardTexts noReturn">{props.apartment.type === "Appartement" || props.apartment.type === "Apartment" ? "Appartement" : "Villa"}</Card.Text></small>
                    <Row noGutters>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <a href={'/apartment/' + props.apartment.id} target='_blank'>
                                <Button size="md" variant="secondary" className='whiteSVG' style={{ marginBottom: "5px" }}>
                                    Visualiser l'annonce
                                </Button>
                            </a>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <Button
                                size="md"
                                className='whiteSVG'
                                variant="secondary"
                                style={{ marginBottom: "5px" }}
                                onClick={() => {
                                    dispatch(ShowCreate(props.apartment));
                                    dispatch(setTypeListToReload(props.type));
                                }}>
                                Voir le descriptif
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}