import React, { useState } from 'react';
import Spinner from '../../../../../spinner/Spinner';
import instanceOfAxios from '../../../../../../app/axios/axios';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { show } from '../../../../Alerter/alerterSlice';
import { useDispatch } from 'react-redux';
import '../../../bookingTab.scss'

export default function CreateOrEditPayment(props) {
    const [isSubmit, setIsSubmit] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const [option, setOption] = useState(props.option || {});
    const [error, setError] = useState([]);
    const [param, setParam] = useState(Object.assign({}, props.option) || {});
    const dispatch = useDispatch();

    function handleChange(event) {
        const values = Object.assign({}, param);
        values[event.target.name] = event.target.value;
        setParam(values);
    }

    function handleSubmit() {
        setIsSubmit(true);
        const parameter = new FormData();
        if (props.for === 'update') {
            setFormData(parameter, 'update');
            parameter.set('_method', 'PATCH');
            instanceOfAxios.post('/apartment/' + props.booking.apartment_id + '/booking/' + option.booking_id + '/option/' + option.id, parameter)
                .then((res) => {
                    instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                        .then(res => {
                            setVisibility(false);
                            setIsSubmit(false);
                            dispatch(show({ message: 'Option mise à jour', variant: 'success' }));
                            if(props.bookingDetailsOnChange){
                                props.BookingDetailsOnChange(res.data.booking);
                            }
                        })
                }).catch((error) => {
                    handleError(error);
                });
        } else {
            setFormData(parameter, 'post');
            instanceOfAxios.post('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id + '/option', parameter)
                .then((res) => {
                    instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                        .then(res => {
                            setVisibility(false);
                            setIsSubmit(false);
                            setParam({});
                            props.BookingDetailsOnChange(res.data.booking);
                            dispatch(show({ message: 'Option ajoutée', variant: 'success' }));
                        })
                }).catch((error) => {
                    handleError(error);
                });
        }
    }

    function setFormData(formData, typeRequest) {
        if (typeRequest === 'update') {
            Object.keys(option).forEach((key) => {
                formData.set(key, param[key] || option[key] || '');
            });
            formData.set('_method', 'PATCH');
        } else {
            Object.keys(param).forEach((key) => {
                formData.set(key, param[key] || '');
            });
        }
    }

    function handleError(error) {
        const logError = [];
        Object.keys(error.response.data).forEach(function (key) {
            logError[key] = error.response.data[key];
        });
        setIsSubmit(false);
        setError(logError);
        dispatch(show({ message: 'Erreur lors de l\'opération', variant: 'danger' }));
    }
    return (
        <>
            {props.for === 'create' ? <Button className="addOptionButton whiteSVG" variant="secondary" size="sm" onClick={() => setVisibility(true)}> <MdAdd /> </Button>
                :
                <Button className="modifyOptionButton whiteSVG" variant="secondary" style={{ margin: "1vh" }} size="sm" onClick={() => setVisibility(true)}><FaPencilAlt /></Button>}
            <Modal
                show={visibility}
                showMask={false}
                onHide={() => setVisibility(false)}
                centered
                backdropClassName="backdropFourth"
                style={{ zIndex: '20003' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.option ? 'Modification d\'une option' : 'Ajout d\'une option'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label htmlFor='value'>Montant TTC*</Form.Label>
                        {error['value'] ? <label htmlFor="value"><small style={{ color: "red" }}>{error['value']}</small></label> : <></>}
                        <InputGroup className="mb-3">
                            <Form.Control name='value' id='value' placeholder="Montant" aria-describedby="basic-addon1" onChange={(e) => handleChange(e)} value={param.value || ""} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor='value'>Dont TVA</Form.Label>
                        {error['TVA'] ? <label htmlFor="TVA"><small style={{ color: "red" }}>{error['TVA']}</small></label> : <></>}
                        <InputGroup className="mb-3">
                            <Form.Control name='TVA' id='TVA' placeholder="TVA" aria-describedby="basic-addon1" onChange={(e) => handleChange(e)} value={param.TVA || ""} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor='description'>Description *</Form.Label>
                        {error['description'] ? <label htmlFor="description"><small style={{ color: "red" }}>{error['description']}</small></label> : <></>}
                        <InputGroup className="mb-3">
                            <Form.Control name='description' id='description' placeholder="Description" aria-describedby="basic-addon1" onChange={(e) => handleChange(e)} value={param.description || ""} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Ajout/Retrait *</Form.Label>
                        {error['sign'] ? <label htmlFor="sign"><small style={{ color: "red" }}>{error['sign']}</small></label> : <></>}
                        <Form.Control as="select" name="sign" value={param.sign || ''} onChange={(e) => handleChange(e)} required>
                            <option>Selectionner un signe</option>
                            <option>-</option>
                            <option>+</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {!isSubmit ? <button className="btn btn-secondary" onClick={handleSubmit}>Valider</button> : <Spinner></Spinner>}
                </Modal.Footer>
            </Modal>
        </>
    )

}