import instanceOfAxios from "../../../../app/axios/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { show } from "../../Alerter/alerterSlice";
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import Spinner from "../../../spinner/Spinner";

export default function GenerateInvoieOrBillRider(props){
    const [visibility, setVisibility] = useState();
    const [modelName, setModelName] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();

    function handleGenerate(){
        setIsSubmit(true);

        instanceOfAxios.get(modelName == 'avoir' ? props.billRiderUrl : props.invoiceUrl)
            .then(() => {
                dispatch(show({message: 'Document correctement généré.', variant: 'success'}));
                setIsSubmit(false);
                if(props.reloadFrom){
                   instanceOfAxios.get(props.reloadFrom)
                   .then(res => {
                        if(res.data.booking){
                            props.set(res.data.booking);
                        }else if(res.data.urls){
                            props.set(res.data.urls)
                        }else{
                            props.set(res.data)
                        }
                        setVisibility(false);
                   })
                }
            })
            .catch(() => {
                dispatch(show({message: 'Erreur lors de la génération du document.', variant: 'danger'}));
                setIsSubmit(false);
            })
    }

    return (
        <>
            <Button variant='secondary' className='goldeHover' onClick={() => setVisibility(!visibility)}>
                Générer un document
            </Button>
            <Modal
                backdropClassName="backdropFifth"
                scrollable
                centered
                show={visibility}
                style={{zIndex: 20004}}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                        Générer une facture ou un avoir
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>Attention :</p>
                            <ul>
                                <li>
                                    Si la dernière facture est en pro format, aucun avoir ne seras généré
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>Veuillez séléctionner un type de document à générer.</Form.Label>
                                <Form.Control as="select" onChange={(e) => setModelName(e.target.value)}>
                                    <option>selectionner un document</option>
                                    <option value='facture'>Facture</option>
                                    <option value='avoir'>Avoir</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            {
                                isSubmit ?
                                    <Spinner/>
                                :
                                    <Button variant='secondary' className='goldeHover' onClick={handleGenerate}>Générer</Button>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}