import React, { useEffect, useState } from "react";
import "./ApartmentRent.scss";
import { FaRegSave } from "react-icons/fa";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  FormControl,
  Modal,
} from "react-bootstrap";
import Spinner from "../../../../spinner/Spinner";
import instanceOfAxios from "../../../../../app/axios/axios";
import { useDispatch } from "react-redux";
import { show } from "../../../Alerter/alerterSlice";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="secondary"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </Button>
));

const CustomMenuRef = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");
    const lang = navigator.language;
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul
          className="list-unstyled"
          style={{
            position: "relative",
            overflow: "auto",
            maxHeight: "300px",
            maxWidth: "600px",
          }}
        >
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().includes(value)
          )}
        </ul>
      </div>
    );
  }
);

export default function AddDetentionWithoutRent(props) {
  const [visibility, setVisibility] = useState(false);
  const [pending, setPending] = useState(false);
  const [option, setOption] = useState({});
  const [apartments, setApartments] = useState([]);
  const [selectedApartment, setSelectedAppartment] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setPending(true);

    instanceOfAxios.get("/admin/SecondIndexApartments").then((res) => {
      setPending(false);
      setApartments(res.data.apartments);
    });
  }, []);

  function handlechange(event) {
    let newOption = Object.assign({}, option);
    newOption[event.target.name] = event.target.value;
    setOption(newOption);
  }

  function handleSave() {
    setPending(true);
    instanceOfAxios
      .post(
        `/admin/apartment/${selectedApartment.id}/addDetention/${props.year}/${props.month}`,
        option
      )
      .then((res) => {
        setPending(false);
        props.reload(true);
        hide();
        dispatch(
          show({ message: "Modification enregistrée", variant: "success" })
        );
      })
      .catch((err) => {
        setPending(false);
        dispatch(
          show({
            message:
              "Une erreur est survenue lors de la sauvegarde veuillez réessayer ultérieurement",
            variant: "danger",
          })
        );
      });
  }
  function hide(){
    setVisibility(false);
    setOption({});
    setSelectedAppartment();
  }

  return (
    <Col xs={12} className="d-flex justify-content-center align-items-center">
      <Button
        size="sm"
        variant="secondary"
        style={{ margin: ".5rem" }}
        onClick={() => setVisibility(true)}
      >
        Ajouter ou retenir sur un loyer non présent
      </Button>
      <Modal
        scrollable
        centered
        show={visibility}
        onHide={() => hide()}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="d-flex justify-content-around"
            style={{ width: "100%" }}
          >
            Ajouter ou retenir
          </Modal.Title>
        </Modal.Header>
        {!pending ? (
          <>
            <Modal.Body style={{ minHeight: "200px" }}>
              <Form>
                <Row>
                  <Col md={12} className="text-center">
                    <Dropdown style={{ padding: "10px" }}>
                      <Dropdown.Toggle as={CustomToggle}>
                        {selectedApartment
                          ? selectedApartment.reference
                          : "Choisir une propriété"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenuRef}>
                        {apartments &&
                          apartments.map((value) => (
                            <Dropdown.Item
                              key={value.id}
                              className="text-center"
                              name="apartment"
                              id={value.id}
                              eventkey={value.reference}
                              onClick={(e) => setSelectedAppartment(value)}
                            >
                              {value.reference}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                {selectedApartment && (
                  <>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Raison</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Raison"
                        name="reason"
                        onChange={(e) => handlechange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Montant TTC</Form.Label>
                      <br />
                      <small> Si valeur négative ajouter le signe "-"</small>
                      <Form.Control
                        type="number"
                        placeholder="50"
                        name="amount"
                        onChange={(e) => handlechange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Dont TVA</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="50"
                        name="tva_value"
                        onChange={(e) => handlechange(e)}
                      />
                    </Form.Group>
                  </>
                )}
              </Form>
              {option.amount && (
                <Row>
                  <Col>
                    <p>
                      {option.amount > 0 ? "Un ajout" : "Une retenue"} d'un
                      montant de {option.amount}€ sera éffectué
                      {option.amount > 0 ? "" : "e"} sur le loyer du logement{" "}
                      {selectedApartment.reference} pour le mois de {props.date}
                    </p>
                  </Col>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              {pending ? (
                <Spinner />
              ) : (
                <Button
                  variant="secondary"
                  className="whiteSVG"
                  onClick={() => handleSave()}
                >
                  <FaRegSave /> Enregistrer
                </Button>
              )}
            </Modal.Footer>
          </>
        ) : (
          <Modal.Body>
            <Row className="d-flex justify-content-center">
              <Col className="d-flex justify-content-center">
                <Spinner />
              </Col>
            </Row>
          </Modal.Body>
        )}
      </Modal>
    </Col>
  );
}
