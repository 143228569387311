import { useState } from "react";
import Spinner from '../../../../spinner/Spinner';
import { Button, Col, Form, Modal } from "react-bootstrap";
import instanceOfAxios from "../../../../../app/axios/axios";
import { useDispatch } from "react-redux";
import { show } from "../../../Alerter/alerterSlice";

export default function PriceInfluencerForm(props) {
    const [visibility, setVisibility] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [newDataParam, setNewDataParam] = useState({});
    const [newDataError, setNewDataError] = useState({});
    const Dispatch = useDispatch();

    function handleNewDataChange(event) {
        if (event.target.type === 'checkbox') {
            newDataParam[event.target.name] = event.target.checked ? 1 : 0
        } else {
            newDataParam[event.target.name] = event.target.value;
        }
        setNewDataParam(newDataParam);
    }

    function handleNewDataSubmit() {
        instanceOfAxios.post(`/apartment/${props.apartment.id}/priceInfluencer`, newDataParam)
            .then(res => {
                setNewDataParam({});
                setSubmit(false);
                props.setPending(true);
                setVisibility(false);
                Dispatch(show({ message: 'Action réussi', variant: 'success' }));
            })
            .catch(error => {
                setSubmit(false);
                setNewDataError(error.response.data.error);
                Dispatch(show({ message: `Action échouée, la période est déjà occupée par ${error.response.data.error}`, variant: 'danger' }));
            })
    }

    return (
        <>
            <Button onClick={() => setVisibility(true)} variant='secondary'>Ajouter un pourcentage</Button>
            <Modal
                show={visibility}
                showMask={false}
                onHide={() => { setVisibility(false); setSubmit(false); setNewDataParam({}); setNewDataError([]) }}
                centered
                style={{ zIndex: '20000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nouveau Pourcentage
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Nom</Form.Label>
                            <Form.Control id={newDataParam.length + ''} name='name' size="sm" placeholder="Entrer un nom" onChange={(e) => handleNewDataChange(e)} />
                            <small style={{ color: 'red' }}>{newDataError['name']}</small>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Début</Form.Label>
                            <Form.Control type='date' id={newDataParam.length + ''} name='begin_date_hour' size="sm" placeholder="jour-mois" onChange={(e) => handleNewDataChange(e)} />
                            <small style={{ color: 'red' }}>{newDataError['begin_date_hour']}</small>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Fin</Form.Label>
                            <Form.Control type='date' id={newDataParam.length + ''} name='end_date_hour' size="sm" onChange={(e) => handleNewDataChange(e)} />
                            <small style={{ color: 'red' }}>{newDataError['end_date_hour']}</small>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Pourcentage</Form.Label>
                            <Form.Control id={newDataParam.length + ''} name='percent' size="sm" placeholder="50" onChange={(e) => handleNewDataChange(e)} />
                            <small style={{ color: 'red' }}>{newDataError['percent']}</small>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label as={Col} className="d-flex justify-self-center">Majoration/Réduction</Form.Label>
                            <Form.Control as="select" id={newDataParam.length + ''} name='is_positive' size="sm" placeholder="3" onChange={(e) => handleNewDataChange(e)}>
                                <option>Selectionner</option>
                                <option value='1'>Réduction</option>
                                <option value='0'>Majoration</option>
                            </Form.Control>
                            {newDataError['is_positive'] && <label htmlFor="is_positive"><small style={{ color: "red" }}>{newDataError['is_positive']}</small></label>}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Check
                            type="switch"
                            name='is_for_owner'
                            id="switch_for_owner"
                            defaultChecked='true'
                            label="Le pourcentage s'appliquera uniquement sur le loyer propriétaire"
                            onClick={(e) => handleNewDataChange(e)}
                        />
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    {submit ? <Spinner /> : <Button variant="secondary" onClick={handleNewDataSubmit}>Valider</Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}