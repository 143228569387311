import React from 'react';
import { Carousel } from 'react-bootstrap';
import "../../../pages/home/Home.scss";
import Skeleton from '@material-ui/lab/Skeleton';

export default function HomeCarousel(props) {

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null; // Retourne null si le cookie n'existe pas
    }

    if (props.page) {
      return (
          <Carousel fade>
              {
                  getCookie('country') === 'suisse'
                      ? ['paysage_1.webp', 'paysage__2.webp', 'paysage__3.webp'].map((localImage, index) => (
                          <Carousel.Item interval={3000} key={`local-${index}`}>
                              <img
                                  alt={`Image ${index + 1} de la Suisse`}
                                  className="d-block w-100 carousselImage"
                                  loading="lazy"
                                  src={`${window.location.origin}/images/homepage_images/switzerland/${localImage}`}
                              />
                              <div className="overlay"></div>
                          </Carousel.Item>
                      ))
                      : (props.page.pictures_url['carousel'] || []).map((picture, index) => (
                          <Carousel.Item interval={3000} key={`api-${index}`}>
                              <img
                                  alt='Photo de logement en location sur montpellier'
                                  className="d-block w-100 carousselImage"
                                  loading="lazy"
                                  srcSet={`${picture.medium} 320w, ${picture.medium} 640w, ${picture.url} 900w`}
                              />
                              <div className="overlay"></div>
                          </Carousel.Item>
                      ))
              }
          </Carousel>
      );
    }
    
    return (
      <Skeleton className="carousel" animation='wave' variant='rect' style={{width: '100%', height: window.innerWidth > 990 ? '80vh' : '30vh'}}/>
    );
}