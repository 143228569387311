import { MdAccountCircle } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../headerSlice';
import '../header.scss';
import FormulaireConnexion from '../../connexion/FormulaireConnexion';
import FormulaireAccount from '../../formulaire/account/formulaireAccount';
import {Button} from "react-bootstrap";

export default function MyAccountButton(props) {
    const [visibility, setVisibility] = useState(false);
    const [country, setCountry] = useState('france'); // Valeur par défaut
    const dispatch = useDispatch();

    const optionsFields = () => {
        if (props.role === 'super-admin' || props.role === 'admin' || props.role === 'trainee' || props.role === 'accountant') {
            return <li><a href="/AdminPannel">Menu Administrateur</a></li>
        }
    }

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('connectOrReset') && urlParams.get('actionType')) {
            setVisibility(true);
        }
    }, [])

    function handleChoice() {
        const newCountry = country === 'suisse' ? 'france' : 'suisse'; // Inverser la valeur
        document.cookie = `country=${newCountry}; path=/`; // Mettre à jour le cookie
        setCountry(newCountry); // Mettre à jour l'état
        window.location.reload(); // Recharger la page
    }

    // Mettre à jour la valeur du cookie country au chargement
    useEffect(() => {
        const currentCountry = getCookie('country') || 'france'; // Par défaut "france"
        setCountry(currentCountry);
    }, []);

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null; // Retourne null si le cookie n'existe pas
    }

    return (
        <div>
            <MdAccountCircle className="logo-account" onClick={() => setVisibility(!visibility)} />
            {
                visibility &&
                <div className='navItemDropDownContent d-flex justify-content-end'>
                    {
                        !props.isConnected ?
                            <>
                                <li>
                                    <FormulaireConnexion />
                                </li>
                                <li>
                                    <FormulaireAccount />
                                </li>
                                <li>
                                    <Button
                                        variant="light"
                                        style={{ backgroundColor: "transparent", width: "100%", border: "none", textAlign: "center", focus: "none", borderRadius: "0px", boxShadow: 'none !important', }}
                                        onClick={(handleChoice)}>{country === 'suisse' ? 'France' : 'Suisse'}</Button>
                                </li>
                            </>
                            :
                            <>
                                <>
                                    <li>
                                        <a href="/MonCompte">Mon compte</a>
                                    </li>
                                    {
                                        optionsFields()
                                    }
                                    <li>
                                        <p onClick={() => dispatch(logout())}>Déconnexion</p>
                                    </li>
                                    <li>
                                        <Button
                                            variant="light"
                                            style={{
                                                backgroundColor: "transparent",
                                                width: "100%",
                                                border: "none",
                                                textAlign: "center",
                                                focus: "none",
                                                borderRadius: "0px",
                                                boxShadow: 'none !important',
                                            }}
                                            onClick={(handleChoice)}>{country === 'suisse' ? 'France' : 'Suisse'}</Button>
                                    </li>
                                </>
                            </>
                    }
                </div>
            }
        </div>
    )
}