import {Container, Col, Image, Row, Button, Card} from 'react-bootstrap';
import { HiCursorClick } from 'react-icons/hi'
import { TbHandClick, TbSearch } from 'react-icons/tb'
import { FiCheckCircle } from 'react-icons/fi'
import { BsCreditCard } from 'react-icons/bs'
import SearchBar from '../../components/searchBar/SearchBar';
import './statics.scss'

export default function LongRent(props) {
    return (
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
            <Row>
                <Col xs={12}>
                <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > <a href='/Locataires' className='golden'>Locataires</a></p>
                </Col>
                <Col sm={12} className="noPadding">
                <SearchBar className="searchBar" />
            </Col>
            <Col xs={12} sm={12}>
                <hr className="page-separator" />
            </Col>
                <Col sm={12}>
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column align-items-end">
                            <div className="d-flex align-items-center step">
                                <TbSearch size='85' className='noDisplay' />
                                <div className='step-content-right'>
                                    <p>Recherchez un logement en renseignant vos critères dans la barre de recherche</p>
                                    <p className='step-number'>1</p>
                                </div>
                            </div>
                            <strong className='step-name'>RECHERCHEZ</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column justify-content-start">
                            <div className="d-flex align-items-center step">
                                <div className='step-content-left'>
                                    <p className='step-number'>2</p>
                                    <p>Bingo, vous l'avez trouvé ! Réservez votre logement en moins de 3 clics</p>
                                </div>
                                <TbHandClick size='85' className='noDisplay' />
                            </div>
                            <strong className='step-name'>SELECTIONNEZ</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column align-items-end">
                            <div className="d-flex align-items-center step">
                                <BsCreditCard size='85' className='noDisplay' />
                                <div className='step-content-right'>
                                    <p>Payez en toute sécurité grâce à notre système sécurisé du Crédit Agricole</p>
                                    <p className='step-number'>3</p>
                                </div>
                            </div>
                            <strong className='step-name'>RÉSERVEZ</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column justify-content-start">
                            <div className="d-flex align-items-center step">
                                <div className='step-content-left'>
                                    <p className='step-number'>4</p>
                                    <p>Vous recevrez un mail de confirmation et toutes les instructions nécessaires pour faire de votre séjour une réussite. A très bientôt !</p>
                                </div>
                                <FiCheckCircle size='85' className='noDisplay' />
                            </div>
                            <strong className='step-name'>C'EST VALIDÉ</strong>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-around'>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <Col xs={12} sm={12}>
                <hr className="page-separator" />
            </Col>
            <Row>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                    <Image loading='lazy' className="images-footer-horizontal" src="/images/footerPages/Serviettes.webp" />
                </Col>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                    <div>
                        <h3>Services inclus</h3>
                        <ul>
                            <li><strong>Préparation du logement selon un protocole COVID</strong></li>
                            <li><strong>L'accueil</strong> directement au logement</li>
                            <li><strong>Un kit d’accueil</strong></li>
                            <li><strong>Une cuisine équipée</strong> : préparez de bons petits plats et profitez de vos déjeuner grâce à un équipement complet</li>
                            <li><strong>Internet gratuit</strong></li>
                            <li><strong>Le linge de maison</strong></li>
                            <li><strong>Le ménage de fin de séjour</strong> (à l'exception de la cuisine et barbecue / plancha)</li>
                            <li><strong>Une assistance technique</strong>: pendant les heures d'ouverture</li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row className='d-flex  flex-wrap-reverse'>


                <div className="services-container py-5" style={{backgroundColor: '#f5f5f5'}}>
                    <Row>
                        <Col xs={12} md={6} className="d-flex align-items-center">
                            <div>
                                <h2 className="mb-4">SERVICES A LA CARTE</h2>
                                <p>
                                    Nous comprenons les besoins uniques de chaque voyageur et offrons des services
                                    personnalisés pour un séjour confortable et sans souci. Notre gamme de services à la
                                    carte, tels que le ménage supplémentaire et la location de matériel pour bébé,
                                    répond à toutes vos attentes pour que vous profitiez pleinement de votre séjour.
                                </p>
                            </div>
                        </Col>

                        {/* Right Column with Cards */}
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={12} md={6} className="mb-4">
                                    <div className="shadow-sm p-3 h-100"
                                         style={{borderRadius: '5px', backgroundColor: '#fff'}}>
                                        <img src="/icons/menage-icon.svg" alt="Méénage icon" className="mb-3"
                                             style={{maxWidth: '40px'}}/>
                                        <h5 className="fw-bold">Ménage</h5>
                                        <p>
                                            Réservez un ménage lors de votre séjour ainsi qu'un changement de linge pour
                                            toute la famille.
                                        </p>
                                    </div>
                                </Col>

                                <Col xs={12} md={6} className="mb-4">
                                    <Card className="shadow-sm">
                                        <Card.Body>
                                            <Card.Img variant="top" src="/icons/lit-bebe-icon.svg" alt="Lit bébé icon"
                                                      className="mb-3" style={{maxWidth: '40px'}}/>
                                            <Card.Title className="fw-bold">Lit bébé</Card.Title>
                                            <Card.Text>
                                                Nous vous proposons une location à 50€/séjour pour un maximum de 15
                                                jours.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={12} md={6} className="mb-4">
                                    <Card className="h-100 shadow-sm">
                                        <Card.Body>
                                            <Card.Img variant="top" src="/icons/chaise-haute-icon.svg"
                                                      alt="Chaise haute icon" className="mb-3"
                                                      style={{maxWidth: '40px'}}/>
                                            <Card.Title className="fw-bold">Chaise haute</Card.Title>
                                            <Card.Text>
                                                Nous vous proposons une location à 25€/séjour pour un maximum de 15
                                                jours.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col xs={12} md={6} className="mb-4">
                                    <Card className="h-100 shadow-sm">
                                        <Card.Body>
                                            <Card.Img variant="top" src="/icons/concierge-icon.svg"
                                                      alt="Conciergerie icon" className="mb-3"
                                                      style={{maxWidth: '40px'}}/>
                                            <Card.Title className="fw-bold">Et plus encore !</Card.Title>
                                            <Card.Text>
                                                Class Appart est à votre écoute pour vous offrir un service personnalisé
                                                de qualité tout au long de votre séjour.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>


                {/*<Col xs={12} md={6} className='d-flex align-items-center bg-light p-2'>*/}
                {/*    <div>*/}
                {/*        <h3> Services à la carte</h3>*/}
                {/*        <p>Nous comprenons les besoins*/}
                {/*            uniques de chaque voyageur et*/}
                {/*            offrons des services personnalisés*/}
                {/*            pour un séjour confortable et sans*/}
                {/*            souci. Notre gamme de services à la*/}
                {/*            carte, tels que le ménage*/}
                {/*            supplémentaire et la location de*/}
                {/*            matériel pour bébé, répond à toutes*/}
                {/*            vos attentes pour que vous profitiez*/}
                {/*            pleinement de votre séjour.</p>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                {/*<Col xs={12} md={4} className='d-flex justify-content-center'>*/}
                {/*    <div>*/}
                {/*        <h4 className="">Ménage</h4>*/}
                {/*        <p>Réservez un ménage lors de*/}
                {/*            votre séjour ainsi qu'un*/}
                {/*            changement de linge pour*/}
                {/*            toute la famille.</p>*/}
                {/*    </div>*/}
                {/*    <Image loading='lazy' className="images-footer-horizontal" src="/images/footerPages/Services.webp" />*/}
                {/*</Col>*/}
            </Row>
            <Col xs={12} sm={12}>
                <hr className="page-separator"/>
            </Col>
            <Row>
                <Col sm={12} className="centered">
                    <h2>Nos avantages</h2>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col sm={12} md={4}>
                    <Image loading='lazy' className="images-footer-horizontal" src="/images/footerPages/5074.webp"/>
                </Col>
                <Col sm={12} md={6} className='d-flex justify-content-center flex-column'>
                    <h3>Pourquoi choisir Class Appart</h3>
                    <p>Des logements sélectionnés de hauts standing</p>
                    <p>Un concept économique</p>
                    <p>Un service "all inclusive" sans frais additionnels</p>
                </Col>
                <Col xs={12} className="d-flex justify-content-center">
                    <a href="/Contact">
                        <Button variant="secondary">
                            Demander un devis
                        </Button>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}