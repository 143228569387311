import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import CreateOrEditOption from './formulaire/createOrEditOption';
import DeleteModal from './deleteModal/deleteModal';
import { useDispatch, useStore } from 'react-redux';
import instanceOfAxios from '../../../../../app/axios/axios';
import { show } from '../../../Alerter/alerterSlice';
import Spinner from '../../../../spinner/Spinner';
import InvoiceModal from './InvoiceModal';
import { FcCheckmark, FcCancel } from 'react-icons/fc';

export default function OptionCard(props) {
    const [isSubmit, setIsSubmit] = useState(false);
    const [pending, setPending] = useState(true);
    const [urls, setUrls] = useState([]);
    const role = useStore().getState().header.role;
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            setPending(false)
            instanceOfAxios.get(`/invoices/option/${props.option.id}`)
                .then(res => setUrls(res.data.urls));
        }
    }, [pending])

    function reloadBooking() {
        instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
            .then(res => {
                props.BookingDetailsOnChange(res.data.booking);
                setPending(true);
                setIsSubmit(false);
            })
    }

    function deleteOption() {
        setIsSubmit(true);
        instanceOfAxios.delete('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id + '/option/' + props.option.id)
            .then((res) => {
                reloadBooking();
                dispatch(show({ message: urls.length > 0 ? 'Un avoir pour la facture viens d\'être créé' : 'Option supprimée', variant: 'success' }));
            })
            .catch((error) => {
                setIsSubmit(false);
                dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
            })
    }

    return (
            <div className='cartridge mt-2'>
                <Row noGutters className='d-flex justify-content-center'>

                    <Col xs={12} lg={6} className="d-flex flex-column justify-content-start align-items-center">
                        <p className="apartmentText">Description :</p>
                        <p className="apartmentText">{props.option.description}</p>
                    </Col>
                    <Col xs={12} lg={3} className="d-flex flex-column justify-content-center align-items-center">
                        <p className="apartmentText">Montant TTC :</p>
                        <p className="apartmentText "><strong>{props.option.sign} {parseFloat(props.option.value)} €</strong></p>
                    </Col>
                    <Col xs={12} lg={3} className="d-flex flex-column justify-content-center align-items-center">
                        <p className="apartmentText">TVA :</p>
                        <p className="apartmentText "><strong>{parseFloat(props.option.TVA)} €</strong></p>
                    </Col>
                    {role === 'super-admin' || role === 'admin' ?
                        <>
                            {
                                isSubmit ?
                                    <></>
                                    :
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <CreateOrEditOption for={'update'} booking={props.booking} option={props.option} setOption={reloadBooking} BookingDetailsOnChange={props.BookingDetailsOnChange} />
                                    </Col>
                            }
                        </>
                        :
                        <></>}
                    {(role === 'super-admin' || role === 'admin') && urls[urls.length - 1] && !urls[urls.length - 1]['avoir'] || !urls[urls.length - 1] ?
                        <>
                            {
                                isSubmit ?
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <Spinner />
                                    </Col>
                                    :
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <DeleteModal size='sm' deleteFunction={() => deleteOption()} canBeDeleted={!urls[urls.length - 1]}/>
                                    </Col>
                            }
                        </>
                        :
                        <></>}
                    <InvoiceModal booking={props.booking} option={props.option} urls={urls} setPending={setPending} BookingDetailsOnChange={reloadBooking} />
                    <Col xs={12} className="d-flex flex-column justify-content-start align-items-center">
                        {
                            urls.length > 0 ?
                                <small><FcCheckmark /> Cette option possède une facture {props.option.invoice_as_pro_forma == 1 && 'pro-forma'} {urls[urls.length - 1]['avoir'] ? 'et un avoir' : 'sans avoir'}</small>
                                :
                                <small><FcCancel /> Cette option ne possède pas de facture</small>
                        }
                    </Col>
                </Row>
            </div>
    )
}
