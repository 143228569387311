import { useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { resetDate } from '../../searchBar/dateRange/dateRangeSlice';
import { HiOutlineCalendar } from 'react-icons/hi';
import VerticalCenteredModal from './modal';

export default function DateViewer(props) {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    function setVisibility() {
        setShow(!show);
        if (!show) {
            dispatch(resetDate());
        }
    }

    function handleChange() {
        props.handleChange();
    }

    return (
        <>
            {
                props.type == 'card' ?
                    <Col
                        md={12}
                        lg={4}
                        className='menuItem d-flex justify-content-center align-items-end'
                        onClick={() => setVisibility(true)}>
                        <div className='container-text d-flex flex-column align-items-center justify-content-center'>
                            <HiOutlineCalendar/>
                            <h2>Planning</h2>
                        </div>
                    </Col>
                    :
                    <Button className='goldenHover' onClick={() => setVisibility(true)}>Bloquer des dates</Button>
            }
            <VerticalCenteredModal
                show={show}
                onHide={() => setVisibility()}
                apartment={props.apartment}
                handleChange={handleChange}
            />
        </>
    )
}