import React, { useEffect, useState } from 'react';
import { getApartmentForUpdate } from '../apartmentSlice'
import { connect, useDispatch, useStore } from 'react-redux';
import { Col, Container, Form, Row, Button, Card, Modal } from 'react-bootstrap';
import Spinner from '../../../spinner/Spinner';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import '../Apartments.scss';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import CustomOver from './popoverInformations/customOver';
import RateHistoric from './rateHistoric/rateHistoric';
import ListArchivedRate from './archivedRate/listArchivedRate';
import ArchiveRate from './archivedRate/archiveRate';
import PriceInfluencerList from './priceInfluenceur/priceInfluencerList';
import { FaRegSave } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

function StepThree(props) {
    const [ratesParam, setRateParam] = useState(props.apartment.rates);
    const [newRatesParam, setNewRateParam] = useState({});
    const [rates, setRates] = useState(props.apartment.rates || undefined);
    const [bailParam, setBailParam] = useState(props.apartment.bail);
    const [bail, setBail] = useState(props.apartment.bail || undefined);
    const [visibility, setVisibility] = useState(false);
    const [errors, setErrors] = useState([]);
    const [newRateErrors, setNewRateErrors] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [pendingTaxeTypes, setPendingTaxeTypes] = useState(false);
    const [apartment, setApartment] = useState(props.apartment || undefined);
    const [taxeType, setTaxeType] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [errorsApartment, setErrorsApartment] = useState([]);
    const role = useStore().getState().header.role;
    const dispatch = useDispatch();

    useEffect(() => {
        if (apartment) {
            setPendingTaxeTypes(true)
            instanceOfAxios.get(`admin/taxeType?agencyId=${apartment.agency_id}`)
                .then(res => {
                    setTaxeType(res.data.types);
                    setPendingTaxeTypes(false)
                })
                .catch(res => {
                    setPendingTaxeTypes(false)
                });
        }
    }, [apartment.agency_id])

    useEffect(() => {
        instanceOfAxios.get(`admin/agency`)
            .then(res => setAgencies(res.data.succes));
    }, [])

    function handleApartmentChange(event) {
        let param = Object.assign({}, apartment)
        param[event.target.name] = event.target.value;
        setApartment(param);
    }

    function handleApartmentSubmit() {
        setIsSubmit(true);
        let url = '/apartment/' + apartment.id;
        instanceOfAxios.post(url, { ...apartment, _method: 'PATCH' })
            .then((res) => {
                let apartment = res.data.Apartment;
                instanceOfAxios.get('/apartment/' + res.data.Apartment.id)
                    .then((res) => {
                        dispatch(setUpdater(apartment));
                        setApartment(apartment);
                        setIsSubmit(false);
                        dispatch(setReload(true));
                        dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    })
            })
            .catch((error) => {
                handleError(error, setErrorsApartment);
            })
    }

    function handleChange(params, event) {
        let values = Object.assign([], params);
        let index;

        if (rates.length == 0) {
            index = 0;
        }else{
            index = values.findIndex(i => i.id == event.target.id);
        }

        if (!values[index]) {
            values[index] = {};
        }

        values[index] = Object.assign({}, values[index]);
        if (event.target.name === 'end_period' || event.target.name === 'begin_period') {
            let date = new Date(event.target.value);
            if (event.target.name === 'end_period') {
                date.setDate(date.getDate() - 1);
            }
            let month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            values[index][event.target.name] = date.getDate() + '-' + month[date.getMonth()];
        } else {
            values[index][event.target.name] = event.target.value;
        }
        return values;
    }

    function handleRateChange(event) {
        let data = handleChange(ratesParam, event);
        setRateParam(data);
    }

    function handleNewRateChange(event) {
        if (event.target.name === 'end_period' || event.target.name === 'begin_period') {
            let date = new Date(event.target.value);
            if (event.target.name === 'end_period') {
                date.setDate(date.getDate() - 1);
            }
            let month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            newRatesParam[event.target.name] = date.getDate() + '-' + month[date.getMonth()];
        } else {
            newRatesParam[event.target.name] = event.target.value;
        }
        setNewRateParam(newRatesParam);
    }

    function handleBailChange(event) {
        let data = Object.assign({}, bailParam);
        data[event.target.id] = event.target.value;
        setBailParam(data);
    }

    function handleBailSubmit() {
        setIsSubmit(true);
        let bailData = setFormData(bailParam, bail);
        let url = '/apartment/' + apartment.id + '/bail';
        if (bail) {
            url = '/apartment/' + apartment.id + '/bail/' + bail.id
        }
        let send = 0;
        for (var pair of bailData.entries()) {
            send++;
        }
        if (send > 1) {
            instanceOfAxios.post(url, bailData)
                .then((res) => {
                    let values = Object.assign([], bail);
                    let stateApartment = Object.assign({}, apartment);
                    stateApartment.bail = res.data.Bail;
                    dispatch(setUpdater(stateApartment));
                    setBail(values);
                    setIsSubmit(false);
                    setErrors([]);
                    setVisibility(false);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    dispatch(setReload(true));
                })
                .catch((error) => {
                    handleError(error, setErrors);
                });
        } else {
            setIsSubmit(false);
        }
    }

    function handleRateSubmit() {
        setIsSubmit(true);
        setErrors([]);
        for (let i = 0; i < ratesParam.length; i++) {
            let url = '/apartment/' + apartment.id + '/rate';
            if (ratesParam[i]) {
                let rateData = setFormData(ratesParam[i], rates[i]);
                if (rates[i] && rates[i].id) {
                    url = '/apartment/' + apartment.id + '/rate/' + rates[i].id;
                }

                instanceOfAxios.post(url, rateData)
                    .then((res) => {
                        
                        instanceOfAxios.get('/apartment/' + apartment.id + '/rate')
                            .then((res) => {
                                let values = res.data.rate;
                                let stateApartment = Object.assign({}, apartment);
                                stateApartment.rates = values;
                                dispatch(setUpdater(stateApartment));
                                setRates(values);
                                setIsSubmit(false);
                                setErrors([]);
                                setVisibility(false);
                                dispatch(show({ message: 'Action réussi', variant: 'success' }));
                                dispatch(setReload(true));
                            })
                    })
                    .catch((error) => {
                        handleError(error, setErrors);
                    });
            }
        }
        if (ratesParam.length == 0) {
            setIsSubmit(false);
        }
    }

    function handleNewRateSubmit() {
        setIsSubmit(true);
        setErrors([]);
        let url = '/apartment/' + apartment.id + '/rate';
        instanceOfAxios.post(url, newRatesParam)
            .then((res) => {
                instanceOfAxios.get('/apartment/' + apartment.id + '/rate')
                    .then((res) => {
                        let values = res.data.rate;
                        let stateApartment = Object.assign({}, apartment);
                        stateApartment.rates = values;
                        dispatch(setUpdater(stateApartment));
                        setRates(values);
                        setIsSubmit(false);
                        setErrors([]);
                        setVisibility(false);
                        dispatch(show({ message: 'Action réussi', variant: 'success' }));
                        dispatch(setReload(true));
                        setNewRateParam({});
                    })
            })
            .catch((error) => {
                handleError(error, setNewRateErrors);
            });
        if (ratesParam.length == 0) {
            setIsSubmit(false);
        }
    }

    function setFormData(params, object) {
        let formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        });
        if (object && object.id) {
            formData.set('_method', 'PATCH');
        }
        return formData;
    }

    function handleError(errors, callable) {
        if (typeof errors.response.data.error == 'object') {
            let error = Object.assign([], errors.response.data.error);
            Object.keys(error).forEach(function (key) {
                error[key] = error[key];
            });
            callable(error);
        }
        setIsSubmit(false);
        let message = 'Erreur lors de l\'action';
        if (typeof errors.response.data.error == 'string') {
            message = errors.response.data.error;
        }
        dispatch(show({ message: message, variant: 'danger' }));
    }

    function updateParent(array) {
        setRates(array);
        setRateParam(array);
    }

    function addForm() {
        return (
            <Modal
                show={visibility}
                showMask={false}
                onHide={() => { setVisibility(false); setIsSubmit(false); setNewRateParam({}); setNewRateErrors([]) }}
                centered
                style={{ zIndex: '20000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nouveau Loyer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Nom</Form.Label>
                            <Form.Control id={rates.length + ''} name='name' size="sm" placeholder="Entrer un nom" onChange={(e) => handleNewRateChange(e)} />
                            <small style={{ color: 'red' }}>{newRateErrors['name']}</small>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Début</Form.Label>
                            <Form.Control type='date' id={rates.length + ''} name='begin_period' size="sm" placeholder="jour-mois" onChange={(e) => handleNewRateChange(e)} />
                            <small style={{ color: 'red' }}>{newRateErrors['begin_period']}</small>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Fin</Form.Label>
                            <Form.Control type='date' id={rates.length + ''} name='end_period' size="sm" onChange={(e) => handleNewRateChange(e)} />
                            <small style={{ color: 'red' }}>{newRateErrors['end_period']}</small>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Loyer jour</Form.Label>
                            <Form.Control id={rates.length + ''} name='rent_by_night' size="sm" placeholder="50" onChange={(e) => handleNewRateChange(e)} />
                            <small style={{ color: 'red' }}>{newRateErrors['rent_by_night']}</small>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Loyer semaine</Form.Label>
                            <Form.Control id={rates.length + ''} name='rent_by_week' size="sm" placeholder="400" onChange={(e) => handleNewRateChange(e)} />
                            <small style={{ color: 'red' }}>{newRateErrors['rent_by_week']}</small>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Loyer mois</Form.Label>
                            <Form.Control id={rates.length + ''} name='rent_by_month' size="sm" placeholder="1200" onChange={(e) => handleNewRateChange(e)} />
                            <small style={{ color: 'red' }}>{newRateErrors['rent_by_month']}</small>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Minimum de nuit</Form.Label>
                            <Form.Control id={rates.length + ''} name='min_night' size="sm" placeholder="4" onChange={(e) => handleNewRateChange(e)} />
                            <small style={{ color: 'red' }}>{newRateErrors['min_night']}</small>
                        </Form.Group>
                        <small>Si vous ne remplissez pas le champ "Minimum de nuit" il prendras la valeur "{apartment.min_night}" par défault (minimum de nuit renseigné sur le logement)</small>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    {isSubmit ? <Spinner /> : <Button variant="secondary" onClick={handleNewRateSubmit}>Valider</Button>}
                </Modal.Footer>
            </Modal>)
    }

    function mapRates() {
        if (rates.length == 0) {
            return (
                <Col xs={6}>
                    <Card>
                        <Card.Header>Premier Tarif</Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control id={rates.length + ''} name='name' size="sm" placeholder="Entrer un nom" onChange={(e) => handleRateChange(e)} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Loyer jour</Form.Label>
                                    <Form.Control id={rates.length + ''} name='rent_by_night' size="sm" placeholder="50" onChange={(e) => handleRateChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Loyer semaine</Form.Label>
                                    <Form.Control id={rates.length + ''} name='rent_by_week' size="sm" placeholder="400" onChange={(e) => handleRateChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Loyer mois</Form.Label>
                                    <Form.Control id={rates.length + ''} name='rent_by_month' size="sm" placeholder="1200" onChange={(e) => handleRateChange(e)} />
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Col>)
        }

        return rates.filter(rate => rate.is_archived == 0).map((rate, index) => {
            let year = new Date().getFullYear();
            let end_period = rate.end_period.split('-');
            let begin_period = rate.begin_period.split('-');
            end_period = new Date(year, parseInt(end_period[1]) - 1, parseInt(end_period[0]) + 1, 12);
            begin_period = new Date(year, parseInt(begin_period[1]) - 1, parseInt(begin_period[0]), 12);

            return (rate.is_principal ?
                <>
                    <Col xs={10}>
                        <label><strong>Loyer Principal</strong></label>
                    </Col>
                    <Col xs={2}>
                        <RateHistoric apartment={apartment} rate={rate} />
                    </Col>
                    <Col xs={12}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label as={Col} className="d-flex justify-self-center">nuit</Form.Label>
                                <Form.Control id={rate.id + ''} name='rent_by_night' size="sm" placeholder="50" onChange={(e) => handleRateChange(e)} defaultValue={rate.rent_by_night} />
                                {errors['rent_by_night'] && <label htmlFor="rent_by_night"><small style={{ color: "red" }}>{errors['rent_by_night']}</small></label>}
                            </Form.Group>
                            <Form.Group as={Col} >
                                <Form.Label as={Col} className="d-flex justify-self-center">semaine</Form.Label>
                                <Form.Control id={rate.id + ''} name='rent_by_week' size="sm" placeholder="400" onChange={(e) => handleRateChange(e)} defaultValue={rate.rent_by_week} />
                                {errors['rent_by_week'] && <label htmlFor="rent_by_week"><small style={{ color: "red" }}>{errors['rent_by_week']}</small></label>}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label as={Col} className="d-flex justify-self-center">mois</Form.Label>
                                <Form.Control id={rate.id + ''} name='rent_by_month' size="sm" placeholder="1200" onChange={(e) => handleRateChange(e)} defaultValue={rate.rent_by_month} />
                                {errors['rent_by_month'] && <label htmlFor="rent_by_month"><small style={{ color: "red" }}>{errors['rent_by_month']}</small></label>}
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    <Col xs={6} className='mb-2 mt-2'>
                        <label><strong>Loyers Personalisés</strong></label>
                    </Col>
                    <Col xs={6} className='d-flex justify-content-end align-items-center'>
                        <CustomOver />
                    </Col>
                    <Col xs={12} className='mb-2 mt-2'>
                        <small style={{ color: 'red' }}>le nombre de nuitées ne peut être inférieur au nombre de nuits minimum inscrit dans / DESCRIPTION</small>
                    </Col>
                    <Col xs={12} className='d-flex justify-content-around mb-3'>
                        {rates.length > 0 && <Button className='whiteSVG' variant='secondary' onClick={() => setVisibility(true)} ><IoMdAddCircleOutline /> Ajouter un tarif </Button>}
                    </Col>
                    <Col xs={12} className='d-flex justify-content-around mb-3'>
                        {rates.length > 0 && <ListArchivedRate apartment={apartment} rates={rates} updateParent={updateParent}> Tarifs archivés </ListArchivedRate>}
                    </Col>
                </>
                :
                <Col sm={12} md={6} xl={4}>
                    <Card key={rate.id} className='mb-2 shadowForCard'>
                        <Card.Header>
                            <Row className='d-flex justify-content-center align-items-center'>
                                {rate.name ? <Col xs={8}><strong>{rate.name}</strong> </Col> : <strong></strong>}
                                <Col xs={2}>
                                    <RateHistoric apartment={apartment} rate={rate} />
                                </Col>
                                <Col xs={2}>
                                    <ArchiveRate rates={rates} rate={rate} apartment={apartment} updateParent={updateParent} />
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control id={rate.id + ''} name='name' size="sm" placeholder="Entrer un nom" onChange={(e) => handleRateChange(e)} defaultValue={rate.name} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Début</Form.Label>
                                    <Form.Control type='date' id={rate.id + ''} name='begin_period' size="sm" defaultValue={begin_period.toISOString().split('T')[0]} onChange={(e) => handleRateChange(e)} />
                                    {errors['begin_period'] && <label htmlFor="begin_period"><small style={{ color: "red" }}>{errors['begin_period']}</small></label>}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Fin</Form.Label>
                                    <Form.Control type='date' id={rate.id + ''} name='end_period' size="sm" defaultValue={end_period.toISOString().split('T')[0]} onChange={(e) => handleRateChange(e)} />
                                    {errors['end_period'] && <label htmlFor="end_period"><small style={{ color: "red" }}>{errors['end_period']}</small></label>}
                                </Form.Group>
                            </Form.Row>
                            <strong as={Row} className="d-flex justify-self-center">Loyers</strong>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label as={Col} className="d-flex justify-self-center">nuit</Form.Label>
                                    <Form.Control id={rate.id + ''} name='rent_by_night' size="sm" placeholder="50" onChange={(e) => handleRateChange(e)} defaultValue={rate.rent_by_night} />
                                    {errors['rent_by_night'] && <label htmlFor="rent_by_night"><small style={{ color: "red" }}>{errors['rent_by_night']}</small></label>}
                                </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label as={Col} className="d-flex justify-self-center">semaine</Form.Label>
                                    <Form.Control id={rate.id + ''} name='rent_by_week' size="sm" placeholder="400" onChange={(e) => handleRateChange(e)} defaultValue={rate.rent_by_week} />
                                    {errors['rent_by_week'] && <label htmlFor="rent_by_week"><small style={{ color: "red" }}>{errors['rent_by_week']}</small></label>}
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label as={Col} className="d-flex justify-self-center">mois</Form.Label>
                                    <Form.Control id={rate.id + ''} name='rent_by_month' size="sm" placeholder="1200" onChange={(e) => handleRateChange(e)} defaultValue={rate.rent_by_month} />
                                    {errors['rent_by_month'] && <label htmlFor="rent_by_month"><small style={{ color: "red" }}>{errors['rent_by_month']}</small></label>}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label as={Col} className="d-flex justify-self-center">Minimum de nuit</Form.Label>
                                    <Form.Control id={rate.id + ''} name='min_night' size="sm" placeholder="50" onChange={(e) => handleRateChange(e)} defaultValue={rate.min_night} />
                                    {errors['min_night'] && <label htmlFor="min_night"><small style={{ color: "red" }}>{errors['min_night']}</small></label>}
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                </Col>)
        })
    }
    return (
        <Container className='pb-3 noPadding' style={{ height: 'unset' }}>
            <label><strong>Informations tarifaires</strong></label>
            <Row>
                <Col>
                    <Form.Row>
                        <Col xs={12} md={6} xl={4}>
                            <Form.Group id="min_night">
                                <Form.Label>Nombre d'étoiles</Form.Label>
                                <Form.Control name="nb_star" as="select" onChange={(e) => handleApartmentChange(e)}>
                                    <option id='0' selected={apartment && apartment.nb_star == '0'}>0</option>
                                    <option id='1' selected={apartment && apartment.nb_star == '1'}>1</option>
                                    <option id='2' selected={apartment && apartment.nb_star == '2'}>2</option>
                                    <option id='3' selected={apartment && apartment.nb_star == '3'}>3</option>
                                    <option id='4' selected={apartment && apartment.nb_star == '4'}>4</option>
                                    <option id='5' selected={apartment && apartment.nb_star == '5'}>5</option>
                                    <option id='Palace' selected={apartment && apartment.nb_star == 'Palace'}>Palace</option>
                                </Form.Control>
                                {errorsApartment['nb_star'] && <label htmlFor="nb_star"><small style={{ color: "red" }}> {errorsApartment['nb_star']}</small></label>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <Form.Group>
                                <Form.Label>Agence</Form.Label>
                                <Form.Control name="agency_id" as="select" onChange={(e) => handleApartmentChange(e)}>
                                    <option id='0'>Selectionné un agence</option>
                                    {
                                        agencies.map(data => <option id={data.type} value={data.id} selected={apartment && apartment.agency_id == data.id}>{data.location}</option>)
                                    }
                                </Form.Control>
                                {errorsApartment['agency_id'] && <label htmlFor="agency_id"><small style={{ color: "red" }}> {errorsApartment['agency_id']}</small></label>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} xl={4}>
                            <Form.Group>
                                <Form.Label>Classe de calcule de tarifs</Form.Label>
                                <>
                                    {
                                        pendingTaxeTypes ?
                                            <>
                                                <br />
                                                <Spinner />
                                            </>
                                            :
                                            <Form.Control name="taxe_type_id" as="select" onChange={(e) => handleApartmentChange(e)}>
                                                <option id='0'>Selectionné une classe de tarifs</option>
                                                {
                                                    taxeType.map(data => <option id={data.type} value={data.id} selected={apartment && apartment.taxe_type_id == data.id}>{data.type}</option>)
                                                }
                                            </Form.Control>
                                    }
                                </>
                                {errorsApartment['taxe_type_id'] && <label htmlFor="taxe_type_id"><small style={{ color: "red" }}> {errorsApartment['taxe_type_id']}</small></label>}
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Col>
            </Row>
            <Row className="justify-content-end">
                {
                    isSubmit ?
                        <Spinner />
                        :
                        <Button
                            className='whiteSVG'
                            variant="secondary"
                            onClick={() => {
                                handleApartmentSubmit();
                            }}><FaRegSave /> Enregistrer</Button>
                }
            </Row>
            <Row>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
                <label><strong>Depot de garantie</strong></label>
                <Col md={12}>
                    <Form.Row>
                        <Form.Group as={Col} sm={12} md={4}>
                            {errors['percent_depot'] && <label htmlFor="percent_depot"><small style={{ color: "red" }}>{errors['percent_depot']}</small></label>}
                            <Form.Label>Pourcentage de depot</Form.Label>
                            <Form.Control type={'number'} id={'percent_depot'} name='percent_depot' size="sm" placeholder="50" onChange={(e) => handleBailChange(e)} defaultValue={bail ? bail.percent_depot : ''} />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            {errors['percent_down_payment'] && <label htmlFor="percent_down_payment"><small style={{ color: "red" }}>{errors['percent_down_payment']}</small></label>}
                            <Form.Label>Pourcentage acompte</Form.Label>
                            <Form.Control type={'number'} id='percent_down_payment' name='percent_down_payment' size="sm" placeholder="400" onChange={(e) => handleBailChange(e)} defaultValue={bail ? bail.percent_down_payment : ''} />
                        </Form.Group>
                        <Form.Group as={Col} sm={12} md={4}>
                            {errors['min'] && <label htmlFor="min"><small style={{ color: "red" }}>{errors['min']}</small></label>}
                            <Form.Label>Minimum</Form.Label>
                            <Form.Control type={'number'} id='min' name='min' size="sm" placeholder="1200" onChange={(e) => handleBailChange(e)} defaultValue={bail ? bail.min : ''} />
                        </Form.Group>
                    </Form.Row>
                </Col>
            </Row>
            <Row className="justify-content-end">
                {
                    isSubmit ?
                        <Spinner />
                        :
                        <Button
                            className='whiteSVG'
                            variant="secondary"
                            onClick={() => {
                                handleBailSubmit();
                            }}><FaRegSave /> Enregistrer</Button>
                }
            </Row>
            <Row>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
                <Col lg={12} className='noPadding'>
                    <Row className='d-flex justify-content-center noPadding'>
                        {mapRates()}
                    </Row>
                    {visibility && addForm()}
                    <Row className="justify-content-center mt-3">
                        {isSubmit ?
                            <Spinner />
                            :
                            <Button
                                className='whiteSVG'
                                variant="secondary"
                                onClick={() => {
                                    handleRateSubmit()
                                }}><FaRegSave /> Enregistrer</Button>}
                    </Row>
                </Col>
                <Col lg={12}>
                    <PriceInfluencerList apartment={apartment} />
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepThree);