export const items = [
    {
        id: `(`,
        value:'(',
        valueForCalc: '(',
        content: `(`
    },
    {
        id: `)`,
        value:')',
        valueForCalc: ')',
        content: `)`
    },
    {
        id: `x`,
        value:'*',
        valueForCalc: '*',
        content: `x`
    },
    {
        id: `/`,
        value:'/',
        valueForCalc: '/',
        content: `/`
    },
    {
        id: `+`,
        value:'+',
        valueForCalc: '+',
        content: `+`
    },
    {
        id: `-`,
        value:'-',
        valueForCalc: '-',
        content: `-`
    },
    {
        id: `nb_person`,
        value:'$this->nb_person',
        valueForCalc: '5',
        content: `Nb personnes`
    },
    {
        id: `nb_adult`,
        value:'$this->nb_adult',
        valueForCalc: '2',
        content: `Nb adulte`
    },
    {
        id: `rent`,
        value:'$this->rent',
        valueForCalc: '500',
        content: `loyer`
    },
    {
        id: `nb_nuit`,
        value:'nb_night',
        valueForCalc: '5',
        content: `Nb nuit`
    },
    {
        id: `€`,
        value:'€',
        valueForCalc: '0.70',
        content: `taxe`
    }
]