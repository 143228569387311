import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getResult, hideAndShowCreate, hideAndShowList, setReload } from '../quoteSlice';
import { Row, Col, Button, Form, FormControl, Dropdown, Badge, Card } from 'react-bootstrap';
import DateRange from '../../../searchBar/dateRange/dateRange';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../../admin/Alerter/alerterSlice';
import { resetDate } from '../../../searchBar/dateRange/dateRangeSlice';
import { getParam } from '../../../searchBar/searchBarSlice';
import Spinner from '../../../spinner/Spinner';
import { getBeginDate, getEndDate } from '../../../searchBar/dateRange/dateRangeSlice';

function CreateQuote(props) {
    const quotes = useState(props.result);
    const dispatch = useDispatch();
    const [apartmentsIdParams, setApartmentsIdParams] = useState([]);
    const [params, setParams] = useState({ send_fr: 1, send_en: 0 });
    const [errors, setErrors] = useState([]);
    const [apartments, setApartments] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersPending, setUsersPending] = useState(true);
    const [apartmentsPending, setApartmentsPending] = useState(true);
    const [simulated, setSimulated] = useState();
    const [nbPerson, setNbPerson] = useState(10);
    const [selectedApartment, setSelectedApartment] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [selectedContact, setSelectedContact] = useState();
    const [contacts, setContacts] = useState();
    const [pending, setPending] = useState(false);

    if (usersPending) {
        instanceOfAxios.get('/admin/account')
            .then((res) => {
                setUsers(res.data.accounts);
            });
        setUsersPending(false);
    }

    if (apartmentsPending) {
        instanceOfAxios.get('/admin/SecondIndexApartments')
            .then((res) => {
                setApartments(res.data.apartments);
            });
        setApartmentsPending(false);
    }

    useEffect(() => {
        let param = Object.assign({}, params);
        param.begin_date_hour = props.param.begin_date;
        param.end_date_hour = props.param.end_date;
        setParams(param);
    }, [props.param])

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button variant="secondary" className=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </Button>
    ));
    useEffect(() => {
        let newParam = Object.assign({}, params);
        newParam.begin_date_hour = props.beginDate;
        setParams(newParam);
        setSimulated(undefined);
    }, [props.beginDate])

    useEffect(() => {
        let newParam = Object.assign({}, params);
        newParam.end_date_hour = props.endDate;
        setParams(newParam);
        setSimulated(undefined);
    }, [props.endDate])

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');
            const lang = navigator.language;
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled" style={{ position: 'relative', overflow: 'auto', maxHeight: '150px' }}>
                        {React.Children.toArray(children).filter(
                            (child) => {
                                return !value || child.props.children.toLowerCase().includes(value)
                            }
                        )}
                    </ul>
                </div>
            );
        },
    );

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function handleChange(event) {
        let data = Object.assign({}, params);
        data[event.target.name] = event.target.value || event.target.id;
        setSimulated();
        setParams(data);
    }

    function handleChangeApartmentsIdParams(event) {
        let ids = Object.assign([], apartmentsIdParams);
        if (event.target.checked) {
            ids.push(event.target.id);
        } else {
            ids = ids.filter((id) => event.target.id != id);
        }
        setApartmentsIdParams(ids);
    }

    function handleSendLangParams(event) {
        let data = Object.assign({}, params);
        if (event.target.checked) {
            data[event.target.name] = 1;
        } else {
            data[event.target.name] = 0;
        }
        setParams(data);
    }

    function handleSimulate() {
        setPending(true);
        let query = '/quote/simulateQuote?';
        for (const [key, value] of Object.entries(params)) {
            if (value !== undefined
                && value !== ""
                && value !== "Selectionner un nombre"
                && key !== 'send_fr'
                && key !== 'account_id'
                && key !== 'contact_id'
                && key !== 'send_en') {

                if (key == 'begin_date_hour' || key == 'end_date_hour') {
                    const date = new Date(value);
                    query += key + '=' + date.toUTCString() + '&';
                } else {
                    query += key + '=' + value + '&';
                }
            }
        }

        instanceOfAxios.post(query)
            .then((res) => {
                setSimulated(res.data.success);
                if (res.data.success.length < 1) {
                    dispatch(show({ message: 'Aucune location de disponible', variant: 'danger' }));
                }
                setPending(false);
            })
            .catch((error) => {
                let message = 'Les informations renseignées ne sont pas bonnes';
                handleError(error);
                dispatch(show({ message: message, variant: 'danger' }));
                setPending(false);
            })
    }

    function handleSubmit() {
        setPending(true);
        const beginDate = new Date(simulated[0].begin_date_hour.replace(/\s/, 'T'));
        const endDate = new Date(simulated[0].end_date_hour.replace(/\s/, 'T'));
        let data = {
            'begin_date_hour': beginDate.toUTCString(),
            'end_date_hour': endDate.toUTCString(),
            'apartments_id': apartmentsIdParams,
            'nb_person': params.nb_person,
            'nb_adult': params.nb_adult,
            'account_id': params.account_id,
            'send_fr': params.send_fr,
            'send_en': params.send_en
        }

        instanceOfAxios.post('/admin/quote', data)
            .then((res) => {
                dispatch(show({ message: 'Devis créé', variant: 'success' }));
                dispatch(setReload(true));
                dispatch(hideAndShowCreate());
                dispatch(resetDate());
                setPending(false);
            })
            .catch((error) => {
                let message = 'Les informations renseignées ne sont pas bonnes';
                if (error.response.data.error == 'non disponible') {
                    message = 'Les dates entrées ne sont pas disponible';
                }
                handleError(error);
                dispatch(show({ message: message, variant: 'danger' }));
                setPending(false);
            })
    }

    function handleError(errors) {
        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        setErrors(error);
    }

    function listNbPerson() {
        const items = []
        for (let index = 1; index <= nbPerson; index++) {
            items.push(<option key={index}>{index}</option>)
        };
        return (items);
    }

    return (<div className={props.className}>
        <Row>
            <Button
                variant="secondary"
                onClick={() => {
                    setSelectedApartment(undefined);
                    dispatch(hideAndShowList());
                    dispatch(resetDate());
                }}>Retour</Button>
        </Row>
        {apartments.length > 0 && users.length > 0 ?
            <Form>
                <DateRange adminUsage usage='admin' id='dateRangeBooking' minLength={1}/>
                <Form.Row className="justify-content-center">
                    <Col md={6}>
                        <Form.Group as={Col} className="m-1">
                            {errors['nb_person'] && <label htmlFor="nb_person"><small style={{ color: "red" }}>Le champ est requis ou invalide</small></label>}
                            <Form.Label>Nombre de personnes</Form.Label>
                            <Form.Control name='nb_person' as="select" defaultValue="0" onChange={handleChange}>
                                <option>Selectionner un nombre</option>
                                {listNbPerson()}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group as={Col} className="m-1">
                            {errors['nb_adult'] && <label htmlFor="nb_adult"><small style={{ color: "red" }}>Le champ est requis ou invalide</small></label>}
                            <Form.Label>Nombre d'adultes</Form.Label>
                            <Form.Control name='nb_adult' as="select" defaultValue="0" onChange={handleChange}>
                                <option>Selectionner un nombre</option>
                                {listNbPerson()}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="justify-content-center">
                    <Col className="d-flex justify-content-center" md={4}>
                        <Dropdown style={{ padding: '10px' }}>
                            <Dropdown.Toggle as={CustomToggle}>
                                {selectedApartment ? selectedApartment.ref : 'Selectionner un apartement/villa'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu}>
                                <Dropdown.Item
                                    name='reference'
                                    eventkey={undefined}
                                    onClick={(e) => {
                                        setSelectedApartment({ id: undefined, ref: '--------' });
                                        handleChange(e);
                                    }}>-------</Dropdown.Item>
                                {apartments.map((value) =>
                                    <Dropdown.Item
                                        key={value.id}
                                        name='reference'
                                        id={value.reference}
                                        eventkey={value.reference}
                                        onClick={(e) => {
                                            setSelectedApartment({ id: value.id, ref: value.reference });
                                            handleChange(e);
                                            setNbPerson(value.nb_person);
                                        }}>{value.reference}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                        {errors['account_id'] && <label htmlFor="account_id"><small style={{ color: "red" }}>Le champ est requis ou invalide</small></label>}
                    </Col>
                    <Col md={4} className="d-flex justify-content-center">
                        <Dropdown style={{ padding: '10px' }}>
                            <Dropdown.Toggle as={CustomToggle}>
                                {selectedUser ? selectedUser.email : 'Choisir un compte'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu}>
                                <Dropdown.Item
                                    name='account_id'
                                    eventkey={undefined}
                                    onClick={(e) => {
                                        setSelectedUser(undefined);
                                        handleChange(e);
                                    }}>-------</Dropdown.Item>
                                {users ? users.map((value) => {
                                    if (value.principal_contact) {
                                        return (
                                            <Dropdown.Item
                                                key={value.id}
                                                name='account_id'
                                                id={value.id}
                                                eventkey={value.principal_contact.first_name + ' ' + value.principal_contact.last_name + ' ' + value.email}
                                                onClick={(e) => {
                                                    setSelectedUser(value);
                                                    setContacts(value.contacts);
                                                    handleChange(e);
                                                }}>{value.email}</Dropdown.Item>)
                                    }
                                }
                                )
                                    : <Dropdown.Item>Aucun utilisateurs</Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={4} className="d-flex justify-content-center" >
                        <Dropdown style={{ padding: '10px' }}>
                            <Dropdown.Toggle as={CustomToggle}>
                                {selectedContact ? selectedContact.first_name + ' ' + selectedContact.last_name : 'Choisir un contact'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu}>
                                {contacts ? contacts.map((value) =>
                                    <Dropdown.Item
                                        key={value.id}
                                        name='contact_id'
                                        id={value.id}
                                        eventkey={value.first_name + ' ' + value.last_name}
                                        onClick={(e) => {
                                            setSelectedContact(value)
                                            handleChange(e)
                                        }}>{value.first_name + ' ' + value.last_name}</Dropdown.Item>)
                                    :
                                    <Dropdown.Item>Aucun contacts</Dropdown.Item>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={12} className="d-flex justify-content-center mt-3 mb-2">
                        <p>Selectionnez les devis que vous désirez transmettre à l'utilisateur avant de cliquer sur le bouton 'Envoyer'</p>
                    </Col>
                </Form.Row>

                {simulated && simulated.map((quote) => {
                    let now = new Date();
                    let beginDate = new Date(quote.begin_date_hour.replace(/\s/, 'T'));
                    let endDate = new Date(quote.end_date_hour.replace(/\s/, 'T'));
                    return (
                        <Row className="mt-2">
                            <Col>
                                <Card>
                                    <Card.Header>
                                        <Row className='d-flex justify-content-lg-between justify-content-center align-items-center'>
                                            <Col xs={1}>
                                                <Badge className="apartmentTextTitle" variant={'info'}>{quote.apartment.reference}</Badge>
                                            </Col>
                                            {quote.nb_person && quote.nb_adult && params.account_id
                                                ?
                                                <Col xs={12} md={3} className='d-flex justify-content-center justify-content-lg-end align-items-center'>
                                                    <Form.Check type="checkbox" id={quote.apartment.id} label="Envoyer ce devis" onClick={(e) => handleChangeApartmentsIdParams(e)} />
                                                </Col>
                                                :
                                                <Col>
                                                    <Badge className="apartmentTextAlignLeft" variant={'danger'}>Ce devis est approximatif car certaines informations sont manquante et ne peuvent donc pas être envoyer</Badge>
                                                </Col>
                                            }
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={6} lg={3}>
                                                <p className="apartmentText">Tarif séjour :</p>
                                                <p className="apartmentText">Tarif par nuit :</p>
                                                <p className="apartmentText">Caution :</p>
                                                <p className="apartmentText">acompte :</p>
                                                <p className="apartmentText">Nuitées :</p>
                                            </Col>
                                            <Col xs={6} lg={3}>
                                                <p className="apartmentTextAlignRight"> <strong>{(quote.rate).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"> <strong>{((quote.rate).toFixed(2) / getDifferenceInDays(beginDate, endDate)).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"> <strong>{(quote.bail_amount).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"> <strong>{getDifferenceInDays(now, beginDate) > 14 ? (quote.rate / 2).toFixed(2) : (quote.rate).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"> <strong>{getDifferenceInDays(beginDate, endDate)}</strong></p>
                                            </Col>
                                            <Col xs={6} lg={3}>
                                                <p className="apartmentText">Loyer propriétaire : </p>
                                                <p className="apartmentText">Commision(TTC) : </p>
                                                <p className="apartmentText">TVA :</p>
                                                <p className="apartmentText">Ménage : </p>
                                                <p className="apartmentText">EDF :</p>
                                                <p className="apartmentText">Taxe de séjour : </p>
                                                <p className="apartmentText">Assurance :</p>
                                                <p className="apartmentText"><strong>total :</strong></p>
                                            </Col>
                                            <Col xs={6} lg={3}>
                                                <p className="apartmentTextAlignRight"><strong>{(quote.rent).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"><strong>{(quote.commission).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"> <strong>{(quote.tva_value).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"><strong>{(quote.cleaning).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"><strong>{(quote.EDF).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"><strong>{(quote.tourism).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"><strong>{(quote.assurance).toFixed(2)} €</strong></p>
                                                <p className="apartmentTextAlignRight"><strong>{(parseFloat((quote.assurance).toFixed(2)) + parseFloat((quote.tourism).toFixed(2)) + parseFloat((quote.EDF).toFixed(2)) + parseFloat((quote.cleaning).toFixed(2)) + parseFloat((quote.commission).toFixed(2)) + parseFloat((quote.rent).toFixed(2))).toFixed(2)} €</strong></p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )
                })}
                {simulated && simulated.length > 0 && params.nb_person && params.nb_adult && params.account_id &&
                    <Form.Row className="justify-content-end mt-2">
                        <Form.Group controlId="formBasicCheckbox" className='mr-2'>
                            <Form.Check name='send_en' type="checkbox" label="En Anglais" checked={params.send_en} onClick={(e) => handleSendLangParams(e)} />
                        </Form.Group>
                        <br />
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check name='send_fr' type="checkbox" label="En français" checked={params.send_fr} onClick={(e) => handleSendLangParams(e)} />
                        </Form.Group>
                    </Form.Row>
                }
                <Form.Row className="d-flex justify-content-center mt-2" style={{height: '80px'}}>
                    {simulated && params.nb_person && params.nb_adult && params.account_id ?
                        <>
                            {
                                pending ?
                                    <Spinner />
                                    :
                                    <Button className='h-50' variant="secondary" onClick={() => handleSubmit()}>Envoyer</Button>
                            }
                        </>
                        :
                        <>
                            {
                                pending ?
                                    <Spinner />
                                    :
                                    <Button className='h-50' variant="secondary" onClick={() => handleSimulate()}>Simuler</Button>
                            }
                        </>}
                </Form.Row>
            </Form>
            :
            <Row>
                <Col xs={12} className='d-flex justify-content-center'>
                    <Spinner />
                </Col>
            </Row>}
    </div>)
}

function mapStateToProps(state) {
    return {
        result: getResult(state),
        param: getParam(state),
        beginDate: getBeginDate(state),
        endDate: getEndDate(state)
    }
}

export default connect(mapStateToProps)(CreateQuote);