import { useEffect, useState } from "react";
import instanceOfAxios from "../../../../app/axios/axios";
import CommentsPreviewCard from "./commentsPreviewCard";
import { Row, Col } from 'react-bootstrap'
import CommentsModal from "../modalList/commentsModal";

export default function CommentsPreview(props) {
    const [comments, setComments] = useState([]);
    const [pendin, setPending] = useState(true);

    useEffect(() => {
        if (pendin) {
            setPending(false);
            instanceOfAxios.get('/apartment/' + props.apartment.id + '/comment')
                .then((res) => {
                    let comments = res.data.comments;
                    comments.map(comment => {

                        comment.content = JSON.parse(comment.content)

                        comment.total_equip = 0;
                        comment.total_state = 0;
                        comment.total_cleaning = 0;
                        comment.total_check_in = 0;

                        comment.total_check_in += comment.content.check_in_score.score;
                        // equipement
                        comment.total_equip += parseInt(comment.content.equipment_score.bathroom_score);
                        comment.total_equip += parseInt(comment.content.equipment_score.living_room_score);
                        comment.total_equip += parseInt(comment.content.equipment_score.kitchen_score);
                        comment.total_equip += parseInt(comment.content.equipment_score.outside_score);
                        comment.total_equip += parseInt(comment.content.equipment_score.room_score);
                        comment.total_equip += parseInt(comment.content.equipment_score.toilet_score);
                        // état générale
                        comment.total_state += parseInt(comment.content.state_score.bathroom_score);
                        comment.total_state += parseInt(comment.content.state_score.living_room_score);
                        comment.total_state += parseInt(comment.content.state_score.floor_score);
                        comment.total_state += parseInt(comment.content.state_score.kitchen_score);
                        comment.total_state += parseInt(comment.content.state_score.outside_score);
                        comment.total_state += parseInt(comment.content.state_score.room_score);
                        comment.total_state += parseInt(comment.content.state_score.toilet_score);

                        // état générale
                        comment.total_cleaning += parseInt(comment.content.cleaning_score.bathroom_score);
                        comment.total_cleaning += parseInt(comment.content.cleaning_score.living_room_score);
                        comment.total_cleaning += parseInt(comment.content.cleaning_score.floor_score);
                        comment.total_cleaning += parseInt(comment.content.cleaning_score.kitchen_score);
                        comment.total_cleaning += parseInt(comment.content.cleaning_score.outside_score);
                        comment.total_cleaning += parseInt(comment.content.cleaning_score.room_score);
                        comment.total_cleaning += parseInt(comment.content.cleaning_score.toilet_score);
                    })
                    setComments(res.data.comments);
                })
        }
    }, [])

    return (
        <Row>
            <Col xs={12} className='noPadding'>
                <h4>COMMENTAIRES ({comments.length})</h4>
            </Col>
            {
                comments.length > 0 && comments.filter((item, idx) => idx < 4).map(comment => <CommentsPreviewCard comment={comment} comments={comments} apartment={props.apartment}/>)
            }
        </Row>
    )
}