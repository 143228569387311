import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Modal, Row, Col, Form, Badge, InputGroup } from 'react-bootstrap';
import { items } from './items';
import { FcPlus } from 'react-icons/fc';
import { AiOutlineClose } from 'react-icons/ai';
import { show } from "../../Alerter/alerterSlice";
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../app/axios/axios";
import { FaPencilAlt } from 'react-icons/fa';
import './tourism.scss';
import { useEffect } from "react";
import Spinner from "../../../spinner/Spinner";
import { AiFillStar } from 'react-icons/ai';
import { FaRegSave } from 'react-icons/fa';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid,
    borderRadius: '10px',
    margin: `0px ${grid}px 0 0`,
    display: 'flex',
    alignItems: 'center',

    // change background colour if dragging
    background: isDragging ? "lightgrey" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    margin: `0 0 ${grid}px ${grid}px`,
    borderRadius: '10px',
    display: 'flex',
    height: '7vh'
});

export default function EditOrCreateTourism(props) {
    const [order, setOrder] = useState([]);
    const [visibility, setVisibility] = useState(false);
    const [resultat, setResultat] = useState(0);
    const [tourismValue, setTourismValue] = useState(0);
    const [stars, setStars] = useState({ one_star: 0, two_stars: 0, three_stars: 0, four_stars: 0, five_stars: 0, palace: 0 });
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [postaleCode, setPostaleCode] = useState('');
    const [postalesCodes, setPostalesCodes] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();

    if (props.tourism && !postalesCodes) {
        setPostalesCodes(props.tourism.postales_codes);
        let calcul = [];
        props.tourism.calcul.forEach((element, index) => {
            items.forEach(item => {
                if (item.value == element) {
                    calcul.push(item);
                }
            });

            if (element == '$tourism->value_tax') {
                let item = items.find(element => element.content == 'taxe');
                calcul.push(item);
            } else if (element == '$total_period') {
                let item = items.find(element => element.content == 'Nb nuit');
                calcul.push(item);
            }
        });
        setMin(props.tourism.min_amount);
        setMax(props.tourism.max_amount);
        setTourismValue(props.tourism.value_tax);
        setOrder(calcul);
    }

    useEffect(() => {
        if (!props.tourism) {
            setMin();
            setMax();
            setTourismValue();
            setOrder([]);
            setPostalesCodes();
        } else {
            setStars({
                one_star: props.tourism.one_star,
                two_stars: props.tourism.two_stars,
                three_stars: props.tourism.three_stars,
                four_stars: props.tourism.four_stars,
                five_stars: props.tourism.five_stars,
                palace: props.tourism.palace
            });
        }
    }, [props.tourism])

    function calculate() {
        let calc = '';
        order.map(item => {
            if (item.value == '%' && tourismValue) {
                return calc += tourismValue
            } else {
                return calc += item.valueForCalc
            }
        });
        try {
            setResultat(eval(calc).toFixed(2));
        } catch (error) {
            dispatch(show({ message: 'Erreur de calcul', variant: 'danger' }));

        }
    }

    function handleChange(event) {
        setPostaleCode(event.target.value);
    }

    function addPostaleCode() {
        let pc = Object.assign([], postalesCodes);
        if (pc.indexOf(postaleCode) == -1) {
            pc.push(postaleCode);
            setPostalesCodes(pc);
        }
    }

    function removePostaleCode(index) {
        let pc = Object.assign([], postalesCodes);
        pc.splice(index, 1);
        setPostalesCodes(pc);
    }

    function handleStarsChange(event) {
        stars[event.target.name] = event.target.value;
        setStars(stars);
    }

    function handleSubmit() {
        setIsSubmit(true);
        let calc = '';
        let url = '/admin/tourism';
        let data = { calcul: calc, postales_codes: postalesCodes, value_tax: tourismValue, min_amount: min, max_amount: max, ...stars }

        if (props.tourism) {
            url += '/' + props.tourism.id;
            data._method = 'PATCH';
        }

        order.map(item => {
            return data.calcul += item.value + '.'
        });

        instanceOfAxios.post(url, data)
            .then(res => {
                props.handleChange(true);
                setVisibility(!visibility);
                let message = props.tourism ? 'Tarrification mise à jour' : 'Nouvelle tarification créée';
                dispatch(show({ message: message, variant: 'success' }));
                setIsSubmit(false);
            })
            .catch(err => {
                let message = err.response.data.type == 'use' ? 'Code postal déjà utilisé : ' + err.response.data.code : 'Erreur lors de l\'enregistrement';
                dispatch(show({ message: message, variant: 'danger' }));
                setIsSubmit(false);
            });
    }

    /**
     * Moves an item from one list to another list.
     */
    const move = (destination, droppableSource, droppableDestination) => {
        const destClone = Array.from(destination);
        const sourceClone = Object.assign([], items);

        const item = Object.assign({}, sourceClone.splice(droppableSource.index, 1)[0]);

        destClone.splice(droppableDestination.index, 0, item);

        return destClone;
    };

    function onDragEnd(result) {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === 1 && dInd === 1 && sInd === dInd) {
            const result = reorder(order, source.index, destination.index);
            setOrder(result);
        } else if (sInd === 0 && dInd === 1) {
            const result = move(order, source, destination);
            setOrder(result);
        } else {
            order.splice(source.index, 1);
            setOrder(order);
        }
    }

    return (
        <>
            {props.for == 'edit' ?
                <Button size="md" className='goldenHover' onClick={() => setVisibility(!visibility)}>
                    <FaPencilAlt />
                </Button>
                :
                <Button size="md" variant="secondary" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={() => setVisibility(!visibility)}>
                    Créer un nouveau calcul
                </Button>
            }
            <Modal
                backdropClassName="static"
                backdrop="static"
                show={visibility}
                onHide={() => setVisibility(!visibility)}
                centered
                size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Définition d'une taxe de séjour
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row className='justify'>
                            <Col md={4}>
                                <h3>Tarif nuité par personne</h3>
                                <InputGroup>
                                    <Form.Control type="text" name="tourism" placeholder="20" onChange={(e) => setTourismValue(e.target.value)} defaultValue={tourismValue ? tourismValue : ''} />
                                    <InputGroup.Text>€</InputGroup.Text>
                                </InputGroup>
                            </Col>
                            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                                <div className='separator'
                                    style={{
                                        border: '1px solid #B7A57E',
                                        color: '#b7a57e',
                                        width: '90%',
                                        marginBottom: '40px',
                                        marginTop: '40px'
                                    }}></div>
                            </Col>
                        </Row>
                        <div style={{ display: "inline" }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable key={0} droppableId='0' direction='horizontal'>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                        >
                                            {items.map((item, index) => (
                                                <Draggable
                                                    key={item.id + '-static'}
                                                    draggableId={item.id + '-static'}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-around",
                                                                    color: 'white',
                                                                }}
                                                            >
                                                                {item.content}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                <Droppable key={1} droppableId='1' direction='horizontal'>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                        >
                                            {order.map((item, index) => {
                                                return item &&
                                                    <Draggable
                                                        key={item.id + index}
                                                        draggableId={item.id + index}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-around",
                                                                        color: 'white'
                                                                    }}
                                                                >
                                                                    {item.content}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                            }
                                            )
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                    <Row className='text-center justify'>
                        <Col md={3}>
                            <Button variant='secondary' onClick={() => { setOrder([]); setResultat(0) }}>Réinitialiser Le calcul</Button>
                        </Col>
                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%',
                                    marginBottom: '40px',
                                    marginTop: '40px'
                                }}></div>
                        </Col>
                        <Col md={12} className='mt-3'>
                            <h3>Tester votre calcul</h3>
                        </Col>
                        <Col md={12} className='mt-3'>
                            <Row className='d-flex justify-content-center'>
                                {order.map(item => {
                                    return item && <Col xs={'auto'}>
                                        <p style={{ margin: 'unset' }}>{item.value == '%' && tourismValue ? tourismValue : item.valueForCalc}</p>
                                        <small>{item.content}</small>
                                    </Col>
                                })}
                                <Col xs='auto'>
                                    <p className='text-center'> = {resultat}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} className='mt-3'>
                            <Button variant='secondary' onClick={calculate}>Calculer</Button>
                        </Col>
                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%',
                                    marginBottom: '40px',
                                    marginTop: '40px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='text-center justify'>
                        <Col md={3}>
                            <h3>Planché</h3>
                            <Form.Control type="text" name="min_amount" placeholder="5" onChange={(e) => setMin(e.target.value)} defaultValue={min ? min : ''} />
                        </Col>
                        <Col md={3}>
                            <h3>Plafond</h3>
                            <Form.Control type="text" name="max_amount" placeholder="40" onChange={(e) => setMax(e.target.value)} defaultValue={max ? max : ''} />
                        </Col>
                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%',
                                    marginBottom: '20px',
                                    marginTop: '40px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='text-center d-flex justify-content-center align-items-end'>
                        <Col xs={12}>
                            <h3>Prix étoilés</h3>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <AiFillStar />
                            <Form.Control type="text" name="one_star" placeholder="5" onChange={(e) => handleStarsChange(e)} defaultValue={stars.one_star} />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <AiFillStar />
                            <AiFillStar />
                            <Form.Control type="text" name="two_stars" placeholder="40" onChange={(e) => handleStarsChange(e)} defaultValue={stars.two_stars} />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <AiFillStar />
                            <AiFillStar />
                            <AiFillStar />
                            <Form.Control type="text" name="three_stars" placeholder="5" onChange={(e) => handleStarsChange(e)} defaultValue={stars.three_stars} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className='mt-3'>
                            <AiFillStar />
                            <AiFillStar />
                            <AiFillStar />
                            <AiFillStar />
                            <Form.Control type="text" name="four_stars" placeholder="40" onChange={(e) => handleStarsChange(e)} defaultValue={stars.four_stars} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className='mt-3'>
                            <AiFillStar />
                            <AiFillStar />
                            <AiFillStar />
                            <AiFillStar />
                            <AiFillStar />
                            <Form.Control type="text" name="five_stars" placeholder="5" onChange={(e) => handleStarsChange(e)} defaultValue={stars.five_stars} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className='mt-3'>
                            <Form.Label>Palace</Form.Label>
                            <Form.Control type="text" name="palace" placeholder="40" onChange={(e) => handleStarsChange(e)} defaultValue={stars.palace} />
                        </Col>
                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                            <div className='separator'
                                style={{
                                    border: '1px solid #B7A57E',
                                    color: '#b7a57e',
                                    width: '90%',
                                    marginBottom: '20px',
                                    marginTop: '40px'
                                }}></div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Col md={12} className='text-center'>
                            <h3>Les codes postaux</h3>
                        </Col>
                        <Col md={12} className='text-center'>
                            <small>Pour ajouter un code postal entrez le code dans l'encart prévu à cet effet et cliquez sur l'icone "+"</small>
                        </Col>
                        <Col md={7} className='mt-3'>
                            <InputGroup>
                                <Form.Control type="text" name="postal" placeholder="34000" onChange={(e) => handleChange(e)} />
                                <Button onClick={addPostaleCode} className="goldenHover" id="basic-addon3">
                                    <FcPlus />
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col md={6} className='mt-3'>
                            {postalesCodes && postalesCodes.map((code, index) => <Badge key={index} variant='light'>{code} <AiOutlineClose onClick={() => removePostaleCode(index)} /></Badge>)}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                            <Spinner />
                            :
                            <Button
                                className='whiteSVG'
                                variant="secondary"
                                onClick={handleSubmit}>
                                <FaRegSave /> Enregistrer
                            </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}
