import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'
import Spinner from '../../../../../spinner/Spinner';
import instanceOfAxios from '../../../../../../app/axios/axios';
import { FaRegSave } from 'react-icons/fa';

export default function DrawTextModal(props) {
    const [text, setText] = useState(undefined);
    const [textSize, setTextSize] = useState(10);

    async function draw() {
        props.setSubmit(true);
        const existingPdfBytes = await fetch(props.file, {
            credentials: "include"
        }).then(res => res.arrayBuffer())
        var bytes = new Uint8Array(existingPdfBytes);

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const page = pdfDoc.getPages()[props.pageNumber - 1];

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        page.drawRectangle({
            x: props.positions.x - 5,
            y: page.getHeight() - props.positions.y - parseInt(textSize) / 2,
            height: parseInt(textSize),
            width: parseInt(textSize) * text.length,
            color: rgb(1, 1, 1),
            opacity: 1
        })
        page.drawText(text, {
            x: props.positions.x,
            y: page.getHeight() - props.positions.y - parseInt(textSize) / 2,
            size: parseInt(textSize),
            font: helveticaFont
        })
        const pdfBytes = await pdfDoc.save();

        const myFile = new File([pdfBytes], props.type + '.pdf', {
            type: pdfBytes.type,
        });
        let form = new FormData();
        form.append('_method', 'PATCH');
        form.append('blob', myFile);
        instanceOfAxios.post(props.updateUrl, form)
            .then(res => {
                props.onHide();
            });
    }

    return (
        <Modal
            show={props.visibility}
            onHide={() => props.onHide(false)}
            backdropClassName="backdropFourth"
            scrollable
            backdrop="static"
            style={{ zIndex: '20005' }}
            centered>
            <Modal.Header closeButton>
                Entrez un text à ajouter
            </Modal.Header>
            <Modal.Body>
                <Form.Group xs={12} sm={3}>
                    <Form.Control size="sm" placeholder="Bernard Durant" onChange={(e) => setText(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Taille de text</Form.Label>
                    <Form.Control as="select" defaultValue='10' onChange={(e) => setTextSize(e.target.value)}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                {
                    props.isSubmit ?
                        <Spinner />
                        :
                        <Button variant='secondary' className='whiteSVG' onClick={draw}><FaRegSave/> Enregistrer</Button>
                }
            </Modal.Footer>
        </Modal>
    )
}