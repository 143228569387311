// let indexEnv = 'local';
// let indexEnv = 'preProd';
let indexEnv = 'prod';

const env = {
    local: {
        axios: 'http://localhost:8000/api',
        payment: 'https://recette-tpeweb.e-transactions.fr/php/'
    },
    preProd: {
        axios: 'https://preprod.www.class-appart.com.web09.ovea.com/api/api',
        payment: 'https://recette-tpeweb.e-transactions.fr/php/'
    },
    prod: {
        axios: 'https://www.class-appart.com/api/api',
        payment: 'https://tpeweb.e-transactions.fr/php/'
    }
}
export default env[indexEnv];