import { useState } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import instanceOfAxios from '../../../../../app/axios/axios';
import Spinner from '../../../../spinner/Spinner';
import { show } from '../../../Alerter/alerterSlice';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import SignModal from './modifyPdf/SignModal';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { ImCross } from 'react-icons/im';
import './checkIn.scss';
import { FaRegSave } from 'react-icons/fa';
import { TiDocumentAdd } from 'react-icons/ti'; 
import { BsKey } from 'react-icons/bs';
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CheckInKeys(props) {
    const [visibility, setVisibility] = useState(false);
    const [confirmVisibility, setConfirmVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [updateParam, setUpdateParam] = useState({ plain_hour: 0, empty_hour: 0, sign: null });
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState({ keys: 0, badge: 0, garage: 0, plain_hour: 0, empty_hour: 0 })
    const dispatch = useDispatch();

    function handleKeysChange(e) {
        data[e.target.name] = e.target.value;
        setData(data);
    }

    function handleKeysUpdate(e) {
        updateParam[e.target.name] = e.target.value;
        setData(data);
    }

    function submit() {
        setIsSubmit(true);
        instanceOfAxios.post('/admin/booking/' + props.infos.id + '/createKeys', data)
            .then(res => {
                dispatch(show({ message: 'Document remise de clés généré', variant: 'success' }));
                setConfirmVisibility(false);
                setIsSubmit(false);
                props.handleChange(res.data.booking);
            })
            .catch(err => {
                dispatch(show({ message: 'Document remise de clés non généré', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    function callClassAppartConnect() {
        setIsSubmit(true);
        const url = `https://connect-classappart.fr/api/intervention/popup/${props.infos.apartment.reference}/start`;
        axios.get(url,{
            headers: { 'Cache-Control': 'no-cache' },
        })
            .then(res => {
                dispatch(show({ message: 'Document remise de clés généré', variant: 'success' }));

                // Si une URL est retournée, redirige l'utilisateur
                if (res.data.url) {
                    window.open(res.data.url, '_blank'); // "_blank" ouvre un nouvel onglet
                }

                /*setConfirmVisibility(false);*/
                setIsSubmit(false);
                /*props.handleChange(res.data.booking);*/
            })
            .catch(err => {
                dispatch(show({ message: 'Document remise de clés non généré', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    async function addingUpdateAndSave() {
        setIsSubmit(true);
        const existingPdfBytes = await fetch(props.infos.check_in_keys.file, {
            credentials: "include"
        }).then(res => res.arrayBuffer())

        var bytes = new Uint8Array(existingPdfBytes);

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const page = pdfDoc.getPages()[0];

        if (updateParam.sign) {
            const pngImageBytes = await fetch(updateParam.sign).then((res) => res.arrayBuffer());

            const pngImage = await pdfDoc.embedPng(pngImageBytes)

            const pngDims = pngImage.scale(0.5);

            page.drawImage(pngImage, {
                x: page.getWidth() - pngDims.width,
                y: pngDims.height / 2 - 30,
                width: pngDims.width,
                height: pngDims.height,
            })
        }

        if (updateParam.plain_hour) {
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
            page.drawText(updateParam.plain_hour, {
                x: 165,
                y: page.getHeight() / 2 + 82,
                size: 15,
                font: helveticaFont
            })
        }

        if (updateParam.empty_hour) {
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
            page.drawText(updateParam.empty_hour, {
                x: 330,
                y: page.getHeight() / 2 + 82,
                size: 15,
                font: helveticaFont
            })
        }

        const pdfBytes = await pdfDoc.save();
        const myFile = new File([pdfBytes], 'remise.pdf', {
            type: pdfBytes.type,
        });
        let form = new FormData();
        form.append('_method', 'PATCH');
        form.append('blob', myFile);
        instanceOfAxios.post(props.infos.check_in_keys.file, form)
            .then(res => {
                setReRender(true)
                setIsSubmit(false);
            });
    }

    function deleteSign() {
        let param = Object.assign({}, updateParam);
        param.sign = null;
        setUpdateParam(param);
    }

    return (
        <>
            <Button variant='secondary' className='whiteSVG' onClick={() => setVisibility(true)}><BsKey/> Remise de clés</Button>
            {
                visibility &&
                <Modal
                    dialogClassName='custom-dialog'
                    show={visibility}
                    onHide={() => setVisibility(false)}
                    backdropClassName="backdropFourth"
                    scrollable
                    size='xl'
                    backdrop="static"
                    style={{ zIndex: '20003' }}
                    centered>
                    <Modal.Header className='d-flex align-items-center' closeButton>
                        <Row style={{ width: '100%' }}>
                            <Col xs={12}>
                                <Modal.Title>
                                    Remise des clés et relevé de compteurs
                                </Modal.Title>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '70vh' }}>
                        {
                            props.infos && !props.infos.check_in_keys.as_file ?
                                <>
                                    <p style={{ color: 'red' }} className='text-center'>Attention toute valeur non remplis sera mise à 0 par défaut</p>

                                    <Row>
                                        <Col as={Col} sm={6} md={4}>
                                            <Button variant='secondary' onClick={callClassAppartConnect}>Class Appart Connect</Button>
                                        </Col>
                                    </Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={6} md={4}>
                                            {errors['keys'] && <label htmlFor="keys"><small style={{ color: "red" }}>{errors['keys']}</small></label>}
                                            <br />
                                            <Form.Label>Nombre de clés remise à l'arrivée</Form.Label>
                                            <Form.Control as="select" name='keys' size="sm" onChange={(e) => handleKeysChange(e)}>
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6} md={4}>
                                            {errors['badge'] && <label htmlFor="badge"><small style={{ color: "red" }}>{errors['badge']}</small></label>}
                                            <br />
                                            <Form.Label>Nombre de badge remis à l'arrivée</Form.Label>
                                            <Form.Control as="select" name='badge' size="sm" onChange={(e) => handleKeysChange(e)}>
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6} md={4}>
                                            {errors['garage'] && <label htmlFor="garage"><small style={{ color: "red" }}>{errors['garage']}</small></label>}
                                            <br />
                                            <Form.Label>Nombre de badge garage remis à l'arrivée</Form.Label>
                                            <Form.Control as="select" name='garage' size="sm" onChange={(e) => handleKeysChange(e)}>
                                                <option>0</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className='d-flex justify-content-center'>
                                        <Form.Group as={Col} xs={12} sm={3}>
                                            {errors['plain_hour'] && <label htmlFor="plain_hour"><small style={{ color: "red" }}>{errors['plain_hour']}</small></label>}
                                            <Form.Label>Heures pleines</Form.Label>
                                            <Form.Control id='plain_hour' name='plain_hour' size="sm" placeholder="3500" onChange={(e) => handleKeysChange(e)} />
                                        </Form.Group>
                                        <Form.Group as={Col} xs={12} sm={3}>
                                            {errors['empty_hour'] && <label htmlFor="empty_hour"><small style={{ color: "red" }}>{errors['empty_hour']}</small></label>}
                                            <Form.Label>Heures creuse</Form.Label>
                                            <Form.Control id='empty_hour' name='empty_hour' size="sm" placeholder="3500" onChange={(e) => handleKeysChange(e)} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className='d-flex justify-content-center'>
                                        <Form.Group as={Col} xs={12} sm={3}>
                                            {errors['name'] && <label htmlFor="name"><small style={{ color: "red" }}>{errors['name']}</small></label>}
                                            <Form.Label>Nom complet</Form.Label>
                                            <Form.Control id='name' name='name' size="sm" placeholder="Bernard Durant" onChange={(e) => handleKeysChange(e)} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className='d-flex justify-content-center'>
                                        <Button variant='secondary' className='whiteSVG' onClick={() => setConfirmVisibility(!confirmVisibility)}><TiDocumentAdd/>Générer l'état des lieux</Button>
                                    </Form.Row>
                                    <Modal
                                        show={confirmVisibility}
                                        onHide={() => setConfirmVisibility(false)}
                                        backdropClassName="backdropFourth"
                                        scrollable
                                        size='xl'
                                        style={{ zIndex: '20050' }}
                                        centered>
                                        <Modal.Header>
                                            <Modal.Title>Confirmation des informations</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Row className='text-center d-flex justify-content-center'>
                                                <Col>
                                                    <p>Nombre de clés : </p><strong>{data.keys}</strong>
                                                </Col>
                                                <Col>
                                                    <p>Nombre de badge: </p><strong>{data.badge}</strong>
                                                </Col>
                                                <Col>
                                                    <p>Nombre de télécommande garage : </p><strong>{data.garage}</strong>
                                                </Col>
                                            </Row>
                                            <Row className='text-center'>
                                                <Col>
                                                    <p>Heure pleines : </p><strong>{data.plain_hour}</strong>
                                                </Col>
                                                <Col>
                                                    <p>Heures creuses : </p><strong>{data.empty_hour}</strong>
                                                </Col>
                                            </Row>
                                            <Row className='text-center'>
                                                <Col>
                                                    <p>Nom complet : </p><strong>{data.name}</strong>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
                                        <Modal.Footer className='justify-content-center'>
                                            <Row style={{ width: '100%' }}>
                                                {
                                                    isSubmit ?
                                                        <Col className='d-flex justify-content-center'>
                                                            <Spinner />
                                                        </Col>
                                                        :
                                                        <>
                                                            <Col className='d-flex justify-content-center'>
                                                                <Button variant='danger' onClick={() => setConfirmVisibility(!confirmVisibility)}>Annuler</Button>
                                                            </Col>
                                                            <Col className='d-flex justify-content-center'>
                                                                <Button variant='secondary' onClick={submit}>Confirmer</Button>
                                                            </Col>
                                                        </>
                                                }
                                            </Row>
                                        </Modal.Footer>
                                    </Modal>
                                </>
                                :
                                <>
                                    <Row>
                                        <Col md={12} lg={6} className='d-flex justify-content-center align-items-end align-items-md-center'>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Row className='d-flex justify-content-center align-items-end'>
                                                        <Form.Group as={Col} xs={12} sm={3}>
                                                            {errors['plain_hour'] && <label htmlFor="plain_hour"><small style={{ color: "red" }}>{errors['plain_hour']}</small></label>}
                                                            <Form.Label>Heures pleines</Form.Label>
                                                            <Form.Control id='plain_hour' name='plain_hour' size="sm" placeholder="3500" onChange={(e) => handleKeysUpdate(e)} />
                                                        </Form.Group>
                                                        <Form.Group as={Col} xs={12} sm={3}>
                                                            {errors['empty_hour'] && <label htmlFor="empty_hour"><small style={{ color: "red" }}>{errors['empty_hour']}</small></label>}
                                                            <Form.Label>Heures creuse</Form.Label>
                                                            <Form.Control id='empty_hour' name='empty_hour' size="sm" placeholder="3500" onChange={(e) => handleKeysUpdate(e)} />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Col>
                                                <Col xs={12} className='d-flex flex-column justify-content-center align-items-center'>
                                                    {
                                                        updateParam.sign &&
                                                        <>
                                                            <p>Signature allant être apposée sur le document</p>
                                                            <img src={updateParam.sign} loading="lazy"/>
                                                            <Form.Row className='d-flex justify-content-end align-items-end'>
                                                                <Form.Group>
                                                                    <Button onClick={deleteSign}><ImCross /></Button>
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </>
                                                    }

                                                    <Form.Row className='d-flex justify-content-center align-items-end'>
                                                        <Form.Group>
                                                            <SignModal onSave={setUpdateParam} updateParam={updateParam} />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={12} lg={6} className='d-flex justify-content-center'>
                                            <Document
                                                className='page'
                                                file={{ url: props.infos.check_in_keys.file, httpHeaders: {}, withCredentials: true }}
                                                loading={<div className='loading d-flex justify-content-center align-items-center'><Spinner /></div>}
                                            >
                                                <Page pageNumber={1} />
                                            </Document>
                                        </Col>
                                        <Col xs={12} className='d-flex justify-content-center mt-2'>
                                            <a href={props.infos.check_in_keys.file} target='_blank'><Button>Visualiser</Button></a>
                                        </Col>
                                    </Row>
                                </>
                        }
                    </Modal.Body>
                    {
                        props.infos && props.infos.check_in_keys.as_file &&
                        <Modal.Footer>
                            {
                                isSubmit ?
                                    <Spinner />
                                    :
                                    <Button variant='secondary' className='whiteSVG' onClick={addingUpdateAndSave}><FaRegSave/> Enregistrer</Button>
                            }
                        </Modal.Footer>
                    }
                </Modal>
            }
        </>
    )
}