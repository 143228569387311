import { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import instanceOfAxios from '../../../../app/axios/axios';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import Spinner from '../../../spinner/Spinner';
import { useEffect } from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';

export default function AccountingInvoices() {
    const [pending, setPending] = useState(false);
    const beginYear = new Date();
    const endYear = new Date();
    beginYear.setMonth(0);
    beginYear.setDate(1);

    endYear.setMonth(11);
    endYear.setDate(31);
    const [params, setParams] = useState({ begin_period: beginYear.toISOString().split('T')[0], end_period: endYear.toISOString().split('T')[0] });

    useEffect(() => {
        if (pending) {
            instanceOfAxios.post('/admin/invoices/getAll', params, { responseType: "arraybuffer" })
                .then(res => {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    var href = window.URL.createObjectURL(blob);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `facture et avoir ${params.begin_period}/${params.end_period}`); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element
                    document.body.removeChild(link);
                    setPending(false);
                })
                .catch(err => {
                    setPending(false);
                })
        }
    }, [pending])

    function handleChange(event) {
        params[event.target.name] = event.target.value;
        setParams(params);
    }

    function handleCheck(event) {
        let newParams = Object.assign({}, params);

        // on retire dans tout les cas l'object pour simplifier
        const index = newParams.states.indexOf(event.target.value);
        if (index != -1) {
            newParams.states = newParams.states.filter((item) => item != event.target.value);
        }

        // on verifie si on coche ou décoche l'input
        if (event.target.checked) {
            newParams.states.push(event.target.value);
        }
        setParams(newParams);
    }


    return (
        <>
            <Row className='d-flex align-itels-center justify-content-center mb-3'>
                <Col xs={12} className='mt-2'>
                    <h4>Période</h4>
                </Col>
                <Col md={6}>
                    Du
                    <Form.Control type="date" name="begin_period" defaultValue={beginYear.toISOString().slice(0, 10)} onChange={(e) => handleChange(e)} />
                </Col>
                <Col md={6}>
                    Au
                    <Form.Control type="date" name="end_period" defaultValue={endYear.toISOString().slice(0, 10)} onChange={(e) => handleChange(e)} />
                </Col>
                <Col xs={12} lg={6} className='d-flex align-items-center justify-content-center mt-2'>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button variant='secondary' className='whiteSVG' onClick={() => setPending(true)}><AiOutlineCloudDownload/> Télécharger</Button>
                    }
                </Col>
            </Row>
        </>
    )
}