import { createSlice } from '@reduxjs/toolkit';
import instanceOfAxios from '../../../app/axios/axios';

export const bookingAdminSlice = createSlice({
    name: 'bookingAdmin',
    initialState: {
        result: [],
        isLoading: true,
        pageNumber: 0,
        param: {},
        activePage: 1,
        resultFromSearch: [],
        createVisibility: false,
        listVisibility: true
    },
    reducers: {
        setResult: (state, value) => {
            state.result = value.payload
        },
        setPageNumber: (state, value) => {
            state.pageNumber = value.payload
        },
        setLoading: (state, value) => {
            state.isLoading = value.payload
        },
        setResultFromSearch: (state, value) => {
            state.resultFromSearch = value.payload
            state.activePage = 1;
        },
        setParam: (state, value) => {
            state.param = value.payload
        },
        setActivePage: (state, value) => {
            state.activePage = value.payload
        },
        setCreateVisibility: (state, value) => {
            state.createVisibility = value.payload
        },
        setListVisibility: (state, value) => {
            state.listVisibility = value.payload
        }
    },
});

export const { setResult, setLoading, setActivePage, setParam, setResultFromSearch, setListVisibility, setCreateVisibility, setPageNumber } = bookingAdminSlice.actions;

export function FetchResultatAdmin() {
    return function (dispatch) {
        return instanceOfAxios.get('/admin/bookings?page=1')
            .then((res) => {
                dispatch(setResultFromSearch(res.data.bookings.data));
                dispatch(setResult(res.data.bookings.data));
                dispatch(setPageNumber(res.data.bookings.last_page));
                dispatch(setLoading(false));
            });
    }
};

export function handleChange(event, param) {
    return function (dispatch) {
        let paramsQuery = Object.assign({}, param);
            if (event.target.id === 'apartment') {
                paramsQuery['apartment_ref'] = event.target.value.toLowerCase();
            }
            if (event.target.id === 'booking') {
                paramsQuery['reference'] = event.target.value.toLowerCase();
            }
            if (event.target.id === 'tenant') {
                paramsQuery['tenant_name'] = event.target.value.toLowerCase();
            }
            if (event.target.id === 'state') {
                if(event.target.value === 'toutes'){
                     paramsQuery['state'] = '';
                }else{
                    paramsQuery['state'] = event.target.value.toLowerCase();
                }
            }
        dispatch(setParam(paramsQuery));
    }
}

export function search(activePage, param){
    return function(dispatch){
        dispatch(setLoading(true));
        let query = '';
        for (const [key, value] of Object.entries(param)) {
                query += key + '=' + value + '&';
        }
        instanceOfAxios.get('/admin/bookings?page=' + (parseInt(activePage)) + '&' + query)
            .then((res) => {
                dispatch(setResultFromSearch(res.data.bookings.data));
                dispatch(setResult(res.data.bookings.data));
                dispatch(setPageNumber(res.data.bookings.last_page));
                dispatch(setLoading(false));
            })
    }
}

export function prevPage(active = null, param) {
    return function (dispatch) {
        if (active > 1) {
            dispatch(setLoading(true));
            let query = '';
            for (const [key, value] of Object.entries(param)) {
                    query += key + '=' + value + '&';
            }
            return instanceOfAxios.get('/admin/bookings?page=' + (parseInt(active) - 1) + '&' + query)
            .then((res) => {
                dispatch(setResultFromSearch(res.data.bookings.data));
                dispatch(setResult(res.data.bookings.data));
                dispatch(setLoading(false));
                dispatch(setActivePage(parseInt(active) - 1));
            })
        }
    }
}

export function nextPage(active = null, pageNumber = 0, param) {
    return function (dispatch) {
        if (active < pageNumber) {
            dispatch(setLoading(true));
            let query = '';
            for (const [key, value] of Object.entries(param)) {
                    query += key + '=' + value + '&';
            }
            return instanceOfAxios.get('/admin/bookings?page=' + (parseInt(active) + 1) + '&' + query)
            .then((res) => {
                dispatch(setResultFromSearch(res.data.bookings.data));
                dispatch(setResult(res.data.bookings.data));
                dispatch(setLoading(false));
                dispatch(setActivePage(parseInt(active) + 1));
            })
        }
    }
}

export function showCreate(){
    return function (dispatch){
        dispatch(setCreateVisibility(true));
        dispatch(setListVisibility(false));
    }
}

export function hideCreate(){
    return function (dispatch){
        dispatch(setCreateVisibility(false));
        dispatch(setListVisibility(true));
    }
}

export const getSearchResult = state => state.bookingAdmin.resultFromSearch;
export const getLoading = state => state.bookingAdmin.isLoading;
export const getPageNumber = state => state.bookingAdmin.pageNumber;
export const getParam = state => state.bookingAdmin.param;
export const getResult = state => state.bookingAdmin.result;
export const getActivePage = state => state.bookingAdmin.activePage;
export const getCreateVisibility = state => state.bookingAdmin.createVisibility;
export const getListVisibility = state => state.bookingAdmin.listVisibility;

export default bookingAdminSlice.reducer;
