import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import TourismTable from "./tourism/tourismTable";
import AgencyTax from './agencyTax/AgencyTax';

export default function Taxes(props) {
    return (
        <div className='users slider-list-left slide-in-list-left'>
            <Row>
                <Col md={12} className='text-center'>
                    <h2>Taxes et Agences</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="commun" className="mb-3">
                <Tab eventKey="commun" title="Taxes communes">
                    <AgencyTax/>
                </Tab>
                <Tab eventKey="tourism" title="Taxe de séjours">
                    <TourismTable />
                </Tab>
            </Tabs>
        </div>
    )
}