import ViewerYear from '../../admin/apartments/form/viewerYear/viewerYear';
import { show } from '../../admin/Alerter/alerterSlice';
import DateRange from '../../searchBar/dateRange/dateRange';
import { useEffect, useState } from 'react';
import { Col, Row, Button, Modal, Form, Pagination } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import instanceOfAxios from '../../../app/axios/axios';
import { resetDate } from '../../searchBar/dateRange/dateRangeSlice';
import { getBeginDate, getEndDate } from '../../searchBar/dateRange/dateRangeSlice';
import Spinner from '../../spinner/Spinner';

function VerticalCenteredModal(props) {
    let currentYear = new Date();
    const [error, setError] = useState([]);
    const [recapVisibility, setRecapVisibility] = useState(false);
    const [apartment, setApartment] = useState(props.apartment);
    const [params, setParams] = useState({ reason: 'Blocage propriétaire' });
    const dispatch = useDispatch();
    const [date, setDate] = useState([]);
    const [year, setYear] = useState(currentYear.getFullYear());

    let items = [
        <Pagination.Item key={year} id={year} active='true'>
            {year}
        </Pagination.Item>
    ];

    function prev() {
        setYear(year - 1);
    }

    function next() {
        setYear(year + 1);
    }

    useEffect(() => {
        instanceOfAxios.get('/admin/apartments/' + apartment.id + '/dateBlocked/' + year)
            .then((res) => {
                setDate(res.data.apartment);
            })
    }, [year]);

    useEffect(() => {
        setApartment(props.apartment);
        instanceOfAxios.get('/admin/apartments/' + apartment.id + '/dateBlocked/' + year)
            .then((res) => {
                setDate(res.data.apartment);
            })
    }, [props.apartment]);

    useEffect(() => {
        if (props.begin_date) {
            let data = Object.assign({}, params);
            let date = new Date(props.begin_date);
            data['begin_date_hour'] = date.toISOString();
            setParams(data);
        }
    }, [props.begin_date])

    useEffect(() => {
        if (props.end_date) {
            let data = Object.assign({}, params);
            let date = new Date(props.end_date);
            data['end_date_hour'] = date.toISOString();
            setParams(data);
        }
    }, [props.end_date])

    function handleCalendarChange(event) {
        let data = Object.assign({}, params);
        data[event.target.name] = event.target.value;
        setError([]);
        setParams(data);
    }

    function handleCalendarSubmit() {
        let url = '/apartment/' + apartment.id + '/calendar';

        let calendarData = setFormData(params);

        instanceOfAxios.post(url, calendarData)
            .then((res) => {
                dispatch(resetDate());
                props.handleChange();
                dispatch(show({ message: 'Le blocage à été pris en compte', variant: 'success' }));
            })
            .catch((error) => {
                if (error.response.data.dateError) {
                    dispatch(show({ message: error.response.data.dateError, variant: 'danger' }));
                    let arrayError = { 'date': error.response.data.dateError };
                    setError(Object.assign(arrayError, []));
                } else {
                    handleError(error);
                }
                setRecapVisibility(false);
            })
    }

    function setFormData(params, object) {
        let formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        });
        if (object && object.id) {
            formData.set('_method', 'PATCH');
        }
        return formData;
    }

    function handleError(errors) {
        let error = Object.assign([], errors);
        let message = 'Erreur lors de l\'action'
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        setError(error);
        if (error.response.data.error == 'non disponible') {
            message = 'Appartement déjà bloqué';
        }
        dispatch(show({ message: message, variant: 'danger' }));
    }

    function formateDate(date){
        date = new Date(date);
        return date.toLocaleDateString();
    }

    return (
        <Modal
            style={{ zIndex: '20001' }}
            {...props}
            onHide={() => {
                props.onHide();
                setParams({ reason: 'Blocage propriétaire' });
            }}
            size='xl'
            aria-labelledby="example-custom-modal-styling-title"
            scrollable
            centered
            backdropClassName="backdrop"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Disponibilités
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: '.8rem' }}>
                <Row noGutters>
                    <Col xs={12}>
                        <h2>Bloquer des dates</h2>
                    </Col>
                    <Form.Group as={Col}>
                        <Form.Row>
                            <Form.Group as={Col} md={12}>
                                <Form.Label style={{ color: error['endtitle'] ? "red" : "" }}>Message *</Form.Label><br />
                                <small>exemple: titre du blocage / demandez à class appart de fournir du linge ou autre...</small>
                                <Form.Control as="textarea" id='endtitle' name='endtitle' size="sm" placeholder="Vacances avec linge pour 2 lits et 4 personnes" onChange={(e) => handleCalendarChange(e)} />
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12}>
                                <Form.Label style={{ color: error['begin_hour'] ? "red" : "" }}>Heure d'arrivée *</Form.Label><br />
                                <small>Important: le logement sera pret à partir de 16h</small>
                                <Form.Control size="sm" name="begin_hour" as="select" onChange={(e) => handleCalendarChange(e)}>
                                    <option value='16'>Selectionner une heure</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} lg={6} md={12}>
                                <Form.Label style={{ color: error['end_hour'] ? "red" : "" }}>Heure de départ *</Form.Label><br />
                                <small>Important: le logement devra etre liberé avant 10h</small>
                                <Form.Control size="sm" name="end_hour" as="select" onChange={(e) => handleCalendarChange(e)}>
                                    <option value='7'>Selectionner une heure</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Form.Group>
                    <Col sm={12} lg={12} style={{ color: error['date'] ? "red" : "" }}>
                        {error['date'] && <label style={{ color: "red" }}><small>{error['date']}</small></label>}
                        <DateRange usage='admin' id='dateRangeBooking' minLength={1} />
                    </Col>
                    <Col sm={12} lg={12} className='d-flex justify-content-end mt-3'>
                        * champ obligatoire
                    </Col>
                    <Col sm={12} lg={12} className='d-flex justify-content-center mt-3' style={{ marginBottom: '2vh' }}>
                        <Button className='goldenHover' onClick={() => setRecapVisibility(true)}>Valider mon blocage de dates</Button>
                        {
                            recapVisibility && 
                            <Modal
                            backdropClassName="backdropFourth"
                            show={recapVisibility}
                            onHide={() => setRecapVisibility(false)}
                            centered
                            scrollable
                            style={{ zIndex: '20005' }}>
                            <Modal.Header closeButton>
                                Validation des données
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col xs={12} className='d-flex justify-content-center'>
                                        Message à l'intention de Class Appart : <br /><br />
                                        {
                                            params.endtitle
                                        }
                                    </Col>
                                    <Col xs={12} sm={6} className='d-flex flex-column justify-content-center'>
                                    <br /> arrivée : <br />
                                        {
                                            params.begin_date_hour ? formateDate(params.begin_date_hour) : <small style={{color: 'red'}}>veuillez selectionner une date valide</small>
                                        }
                                        {
                                            params.begin_hour ? <div>à {params.begin_hour + 'h'}</div> : <small style={{color: 'red'}}>veuillez selectionner une heure de départ</small>
                                        }
                                    </Col>
                                    <Col xs={12} sm={6} className='d-flex flex-column justify-content-center'>
                                    <br />départ : <br />
                                        {
                                            params.end_date_hour ? formateDate(params.end_date_hour) : <small style={{color: 'red'}}>veuillez selectionner une date valide</small>
                                        }
                                        {
                                            params.end_hour ? <div>à {params.end_hour + 'h'}</div> : <small style={{color: 'red'}}>veuillez selectionner une heure de départ</small>
                                        }
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className='goldenHover' onClick={() => handleCalendarSubmit()}>Valider mon blocage de dates</Button>
                            </Modal.Footer>
                        </Modal>
                        }
                    </Col>
                    <Col sm={12} lg={12}>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                                marginBottom: '40px',
                                marginTop: '40px'
                            }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h2 style={{ marginBottom: '35px' }}>Calendrier</h2>
                    </Col>
                    <Col xs={12} className='d-flex justify-content-center'>
                        <Pagination style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                            <Pagination.Prev id={'prev'} onClick={() => prev()} />
                            {items}
                            <Pagination.Next id={'next'} onClick={() => next()} />
                        </Pagination>
                    </Col>
                    <Col xs={12} className='noPadding d-flex justify-content-center'>
                        {
                            date.length > 1 ?
                                <ViewerYear dateUse={date} handleChange={props.handleChange} year={year} />
                                :
                                <Spinner />
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className='goldenHover' onClick={props.onHide}>Fermer</Button>
            </Modal.Footer>
        </Modal>
    )
}
const mapStateToProps = state => {
    return {
        begin_date: getBeginDate(state),
        end_date: getEndDate(state)
    };
}

export default connect(mapStateToProps)(VerticalCenteredModal);