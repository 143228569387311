import { useState } from 'react'
import { Modal, Button, Row, Col, Badge } from 'react-bootstrap'
import { IoIosMail } from 'react-icons/io';
import { FaPhoneAlt } from 'react-icons/fa';

export default function CheckInInformation(props) {
    const [visibility, setVisibility] = useState(false);
    return (
        <>
            <Button variant='secondary' onClick={() => setVisibility(true)}>Informations de check-in</Button>
            {
                visibility &&
                <Modal
                    show={visibility}
                    onHide={() => setVisibility(false)}
                    backdropClassName="backdropFourth"
                    scrollable
                    size='xl'
                    style={{ zIndex: '20003' }}
                    centered>
                    <Modal.Header className='d-flex align-items-center' closeButton>
                        <Row style={{ width: '100%' }}>
                            <Col xs={12} md={6} className='d-flex justify-content-center'>
                                <Modal.Title>
                                    Informations
                                </Modal.Title>
                            </Col>
                            <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
                                <Badge variant={props.infos ? 'danger' : 'success'}>{props.infos ? 'à contacter' : ''}</Badge>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12} lg={4} className='text-center mb-3 d-flex align-items-center justify-content-center'>
                                <p>Heure d'arrivée souhaitée : <strong>{props.infos.begin_hour}</strong>h</p>
                            </Col>
                            {
                                props.info &&
                                <>
                                    <Col sm={12} lg={4} className='text-center mb-3 d-flex align-items-center justify-content-center'>
                                        <span className='noReturn apartmentText'><IoIosMail /><strong><a href={'mailto:' + props.infos.email}> {props.infos.email}</a></strong></span>
                                    </Col>
                                    <Col sm={12} lg={4} className='text-center mb-3 d-flex align-items-center justify-content-center'>
                                        <span className='noReturn apartmentText'><FaPhoneAlt /><strong><a href={'tel:' + props.infos.phone}> {props.infos.phone}</a></strong></span>
                                    </Col>
                                </>
                            }
                            <Col xs={12} className='noPadding mb-3'>
                                <Row className='noPadding d-flex justify-content-center'>
                                    {
                                        props.infos.double_bed > 0 &&
                                        <Col sm={6} lg={3} className='text-center'>
                                            <p>Lit Double : <strong>{props.infos.double_bed}</strong></p>
                                        </Col>
                                    }
                                    {
                                        props.infos.simple_bed > 0 &&
                                        <Col sm={6} lg={3} className='text-center'>
                                            <p>Lit simple : <strong>{props.infos.simple_bed}</strong></p>
                                        </Col>
                                    }
                                    {
                                        props.infos.convert_sofa > 0 &&
                                        <Col sm={6} lg={3} className='text-center'>
                                            <p>Canapé convertible : <strong>{props.infos.convert_sofa}</strong></p>
                                        </Col>
                                    }
                                    {
                                        props.infos.convert_bed > 0 &&
                                        <Col sm={6} lg={3} className='text-center'>
                                            <p>Lit simple convertible : <strong>{props.infos.convert_bed}</strong></p>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                            <Col xs={12} className='text-center'>
                                <p>Message à l'intention de Class Appart :</p>
                                <p><strong>{props.infos.message ? props.infos.message : 'Aucun'}</strong></p>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setVisibility(false)}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}