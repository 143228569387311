import { useEffect, useState } from "react";
import { Container, Form, Col, Row, Button, FormCheck } from "react-bootstrap";
import instanceOfAxios from "../../app/axios/axios";
import Steppers from "../../components/Stepper/Stepper";
import './PersonalInformation.scss';
import { useDispatch } from "react-redux";
import { show } from "../../components/admin/Alerter/alerterSlice";
import Spinner from "../../components/spinner/Spinner";
import { MdOutlineBedroomParent, MdOutlineBedroomChild } from 'react-icons/md';
import { FaCouch } from 'react-icons/fa';
import CheckAnim from "../../components/checkAnim/checkAnim";

export default function PersonalInformation() {
    const paramsUrl = new URLSearchParams(window.location.search)
    const [activeStep, SetActiveStep] = useState(0);
    const [timer, SetTimer] = useState(20);
    const [errors, setErrors] = useState([]);
    const [cglAccepted, setCglAccepted] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [datas, setDatas] = useState({ begin_hour: 16, double_bed: 0, simple_bed: 0, convert_bed: 0, convert_sofa: 0, booking_id: paramsUrl.get('booking'), contact_id: paramsUrl.get('contact') });
    const [pending, setPending] = useState(true);
    const dispatch = useDispatch();
    const steps = ['Horaires et gestion de séjour', 'Informations Personnelles', 'Validation'];

    useEffect(() => {
        if(startTimer){
            const timeOut = setTimeout(() => {
                SetTimer(timer - 1);
              }, 1000);

              if(timer == 0){
                clearTimeout(timeOut);
                window.location.href = '/';
              }
        }
    }, [timer, startTimer]);

    useEffect(() => {
        validateToken();
        instanceOfAxios.post('verifyPersonalInformation/' + paramsUrl.get('token'), datas)
            .then(res => {
                setPending(false)
            })
            .catch(err => {
                window.location.href = '/';
            })
    }, []);

    function validateToken() {
        instanceOfAxios.get('validateTokenForInfo/' + paramsUrl.get('token'))
            .then(res => {
            })
            .catch(err => {
                window.location.href = '/';
            })
    }

    function testData() {
        setPending(true)
        let sendData = Object.assign({}, datas);
        sendData.step = activeStep;
        instanceOfAxios.post('verifyPersonalInformation/' + paramsUrl.get('token'), sendData)
            .then(res => {
                SetActiveStep(activeStep + 1);
                setPending(false);
                setErrors([]);
            })
            .catch(err => {
                setPending(false);
                setErrors(err.response.data.errors);
                dispatch(show({ message: 'Les informations renseignées ne sont pas bonnes', variant: 'danger' }));
            })
    }

    function createInfo() {
        setPending(true)
        instanceOfAxios.post('createPersonalInformation/' + paramsUrl.get('token'), datas)
            .then(res => {
                setPending(false);
                SetActiveStep(3);
                setStartTimer(true);
            })
            .catch(err => {
                setPending(false);
                setErrors(err.response.data.errors);
                dispatch(show({ message: 'Les informations renseignées ne sont pas bonnes', variant: 'danger' }))
            })
    }

    function handleChange(e) {
        let newDatas = Object.assign({}, datas);
        newDatas[e.target.name] = e.target.value;
        setDatas(newDatas);
    }

    function getOptionFieldHour(index) {
        let options = [];
        for (let i = 17; i <= 19; i++) {
            options.push(<option className='text-center' value={i} key={i + index}>{i}h</option>)
        }
        return options.map(option => option);
    }

    function getOptionFieldInf(index) {
        let options = [];
        for (let i = 0; i <= parseInt(paramsUrl.get(index)); i++) {
            options.push(<option className='text-center' value={i} key={i + index}>{i}</option>)
        }
        return options.map(option => option);
    }

    function handleCglChecked(boolean) {
        setCglAccepted(boolean);
        let newDatas = Object.assign({}, datas);
        newDatas['cgl_accepted'] = boolean;
        setDatas(newDatas);
    }

    function handlePrestaChecked(boolean) {
        let newDatas = Object.assign({}, datas);
        newDatas['presta_checked'] = boolean;
        setDatas(newDatas);
    }

    function returnStep() {
        switch (activeStep) {
            case 0:
                return (
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} className='text-center'>
                            <hr className="register-separator" />
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group id="begin_hour">
                                <Form.Label>Heure d'arrivée souhaitée </Form.Label>
                                <Form.Control className='text-center' defaultValue={datas.begin_hour || ''} name="begin_hour" as="select" onChange={(e) => handleChange(e)}>
                                    {getOptionFieldHour('begin_min')}
                                </Form.Control>
                                {errors['begin_hour'] ? <small style={{ color: "red" }}>{errors['begin_hour']}</small> : <></>}
                                <small>Cette demande peut être modifié par notre équipe</small>
                            </Form.Group>
                        </Col>
                        <Col xs={12} className='noPadding'>
                            <Row className='noPadding d-flex justify-content-center'>
                                {
                                    paramsUrl.get('double_bed') > 0 &&
                                    <Col xs={6} md={3}>
                                        <Form.Group className='text-center' id="double_bed">
                                            <Form.Label><MdOutlineBedroomParent /></Form.Label>
                                            <Form.Control className='text-center' defaultValue={datas.double_bed || ''} name="double_bed" as="select" onChange={(e) => handleChange(e)}>
                                                {getOptionFieldInf('double_bed')}
                                            </Form.Control>
                                            {errors['double_bed'] ? <><br /><small style={{ color: "red" }}>{errors['double_bed']}</small></> : <></>}
                                            <small>Lit double</small>
                                        </Form.Group>
                                    </Col>
                                }
                                {
                                    paramsUrl.get('simple_bed') > 0 &&
                                    <Col xs={6} md={3}>
                                        <Form.Group className='text-center' id="simple_bed">
                                            <Form.Label><MdOutlineBedroomChild /></Form.Label>
                                            <Form.Control className='text-center' defaultValue={datas.simple_bed || ''} name="simple_bed" as="select" onChange={(e) => handleChange(e)}>
                                                {getOptionFieldInf('simple_bed')}
                                            </Form.Control>
                                            {errors['simple_bed'] ? <><br /><small style={{ color: "red" }}>{errors['simple_bed']}</small></> : <></>}
                                            <small>Lit simple</small>
                                        </Form.Group>
                                    </Col>
                                }
                                {
                                    paramsUrl.get('convert_sofa') > 0 &&
                                    <Col xs={6} md={3}>
                                        <Form.Group className='text-center' id="convert_sofa">
                                            <Form.Label><FaCouch /></Form.Label>
                                            <Form.Control className='text-center' defaultValue={datas.convert_sofa || ''} name="convert_sofa" as="select" onChange={(e) => handleChange(e)}>
                                                {getOptionFieldInf('convert_sofa')}
                                            </Form.Control>
                                            {errors['convert_sofa'] ? <><br /><small style={{ color: "red" }}>{errors['convert_sofa']}</small></> : <></>}
                                            <small>Canapé convertible</small>
                                        </Form.Group>
                                    </Col>
                                }
                                {
                                    paramsUrl.get('convert_bed') > 0 &&
                                    <Col xs={6} md={3}>
                                        <Form.Group className='text-center' id="convert_bed">
                                            <Form.Label><MdOutlineBedroomChild /><MdOutlineBedroomChild /></Form.Label>
                                            <Form.Control className='text-center' defaultValue={datas.convert_bed || ''} name="convert_bed" as="select" onChange={(e) => handleChange(e)}>
                                                {getOptionFieldInf('convert_bed')}
                                            </Form.Control>
                                            {errors['convert_bed'] ? <><br /><small style={{ color: "red" }}>{errors['convert_bed']}</small></> : <></>}
                                            <small>Lit simple convertible double</small>
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Form.Group id="message">
                                <Form.Label>Message à l'intention de Class appart</Form.Label>
                                <Form.Control defaultValue={datas.message || ''} name="message" as="textarea" rows={5} onChange={(e) => handleChange(e)}>
                                </Form.Control>
                                {errors['message'] ? <><br/><small style={{ color: "red" }}>{errors['message']}</small></> : <></>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} className='text-center'>
                            <hr className="register-separator" />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-around'>
                            <Button onClick={() => SetActiveStep(0)} className='goldenHover'>Précédent</Button>

                            <Button onClick={testData} className='goldenHover'>Suivant</Button>
                        </Col>
                    </Row>
                )
            case 1:
                return (
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} className='text-center'>
                            <hr className="register-separator" />
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group id="phone">
                                <Form.Label>Téléphone de contact </Form.Label>
                                <Form.Control defaultValue={datas.phone || ''} name="phone" onChange={(e) => handleChange(e)}>
                                </Form.Control>
                                {errors['phone'] ? <><br/><small style={{ color: "red" }}>{errors['phone']}</small></> : <></>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form.Group id="email">
                                <Form.Label>E-mail de contact </Form.Label>
                                <Form.Control defaultValue={datas.email || ''} name="email" onChange={(e) => handleChange(e)}>
                                </Form.Control>
                                {errors['email'] ? <><br/><small style={{ color: "red" }}>{errors['email']}</small></> : <></>}
                            </Form.Group>
                        </Col>
                        <Col xs={12} className='text-center'>
                            <hr className="register-separator" />
                        </Col>
                        <Col xs={12} className='d-flex justify-content-around'>
                            <Button onClick={() => SetActiveStep(0)} className='goldenHover'>Précédent</Button>
                            <Button onClick={testData} className='goldenHover'>Suivant</Button>
                        </Col>
                    </Row>
                )
            case 2:
                return (
                    <Row>
                        <Col xs={12} className='text-center'>
                            <hr className="register-separator" />
                        </Col>
                        <Col xs={12} className='text-center mb-3'>
                            <p>Heure d'arrivée souhaitée :</p>
                            <p><strong>{datas.begin_hour}</strong>h</p>
                        </Col>
                        <Col xs={12} className='noPadding mb-3'>
                            <Row className='noPadding d-flex justify-content-center'>
                                {
                                    paramsUrl.get('double_bed') > 0 &&
                                    <Col xs={6} sm={3} className='text-center'>
                                        <p>Lit Double :</p>
                                        <p><strong>{datas.double_bed}</strong></p>
                                    </Col>
                                }
                                {
                                    paramsUrl.get('simple_bed') > 0 &&
                                    <Col xs={6} sm={3} className='text-center'>
                                        <p>Lit simple :</p>
                                        <p><strong>{datas.simple_bed}</strong></p>
                                    </Col>
                                }
                                {
                                    paramsUrl.get('convert_sofa') > 0 &&
                                    <Col xs={6} sm={3} className='text-center'>
                                        <p>Canapé convertible :</p>
                                        <p><strong>{datas.convert_sofa}</strong></p>
                                    </Col>
                                }
                                {
                                    paramsUrl.get('convert_bed') > 0 &&
                                    <Col xs={6} sm={3} className='text-center'>
                                        <p>Lit simple convertible :</p>
                                        <p><strong>{datas.convert_bed}</strong></p>
                                    </Col>
                                }
                            </Row>
                        </Col>
                        <Col xs={12} sm={6} className='text-center mb-3'>
                            <p>E-mail de contact :</p>
                            <p><strong>{datas.email}</strong></p>
                        </Col>
                        <Col xs={12} sm={6} className='text-center mb-3'>
                            <p>Téléphone de contact :</p>
                            <p><strong>{datas.phone ? datas.phone : 'Aucun'}</strong></p>
                        </Col>
                        <Col xs={12} className='text-center'>
                            <p>Message à l'intention de Class Appart :</p>
                            <p><strong>{datas.message ? datas.message : 'Aucun'}</strong></p>
                        </Col>
                        <Col xs={12} className='text-center'>
                            <hr className="register-separator" />
                        </Col>
                        <Col xs={12} className='text-center'>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check>
                                    <FormCheck.Input type="checkbox" onClick={(e) => handlePrestaChecked(e.target.checked)} checked={datas.presta_checked} />
                                    <FormCheck.Label>
                                        J'aimerais être contacter par Class Appart en ce qui concerne des prestations supplémentaires
                                    </FormCheck.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        <Col xs={12} className='text-center'>
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check>
                                    <FormCheck.Input type="checkbox" onClick={(e) => handleCglChecked(e.target.checked)} checked={cglAccepted} />
                                    <FormCheck.Label>
                                        En cochant cette case j'accepte <a href='/CGL' target='_blank'>les conditions générales de location</a> *
                                    </FormCheck.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <Button disabled={!cglAccepted} className={cglAccepted ? 'goldenHover' : ''} onClick={createInfo}>Je m'enregistre</Button>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center justify-content-sm-start mt-2'>
                            <Button onClick={() => SetActiveStep(1)} className='goldenHover'>Précédent</Button>
                        </Col>
                    </Row>
                )
            case 3 :
                return (
                    <Row>
                        <Col xs={12} className='text-center'>
                            <CheckAnim/>
                            Merci de vous être enregistré. Vous recevrez sous peu un e-mail récapitulatif des informations remplies.
                        </Col>
                        <Col xs={12} className='text-center mt-3'>
                            Vous allez être redirigé dans {timer} sec
                        </Col>
                    </Row>
                )
        }
    }

    return (
        <div className="enregistrement d-flex flex-column justify-content-center align-items-center mb-3 mt-3">
            <h2>Enregistrement</h2>
            <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
                <Steppers steps={steps} activeStep={activeStep} />
                {pending ?
                    <Row>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <p>Le temps d'attente peut excéder 1 min</p>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <Spinner/>
                        </Col>
                    </Row>
                    : returnStep()}
            </Container>
        </div>
    )
}