import { useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import instanceOfAxios from "../../../../../app/axios/axios";
import Spinner from "../../../../spinner/Spinner";
import { useDispatch } from "react-redux";
import { show } from "../../../Alerter/alerterSlice";

export default function AskProForma(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const dispatch = useDispatch();

    function handleDisableProForma() {
        setPending(true);
        instanceOfAxios.get(props.url)
            .then(res => {
                props.handleChange(res.data.success);
                setPending(false);
                setVisibility(false);
                dispatch(show({ message: 'Pro Format correctement désactivé', variant: 'success' }));
            })
            .catch(err => {
                dispatch(show({ message: 'Une erreur est survenue veuillez rééssayer l\'action utlitérieurement ou contacter un administrateur', variant: 'danger' }));
            })
    }
    return (
            <Col className='d-flex justify-content-center align-items-center reducePadding mb-3' xs={12} lg={6}>
                <Button variant='secondary' onClick={() => setVisibility(!visibility)}>
                    Désactivation du Pro format
                </Button>
                <Modal
                    scrollable
                    centered
                    show={visibility}
                    onHide={() => setVisibility(!visibility)}
                    backdropClassName="backdropFourth"
                    style={{ zIndex: '20004' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Désactivation du Pro format
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-center'>
                        Vous voulez-vous désactiver le pro format pour cette ressource ?
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-around'>
                        {
                            pending ?
                                <Spinner />
                                :
                                <>
                                    <Button variant='danger' onClick={() => setVisibility(!visibility)}>
                                        Annuler
                                    </Button>
                                    <Button variant='success' onClick={handleDisableProForma}>
                                        Valider
                                    </Button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>
            </Col>
    )
}