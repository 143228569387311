import { useState } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import GenericModifyPdf from './modifyPdf/genericModifyPdf';

export default function CheckInInventory(props) {
    const [visibility, setVisibility] = useState(false);

    return (
        <>
            <Button variant='secondary' onClick={() => setVisibility(true)}>Inventaire</Button>
            {
                visibility &&
                <Modal
                    dialogClassName='custom-dialog'
                    show={visibility}
                    onHide={() => setVisibility(false)}
                    backdropClassName="backdropFourth"
                    scrollable
                    size='xl'
                    backdrop="static"
                    style={{ zIndex: '20003', minHeight: '90vh' }}
                    centered>
                    <Modal.Header className='d-flex align-items-center' closeButton>
                        <Row style={{ width: '100%' }}>
                            <Col xs={12}>
                                <Modal.Title>
                                    Inventaire
                                </Modal.Title>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '70vh' }}>
                        <Row>
                            <Col xs={12} className='d-flex justify-content-center align-items-center'>
                                <p style={{ color: 'red' }} className='text-center'>Attention toute modification affectera le fichier global du logement</p>
                            </Col>
                            <Col xs={12} className='d-flex flex-column justify-content-center'>
                                <GenericModifyPdf 
                                file={props.infos.apartment.inventory_url} 
                                updateUrl={'/admin/apartment/' + props.infos.apartment.id + '/inventory'}
                                type='inventory'/>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}