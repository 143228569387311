import React, { useState } from 'react';
import { Row, Col, Badge, Card, Button } from 'react-bootstrap';
import { AiOutlineSend } from 'react-icons/ai';
import { FcHighPriority } from 'react-icons/fc';
import { useDispatch, useStore } from 'react-redux';
import instanceOfAxios from '../../../../../app/axios/axios';
import CreateOrEditPayment from './formulaire/createOrEditPayment';
import { show } from '../../../Alerter/alerterSlice';
import DeleteModal from '../../../../generiqueModal/deleteModal/deleteModal';
import Recall from './recall/recall';

export default function PaymentCard(props) {

    const [payment, setPayment] = useState(props.payment);
    const role = useStore().getState().header.role;
    const dispatch = useDispatch();
    const stateColor = { ['à payer']: 'danger', payé: 'success' };
    let payedAt = 'Non payé';

    if (payment.payed_at) {
        payedAt = new Date(payment.payed_at.replace(/\s/, 'T'));
        payedAt = payedAt.toLocaleDateString();
    }

    function handleChange(payment) {
        setPayment(payment);
    }

    function deletePayment() {
        instanceOfAxios.delete(`/${props.type}/${props.payable.id}/payment/${payment.id}`)
            .then((res) => {
                instanceOfAxios.get(`/apartment/${props.payable.apartment_id || props.booking.apartment_id}/booking/${props.booking.id}`)
                    .then(res => {
                        props.BookingDetailsOnChange(res.data.booking);
                        dispatch(show({ message: 'Paiement supprimé', variant: 'success' }));
                    })
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));

            })
    }

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function validateSwik() {
        if (getDifferenceInDays(new Date(payment.payed_at.replace(/\s/, 'T')), new Date()) > 180) {
            return <p style={{ color: 'red' }}><FcHighPriority />Le paiement Swik n'est plus valable</p>
        } else if (getDifferenceInDays(new Date(payment.payed_at.replace(/\s/, 'T')), new Date()) > 150) {
            return <p style={{ color: 'red' }}><FcHighPriority />Le paiement swik arrive à terme</p>
        }
    }

    return (
        <div className='cartridge mt-2'>
            {
                payment.payment_mode == 'Swikly' && payment.payed_at &&
                <Row noGutters className='text-center d-flex justify-content-center'>
                    {validateSwik()}
                </Row>
            }
            <Row noGutters className='text-center d-flex justify-content-center'>
                {props.payable.state !== 'annulé' &&
                    <Col md={12} lg={2} className="d-flex justify-content-center align-items-center">
                        <Badge variant={stateColor[payment.state]}>{payment.state}</Badge>
                    </Col>}
                <Col md={12} lg={3} style={{ paddingLeft: '1vw' }} className="d-flex flex-column justify-content-center">
                    <p className="apartmentText">Montant:</p>
                    <strong>{parseFloat(payment.amount).toFixed(2)} €</strong>
                </Col>
                <Col md={12} lg={3} style={{ paddingLeft: '1vw' }} className="d-flex flex-column justify-content-center">
                    <p className="apartmentText">Payé le:</p>
                    <p className="apartmentText">{payedAt}</p>
                </Col>
                <Col md={12} lg={4} style={{ paddingLeft: '1vw' }} className="d-flex flex-column justify-content-center">
                    <p className="apartmentText">Mode de paiement:</p>
                    <p className="apartmentText">{payment.payment_mode || 'Aucun'}</p>
                </Col>
            </Row>
            <Row noGutters className="d-flex justify-content-md-end justify-content-center align-items-center">
                <Col xs='auto' className='p-2'>
                    {props.payable.state !== 'annulé' && payment.state === 'à payer' && role === 'super-admin' || role === 'admin' || role === 'trainee' ?
                        <Recall forBail={props.payable.amount ? true : false} url={'/' + props.type + '/' + props.payable.id + '/payment/' + payment.id + '/sendRecall'} />
                        :
                        <></>}
                </Col>
                {props.payable.state !== 'annulé' && role === 'super-admin' || role === 'admin' ?
                    <Col xs='auto' className='p-2'>
                        <CreateOrEditPayment type={props.type} payable={props.payable} for={'update'} booking={props.booking} payment={payment} setPayment={handleChange} BookingDetailsOnChange={props.BookingDetailsOnChange} />
                    </Col>
                    :
                    <></>}
                {props.payable.state !== 'annulé' && role === 'super-admin' || role === 'admin' || role === 'trainee' ?
                    <Col xs='auto' className='p-2'>
                        <DeleteModal size='sm' deleteFunction={() => deletePayment()} model={payment} role={role} />
                    </Col>
                    :
                    <></>}
            </Row>
        </div>
    )
}