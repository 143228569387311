import { useState } from "react"
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../../app/axios/axios";
import Spinner from "../../../../spinner/Spinner";
import { show } from "../../../Alerter/alerterSlice";
import { FaRegSave } from 'react-icons/fa';

export default function UpdateComment(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const [datasContent, setDataContent] = useState(JSON.parse(props.comment.content));
    const dispatch = useDispatch();

    function handleChange(e) {
        let newDatas = Object.assign({}, datasContent);
        newDatas[e.target.getAttribute('name')] = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
        setDataContent(newDatas);
    }

    function handleSubmit() {
        setPending(true);
        instanceOfAxios.post('/apartment/' + props.comment.apartment_data.id + '/comment/' + props.comment.id, { _method: 'PATCH', content: JSON.stringify(datasContent) })
            .then(res => {
                setPending(false);
                dispatch(show({ message: 'Les modifications on été enregistrées', variant: 'success' }));
                props.setReload(true);
            })
            .catch(err => {
                setPending(false);
                dispatch(show({ message: 'Erreur lors de la sauvegarde', variant: 'danger' }));
            })
    }

    return (
        <>
            <Button variant='secondary' onClick={() => setVisibility(!visibility)}>Attribuer des personnes</Button>
            <Modal
                scrollable
                centered
                backdropClassName="backdropThird"
                style={{ zIndex: '20001' }}
                show={visibility}
                size='lg'
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Attribué un rôle à des personnes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Qui à fait le check-in pour cette réservation ?
                            </Form.Label>
                            <Form.Control type="text" name="check_in" defaultValue={datasContent.check_in} onChange={(e) => handleChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Qui à fait le ménage pour cette réservation ?
                            </Form.Label>
                            <Form.Control type="text" name="cleaning" defaultValue={datasContent.cleaning} onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button className='whiteSVG' variant='secondary' onClick={() => handleSubmit()}><FaRegSave/> Enregistrer</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}