import { useState } from "react"
import '../Magazine.scss';
import './categories.scss';

export default function Categories(props) {
    const [collapse, setCollapse] = useState(false);

    return (
        <>
            <ul>
                {
                    props.category.slice(0, (collapse ? props.category.length : 3)).map(article =>
                        <li>
                            <a href={'/Magazine/article/' + article.id} className='golden'>
                                {article.name}
                            </a>
                        </li>)
                }
            </ul>
            {
                props.category.length > 3
                    &&
                <p className='more-infos' onClick={() => setCollapse(!collapse)}>Voir {collapse ? 'moins' : 'plus'}</p>
            }
        </>
    )
}