import { useState } from "react";
import { Button, Card, Form, Row, Col, Modal } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../../../app/axios/axios";
import Spinner from "../../../../../spinner/Spinner";
import { show } from "../../../../Alerter/alerterSlice";
import { FaRegSave } from 'react-icons/fa';

export default function AddDoc(props) {
    const [visibility, setVisibility] = useState(false);
    const [forms, setForms] = useState([]);
    const [formData, setFormData] = useState(new FormData());
    const [submit, setSubmit] = useState(false);
    const dispatch = useDispatch();

    function addInput(){
        let formsCopy = [...forms];
        formsCopy.push({ title: '' });
        setForms(formsCopy);
    }

    function handleChange(index, event){
        let formsCopy = [...forms];
        formsCopy[index].title = event.target.value;
        setForms(formsCopy);
    }

    function handleFileChange(index, name, event){
        formData.append(name, event.target.files[0]);
        let formsCopy = [...forms];
        formsCopy[index].fileName = event.target.files[0].name;
        setForms(formsCopy);
        setFormData(formData);
    }

    function removeInput(index, title){
        let formsCopy = [...forms];
        formsCopy.splice(index, 1);
        if(formData.has(title)){
            formData.delete(title);
            setFormData(formData);
        }
        setForms(formsCopy);
    }

    function handleSubmit(){
        setSubmit(true);
        instanceOfAxios.post(`/admin/booking/${props.booking.id}/files`, formData)
        .then( res => {
            dispatch(show({variant: 'success', message: 'Documents ajoutés'}));
            setSubmit(false);
            setFormData(new FormData());
            setForms([]);
            props.reload(true);
            setVisibility(false);
        })
        .catch( err => {
            dispatch(show({variant: 'danger', message: 'Erreur lors de l\'ajout'}));
            setSubmit(false);
        })
    }

    return (
        <>
            <Button className="addOptionButton whiteSVG" variant="secondary" style={{ marginTop: "1vh", marginRight: "0vw", marginLeft: "0vw", marginBottom: "1vh" }} size="sm" onClick={() => setVisibility(true)}> <MdAdd /> </Button>
            {
                visibility &&
                <Modal
                    show={visibility}
                    showMask={false}
                    onHide={() => setVisibility(false)}
                    centered
                    backdropClassName="backdropFourth"
                    style={{ zIndex: '20003' }}>
                    <Modal.Header closeButton>
                        Ajout de document
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {/* à voir si sa le supprime vraiment ou pas petit doute car je use id du media sur le back */}
                            <Col xs={12} className='noPadding mb-3 text-center' >
                                <small>Attention si un document existant à le même nom qu'un des nouveaux document il sera automatiquement supprimé</small>
                            </Col>
                            <Col xs={12} className='noPadding mb-3 d-flex justify-content-center' >
                                <Button className='goldenHover' onClick={() => addInput()}> Ajouter une ligne </Button>
                            </Col>
                            {
                                forms.map((form, index) =>
                                    <>
                                        <Col xs={12} className='reducePadding'>
                                            <Card className='p-3 mb-2 shadowForCard'>
                                                <Form className='d-flex flex-column'>
                                                    <Form.Label>Nom du document</Form.Label>
                                                    <Form.Control size="sm" as="select" onChange={(event) => handleChange(index, event)}>
                                                            <option value=''>Selectionner un nom</option>
                                                            <option>Identité</option>
                                                            <option>Facture</option>
                                                            <option>Contrat</option>
                                                            <option>Rib</option>
                                                            <option>Kbis</option>
                                                    </Form.Control>
                                                    <Form.Group as={Col} xs={12} className='noPadding mt-1'>
                                                        <Form.Control key={index + '.1'} size="sm" defaultValue={form.title} onChange={(event) => handleChange(index, event)}/>
                                                    </Form.Group>
                                                    <Form.Group as={Col} xs={12} className='noPadding'>
                                                        <Form.File
                                                            label={form.fileName || 'Format PDF'}
                                                            lang="fr"
                                                            custom
                                                            accept='document/pdf'
                                                            data-browse="Fichier"
                                                            name={form.title}
                                                            disabled={!form.title ? true : false}
                                                            onChange={(event) => handleFileChange(index, form.title, event)}
                                                        />
                                                    </Form.Group>
                                                    <Button className='goldenHover' onClick={() => removeInput(index, form.title)}>
                                                        Supprimer
                                                    </Button>
                                                </Form>
                                            </Card>
                                        </Col>
                                    </>)
                            }
                            {
                               forms.length > 0 && 
                                <Col xs={12} className='noPadding mb-3 mt-3 d-flex justify-content-center' >
                                    {
                                        submit ?
                                            <Spinner/>
                                        :
                                            <Button className='whiteSVG' variant='secondary' onClick={() => handleSubmit()}><FaRegSave/> Enregistrer</Button>
                                    }
                                </Col>
                            }
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}