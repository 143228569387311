import { Col, Card, Row, Image, Button } from 'react-bootstrap';
import { BsPersonFill } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineBedroomParent, MdOpenInNew } from 'react-icons/md';
import instanceOfAxios from '../../../app/axios/axios';
import { show } from "../../../components/admin/Alerter/alerterSlice";
import { useDispatch } from "react-redux";
import './favoriCard.scss';

export default function FavoriCard(props) {
    const dispatch = useDispatch();

    function nbRoom() {
        let nbRoom = JSON.parse(props.apartment.rooms_details).chambre
        if (nbRoom == 0) {
            return 'Studio';
        } else {
            return nbRoom > 1 ? nbRoom + '\nChambres' : nbRoom + '\nChambre';
        }
    }

    function removeFavori() {
        props.setLoad(true);
        instanceOfAxios.get('removeFavori/' + props.apartment.id)
            .then(res => {
                props.setRerender(true);
                dispatch(show({ message: res.data.message, variant: 'success' }));
            })
            .catch(err => {
                dispatch(show({ message: err.response.data.message, variant: 'danger' }));
            })
    }

    return (
        <Card style={{ marginBottom: '10px' }}>
                <div className='closeButton'>
                    <AiOutlineCloseCircle onClick={removeFavori} />
                </div>
            <Row>
                <Col md={12} xl={7} className="d-flex align-items-center justify-content-center">
                    <a target='_blank' href={'/apartment/' + props.apartment.id}>
                        <Image loading='lazy' src={props.apartment.principal_picture_url ? props.apartment.principal_picture_url.medium : ''} className="apartment-list-image" fluid />
                    </a>
                </Col>
                <Col md={12} xl={5} style={{ marginBottom: '1vh', paddingLeft: 'unset', paddingRight: 'unset' }} className="d-flex align-items-center">
                    <Row className="w-100">
                        <Col xs={12}>
                            <h2>Réf : <strong>{props.apartment.reference}</strong></h2>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Col xs={12} className="d-flex justify-content-center" >
                                <BsPersonFill style={{ height: '25px', width: '25px' }} />
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                <p className='text-center'>
                                    {props.apartment.nb_person} {props.apartment.nb_person > 1 ? 'Personnes' : 'Personne'}
                                </p>
                            </Col>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Col xs={12} className="d-flex justify-content-center">
                                <MdOutlineBedroomParent style={{ height: '25px', width: '25px' }} />
                            </Col>
                            <Col xs={12} className="d-flex justify-content-center" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                <p className='text-center'>
                                    {nbRoom()}
                                </p>
                            </Col>
                        </Col>
                        <Col xs={12} sm={12} className="d-flex justify-content-center">
                            <a target='_blank' href={'/apartment/' + props.apartment.id}><Button className='goldenHover'>Découvrir <MdOpenInNew /></Button></a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}