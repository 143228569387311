import { Form, Col, Row, Button } from "react-bootstrap";
import Rating from "../rating/rating";

export default function CheckInStep(props) {
    return (
        <>
            <Row className='d-flex flex-column align-items-center justify-content-center'>
                <h2>Ménage et informations</h2>
                <Col className='text-center'>
                    <dl>
                        <dt>Informations utiles :</dt>
                        <dd>Si vous ne changez pas les scores ils prendront la note maximal par default</dd>
                        <dd>Les champs marqués d'une * sont obligatoire</dd>
                    </dl>
                </Col>
                <Col xs={12} sm={7} className='d-flex flex-column align-items-center justify-content-center'>
                    <Form.Row as={Col}>
                        <Form.Group as={Col}>
                            <Form.Label>Nom *</Form.Label><br/>
                            {props.errors && props.errors['last_name'] && <small style={{color: 'red'}}>{props.errors['last_name']}</small>}
                            <Form.Control type="text" name="last_name" defaultValue={props.defaultValue.last_name} onChange={(e) => props.handleChange(e)} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Prénom *</Form.Label><br/>
                            {props.errors && props.errors['first_name'] && <small style={{color: 'red'}}>{props.errors['first_name']}</small>}
                            <Form.Control type="text" name="first_name" defaultValue={props.defaultValue.first_name} onChange={(e) => props.handleChange(e)} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>1/ Quelle note attribuez-vous à la personne qui vous a accueilli au logement ?</Form.Label>
                        <Rating defaultValue={props.defaultValue.content.check_in_score.score} handleChange={props.handleJsonChange} inputName='check_in_score' inputId='score' range={10} size={30} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Remarque</Form.Label>
                        <Form.Control defaultValue={props.defaultValue.content.check_in_score.check_in_message} as="textarea" name="check_in_score" id='check_in_message' rows={2} onChange={(e) => props.handleJsonChange(e)} />
                    </Form.Group>
                </Col>
                <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '60%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
            </Row>
            <Row className='d-flex justify-content-end'>
                <Col xs={6} className='d-flex justify-content-end'>
                    <Button className='goldenHover' onClick={() => props.SetActiveStep(1)}>Suivant</Button>
                </Col>
            </Row>
        </>
    )
}