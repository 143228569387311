import { Row, Col, Modal } from 'react-bootstrap'
import '../comments.scss';
import { useState } from "react";
import { useEffect } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';

export default function CommentsModal(props) {
    const [visibility, setVisibility] = useState(false);
    const [totals, setTotals] = useState({ total_equip: 0, total_cleaning: 0, total_check_in: 0, total_state: 0 });

    useEffect(() => {
        let totals = { total_equip: 0, total_cleaning: 0, total_check_in: 0, total_state: 0 }
        props.comments.map(comment => {
            totals.total_equip += comment.total_equip / 6;
            totals.total_state += comment.total_state / 7;
            totals.total_check_in += comment.total_check_in / 2;
            totals.total_cleaning += comment.total_cleaning / 7;
        });
        setTotals(totals);
    }, []);

    return (
        <Col xs={12} className='noPadding'>
            {
                props.comments.length > 0 &&
                <>
                    <p className='more-informations mt-2' onClick={() => setVisibility(!visibility)}>
                        <strong>En savoir plus ></strong>
                    </p>
                    <Modal
                        backdropClassName="backdropThird"
                        style={{ zIndex: '20001' }}
                        scrollable
                        centered
                        show={visibility}
                        onHide={() => setVisibility(!visibility)}
                        size='xl'>
                        <Modal.Body className='d-flex flex-column'>
                            <div className='close-button-comments mr-3' onClick={() => setVisibility(!visibility)}>
                                <IoMdClose size={'20px'} color='black'/>
                            </div>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <div className='d-flex w-100 mt-5'>
                                        <AiFillStar size={'30px'} color='black'/>
                                        <h3>{props.apartment.note} · {props.comments.length} {props.comments.length > 1 ? 'commentaires' : 'commentaire'}</h3>
                                    </div>
                                    <div className='d-flex w-100 mb-2 align-items-center justify-content-between'>
                                        Proprété
                                        <div className='d-flex align-items-center'>
                                            <div className='ml-2 mr-2' style={{ borderRadius: '2px', width: '60px', height: '5px', background: 'linear-gradient(90deg, black ' + ((totals.total_cleaning / props.comments.length) * 20).toFixed(1) + '%, #cfcfcf ' + (100 - (totals.total_cleaning / props.comments.length) * 20).toFixed(1) + '%)' }}></div>
                                            <strong style={{width: '30px', textAlign: 'end'}}>{(totals.total_cleaning / props.comments.length).toFixed(1)}</strong>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 mb-2 align-items-center justify-content-between'>
                                        Arrivé
                                        <div className='d-flex align-items-center'>
                                            <div className='ml-2 mr-2' style={{ borderRadius: '2px', width: '60px', height: '5px', background: 'linear-gradient(90deg, black ' + ((totals.total_check_in / props.comments.length) * 20).toFixed(1) + '%, #cfcfcf ' + (100 - (totals.total_check_in / props.comments.length) * 20).toFixed(1) + '%)' }}></div>
                                            <strong style={{width: '30px', textAlign: 'end'}}>{(totals.total_check_in / props.comments.length).toFixed(1)}</strong>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 mb-2 align-items-center justify-content-between'>
                                        Equipements
                                        <div className='d-flex align-items-center'>
                                            <div className='ml-2 mr-2' style={{ borderRadius: '2px', width: '60px', height: '5px', background: 'linear-gradient(90deg, black ' + ((totals.total_equip / props.comments.length) * 20).toFixed(1) + '%, #cfcfcf ' + (100 - (totals.total_equip / props.comments.length) * 20).toFixed(1) + '%)' }}></div>
                                            <strong style={{width: '30px', textAlign: 'end'}}>{(totals.total_equip / props.comments.length).toFixed(1)}</strong>
                                        </div>
                                    </div>
                                    <div className='d-flex w-100 mb-2 align-items-center justify-content-between'>
                                        Etat générale
                                        <div className='d-flex align-items-center'>
                                            <div className='ml-2 mr-2' style={{ borderRadius: '2px', width: '60px', height: '5px', background: 'linear-gradient(90deg, black ' + ((totals.total_state / props.comments.length) * 20).toFixed(1) + '%, #cfcfcf ' + (100 - (totals.total_state / props.comments.length) * 20).toFixed(1) + '%)' }}></div>
                                            <strong style={{width: '30px', textAlign: 'end'}}>{(totals.total_state / props.comments.length).toFixed(1)}</strong>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={8} className='list-comments'>
                                    {
                                        props.comments.map(comment => {

                                            let createdAt = new Date(comment.created_at.replace(/\s/, 'T'));
                                            var options = { year: 'numeric', month: 'long' };

                                            return (
                                                <>
                                                    <Col xs={12} className='noPadding'>
                                                        <div className='card-list'>
                                                            <span>
                                                                <h4>{comment.first_name} <small>{(((comment.total_equip / 6) + (comment.total_state / 7) + (comment.total_cleaning / 7) + (comment.total_check_in / 2)) / 4).toFixed(1)}</small></h4>  
                                                            </span>
                                                            <p>{comment.content.global}</p>
                                                            <small>{createdAt.toLocaleDateString('fr-CA', options)}</small>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                                                        <div className='separator'
                                                            style={{
                                                                border: '1px solid #B7A57E',
                                                                color: '#b7a57e',
                                                                width: '90%'
                                                            }}></div>
                                                    </Col>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </>
            }
        </Col>
    )
}