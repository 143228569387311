import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Container, Pagination } from 'react-bootstrap';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import { show } from '../../Alerter/alerterSlice';
import { setStep } from './apartmentFormSlice';
import instanceOfAxios from '../../../../app/axios/axios';
import { connect, useDispatch } from 'react-redux';
import { getApartmentForUpdate } from '../apartmentSlice'
import Spinner from '../../../spinner/Spinner';
import ViewerYear from './viewerYear/viewerYear';
import DateRange from '../../../searchBar/dateRange/dateRange'
import { getBeginDate, getEndDate } from '../../../searchBar/dateRange/dateRangeSlice';

function StepSix(props) {
    let currentYear = new Date();
    const [visitsParam, setVisitsParam] = useState({});
    const [calendarParam, setCalendarParam] = useState({});
    const [calendar, setCalendar] = useState(props.apartment.calendar);
    const [visits, setVisits] = useState(props.apartment.visits);
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorsCalendar, setErrorsCalendar] = useState([]);
    const [errorsVisits, setErrorsVisits] = useState([]);
    const [dateUse, setDateUse] = useState([]);
    const [pending, setPending] = useState(true);
    const [year, setYear] = useState(currentYear.getFullYear());

    let items = [
        <Pagination.Item key={year} id={year} active='true'>
            {year}
        </Pagination.Item>
    ];

    function prev() {
        setYear(year - 1);
    }

    function next() {
        setYear(year + 1);
    }

    useEffect(() => {
        handleChangeDate();
    }, [year]);

    useEffect(() => {
        let data = Object.assign({}, calendarParam);
        let date = new Date(props.begin_date);
        data['begin_date_hour'] = date.toUTCString();
        setCalendarParam(data);
    }, [props.begin_date])

    useEffect(() => {
        let data = Object.assign({}, calendarParam);
        let date = new Date(props.end_date);
        data['end_date_hour'] = date.toUTCString();
        setCalendarParam(data);
    }, [props.end_date])

    function handleChangeDate() {
        instanceOfAxios.get('/admin/apartments/' + props.apartment.id + '/dateBlocked/' + year)
            .then((res) => {
                setDateUse(res.data.apartment);
                setPending(false);
            })
    }

    const dispatch = useDispatch();

    function handleCalendarChange(event) {
        let data = Object.assign({}, calendarParam);
        data[event.target.name] = event.target.value;
        setCalendarParam(data);
    }

    function handleVisitsChange(event) {
        handleChange(visitsParam, event, 'visits');
    }

    function handleChange(params, event, type) {
        let param = Object.assign({}, params)
        param[event.target.name] = event.target.value;
        switch (type) {
            case 'calendar':
                setCalendarParam(param);
                break;
            case 'visits':
                setVisitsParam(param);
                break;
        }
    }

    function handleCalendarSubmit() {
        setIsSubmit(true);
        setErrorsCalendar([]);
        let url = '/apartment/' + props.apartment.id + '/calendar';

        if (calendar) {
            url = '/apartment/' + props.apartment.id + '/calendar/' + calendar.id;
        }

        let calendarData = setFormData(calendarParam, calendar);

        let send = 0;
        for (var pair of calendarData.entries()) {
            send++;
        }
        if (send > 1) {
            instanceOfAxios.post(url, calendarData)
                .then((res) => {
                    instanceOfAxios.get('/admin/apartments/' + props.apartment.id + '/dateBlocked/' + year)
                        .then((res) => {
                            setDateUse(res.data.apartment);
                            let stateApartment = Object.assign({}, props.apartment);
                            stateApartment.calendars = res.data.apartment[1]
                            dispatch(setUpdater(stateApartment));
                            setIsSubmit(false);
                            setErrorsVisits([]);
                            dispatch(show({ message: 'Action réussi', variant: 'success' }));
                            dispatch(setReload(true));
                            setPending(false);
                        })
                })
                .catch((error) => {
                    handleError(error, 'calendar');
                })
        } else {
            setIsSubmit(false);
        }
    }

    function handleVisitsSubmit() {
        setIsSubmit(true);
        let url = '/apartment/' + props.apartment.id + '/visits';
        if (visits) {
            url = '/apartment/' + props.apartment.id + '/visits/' + visits.id;
        }

        let visitsData = setFormData(visitsParam, visits);

        let send = 0;
        for (var pair of visitsData.entries()) {
            send++;
        }
        if (send > 1) {
            instanceOfAxios.post(url, visitsData)
                .then((res) => {
                    let stateApartment = Object.assign({}, props.apartment);
                    stateApartment.visits = res.data.Visits;
                    dispatch(setUpdater(stateApartment));
                    setVisits(res.data.Visits);
                    setIsSubmit(false);
                    setErrorsVisits([]);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    dispatch(setReload(true));
                    dispatch(setStep(7));
                })
                .catch((error) => {
                    handleError(error, 'visits');
                })
        } else {
            setIsSubmit(false);
            dispatch(setStep(7));
        }
    }

    function setFormData(params, object) {
        let formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        });
        if (object && object.id) {
            formData.set('_method', 'PATCH');
        }
        return formData;
    }

    function handleError(errors, type) {
        let error = Object.assign([], errors);
        let message = 'Erreur lors de l\'action'
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        switch (type) {
            case 'calendar':
                setErrorsCalendar(error);
                break;
            case 'visits':
                setErrorsVisits(error);
                break;
        }

        if (error.response.data.error == 'non disponible') {
            message = 'Appartement déjà bloqué';
        }
        setIsSubmit(false);
        dispatch(show({ message: message, variant: 'danger' }));
    }

    return (
        <Container className='pb-3'>
            <h4>Paramètre d'accés</h4>
            <Form.Group>
                {errorsVisits['location_door'] && <label htmlFor="location_door"><small style={{ color: "red" }}>{errorsVisits['location_door']}</small></label>}
                <Form.Label>Localisation de la porte d'entrée</Form.Label>
                <Form.Control as='textarea' row={2} id={'location_door'} name='location_door' size="sm" placeholder="au premier étage" onChange={(e) => handleVisitsChange(e)} defaultValue={visits ? visits.location_door : ''} />
            </Form.Group>
            <Form.Row>
                <Form.Group as={Col}>
                    {errorsVisits['arrival_hour'] && <label htmlFor="arrival_hour"><small style={{ color: "red" }}>{errorsVisits['arrival_hour']}</small></label>}
                    <Form.Label>Heure d'arrivée</Form.Label>
                    <Form.Control id='arrival_hour' name='arrival_hour' size="sm" placeholder="18" onChange={(e) => handleVisitsChange(e)} defaultValue={visits ? visits.arrival_hour : ''} />
                </Form.Group>
                <Form.Group as={Col}>
                    {errorsVisits['depart_hour'] && <label htmlFor="depart_hour"><small style={{ color: "red" }}>{errorsVisits['depart_hour']}</small></label>}
                    <Form.Label>Heure de départ</Form.Label>
                    <Form.Control id='depart_hour' name='depart_hour' size="sm" placeholder="11" onChange={(e) => handleVisitsChange(e)} defaultValue={visits ? visits.depart_hour : ''} />
                </Form.Group>
            </Form.Row>
            <Row className="justify-content-center justify-content-lg-end mb-3">
                {isSubmit ? <Spinner /> : <Button variant="secondary" onClick={() => handleVisitsSubmit()}>Suivant</Button>}
            </Row>
            <Row noGutters >
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
                <Col sm={12} lg={12}>
                    <h4>Bloquer des dates</h4>
                </Col>
                <Form.Group as={Col} className='noPadding'>
                    {errorsCalendar['reason'] && <label htmlFor="reason"><small style={{ color: "red" }}>{errorsCalendar['reason']}</small></label>}
                    <Form.Group as={Col} className='noPadding'>
                        <Form.Control size="sm" name="reason" as="select" onChange={(e) => handleCalendarChange(e)}>
                            <Form.Label>Raison</Form.Label>
                            <option>Selectionner une raison</option>
                            <option>Blocage ClassAppart</option>
                            <option>Blocage propriétaire</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className='noPadding'>
                        <Form.Label>intitulé</Form.Label>
                        <br />
                        {errorsCalendar['endtitle'] && <label htmlFor="endtitle"><small style={{ color: "red" }}>{errorsCalendar['endtitle']}</small></label>}
                        <Form.Control id='endtitle' name='endtitle' size="sm" placeholder="Vacances" onChange={(e) => handleCalendarChange(e)} />
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} lg={6} md={12}>
                            <Form.Label>Heure d'arrivée</Form.Label>
                            <br />
                            {errorsCalendar['begin_hour'] && <label htmlFor="begin_hour"><small style={{ color: "red" }}>{errorsCalendar['begin_hour']}</small></label>}
                            <Form.Control size="sm" name="begin_hour" as="select" onChange={(e) => handleCalendarChange(e)}>
                                <option>Selectionner une heure</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                                <option>19</option>
                                <option>20</option>
                                <option>21</option>
                                <option>22</option>
                                <option>23</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} lg={6} md={12}>
                            <Form.Label>Heure de départ</Form.Label>
                            <br />
                            {errorsCalendar['end_hour'] && <label htmlFor="end_hour"><small style={{ color: "red" }}>{errorsCalendar['end_hour']}</small></label>}
                            <Form.Control size="sm" name="end_hour" as="select" onChange={(e) => handleCalendarChange(e)}>
                                <option>Selectionner une heure</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                </Form.Group>
                <Col sm={12} lg={12}>
                    {errorsCalendar['begin_date_hour'] && <label htmlFor="begin_date_hour"><small style={{ color: "red" }}>{errorsCalendar['begin_date_hour']}</small></label>}
                    <DateRange adminUsage usage='admin' id='dateRangeBooking' minLength={1} apartment={props.apartment.id}/>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    {isSubmit ? 
                        <Spinner /> 
                    : 
                        <Button variant="secondary" onClick={() => handleCalendarSubmit()}>Bloquer les dates</Button>}
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
            </Row>
            <Row noGutters>
                <Col xs={12} className='d-flex justify-content-center'>
                    <Pagination variant='secondary' style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                        <Pagination.Prev id={'prev'} onClick={() => prev()} />
                        {items}
                        <Pagination.Next id={'next'} onClick={() => next()} />
                    </Pagination>
                </Col>
                <Col xs={12} className='d-flex justify-content-center'>
                    {
                        dateUse.length > 1 ?
                            <ViewerYear dateUse={dateUse} handleChange={handleChangeDate} year={year} />
                            :
                            <Spinner />
                    }
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state),
        begin_date: getBeginDate(state),
        end_date: getEndDate(state)
    };
}

export default connect(mapStateToProps)(StepSix);
