import './OurTeam.scss';
import { Row, Col } from 'react-bootstrap';

export default function OurTeam() {
    return (
        <Row className='d-flex justify-content-center'>
            <Col xs={12}>
                <h2 style={{textDecoration: 'underline 1px'}}>Notre équipe</h2>
            </Col>
            <div className="person">
                <div className="content-team">
                    <div className="content-team-inner">
                        <div className="circle"></div>
                        <img className="img berengere" src='/images/team/berengere.webp' loading="lazy"/>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="name">Bérengère</div>
                <div className="title">Gérante</div>
            </div>
            <div className="person">
                <div className="content-team">
                    <div className="content-team-inner">
                        <div className="circle"></div>
                        <img className="img img1" src='/images/team/nicola.webp' loading="lazy"/>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="name">Nicolas</div>
                <div className="title">Chargé de clientèle</div>
            </div>
            <div className="person">
                <div className="content-team">
                    <div className="content-team-inner">
                        <div className="circle"></div>
                        <img className="img img1" src='/images/team/lilou.webp' loading="lazy"/>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="name">Lilou</div>
                <div className="title">Assistante Chargée de clientèle</div>
            </div>
            <div className="person">
                <div className="content-team">
                    <div className="content-team-inner">
                        <div className="circle"></div>
                        <img className="img img1" src='/images/team/lilou-rose.webp' loading="lazy"/>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="name">Lilou-rose</div>
                <div className="title">Assistante Chargée de clientèle</div>
            </div>
        </Row>
    )
}