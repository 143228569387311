export default function CleanIcon() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="150px" height="150px" viewBox="0 0 840.000000 592.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,592.000000) scale(0.100000,-0.100000)"
                fill="#B7A57E" stroke="none">
                <path d="M3500 5506 c-316 -81 -421 -479 -187 -709 78 -76 178 -117 287 -117
397 0 572 496 263 746 -95 78 -244 110 -363 80z"/>
                <path d="M4894 4982 c-44 -35 -44 -40 -44 -549 l-1 -488 -101 -75 c-56 -41
-104 -76 -108 -78 -4 -1 -10 80 -13 180 -4 152 -8 193 -26 242 -55 155 -170
262 -330 310 -48 14 -133 16 -671 16 -669 0 -660 1 -773 -56 -63 -32 -160
-130 -193 -195 -60 -120 -58 -78 -61 -890 l-4 -747 -65 -42 c-76 -49 -135
-119 -176 -208 -47 -103 -49 -151 -16 -456 84 -788 72 -707 102 -733 l27 -23
322 0 c345 0 361 3 383 50 7 14 34 241 62 505 55 533 56 548 2 664 -39 83 -97
151 -172 201 l-61 40 2 712 c2 485 6 717 13 725 13 16 40 17 56 1 9 -9 12
-169 12 -660 l0 -648 56 -36 c169 -108 294 -323 294 -503 0 -74 -98 -996 -111
-1044 -21 -79 -120 -156 -201 -157 l-38 0 0 -222 c0 -213 1 -225 24 -274 29
-62 84 -109 154 -131 128 -41 271 44 301 177 7 29 11 391 11 1023 l0 977 50 0
50 0 0 -977 c0 -609 4 -994 10 -1021 32 -136 169 -217 302 -178 71 20 124 65
154 131 l24 50 0 1746 c0 1555 2 1748 15 1759 12 10 22 10 40 2 l24 -11 3
-383 3 -383 28 -47 c30 -52 88 -92 148 -103 66 -12 112 10 279 135 88 66 162
120 165 120 3 0 5 -550 5 -1222 l0 -1223 -68 -27 c-105 -43 -229 -125 -319
-211 -87 -83 -113 -125 -113 -179 0 -62 59 -101 205 -135 73 -16 134 -18 690
-18 336 -1 647 -5 692 -9 146 -14 201 10 169 72 -8 16 -53 72 -100 124 -114
128 -126 133 -327 133 -109 0 -160 4 -171 12 -8 7 -21 32 -29 55 -20 64 -100
140 -173 167 -68 25 -183 51 -227 51 l-29 0 0 1284 0 1285 83 63 c94 72 127
124 127 198 0 52 -27 115 -61 147 -30 27 -90 53 -124 53 l-25 0 0 435 c0 465
-1 479 -49 505 -30 15 -83 12 -107 -8z m-2207 -2598 c47 -20 141 -18 183 4 19
11 46 35 59 55 l24 36 28 -21 c38 -28 75 -84 99 -147 38 -99 67 -91 -311 -91
l-331 0 7 38 c11 63 50 137 95 180 l44 42 35 -42 c19 -22 50 -47 68 -54z"/>
            </g>
        </svg>
    )
}