import { useEffect, useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import instanceOfAxios from '../../../../app/axios/axios';
import EditOrCreateCleaning from './editOrCreateCleaning';
import { Col, Row } from 'react-bootstrap';
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import { useDispatch } from 'react-redux';
import { show } from '../../Alerter/alerterSlice';

export default function CleaningTable(props) {
    const [pending, setPending] = useState(true);
    const [data, setData] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get(`admin/agency/${props.agency}/cleaning?type=${props.type.type}`)
                .then(res => {
                    setData(Object.assign([], res.data.cleaning_values));
                })
            setPending(false);
        }
    }, [pending]);

    function deleteRow(id){
        instanceOfAxios.delete(`admin/agency/${props.agency}/cleaning/${id}`)
        .then(res => {
            setPending(true);
            dispatch(show({ message: 'Tarif mis à jour', variant: 'success' }));
        })
        .catch(error => {
            dispatch(show({ message: 'Erreur lors de la mise à jour', variant: 'danger' }));
        })
    }

    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' €';
    }

    function EditFormateur(cell, row) {
        return (
            <EditOrCreateCleaning taxe_type_id={props.type.id} cleaning={row} agency={props.agency} handleChange={setPending} for='edit' />
        );
    }

    function DeleteFormateur(cell, row) {
        return (
            <DeleteModal deleteFunction={() => deleteRow(row.id)} model={row}></DeleteModal>
        );
    }

    return (
        <>
            <Row className='mt-5'>
                <Col md={12} className='text-center'>
                    <h3>Ménage</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12} className='text-center'>
                    <EditOrCreateCleaning taxe_type_id={props.type.id} agency={props.agency} handleChange={setPending} />
                </Col>
            </Row>
            <Row>
                <Col md={12} className='text-center mt-3'>
                    <BootstrapTable data={data}>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} isKey dataField='type' width='120'>Logement</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataField='min_person' width='120' >inférieur ou égal à x <br/>personnes</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataField='max_person' width='120'>Supérieur ou égal à x <br/>personnes</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataFormat={priceFormatter} dataField='min_person_inf_value' width='120'>Valeur {'< 15 J'}<br/>pour mini<br/>de personnes</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataFormat={priceFormatter} dataField='min_person_sup_value' width='120'>Valeur {'>= 15 J'}<br/>pour mini<br/>de personnes</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataFormat={priceFormatter} dataField='max_person_inf_value' width='120'>Valeur {'< 15 J'}<br/>pour maxi<br/>de personnes</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataFormat={priceFormatter} dataField='max_person_sup_value' width='120'>Valeur {'>= 15 J'}<br/>pour maxi<br/>de personnes</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataFormat={EditFormateur} width='100'>Modifier</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataFormat={DeleteFormateur} width='120'>Supprimer</TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </Row>
        </>
    );
}