import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Row, Col, Navbar, Nav } from 'react-bootstrap'
import { ShowList, resetUpdater, getApartmentForUpdate } from '../apartmentSlice'
import ApartmentForm from '../form/ApartmentForm'
import {
    getStep,
    setError,
    setStep
} from '../form/apartmentFormSlice';
import '../Apartments.scss';

function CreateApartment(props) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);

    let stepTitle = '';

    switch (props.step) {
        case 1:
            stepTitle = 'Description';
            break;
        case 2:
            stepTitle = 'Adresse et Surface';
            break;
        case 3:
            stepTitle = 'Tarifs';
            break;
        case 4:
            stepTitle = 'logistique';
            break;
        case 5:
            stepTitle = 'Diagnostic et Observation';
            break;
        case 6:
            stepTitle = 'Calendrier';
            break;
        case 7:
            stepTitle = 'Photos';
            break;
        case 8:
            stepTitle = 'Pièces et équipements';
            break;
        case 9:
            stepTitle = 'Fiche de bienvenue';
            break;
        case 10:
            stepTitle = 'Documents supplémentaires';
            break;
    }

    return (
        <div className={props.className}>
            <Row className="d-flex">
                <Col md={2} sm={12} className="d-flex justify-content-start align-items-center">
                    <Button variant="secondary" className="btn-rounded" onClick={() => {
                        dispatch(ShowList())
                        dispatch(setStep(1))
                        dispatch(setError({}))
                        dispatch(resetUpdater())
                    }}>retour</Button>
                </Col>
            </Row>
            <Row className="d-flex mt--4" style={{ marginBottom: '5vh' }}>
                <Col xs={12} md={12} className="d-flex justify-content-center">
                    <strong>{props.apartment && props.apartment.reference}</strong>
                </Col>
                <Col xs={12} md={12} className="d-flex justify-content-center">
                    <strong>{stepTitle}</strong>
                </Col>
                <Col className="d-flex justify-content-around mt-4">
                    <Navbar expanded={expanded} expand="lg" variant="light">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} className="m-auto" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="d-flex align-items-center justify-content-between">
                                <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                    dispatch(setStep(1))
                                    setExpanded(false)
                                }} key={1} id={1} active={1 === props.step}>Description</Nav.Link>
                                {
                                    props.apartment
                                    &&
                                    <>
                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(2))
                                            setExpanded(false)
                                        }} key={2} id={2} active={2 === props.step}>Adresse et Surface</Nav.Link>

                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(3))
                                            setExpanded(false)
                                        }} key={3} id={3} active={3 === props.step}>Tarifs</Nav.Link>

                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(4))
                                            setExpanded(false)
                                        }} key={4} id={4} active={4 === props.step}>Logistique</Nav.Link>

                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(5))
                                            setExpanded(false)
                                        }} key={5} id={5} active={5 === props.step}>Diagnostic et Observation</Nav.Link>

                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(6))
                                            setExpanded(false)
                                        }} key={6} id={6} active={6 === props.step}>Calendrier</Nav.Link>

                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(7))
                                            setExpanded(false)
                                        }} key={7} id={7} active={7 === props.step}>Photos</Nav.Link>

                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(8))
                                            setExpanded(false)
                                        }} key={8} id={8} active={8 === props.step}>Pièces et équipements</Nav.Link>

                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(9))
                                            setExpanded(false)
                                        }} key={9} id={9} active={9 === props.step}>Fiche de bienvenue</Nav.Link>
                                        <Nav.Link style={{ width: '100%' }} className='navLinkActive d-flex align-items-center justify-content-center' onClick={() => {
                                            dispatch(setStep(10))
                                            setExpanded(false)
                                        }} key={10} id={10} active={10 === props.step}>Documents supplémentaires</Nav.Link>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
            <ApartmentForm />
        </div>)
}
const mapStateToProps = state => {
    return {
        step: getStep(state),
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(CreateApartment);
