import { useEffect, useState } from 'react';
import { Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import instanceOfAxios from '../../../../../app/axios/axios';
import Spinner from '../../../../spinner/Spinner';
import { show } from '../../../Alerter/alerterSlice';
import AddFile from './addFile/addFile';
import FileCard from './fileCard/fileCard';
import { setUpdater } from '../../apartmentSlice'
import { FaRegSave } from 'react-icons/fa';

export default function FilesList(props) {
    const [order, setOrder] = useState({ asChange: false });
    const [concatBoolean, setConcatBoolean] = useState({ asChange: false });
    const [descriptions, setDescription] = useState({ asChange: false });
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let newOrder = Object.assign({}, order);
        Object.keys(props.apartment.merged_pdf).map(
            index => newOrder[index] = props.apartment.merged_pdf[index].order
        )
        setOrder(newOrder);
    }, []);

    useEffect(() => {
        let newConcatBoolean = Object.assign({}, concatBoolean);
        Object.keys(props.apartment.merged_pdf).map(
            index => newConcatBoolean[index] = props.apartment.merged_pdf[index].is_mergable
        )
        setConcatBoolean(newConcatBoolean);
    }, []);

    function handleConcatChange(name, event) {
        let newConcatBoolean = Object.assign({}, concatBoolean);
        newConcatBoolean.asChange = true;
        newConcatBoolean[name] = event.target.checked;
        setConcatBoolean(newConcatBoolean);
    }

    function handleOrderChange(name, event) {
        let newOrder = Object.assign({}, order);
        newOrder.asChange = true;
        newOrder[name] = event.target.value;
        setOrder(newOrder);
    }


    function handleDescriptionChange(name, event) {
        let newDescriptions = Object.assign({}, descriptions);
        newDescriptions.asChange = true;
        newDescriptions[name] = event.target.value;
        setDescription(newDescriptions);
    }

    function handleSubmit() {
        let datas = {
            order: order.asChange ? order : false,
            concatBoolean: concatBoolean.asChange ? concatBoolean : false,
            descriptions: descriptions.asChange ? descriptions : false
        }
        setIsSubmit(true);
        props.reloadPdfPreview(true);
        instanceOfAxios.post('/admin/apartment/' + props.apartment.id + '/changeOrderAndMergableOfSpecificFiles', datas)
            .then(res => {
                dispatch(setUpdater(res.data.success));
                dispatch(show({ message: 'Modifications enregistrées', variant: 'success' }));
                setIsSubmit(false);
                props.reloadPdfPreview(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Une erreur est survenu lors de la sauvegarde', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    return (
        <Col xs={12}>
            <h3>Documents spécifiques</h3>
            <small className='text-danger'><strong>Pour un affichage optimisé ne sauvegarder que 5 documents maximum</strong></small>
            <Col xs={12} className="d-flex justify-content-center mt-3 mb-3">
                <AddFile apartment={props.apartment} reloadPdfPreview={props.reloadPdfPreview} />
            </Col>
            {
                Object.keys(props.apartment.merged_pdf).map(index =>
                    <FileCard
                        apartment={props.apartment}
                        reloadPdfPreview={props.reloadPdfPreview}
                        handleOrderChange={handleOrderChange}
                        handleConcatChange={handleConcatChange}
                        handleDescriptionChange={handleDescriptionChange}
                        file={props.apartment.merged_pdf[index]}
                        name={index} />)
            }
            {
                Object.keys(props.apartment.merged_pdf).length > 0 &&
                <Col xs={12} className="d-flex justify-content-center mt-3 mb-3">
                    {
                        isSubmit ?
                            <Spinner />
                            :
                            <Button className='whiteSVG' variant='secondary' onClick={handleSubmit}><FaRegSave/> Enregistrer</Button>
                    }
                </Col>
            }
            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                <div className='separator'
                    style={{
                        border: '1px solid #B7A57E',
                        color: '#b7a57e',
                        width: '90%',
                        marginBottom: '40px',
                        marginTop: '40px'
                    }}></div>
            </Col>
        </Col>
    )
}