import { IoMdAddCircleOutline } from 'react-icons/io';
import { Row, Col, Button, Modal, Form, InputGroup, Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Spinner from '../../spinner/Spinner';
import { show } from '../Alerter/alerterSlice';
import instanceOfAxios from '../../../app/axios/axios';
import { FaPencilAlt, FaRegSave } from 'react-icons/fa';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="secondary"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </Button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        const lang = navigator.language;
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled" style={{ position: 'relative', overflow: 'auto', maxHeight: '300px', maxWidth: '600px' }}>
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || (child.props.children[0] + ' ' + child.props.children[4]).toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);

export default function AddAgency(props) {
    const [visibility, setVisibility] = useState(false);
    const [params, setParams] = useState({});
    const [contacts, setContacts] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [valueContact, setValueContact] = useState(props.agency && props.agency.contact ? props.agency.contact.first_name + ' ' + props.agency.contact.last_name : 'Choisir un contact');
    const dispatch = useDispatch();

    useEffect(()=> {
        instanceOfAxios.get('admin/agency/contacts')
        .then(res => setContacts(res.data.contacts))
    }, [])
    
    function handleChange(event) {
        params[event.target.name] = event.target.value ? event.target.value : event.target.id;
        setParams(params);
    }

    function handleSave() {
        setIsSubmit(true);
        let functionName = 'post';
        let path = `admin/agency`
        if (props.for == 'edit') {
            path = `admin/agency/${props.agency.id}`;
            params._method = 'PATCH';
            functionName = 'patch'
        }

        instanceOfAxios[functionName](path, params)
            .then(res => {
                dispatch(show({ message: 'Agence mis à jour', variant: 'success' }));
                props.handleChange(true);
                setIsSubmit(false);
                setVisibility(false);
            })
            .catch(error => {
                dispatch(show({ message: 'Erreur lors de la mise à jour', variant: 'danger' }));
                setIsSubmit(false);
            })
    }
    return (
        <>
            {
                props.for == 'edit' ?
                    <Button className='whiteSVG goldenHover' onClick={() => setVisibility(true)}>
                        <FaPencilAlt />
                    </Button>
                    :
                    <Button variant='secondary' className='whiteSVG' onClick={() => setVisibility(true)}>
                        Ajouter une agence <IoMdAddCircleOutline />
                    </Button>
            }
            <Modal
                show={visibility}
                onHide={() => setVisibility(false)}
                scrollable
                backdropClassName="backdropFourth"
                backdrop="static"
                centered
                style={{ zIndex: '20004' }}
                animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajout d'une agence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Nom / implentation</Form.Label>
                                <InputGroup>
                                    <Form.Control defaultValue={props.agency ? props.agency.location : ''} type="text" name="location" placeholder="Montpellier" onChange={(e) => handleChange(e)} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Description</Form.Label>
                                <InputGroup>
                                    <Form.Control defaultValue={props.agency ? props.agency.description : ''} type="text" name="description" placeholder="Agence ..." onChange={(e) => handleChange(e)} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col md={12} className='text-center'>
                            <Dropdown style={{ padding: '10px' }}>
                                <Dropdown.Toggle as={CustomToggle}>
                                    {valueContact}
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                    {contacts ? contacts.map((value) =>
                                        <Dropdown.Item 
                                        key={value.id} 
                                        name='contact_id' 
                                        id={value.id} 
                                        eventkey={value.first_name + ' ' + value.last_name} 
                                        onClick={(e) => {
                                            handleChange(e)
                                            setValueContact(value.first_name + ' ' + value.last_name)
                                        }}>{value.first_name + ' ' + value.last_name}</Dropdown.Item>)
                                        :
                                        <Dropdown.Item>Aucun contacts</Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                            <Spinner />
                            :
                            <>
                                <Button variant="danger" onClick={() => setVisibility(!visibility)}>
                                    Fermer
                                </Button>
                                <Button variant="secondary" className='whiteSVG' onClick={handleSave}>
                                    <FaRegSave/> Enregistrer
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}