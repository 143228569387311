import Spinner from '../../spinner/Spinner';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import instanceOfAxios from '../../../app/axios/axios';
import ApartmentCard from './apartmentCard';
import AccountingModal from '../AccountingModal/AccountingModal';

export default function ApartmentTab() {
    const [apartments, setApartments] = useState([]);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get('/owner/apartments')
                .then((res) => {
                    setApartments(res.data.apartments);
                    setPending(false);
                });
        }
    }, [pending]);

    function handleChange() {
        instanceOfAxios.get('/owner/apartments')
            .then((res) => {
                setApartments(res.data.apartments);
            });
    }

    function mapApartments() {
        return apartments.map((apartment) =>
            <ApartmentCard apartment={apartment} handleChange={handleChange} />)
    }

    return (
        <>
            {apartments.length > 0 ?
                <Col xs={12} lg={6}>
                    <Row className='d-flex justify-content-center mt-5'>
                        <Col xs={12}>
                            <h2>Mon espace Propriétaire</h2>
                        </Col>
                        {
                            mapApartments()
                        }
                    </Row>
                </Col>
                :
                <Col
                    sm={12}
                    md={4}
                    className='menuItem d-flex justify-content-center align-items-center'>
                    <Spinner />
                </Col>
            }
        </>
    )
}