import React, {useState} from 'react';
import { connect } from 'react-redux';
import UsersList from './list/usersList';
import CreateOrUpdateUser from './create/createOrUpdateUser'
import { getShowCreate, getShowList } from './usersSlice';
import './users.scss';
const Users = ({ showCreate, showList }) => {
    const [reloadList, setReloadList] = useState(false);
    let classNameForCreate = 'slider-create-right slide-out-create-right';
    let classNameForList = 'slider-list-left slide-out-list-left';

    if(showCreate){
        classNameForCreate = 'slider-create-right slide-in-create-right'; 
    }

    if(showList){
        classNameForList = 'slider-list-left slide-in-list-left'; 
    }

    return (
            <div className='users'>
                <CreateOrUpdateUser className={classNameForCreate} reloadList={setReloadList}/>
                <UsersList className={classNameForList} reloadFromCreate={reloadList}/>
            </div>
        )
}

function mapStateToProps(state) {
    return {
        showCreate: getShowCreate(state),
        showList: getShowList(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);