import { useEffect, useState } from "react"
import instanceOfAxios from "../../../../app/axios/axios";
import { Row, Button, Col } from 'react-bootstrap';
import CommentCard from "../card/commentCard";
import Spinner from '../../../spinner/Spinner';

export default function CommentOnHome(props) {
    const [comments, setComments] = useState([]);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get('/comment/indexHome')
                .then(res => {
                    setComments(res.data.comments);
                    setPending(false);
                })
        }
    }, [pending])

    useEffect(() => {
        if (props.reload) {
            setPending(true);
        }
    }, [props.reload]);
    
    return (
        <Row className='d-flex justify-content-center'>
            {
                !pending ?
                    comments.map(comment => 
                    <CommentCard displayButton={true} 
                    comment={comment} 
                    forPublishOrUnPublish={props.forPublishOrUnPublish} 
                    forOnHome={props.forOnHome} 
                    RemoveforOnHome={props.RemoveforOnHome}
                    setReload={props.setReload}/>)
                    :
                    <Col xs={12} className='d-flex justify-content-center align-items-center'>
                        <Spinner />
                    </Col>
            }
        </Row>
    )
}