import React, { useEffect, useState } from 'react';
import Spinner from '../spinner/Spinner';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap'
import {
    setIsConnected,
    setRole
} from '../header/headerSlice';
import instanceOfAxios from '../../app/axios/axios';
import { show } from '../admin/Alerter/alerterSlice';
import "../../pages/home/Home.scss";

export default function FormulaireConnexion(props) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [visibility, setVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [forgotPasswordVisibility, setForgotPasswordVisibility] = useState(false);
    const credentials = [email, password];

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('connectOrReset') && urlParams.get('actionType')) {
            switch (urlParams.get('actionType')) {
                case 'connect':
                    setVisibility(true);
                    setEmail(urlParams.get('email'));
                    break;
                case 'reset':
                    setVisibility(true);
                    setEmail(urlParams.get('email'));
                    setForgotPasswordVisibility(true);
            }
        }
    }, [])


    function forgotPassWord() {
        setIsSubmit(true);
        instanceOfAxios.post('/forgot-password', { email: email })
            .then((res) => {
                dispatch(show({ message: 'Un email de réinitialisation à été envoyé à l\'adresse renseignée', variant: 'success' }));
                setVisibility(false);
                setForgotPasswordVisibility(!forgotPasswordVisibility)
                setIsSubmit(false);
            })
            .catch((error) => {
                setIsSubmit(false);
                dispatch(show({ message: 'Erreur lors de l\'envoi', variant: 'danger' }));
            })
    }

    function loginAsync() {
        setIsSubmit(true);
        setHasError(false);
        let log = new FormData();
        log.set("email", credentials[0]);
        log.set("password", credentials[1]);
        return instanceOfAxios.post('/login', log)
            .then((res) => {
                setIsSubmit(false);
                window.sessionStorage.setItem('Role', res.data.account.roles[0].name);
                setVisibility(false);
                dispatch(setIsConnected(true));
                dispatch(show({ message: 'Bonjour ' + (res.data.account.principal_contact ? res.data.account.principal_contact.civility + ' ' + res.data.account.principal_contact.last_name + ' ' + res.data.account.principal_contact.first_name + ', vous êtes connecté' : 'vous êtes connecté'), variant: 'success' }));
                dispatch(setRole(res.data.account.roles[0].name));
                if (res.data.account.roles[0].name === 'admin' || res.data.account.roles[0].name === 'super-admin') {
                    window.location.href = "/AdminPannel"
                }
            })
            .catch((error) => {
                setIsSubmit(false);
                window.sessionStorage.setItem('Role', undefined);
                dispatch(setIsConnected(false));
                setHasError(true);
            });
    };

    function switchFunction(event = null) {
        if (forgotPasswordVisibility) {
            forgotPassWord();
        } else {
            loginAsync();
        }
    }

    return (
        <div>
            <Button
                variant="light"
                style={{ backgroundColor: "transparent", width: "100%", border: "none", textAlign: "center", focus: "none", borderRadius: "0px", boxShadow: 'none !important', }}
                onClick={() => setVisibility(!visibility)}>Connexion</Button>
            <Modal
                backdropClassName="backdrop"
                show={visibility}
                onHide={() => {
                    setVisibility(!visibility);
                    setForgotPasswordVisibility(false);
                }}
                style={{ zIndex: '20000' }}
                centered>
                <Modal.Header closeButton>
                    {forgotPasswordVisibility ? 'Réinitialiser le mot de passe' : 'Connexion'}
                </Modal.Header>
                <Modal.Body>
                    {forgotPasswordVisibility ?
                        <div className="text-center">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" id="email" placeholder="Email" defaultValue={email} onChange={e => setEmail(e.target.value)} required></input>
                            </div>
                        </div>
                        :
                        <div className="text-center">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" id="email" placeholder="Email" defaultValue={email} onChange={e => setEmail(e.target.value)} required></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Mot de passe</label>
                                <input type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Mot de passe"
                                    onChange={e => setPassword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            switchFunction(e)
                                        }
                                    }}
                                    required></input>
                            </div>
                            <a href='#' onClick={() => setForgotPasswordVisibility(!forgotPasswordVisibility)}>Mot de passe oublié?</a>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <div className='text-center'>
                        {hasError ? <p style={{ color: "red" }}>Adresse email ou mot de passe invalide</p> : <></>}
                        {!isSubmit ? <button className="btn btn-secondary" onClick={() => switchFunction()}>Valider</button> : <Spinner></Spinner>}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}