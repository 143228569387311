import React, { useState } from 'react';
import Typed from 'react-typed';
import "../../../pages/home/Home.scss";
import { Col } from 'react-bootstrap'

export default function Title(props) {
    const [texts, setTexts] = useState([]);
    if(props.page && texts.length == 0){
        setTexts(Object.values(JSON.parse(props.page.content).carousel.text))
    }

    return (
        <Col md={12} className="home-title">
            <h1 className='notranslate'>
                Sur Class Appart :
                <Typed
                    loop
                    typeSpeed={100}
                    backSpeed={80}
                    smartBackspace
                    shuffle={false}
                    backDelay={10}
                    fadeOut={false}
                    fadeOutDelay={100}
                    loopCount={0}
                    showCursor
                    cursorChar="|"
                    strings={texts}
                />
            </h1>
        </Col>
    );
}

