import React, { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { useDispatch, connect } from 'react-redux';
import 'react-nice-dates/build/style.css';
import './searchBar.scss';
import { Link } from 'react-router-dom'
import DateRange from './dateRange/dateRange'
import {
  getNbPersons,
  getDropDownValue,
  getSearchResult,
  getParam,
  changeValue,
  fetchNbPersons
} from './searchBarSlice';
import { getBeginDate, getEndDate } from './dateRange/dateRangeSlice';
import SelectCustom from './selectCustom/selectCustom';

const SearchBar = (props) => {
  const dispatch = useDispatch();

  if (props.nbPersons.length < 1) {
    dispatch(fetchNbPersons());
  }

  useEffect(() => {
    if (props.endDate) {
      dispatch(changeValue({ type: 'end_date', payload: props.endDate.toUTCString() }))
    }
  }, [props.endDate]);

  useEffect(() => {
    if (props.beginDate) {
      dispatch(changeValue({ type: 'begin_date', payload: props.beginDate.toUTCString() }))
    }
  }, [props.beginDate]);
  
  return (
    <Row noGutters className="searchbar">
      <Col lg={12} xl={6}>
        <DateRange usage='search' changeValue={changeValue} />
      </Col>
      <Col lg={12} xl={3}>
        <div className='d-xl-flex align-items-xl-center ColSearchBar ColNbPerson'>
          <Row className="w-100">
            <Col lg={12} className='noPadding'>
              <p style={{margin: 'unset'}}>Nombre de personnes</p>
            </Col>
            <div className='person-svg'>
              <svg className='nbPersonIcon' xmlns="http://www.w3.org/2000/svg" width="80" height="45" viewBox="0 0 47.86 31.104">
                <g id="icone_nombredepersonnes" transform="translate(-2.215 -7.748)">
                  <path id="Tracé_1" data-name="Tracé 1" d="M32.985,31.678c-1.194-.426-3.144-.448-4.008-.778a5.662,5.662,0,0,1-1.855-.863A11.461,11.461,0,0,1,26.813,27a4.907,4.907,0,0,0,1.055-1.492,16.585,16.585,0,0,0,.512-2.931s.7.3.981-1.109c.235-1.215.682-1.855.565-2.75s-.618-.682-.618-.682.618-.906.618-3.987c0-3.176-2.4-6.3-6.886-6.3s-6.9,3.134-6.9,6.3c0,3.081.608,3.987.608,3.987s-.5-.213-.618.682.32,1.535.565,2.75c.277,1.418.981,1.109.981,1.109a16.745,16.745,0,0,0,.512,2.931A4.907,4.907,0,0,0,19.245,27a11.461,11.461,0,0,1-.309,3.038,5.449,5.449,0,0,1-1.855.853c-.853.33-2.8.373-4.008.8a7.523,7.523,0,0,0-4.882,7.163H37.856A7.508,7.508,0,0,0,32.985,31.678Z" transform="translate(3.084)" fill="#b7a57e" />
                  <path id="Tracé_2" data-name="Tracé 2" d="M13.78,24.7s2.548-.085,3.56-.991c-1.652-2.5-.757-5.426-1.1-8.154s-1.887-4.349-4.978-4.349h-.043a4.511,4.511,0,0,0-4.935,4.349c-.341,2.718.608,5.969-1.087,8.154,1.013.917,3.571.885,3.571.885h0a5.4,5.4,0,0,1-.107,1.791,2.692,2.692,0,0,1-1.279.586,24.731,24.731,0,0,0-2.761.767,4.488,4.488,0,0,0-2.409,3.965h8.559c.235-.853,1.844-2.377,3.411-3.176a7.158,7.158,0,0,1,2.633-.554s.405-.64-.927-.885a9.086,9.086,0,0,1-2.047-.714C13.642,26.141,13.78,24.7,13.78,24.7Z" transform="translate(0 1.785)" fill="#b7a57e" />
                  <path id="Tracé_3" data-name="Tracé 3" d="M27.368,24.7s-2.548-.085-3.56-.991c1.652-2.5.757-5.426,1.1-8.154s1.887-4.349,4.978-4.349h.043a4.511,4.511,0,0,1,4.935,4.349c.341,2.718-.608,5.969,1.087,8.154-1.013.917-3.571.885-3.571.885h0a5.4,5.4,0,0,0,.107,1.791,2.692,2.692,0,0,0,1.279.586,24.732,24.732,0,0,1,2.761.767,4.488,4.488,0,0,1,2.409,3.965H30.374c-.235-.853-1.844-2.377-3.411-3.176a7.158,7.158,0,0,0-2.633-.554s-.405-.64.927-.885a9.086,9.086,0,0,0,2.047-.714C27.507,26.141,27.368,24.7,27.368,24.7Z" transform="translate(11.141 1.785)" fill="#b7a57e" />
                </g>
              </svg>
            </div>
            <div style={{height: '80px', width: '100%'}}>
              <SelectCustom nbPerson={props.nbPersons} />
            </div>
          </Row>
        </div>
      </Col>
      <Col lg={12} xl={3}>
        <div className='d-flex flex-column justify-content-center align-items-center ColSearchBar ColSearchButton'>
          {
            window.location.pathname != '/Logements' ?
                <Link 
                to={"/Logements"} 
                className='d-flex flex-column justify-content-center align-items-center' 
                id="searchbutton" 
                size="lg" 
                style={{fontSize: '1.5rem'}}>Rechercher</Link>
              :
                <div 
                className='d-flex flex-column justify-content-center align-items-center' 
                variant='secondary' 
                id="searchbutton" 
                size="lg" onClick={props.onSearch} 
                style={{fontSize: '1.5rem'}}>Rechercher</div>
          }
          <Form.Group className="mt-3">
            <Form.Control 
            type="text" 
            placeholder="Référence" 
            onChange={e => dispatch(changeValue({ type: 'reference', payload: e.target.value }))} 
            onKeyDown={(event) => {
              if (event.key === 'Enter' && window.location.pathname === '/Logements') {
                props.onSearch();
              }
            }}/>
          </Form.Group>
        </div>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    nbPersons: getNbPersons(state),
    dropDownValue: getDropDownValue(state),
    searchResult: getSearchResult(state),
    param: getParam(state),
    beginDate: getBeginDate(state),
    endDate: getEndDate(state)
  };
}

export default connect(mapStateToProps)(SearchBar);