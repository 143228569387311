import { useEffect, useState } from "react"
import instanceOfAxios from "../../../../app/axios/axios";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import SkeletonCard from "../../booking/bookingCard/skeletonCard";
import { show } from "../../Alerter/alerterSlice";
import CardTab from "./card/cardTab";
import { AiOutlineSearch } from 'react-icons/ai';
import { FaRegSave } from 'react-icons/fa';

export default function TabActivateRecallPayment(props) {
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [reference, setReference] = useState('');
    const [recallPayment, setRecallPayment] = useState([]);
    const skeleton = [{}, {}, {}, {}, {}, {}];
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            fetchBooking(activePage);
        }
    }, [pending])

    function fetchBooking(page) {
        setLoading(true);
        let query = new URLSearchParams({ page: page, reference: reference }).toString();
        instanceOfAxios.get(`admin/toActivateAutoRecall?${query}`)
            .then(res => {
                setData(res.data.last_created_booking.data);
                setRecallPayment(res.data.report.is_recallable_payment);
                setPageNumber(res.data.last_created_booking.last_page);
                setLoading(false);
            })
        setPending(false);
    }

    function next() {
        if (activePage < pageNumber) {
            setActivePage(parseInt(activePage) + 1);
            fetchBooking(parseInt(activePage) + 1)
        }
    }

    function prev() {
        if (activePage > 1) {
            setActivePage(parseInt(activePage) - 1);
            fetchBooking(parseInt(activePage) - 1)
        }
    }

    function changeRecallPayment(event, booking) {
        let ids = Object.assign([], recallPayment);

        if (event.target.checked && !ids.includes(booking.id)) {
            ids.push(booking.id);
        } else {
            ids = ids.filter(id => id != booking.id);
        }
        setRecallPayment(ids);
    }

    function handleReportSubmit() {
        instanceOfAxios.post('/admin/activateAutoRecall', { is_recallable_payment: recallPayment })
            .then(res => {
                dispatch(show({ message: 'Changement sauvegardés', variant: 'success' }));
                fetchBooking();
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
            })
    }

    return (
        <>
            <Row>
                <Col md={12} className='text-center mb-3'>
                    <small>
                        Si une réservation est passée en 'Rappel de paiement automatique' le clien seras notifié tout les 5 jours tant que le status de la réservation n'est pas passé à 'payé'. <br/>
                        Le rappel débute 14 jours avant le début de la réservation. <br/>
                        Si la réservation passé en 'Rappel de paiement automatique' débute dans moins de 14 jours, la première notification se feras dans les 12heures.
                    </small>
                </Col>
            </Row>
            <Row>
                <Col md={6} className='text-center d-flex justify-content-center align-items-center mb-2'>
                    <Row>
                        <Col md={6} xs={12} className='mt-2'>
                            <Form.Group style={{marginBottom: 'unset'}}>
                                <Form.Control name="reference" placeholder="reference" onChange={(e) => setReference(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} className='mt-2'>
                            <Button variant='secondary' className='whiteSVG' onClick={() => fetchBooking(1)}>Rechercher <AiOutlineSearch/></Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className='text-center'>
                    <Button variant='secondary' className='whiteSVG mt-2' onClick={() => handleReportSubmit()}><FaRegSave/> Enregistrer</Button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-between mt-2 mb-2'>
                {data.length > 0 ?
                    <>
                        <Col xs={1} className='text-center'>
                            <Button onClick={(e) => prev(e)} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={4} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center'>
                            <Button onClick={(e) => next(e)} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </>
                    :
                    <></>}
            </Row>
            <Col xs={12}>
                <div>
                    {loading ? skeleton.map(skel => {
                        return (
                            <SkeletonCard />
                        )
                    })
                        :
                        data.map(booking => {
                            return (
                                <CardTab
                                    tabType='activateRecallPayment'
                                    booking={booking}
                                    firstChecked={recallPayment.includes(booking.id)}
                                    firstText={'Permettre le rappel de paiement'}
                                    firstChange={changeRecallPayment} />
                            )
                        })
                    }
                </div>
            </Col>
        </>
    )
}