import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form, Badge, InputGroup } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { useDispatch, useStore } from 'react-redux';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import PaymentCard from '../bookingDetails/payment/paymentCard';
import CreateOrEditPayment from '../bookingDetails/payment/formulaire/createOrEditPayment';
import InvoiceList from '../../invoice/invoiceList';
import Spinner from '../../../spinner/Spinner';
import RefundModal from './refundModal';
import { FaRegSave } from 'react-icons/fa';
import { TiDocumentAdd } from 'react-icons/ti'; 
import { IoMdAddCircleOutline } from 'react-icons/io';

export default function BailLife(props) {
    const dispatch = useDispatch();
    const role = useStore().getState().header.role;
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(true);
    const [urls, setUrls] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAmountSubmit, setIsAmountSubmit] = useState(false);
    const [isInvoiceSubmit, setIsInvoiceSubmit] = useState(false);
    const [amountUpdate, setAmountUpdate] = useState({ amount: undefined });
    const [newItem, setNewItem] = useState({ id: null, endtitle: null, amount: null, type: null, quantity: 1 });
    let data = props.booking.bail.items;
    let itemsAmount = 0;

    props.booking.bail.items.map(item => itemsAmount += item.amount);

    function handleChange(event) {
        newItem[event.target.name] = event.target.value
        newItem.id = data.length + 1;
        setNewItem(newItem);
    }

    useEffect(() => {
        if(pending){
            instanceOfAxios.get(`/invoices/bookingBail/${props.booking.bail.id}`)
            .then(res => {
                setUrls(res.data.urls);
                setPending(false);
            })
        }
    }, [pending])

    function addItem() {
        setIsSubmit(true);
        instanceOfAxios.post('/booking/' + props.booking.id + '/booking_bail/' + props.booking.bail.id + '/items', newItem)
            .then(res => {
                instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                    .then(res => {
                        props.BookingDetailsOnChange(res.data.booking);
                        dispatch(show({ message: 'Ajout retenue éffectuée', variant: 'success' }));
                        setIsSubmit(false);
                        setPending(true);
                    });
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de l\'ajout', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    function deleteItem(item) {
        instanceOfAxios.delete('/booking/' + props.booking.id + '/booking_bail/' + props.booking.bail.id + '/items/' + item.id)
            .then(res => {
                instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                    .then(res => {
                        props.BookingDetailsOnChange(res.data.booking);
                        dispatch(show({ message: 'Suppression éffectuée', variant: 'success' }));
                        setPending(true);
                    });
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
            })
    }

    function handleSaveUpdate(item, cellName, cellvalue) {
        item._method = 'PATCH';
        instanceOfAxios.post('/booking/' + props.booking.id + '/booking_bail/' + props.booking.bail.id + '/items/' + item.id, item)
            .then(res => {
                instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                    .then(res => {
                        props.BookingDetailsOnChange(res.data.booking);
                        dispatch(show({ message: 'Modification éffectuée', variant: 'success' }));
                        setPending(true);
                    });
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de la modification', variant: 'danger' }));
            })
    }

    function generateInvoice() {
        setIsInvoiceSubmit(true);
        instanceOfAxios.get('/booking/' + props.booking.id + '/booking_bail/' + props.booking.bail.id + '/generateInvoice')
            .then(res => {
                instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                    .then(res => {
                        props.BookingDetailsOnChange(res.data.booking);
                        setIsInvoiceSubmit(false);
                        dispatch(show({ message: 'Facture créé', variant: 'success' }));
                        setPending(true);
                    });
            })
            .catch(err => {
                setIsInvoiceSubmit(false);
                dispatch(show({ message: 'Erreur lors de la création', variant: 'danger' }));
            })
    }

    function handleUpdate() {
        setIsAmountSubmit(true);
        amountUpdate._method = 'PATCH';
        instanceOfAxios.post('/booking/' + props.booking.id + '/booking_bail/' + props.booking.bail.id, amountUpdate)
            .then((res) => {
                instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                    .then(res => {
                        props.BookingDetailsOnChange(res.data.booking);
                        dispatch(show({ message: 'Modification éffectuée', variant: 'success' }));
                        setIsAmountSubmit(false);
                        setPending(true);
                    });
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de la modification', variant: 'danger' }));
                setIsAmountSubmit(false);
            })
    }

    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' €';
    }

    function DeleteFormater(cell, row) {
        return (
            <DeleteModal deleteFunction={() => deleteItem(row)} model={row}></DeleteModal>
        );
    }

    function TypeFormater(cell, row) {
        let types = {
            coutant: 'Prix coutant',
            proprietaire: 'Rembourssement propriétaire',
            prestataire: 'Frais externe',
            service: 'Commission (service)',
            achat: 'Commission (achat)'
        }
        return (
            types[cell]
        );
    }
    function dateFormatter(cell, row) {
        const date = new Date(cell.replace(/\s/, 'T'));
        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString('fr-CA', options);
    }

    const cellEditProp = {
        mode: 'click',
        blurToSave: true,
        afterSaveCell: handleSaveUpdate
    };

    return (
        <>
            <Button variant="secondary" className="btn-rounded" onClick={() => setVisibility(true)}>
                Caution
            </Button>
            <Modal
                show={visibility}
                dialogClassName='custom-dialog'
                onHide={() => setVisibility(false)}
                scrollable
                centered
                size='xl'
                backdropClassName="backdropFourth"
                style={{ zIndex: '20003' }}>
                <Modal.Header closeButton>
                    <Row style={{ width: '100%' }}>
                        <Col xs={10} md={2}>
                            <Modal.Title>
                                Caution
                            </Modal.Title>
                        </Col>
                        <Col className="d-flex justify-content-center m-auto reducePadding" xs={2}>
                            {props.booking.bail.is_payed ?
                                <Badge variant='success'>Caution payé</Badge>
                                :
                                <Badge variant='danger'>Caution à payer</Badge>
                            }
                        </Col>
                        <Col xs={12} md={3} className='d-flex justify-content-center'>
                            {
                                !isInvoiceSubmit ?
                                    <Button variant='secondary' className='whiteSVG mt-1 mb-1' onClick={generateInvoice}><TiDocumentAdd/> Générer une facture</Button>
                                    :
                                    <Spinner />
                            }
                        </Col>
                        <Col xs={12} md={3} className='d-flex justify-content-center align-items-center'>
                            {/* voir pour ajouter une verif si la résa est finit ou non et verif si le reloadList fonctionne*/}
                            <RefundModal
                                BookingDetailsOnChange={props.BookingDetailsOnChange}
                                refundAt={props.booking.bail.refund_at}
                                booking={props.booking}/>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Row className='d-flex justify-content-around'>
                        <Col xs={12}>
                            <h3>Information de la Caution</h3>
                        </Col>
                        <Col xs={12} md={4} className='d-flex flex-column justify-content-center align-items-center mb-3'>
                            <Form.Group className='w-100'>
                                <Form.Label>
                                    Montant :
                                </Form.Label>
                                <InputGroup className="mb-2">
                                    <Form.Control className='text-center' type='text' defaultValue={props.booking.bail.amount} placeholder="Montant"
                                        onChange={(event) => {
                                            setAmountUpdate({ amount: event.target.value });
                                        }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleUpdate();
                                            }
                                        }} />
                                    <InputGroup.Text>€</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            {
                                !isAmountSubmit ?
                                    <Button variant='secondary' className='whiteSVG' onClick={() => handleUpdate()}><FaRegSave/> Enregistrer</Button>
                                    :
                                    <Spinner />
                            }
                        </Col>
                        <Col xs={12} md={4} className='d-flex flex-column justify-content-center align-items-center'>
                            <p>Montant total des retenues :</p> <h4><strong>{(itemsAmount).toFixed(2)}</strong> €</h4>
                        </Col>
                        <Col xs={12} className='text-center text-md-left'>
                            <h3>Ajouter une retenue</h3>
                        </Col>
                        <Col xs={12} className='noPadding mt-3 mb-3'>
                            <Form>
                                <Row className='d-flex justify-content-center'>
                                    <Col xs={12} md={6} lg={3} className='mb-1'>
                                        <Form.Control type='text' className='text-center' defaultValue={newItem.endtitle} placeholder="Intitulé" name='endtitle' onChange={(e) => handleChange(e)} />
                                    </Col>
                                    <Col xs={12} md={6} lg={3} className='mb-1'>
                                        <Form.Control type='text' className='text-center' defaultValue={newItem.amount} placeholder="Montant" name='amount' onChange={(e) => handleChange(e)} />
                                    </Col>
                                    <Col xs={12} md={6} lg={3} className='mb-1'>
                                        <Form.Control type='number' className='text-center' defaultValue={newItem.tva_value} placeholder="TVA" name='tva_value' onChange={(e) => handleChange(e)} />
                                    </Col>
                                    <Col xs={12} md={6} lg={3} className='mb-1'>
                                        <Form.Control type='number' className='text-center' defaultValue={newItem.quantity} placeholder="Quantité" name='quantity' onChange={(e) => handleChange(e)} />
                                    </Col>
                                    <Col xs={12} md={6} lg={3} className='mb-1'>
                                        <Form.Group controlId="formGridState" className='text-center'>
                                            <Form.Control as="select" defaultValue={newItem.type} name='type' onChange={(e) => handleChange(e)}>
                                                <option value='none'>Selection</option>
                                                <option value='coutant'>Prix coutant</option>
                                                <option value='proprietaire'>Rembourssement propriétaire</option>
                                                <option value='prestataire'>Facture prestataire</option>
                                                <option value='service'>Commission (avec service)</option>
                                                <option value='achat'>Commission (avec achat)</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='d-flex justify-content-center' style={{ paddingBottom: '2vh' }}>
                                    {
                                        !isSubmit ?
                                            <Button variant="secondary" className="whiteSVG" onClick={addItem}><IoMdAddCircleOutline/> Ajouter</Button>
                                            :
                                            <Spinner />
                                    }
                                </Row>
                            </Form>
                        </Col>
                        <Col xs={12} style={{ marginBottom: '2vh' }}>
                            <BootstrapTable data={data} striped={true} hover={true} cellEdit={cellEditProp} className='shadowForCard mt-2'>
                                <TableHeaderColumn dataField="id" isKey dataSort={true} hidden>id</TableHeaderColumn>
                                <TableHeaderColumn dataField="endtitle" dataSort={true}>Intitulé</TableHeaderColumn>
                                <TableHeaderColumn dataField="amount" dataSort={true} dataFormat={priceFormatter}>Montant</TableHeaderColumn>
                                <TableHeaderColumn dataField="tva_value" dataSort={true} dataFormat={priceFormatter}>TVA</TableHeaderColumn>
                                <TableHeaderColumn dataField="type"
                                    editable={{
                                        type: 'select',
                                        options: {
                                            values: [
                                                { value: 'coutant', text: 'Prix coutant' },
                                                { value: 'proprietaire', text: 'Rembourssement propriétaire' },
                                                { value: 'prestataire', text: 'Facture prestataire' },
                                                { value: 'achat', text: 'Commission avec achat' },
                                                { value: 'service', text: 'Commission avec service' }
                                            ]
                                        }
                                    }}
                                    dataSort={true}
                                    dataFormat={TypeFormater}>
                                    Type
                                </TableHeaderColumn>
                                <TableHeaderColumn editable={false} dataFormat={DeleteFormater}>Supprimer</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Col xs={12} style={{ height: '5vh', paddingLeft: '0' }}>
                                Notifications :
                            </Col>
                            <BootstrapTable data={props.booking.bail.notifications} striped={true} hover={true} className='shadowForCard mt-2 mb-2'>
                                <TableHeaderColumn dataField="id" isKey dataSort={true} hidden>id</TableHeaderColumn>
                                <TableHeaderColumn dataField="userName" dataSort={true}>Utilisateur</TableHeaderColumn>
                                <TableHeaderColumn dataField="date" dataSort={true} dataFormat={dateFormatter}>Date</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Row noGutters style={{ height: '5vh' }}>
                                <Col xs={11} >
                                    Paiements :
                                </Col>
                                {!props.booking.bail.is_payed && (role === 'super-admin' || role === 'admin' || role === 'trainee') ?
                                    <Col xs={1} className="d-flex justify-content-center align-items-center noPadding">
                                        <CreateOrEditPayment for={'create'} payable={props.booking.bail} booking={props.booking} BookingDetailsOnChange={props.BookingDetailsOnChange} type={'bookingbail'}/>
                                    </Col>
                                    :
                                    <></>}
                            </Row>
                            <div className="paymentScroll">
                                {props.booking.bail.payments.map((payment) => {
                                    return (<PaymentCard type={'bookingbail'} key={payment.id + payment.state} payable={props.booking.bail} booking={props.booking} payment={payment} BookingDetailsOnChange={props.BookingDetailsOnChange}/>)
                                })}
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center reducePadding" xs={12} lg={8}>
                            <InvoiceList invoices={urls}/>
                        </Col>
                        {
                            props.booking.bail.is_payed && props.booking.bail.payments[0] && props.booking.bail.payments[0].payment_mode == 'Swikly' ?
                                <Col xs={12} className='d-flex justify-content-center'>
                                    <Button variant='secondary'>Retenir les options sur la caution</Button>
                                </Col>
                                :
                                <></>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
