import React from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import './statics.scss'
import OurTeam from './team/OurTeam';

export default function Presentation() {
    return (
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
            <Row>
                <Col xs={12}>
                    <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > <a href='/Agence' className='golden'>Agence</a></p>
                </Col>
                <Col sm={12}>
                    <h2 style={{ textDecoration: 'underline 1px' }}> L'agence</h2>
                </Col>
                <Col md={12} className="agency-pictures" style={{ backgroundImage: 'url(\'/statics/voyage1.webp\')', backgroundRepeat: 'no-repeat' }}>
                </Col>
                <Col xs={12} className='noPadding'>
                    <p>
                        <strong className="text-justify">
                            Class Appart est une agence immobilière spécialisée dans la location meublée de courte et moyenne durée sur Montpellier et ses alentours.
                        </strong>
                    </p>
                    
                    <p className="text-justify strongToGold">
                        Vous êtes un <strong>particulier</strong>  et recherchez une <strong>location de vacances</strong> ? La <strong>location saisonnière </strong> est une solution qui permet de <strong>voyager à Montpellier pas cher</strong>. Nos logements prêts à vivre sont parfaits pour vos séjours de <strong>tourisme</strong> , <strong>vacances</strong> ou <strong>week-end</strong>.
                    </p>
                    <p className="text-justify strongToGold">
                        Vous êtes un <strong>professionnel en déplacement sur Montpellier ou dans l’Hérault</strong> ? Class Appart propose également des <strong>locations meublées au mois</strong> avec par exemple le <strong>bail mobilité</strong> (1 à 10 mois), idéal pour vos besoins en voyages d’affaires, mobilité professionnelle, mutation, mission professionnelle, formation, congrès, séminaire, tournage de film ou de série...
                    </p>
                   
                    <p className="text-justify strongToGold">
                        Cette solution économique est idéale pour découvrir notre <strong>région Montpelliéraine</strong> et y vivre à la manière d’un vrai montpelliérain. Notre équipe ne manquera pas de vous apporter tous les bons plans et bonnes adresses pour rendre votre séjour inoubliable.
                    </p>
                    <br/>
                    <p className="text-justify">
                        <strong>Nous faisons de votre satisfaction une priorité !</strong>
                    </p>
                
                   
                    <p className="text-justify strongToGold">
                        Tous nos logements de standing sont triés « sur le volet » avec soin. Du <strong>studio à la villa de luxe avec piscine</strong>,  ils sont tous meublés, entièrement équipés et très fonctionnels. Chaque logement Class Appart a été personnalisé et décoré avec goût afin de rendre votre séjour agréable, dans un cadre de vie chaleureux et accueillant.
                    </p>
                    <p className="text-justify strongToGold">
                        Tous nos clients ont la possibilité de commander des services à la carte pour un séjour personnalisé (location de lit bébé, chaise haute, ménage supplémentaire à la demande, fourniture du linge de maison).
                    </p>
                    <br />
                    <p>
                        <strong className="text-justify mb-5">
                        Nous avons sélectionné pour vous les meilleurs emplacements géographiques:
                    </strong>
                    </p>
                    <p className="text-justify strongToGold" >
                        Vous venez <strong>vivre à Montpellier</strong> mais ne savez pas dans quel quartier <strong>s'installer à Montpellier</strong> ? Séjourner dans l’un de nos logements, le temps de découvrir la ville, prenez le temps de faire vos démarches administratives et commercer à <strong>vivre et travailler à Montpellier</strong> sans stress.
                    </p>
                    <p className="text-justify">
                        Louez un logement dans le centre historique, appelé aussi l’Ecusson. Vous séjournerez à proximité des musées, du théâtre, de la gare Saint-Roch, du Peyrou, du Corum, de la place de la Comédie et de ses restaurants.
                    </p>
                    <p className="text-justify">
                        Autour des rives du Lez : quartiers Antigone, Richter et Port Marianne (proches de l’Arena, du centre commercial Odysseum, des lignes de tramway, des lieux de promenade le long du lez)
                    </p>
                    <p className="text-justify" >
                        A proximité des plages vers le littoral Montpellierrain: Palavas-les-Flots, La-Grande-Motte, Carnon, Pérols, Lattes et Mauguio
                    </p>
                    <p className="text-justify strongToGold" >
                        Nous proposons également une sélection de <strong>maison meublée dans l’herault</strong> et dans <strong>les meilleurs villages autour de Montpellier</strong>: Montferrier, Prades le lez, Juvignac, Combaillaux, Saint Jean de Védas, Saint gely du Fesc, Baillargues, Sommières, Villevieille...
                    </p>
                </Col>
            </Row>
            <a id="our-team">
                <hr className="page-separator" />
            </a>
            <OurTeam />
            <a id="our-engagements">
                <hr className="page-separator" />
            </a>
            <Row className="mt-5">
                <Col sm={12} className="d-flex justify-content-center">
                    <h2 style={{ textDecoration: 'underline 1px' }}>Nos engagements</h2>
                </Col>
                <Col sm={12}>
                    <p className="text-justify">
                        Pour vous permettre de profiter pleinement et sereinement des avantages de la location de logements meublés à la nuit, à la semaine ou au mois, Class Appart se plie en quatre.
                    </p>
                    <h4>
                        Nos engagements sont avant tout une priorité :
                    </h4>
                    <ul className="vacation-list">
                        <li>
                            Des réservations simplifiées avec des fiches logements détaillées. Une accessibilité et une réservation en ligne 24 heures sur 24 et 7 jours sur 7.
                        </li>
                        <li>
                            Un paiement 100 % sécurisé en ligne.
                        </li>
                        <li>
                            Des accueils personnalisés avec l’un des membres de notre équipe directement au logement.
                        </li>
                        <li>
                            Une qualité de service garantie 7 jours sur 7.
                        </li>
                        <li>
                            Une écoute et une disponibilité sans limite pour vous apporter le meilleur des services.
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col sm={12} md={4} className="d-flex justify-content-center">
                    <Image loading='lazy' src="/statics/concept1.webp" className="needPicture"></Image>
                </Col>
                <Col sm={12} md={4} className="d-flex justify-content-center">
                    <Image loading='lazy' src="/statics/concept2.webp" className="needPicture"></Image>
                </Col>
                <Col sm={12} md={4} className="d-flex justify-content-center">
                    <Image loading='lazy' src="/statics/concept3.webp" className="needPicture"></Image>
                </Col>
            </Row>
        </Container>)
}
