import React, { Component } from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import './statics.scss'

export  default class MyNeeds extends Component {
    render() {
        return (
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
            <a id="my-needs"></a>
            <a id="concept"></a>
            <Row className="mt-5">
                <Col sm={12} className="d-flex justify-content-center mt-5">
                    <h2 className='mb-2' style={{textDecoration: 'underline 1px'}}>Le Concept</h2>
                </Col>
                <Col sm={12}>
                    <p className="text-justify strongToGold">
                        Class Appart est une des premières <strong>AGENCE IMMOBILIERE</strong> à <strong>Montpellier SPECIALISÉE</strong> dans la gestion et la location de <strong>LOGEMENTS MEUBLES</strong> destinés à de la <strong>LOCATION COURTE</strong> et <strong>MOYENNE</strong> durée. 
                        Un concept innovant à Montpellier mais déjà présent dans les grandes villes d’Europe.
                        La location de biens de <strong>STANDING</strong> est une vraie alternative à l’hôtellerie traditionnelle, mais surtout une réponse à un besoin de <strong>LOGEMENTS MEUBLÉS TEMPORAIRES</strong> pour plusieurs semaines ou quelques mois seulement. Disponible à la nuitée, à la semaine ou au mois, nos biens sont tous meublés, équipés et accompagnés de services (linge, ménage, wifi…). Du studio à la grande villa, nos logements sont une vraie solution, à la fois confortable et économique, pour tous les <strong>SÉJOURS D'AFFAIRES</strong>, les <strong>VACANCES</strong>, les <strong>LONGS WEEK-ENDS</strong> et les <strong>MUTATIONS PROFESSIONNELLES </strong>à Montpellier, ses villages environnants (Prades, le lez, Baillargues, Perols, Sommieres…) et son bord de mer (Palavas les flots, Carnon, la grande motte…).
                        Locations saisonnières ou bail mobilité, nous avons le contrat qu’il vous faut pour répondre à votre besoin.
                    </p>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col sm={12} md={4} className="d-flex justify-content-center">
                    <Image loading='lazy' src="/statics/concept1.webp" className="needPicture"/>
                </Col>
                <Col sm={12} md={4} className="d-flex justify-content-center">
                    <Image loading='lazy' src="/statics/concept2.webp" className="needPicture"/>
                </Col>
                <Col sm={12} md={4} className="d-flex justify-content-center">
                    <Image loading='lazy' src="/statics/concept3.webp" className="needPicture"/>
                </Col>
            </Row>
            <a id="work-trip">
                <hr className="page-separator"/>
            </a>
            <Row>
                <Col sm={12}>
                    <h2 className='mb-2' style={{textDecoration: 'underline 1px'}}>Voyages d'affaires</h2>
                </Col>
                <Col sm={12} md={4} className='d-flex align-items-center'>
                    <Image loading='lazy' src="/statics/voyage-affaire.webp" className="needPicture mb-2"/>
                </Col>
                <Col sm={12} md={8} className='d-flex flex-column justify-content-center'>
                    <p className="text-justify">
                        Class Appart vous accompagne pour vos déplacements professionnels de courte et moyenne durée (voyages d’affaires, missions professionnelles, séminaires, formations…) en mettant à votre disposition des appartements de qualité et bien placés à Montpellier.
                    </p>
                    <p className="text-justify">
                        Que votre séjour soit pour 3 nuits, plusieurs semaines ou quelques mois, Class Appart vous propose des studios et  appartements situés au cœur du centre ville à proximité du centre d’affaires, des zones économiques (Corum, Parc des Expositions, Arena…), et des moyens de transport (gare St-Roch, aéroport Fréjorgues, parkings, 4 lignes de tramway…).
                    </p>
                </Col>
                <Col sm={12}>
                    <p className="text-justify">
                        Pour garantir votre confort et vous apporter un service d’une qualité équivalente au standing d’un hôtel 3 ou 4 étoiles, Class Appart met à votre disposition des appartements prêts à vivre pour se sentir “comme à la maison” : meublés et entièrement équipés, chaleureusement décorés et très fonctionnels.
                    </p>
                    <p className="text-justify strongToGold">
                        <strong>AU PETIT SOIN POUR LA CLIENTELE PROFESSIONNELLE</strong>: pour faciliter votre séjour d’affaires, l’ensemble des appartements disposent d’un espace bureau pour préparer vos rendez-vous, d’une connexion internet WIFI gratuite, d’une télévision écran plat, etc. Mais aussi de nombreux services 7 jours sur 7 à la carte : le remplissage du frigo, service de blanchisserie, ménage supplémentaire, etc.
                    </p>
                </Col>
            </Row>
            <a id="mutation">
                <hr className="page-separator"/>
            </a>
            <Row>
                <Col sm={12}>
                    <h2 className='mb-2' style={{textDecoration: 'underline 1px'}}>Mutations</h2>
                </Col>
                <Col sm={12} md={8} className='d-flex flex-column justify-content-center'>
                    <p className="text-justify">
                    Class Appart est la solution de logement pour plusieurs nuits, semaines ou quelques mois sur Montpellier, vous proposant une location de biens simple et pratique.
                    </p>
                    <p className="text-justify">
                    Si vous venez d’être affecté sur le bassin montpellierain et que vous n’avez pas encore trouvé votre futur chez vous, inutile de vous presser : la location d’logement courte et moyenne durée est la solution idéale. Une mutation ou un retour d’expatriation nécessite un relogement rapide dans un logement confortable pour la durée de votre choix (jours, semaines, mois) et vous pourrez ainsi prendre le temps de découvrir les quartiers de Montpellier et trouver votre bien immobilier.
                    </p>
                    <p className="text-justify">
                    Du studio de 18m² sur-équipé, au vaste logement bourgeois en passant par la villa avec piscine, vous avez le choix entre de nombreux biens meublés et équipés pour vous loger vous et/ou votre famille. Chaque logement, a été soigneusement sélectionné par nos soins, vous offre un équipement complet et standardisé.
                    </p>
                </Col>
                <Col sm={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Image loading='lazy' src="/statics/mutation.webp" className="needPicture"/>
                </Col>
            </Row>
            <a id="trip">
                <hr className="page-separator"/>
            </a>
            <Row>
                <Col sm={12}>
                    <h2 className='mb-2' style={{textDecoration: 'underline 1px'}}>Vacances</h2>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={4} className='d-flex align-items-center justify-content-center'>
                    <Row className='d-flex flex-lg-column align-items-center'>
                            <Image loading='lazy' src="/statics/voyage1.webp" className="needPicture p-2"/>
                    </Row>
                </Col>
                <Col md={12} lg={8}>
                    <strong>
                        Montpellier, les vacances, le soleil, la plage
                    </strong>
                    <p className="text-justify">
                        Profitez des appartements de standing de Class Appart pendant vos vacances à Montpellier et vivez votre séjour comme un vrai montpellierain.
                    </p>
                    <p className="text-justify">
                        Nos logements de standing sont tous meublés et décorés avec soin. Ainsi, grâce à leur cuisine équipée, les bars et restaurants ne seront plus une obligation mais un plaisir. Class Appart est la solution économique pour vos vacances en couple, entre amis ou en famille. Grace à leurs emplacements stratégiques dans la ville, vous séjournerez au coeur de tous vos centres d’intérêt :    
                    </p>
                    <ul  className="vacation-list">
                        <li>
                            Le centre historique avec son théâtre, ses musées, sa place de la Comédie, ses petites placettes et leurs terrasses ensoleillées.
                        </li>
                        <br/>
                        <li>
                            Egalement à proximité de parkings et des 4 lignes de tramway, vous n’aurez aucune difficulté à rejoindre les plages de Palavas-les-Flots, Carnon ou encore la Grande-Motte.
                        </li>
                    </ul>
                    <p className="text-justify">
                        Class Appart s’est aussi des logements de vacances autour de Montpellier, dans les villages environnants avec des villas avec piscine à la location à Prades, le lez, Baillargues, Perols, Sommieres…. C'est également des vacances à la plage au bord de l'eau sur les villes de Palavas les flots, Carnon, la grande motte dans des logements avec vue mer.
                    </p>
                    <p className="text-justify">
                        Enfin, Class Appart, c’est aussi les conseils et les bons plans d’une équipe montpelliéraine, qui vous ouvrira son carnet de bonnes adresses et vous fera découvrir les traditions et cultures de sa ville.
                    </p>
                    <p className="text-justify">
                        Class Appart est la solution pour faire de vos vacances un séjour unique, nous avons hâte de vous rencontrer !
                    </p>
                </Col>
            </Row>
            <a id="works">
                <hr className="page-separator"/>
            </a>
            <Row>
                <Col sm={12}>
                    <h2 className='mb-2' style={{textDecoration: 'underline 1px'}}>Je suis en travaux, je déménage</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={8} className='d-flex flex-column justify-content-center'>
                    <p className="text-justify"> 
                        Des travaux, un déménagement ? Class Appart est la location temporaire qu’il vous faut.
                    </p>
                    <p className="text-justify">
                        Vous êtes en travaux ou rénovation et n’avez plus de logement ? <br/>
                        Vous avez vendu votre bien et n’avez pas encore trouvé la perle rare ?
                    </p>
                    <p className="text-justify">
                        Vous arrivez à peine à Montpellier, avez besoin de découvrir la ville et ses quartiers alors que vous n’avez pas encore trouvé de logement ?
                    </p>
                    <p className="text-justify">
                        Class Appart répondra à vos attentes et vous permettra de choisir parmi un large portefeuille d’appartements, tous disponibles pendant de courtes périodes (quelques jours, semaines ou mois).
                    </p>
                    <p className="text-justify">
                        Du studio au T4, que vous soyez seul, en couple ou avec toute votre famille, Class Appart est la solution d’hébergement temporaire à Montpellier.
                    </p>
                </Col>
                <Col sm={12} md={4} className='d-flex align-items-center'>
                    <Image loading='lazy' style={{width:"100%", height:"auto"}} src="/statics/demenagement.webp"/>
                </Col>
            </Row>
        </Container>)
    }
}