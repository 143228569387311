import { useState } from 'react'
import { Modal, Button, Card, Row, Col, Form } from 'react-bootstrap'
import instanceOfAxios from '../../../../../app/axios/axios';
import { useDispatch } from 'react-redux';
import { show } from '../../../Alerter/alerterSlice';
import Spinner from '../../../../spinner/Spinner';
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';

export default function ListArchivedPriceInfluencer(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const Dispatch = useDispatch();

    function handleActivateOrDesactivate(id) {
        setPending(true);
        instanceOfAxios.get(`/admin/apartment/${props.apartment.id}/priceInfluencer/${id}/activateOrDesactivate`)
            .then(res => {
                setPending(false);
                props.setPending(true);
                Dispatch(show({ message: 'Action réussi', variant: 'success' }));
            })
            .catch(error => {
                setPending(false);
                Dispatch(show({ message: 'Action échouée', variant: 'danger' }));
            })
    }

    return (
        <>
            <Button variant='secondary' className='whiteSVG' onClick={() => setVisibility(true)}><MdOutlineFormatListNumberedRtl /> Liste des pourcentages archivés</Button>
            <Modal
                scrollable
                centered
                show={visibility}
                backdrop="static"
                size='xl'
                onHide={() => setVisibility(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Liste des pourcentages archivés
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {
                            props.priceInfluencers.filter(priceInfluencer => priceInfluencer.is_active == 0).length > 0 ?
                                props.priceInfluencers.filter(priceInfluencer => priceInfluencer.is_active == 0).map(priceInfluencer => {
                                    let begin = new Date(priceInfluencer.begin_date_hour);
                                    let end = new Date(priceInfluencer.end_date_hour);
                                    var options = { month: 'long', day: 'numeric' };
                                    return (
                                        <Col xs={12} md={6} lg={4} className='pb-2'>
                                            <Card>
                                                <Card.Header className='d-flex justify-content-around'>
                                                    <Card.Title>
                                                        {priceInfluencer.name}
                                                    </Card.Title>
                                                        {
                                                            pending ?
                                                                <Spinner />
                                                                :
                                                                <Form.Check
                                                                    type="switch"
                                                                    id={"custom-switch-off-" + priceInfluencer.id}
                                                                    label="Activer"
                                                                    defaultChecked={priceInfluencer.is_active}
                                                                    onClick={() => handleActivateOrDesactivate(priceInfluencer.id)}
                                                                />
                                                        }
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row className='d-flex justify-content-center'>
                                                        <Row>
                                                            <Col>
                                                                {priceInfluencer.is_positive ? 'Majoration' : 'Réduction'} de <strong>{priceInfluencer.percent}</strong> %
                                                            </Col>
                                                        </Row>
                                                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-1'>
                                                            <div className='separator'
                                                                style={{
                                                                    border: '1px solid #B7A57E',
                                                                    color: '#b7a57e',
                                                                    width: '90%'
                                                                }}></div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Début de période
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    <strong>{begin.toLocaleDateString('fr-CA', options)}</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Fin de période
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    <strong>{end.toLocaleDateString('fr-CA', options)}</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                                :
                                <Col>
                                    <h2>Aucuns pourcentages archivés</h2>
                                </Col>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}