import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import instanceOfAxios from "../../../../app/axios/axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import EditOrCreateAssurance from "./editOrCreateAssurance";

export default function Assurance(props) {
    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get(`admin/agency/${props.agency}/other?type=${props.type.type}`)
                .then(res => {
                    setData(res.data.other_values);
                })
            setPending(false);
        }
    }, [pending]);


    function EditFormateur(cell, row) {
        return (
            <EditOrCreateAssurance taxe_type_id={props.type.id} other={row} agency={props.agency} handleChange={setPending} for='edit' />
        );
    }

    function percentFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' %';
    }

    return (
        <>
            <Row className='justify-content-center mt-5'>
                <h3>Assurance</h3>
            </Row>
            {
                data.length == 0 &&
                <Row>
                    <Col md={12} className='text-center'>
                        <EditOrCreateAssurance agency={props.agency} taxe_type_id={props.type.id} handleChange={setPending} />
                    </Col>
                </Row>
            }
            <Row className='justify-content-center mt-3 noPadding'>
                <Col xs={12} lg={8}>
                    <BootstrapTable data={data}>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} isKey dataFormat={percentFormatter} dataField='assurance'  width='200'>Pourcentage assurance</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataField='tourism'  width='150'>Taxe séjour</TableHeaderColumn>
                        <TableHeaderColumn style={{ overflow:"scroll", textAlign:"center" }} dataFormat={EditFormateur} width='100'>Modifier</TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </Row>
        </>
    )
}