import { Card, Col, Row, Badge, Button } from 'react-bootstrap';
import AskConfirmCancel from './askConfirmCancel';

export default function BookingCard(props) {
    const beginDate = new Date(props.booking.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(props.booking.end_date_hour.replace(/\s/, 'T'));
    var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }
    return (
        <Col md={6} xs={12}>
            <Card>
                <Card.Header>
                    <Row className='d-flex justify-content-between'>
                        <Col xs={8} className='noPadding'> Reference : {props.booking.reference}</Col>
                        {props.booking.site_tiers &&
                            <Col xs={3} className='noPadding'>
                                <Badge variant={'info'}>{props.booking.site_tiers}</Badge>
                            </Col>}
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={6} className='text-center'>
                            <p className="apartmentText">Loyer: <strong>{(props.booking.rent).toFixed(2)} €</strong></p>
                        </Col>
                        <Col xs={6} className='text-center'>
                            <p className="apartmentText">Charges: <strong>{(props.booking.EDF).toFixed(2)} €</strong></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <p className="apartmentText text-center">Début : {beginDate.toLocaleDateString('fr-CA', options)}</p>
                        </Col>
                        <Col xs={6}>
                            <p className="apartmentText text-center">Fin : {endDate.toLocaleDateString('fr-CA', options)}</p>
                        </Col>
                        <Col xs={12}>
                            <p className="apartmentText text-center">Nuitées : {
                                getDifferenceInDays(beginDate, endDate)}</p>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row className='text-center'>
                        <Col xs={6}>
                            <AskConfirmCancel type={'cancel'} booking={props.booking} handleChange={props.handleChange} />
                        </Col>
                        <Col xs={6}>
                            <AskConfirmCancel type={'confirm'} booking={props.booking} handleChange={props.handleChange} />
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>
    )
}