import { createSlice } from '@reduxjs/toolkit';
import instanceOfAxios from '../../../../app/axios/axios';
import { FetchResultatAdmin, hideCreate } from '../bookingTabSlice';
import { show } from '../../Alerter/alerterSlice';
import { resetDate } from '../../../searchBar/dateRange/dateRangeSlice';

export const createBookingAdminSlice = createSlice({
    name: 'createBookingAdmin',
    initialState: {
        isSubmit: false,
        param: {},
        error: {},
        succes: false,
        selectedAppartment: undefined,
        valueUser: undefined,
        valueContact: undefined,
        apartments: [],
        users: [],
        siteTiers: {},
        simulated: undefined,
        pending: true
    },
    reducers: {
        setIsSubmit: (state, value) => {
            state.isSubmit = value.payload
        },
        setError: (state, value) => {
            state.error = value.payload;
        },
        changeValue: (state, value) => {
            state.param[value.payload.type] = value.payload.payload
        },
        addError: (state, value) => {
            state.error[value.payload.type] = value.payload.payload
        },
        resetParam: state => {
            state.param = {};
            state.valueUser = undefined;
            state.selectedAppartment = undefined;
            state.valueContact = undefined;
            state.simulated = undefined;
        },
        setParam: (state, value) => {
            state.param = value.payload;
        },
        setDropDownValue: (state, value) => {
            state.dropDownValue = value.payload
        },
        setApartments: (state, value) => {
            state.apartments = value.payload;
        },
        setAppartment: (state, value) => {
            state.selectedAppartment = value.payload;
        },
        setUser: (state, value) => {
            state.valueUser = value.payload;
        },
        setContact: (state, value) => {
            state.valueContact = value.payload;
        },
        setSiteTiers: (state, value) => {
            state.siteTiers = value.payload;
        },
        setSimulated: (state, value) => {
            state.simulated = value.payload;
        },
        setPending: (state, value) => {
            state.pending = value.payload;
        }
    },
});

export const { setApartments, setPending, setSimulated, setIsSubmit, setError, changeValue, addError, resetParam, setParam, setDropDownValue, setContact, setAppartment, setUser, setSiteTiers } = createBookingAdminSlice.actions;

export function handleChange(event) {
    event.preventDefault();
    return function (dispatch) {
        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                dispatch(changeValue({ type: event.target.name, payload: 1 }));
            } else {
                dispatch(changeValue({ type: event.target.name, payload: 0 }));
            }
        } else if (event.target.value !== undefined) {
            dispatch(changeValue({ type: event.target.name, payload: event.target.value }));
        } else {
            dispatch(changeValue({ type: event.target.name, payload: event.target.id }));
            if (event.target.getAttribute('name') === 'apartment') {
                dispatch(setAppartment(event.target.getAttribute('eventkey')));
            } else if (event.target.getAttribute('name') === 'account_id') {
                dispatch(setUser(event.target.getAttribute('eventkey')));
            } else {
                dispatch(setContact(event.target.getAttribute('eventkey')));
            }
        }
    }
}

export function simulate(params) {
    return function (dispatch) {
        let parameter = new FormData();
        setFormData(parameter, params);
        instanceOfAxios.post('/apartment/' + params.apartment + '/simulateBooking', parameter)
            .then((res) => {
                dispatch(setSimulated(res.data.succes));
            })
            .catch((error) => {
                let message = 'Erreur lors de l\'action';
                if (error.response.data.error === 'non disponible') {
                    message = 'Propriété déjà réservé pour ces dates';
                }
                dispatch(show({ message: message, variant: 'danger' }));
                
            })
    }
}

function setFormData(formData, params) {
    Object.keys(params).forEach((key) => {
        if (key === 'begin_date' || key === 'end_date') {
            let date = new Date(params[key]);
            formData.set(key + '_hour', date.toUTCString() || undefined);
        } else {
            formData.set(key, params[key] || undefined);
        }
    });
}

export function handleSubmit(params) {
    return function (dispatch) {
        dispatch(setIsSubmit(true));
        dispatch(setError({}));
        let parameter = new FormData();
        setFormData(parameter, params);
        instanceOfAxios.post('/apartment/' + params.apartment + '/booking', parameter)
            .then((res) => {
                dispatch(setIsSubmit(false));
                dispatch(resetParam());
                dispatch(FetchResultatAdmin());
                dispatch(hideCreate());
                dispatch(resetDate());
            }).catch((error) => {
                let message = 'Une erreur est survenue lors de l\'enregistrement de la réservation'
                if (error.response.data.error === 'contact sans adresse') {
                    message = 'Vous ne pouvez pas créer de réservation pour ce contact car il ne possède pas d\'adresse'
                }
                dispatch(show({ message: message, variant: 'danger' }));
                dispatch(handleError(error));
            });
    }
}

export function handleError(error) {
    return function (dispatch) {
        dispatch(setIsSubmit(false));
        Object.keys(error.response.data.error).forEach(function (key) {
            dispatch(addError({ type: key, payload: error.response.data.error[key] }));
        });
    }
}

export const getSearchResult = state => state.createBookingAdmin.resultFromSearch;
export const getError = state => state.createBookingAdmin.error;
export const getParam = state => state.createBookingAdmin.param;
export const getAppartment = state => state.createBookingAdmin.selectedAppartment;
export const getApartments = state => state.createBookingAdmin.apartments;
export const getUser = state => state.createBookingAdmin.valueUser;
export const getIsSubmit = state => state.createBookingAdmin.isSubmit;
export const getContact = state => state.createBookingAdmin.valueContact;
export const getSiteTiers = state => state.createBookingAdmin.siteTiers;
export const getSimulated = state => state.createBookingAdmin.simulated;
export const getPending = state => state.createBookingAdmin.pending;

export default createBookingAdminSlice.reducer;
