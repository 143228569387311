import React, { useEffect, useState } from 'react';
import {Col, Modal, Row} from 'react-bootstrap';
import { BiCookie } from 'react-icons/bi';
import { AccepteCookies, RefuseCookies, VerifyCookies } from './CookiesFunction';
import './cookies.scss';

export default function CookiesCountry() {
    const [showModal, setShowModal] = useState(false); // Gère la visibilité du modal

    // Vérifiez si le cookie 'country' existe
    useEffect(() => {
        const country = getCookie('country');
        if (!country) {
            setShowModal(true); // Affiche le popup si aucun cookie n'est défini
        }
    }, []);

    function handleChoice(country) {
        // Enregistrez la version dans un cookie
        document.cookie = `country=${country}; path=/`;
        if(country === "suisse"){
            window.location.reload(); // Recharger la page
        }
        setShowModal(false); // Fermez le modal
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null; // Retourne null si le cookie n'existe pas
    }

    return (
        <Modal
            backdropClassName="backdropCountry"
            show={showModal}
            onHide={() => setShowModal(false)}
            style={{ zIndex: '20000' }}
            centered
        >
            <Modal.Header>
                <Modal.Title>Choisissez votre destination</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <p>Veuillez sélectionner la destination vers laquelle vous voulez vous rendre :</p>
                <div className="d-flex justify-content-around">
                    <button
                        className="btn btn-primary"
                        onClick={() => handleChoice('france')}
                    >
                        France
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => handleChoice('suisse')}
                    >
                        Suisse
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}