import { useState } from 'react';
import {
    changeNbPerson
  } from '../searchBarSlice';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import './selectCustom.scss';

export default function SelectCustom(props){
    const [selected, setSelected] = useState(1);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    return (
        <div className='selectNbPerson'>
            <div onClick={() => setVisible(!visible)}>
                {selected} 
                <IoIosArrowDown className={visible ? 'arrow animUp' : 'arrow animDown'}/>
            </div>
            <ul className={visible ? 'listNbPerson optionVisible' : 'optionInvisible'} >
                {
                    props.nbPerson.map(
                        nb => 
                            <li className="optionNbPerson" 
                                value={nb} 
                                key={nb} 
                                name={props.name}
                                onClick={(e) => {
                                props.handleChange ? props.handleChange(e) : dispatch(changeNbPerson(e.target.value));
                                setSelected(e.target.value)
                                setVisible(false);
                            }}>
                                {nb}
                            </li>
                        )
                }
            </ul>
        </div>
    )
}